import React, { useState } from "react";
import "../PatientProfile.css";
import Demographics from "./Demographics";
import Insurance from "./Insurance";
import Contact from "./Contact";
import { useSelector } from "react-redux";
function Tabs({ patientDetails, handleGetSidePatient }) {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [activeTab, setActiveTab] = useState("Demographics");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="d-flex flex-column h-100 overflow-hidden scrollable">
      <div className="demo-bg">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className={`nav-link ${
                activeTab === "Demographics" ? "active" : ""
              } mb-2`}
              id="nav-Demographics-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-Demographics"
              type="button"
              role="tab"
              aria-controls="nav-Demographics-tab"
              aria-selected={activeTab === "Demographics"}
              onClick={() => handleTabClick("Demographics")}
            >
              Demographics
            </button>

            {permissions.insurance_tab && (
              <button
                className={`nav-link ${
                  activeTab === "Insurance" ? "active" : ""
                } mb-2`}
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-Insurance"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected={activeTab === "Insurance"}
                onClick={() => handleTabClick("Insurance")}
              >
                Insurance
              </button>
            )}
            {/* {permissions.contact_tab && (
            <button
              className={`nav-link ${activeTab === 'Contact' ? 'active' : ''} mb-2`}
              id="nav-contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-contact"
              type="button"
              role="tab"
              aria-controls="nav-contact"
              aria-selected={activeTab === 'Contact'}
              onClick={() => handleTabClick('Contact')}
            >
              Contact
            </button>
          )} */}
            {/* Add more buttons here if needed */}
          </div>
        </nav>
      </div>
      {/* Render corresponding component based on activeTab state */}

      {activeTab === "Demographics" && (
        <Demographics
          details={patientDetails}
          handleGetSidePatient={handleGetSidePatient}
        />
      )}
      {activeTab === "Insurance" && (
        <Insurance id={patientDetails.id} details={patientDetails} />
      )}
      {activeTab === "Contact" && <Contact id={patientDetails.id} />}
    </div>
  );
}

export default Tabs;
