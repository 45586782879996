import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store,persistor } from './components/redux/Store';
import { AclStore } from './components/redux/ACLStore';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { StyledEngineProvider } from '@mui/material';
ReactDOM.createRoot(document.getElementById('root')).render(
  <StyledEngineProvider injectFirst>
  <Provider store={store || AclStore}>
    <PersistGate loading={null} persistor={persistor}>
  <App />
  </PersistGate>
  </Provider>
  </StyledEngineProvider>

);
