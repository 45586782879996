import React, { useState, useEffect } from "react";
import "../PatientProfile.css";
import axios from "axios";
import AddMedication from "./AddMedication";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import NoData from "../../../../assets/images/no-data-found.png";

import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
function Medications({ id, handleMedicationCreated }) {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [medications, setMedications] = useState([]);
  const [showMedication, setShowMedication] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [showEditMedication, setShowEditMedication] = useState(false);
  const [discountinueDate, setDiscountinueDate] = useState("");
  const [discountinueReason, setDiscountinueReason] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);
  const [statusId, setStatusId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getMedications = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const medications = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-medication/${id}`,
        config
      );
      setMedications(medications.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const StatusChange = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const medications = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/change-status-medication`,
        {
          medication_id: statusId,
          discountinue_date: discountinueDate,
          discountinue_reason: discountinueReason,
        },
        config
      );
      setMedications(medications.data);
      getMedications();
      setStatusId(null);
      setShow(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getMedications();
  }, []);

  const handleMenuOpen = (event, medication) => {
    setSelectedMedication(medication);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  console.log("medications", medications);
  const handleCloseMedicationClick = () => {
    setShowMedication(false);
    getMedications();
  };
  const handleInactiveClick = () => {
    // Implement the logic for marking medication as inactive
    handleMenuClose();
  };

  const handleCloseEditMedicationClick = () => {
    setShowEditMedication(false);
    getMedications();
  };

  const handleEditClick = (medication) => {
    setSelectedMedication(medication);
    setShowEditMedication(true);
  };

  const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
  }));

  console.log("medicationdata", medications);
  return (
    <div>
      <div className="d-flex flex-column h-100 problem-container">
        {showMedication ? (
          <AddMedication
            id={id}
            handleCloseMedicationClick={handleCloseMedicationClick}
            handleMedicationCreated={handleMedicationCreated}
          />
        ) : showEditMedication ? (
          <AddMedication
            id={id}
            handleCloseEditMedicationClick={handleCloseEditMedicationClick}
            medication={selectedMedication}
            handleMedicationCreated={handleMedicationCreated}
          />
        ) : (
          <>
            <div className="medication-links d-flex align-items-center">
              <h5>Active Medication</h5>
              {permissions.medication_add && (
                <div className="btn-group me-3 ms-auto Medications-btn-add">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => setShowMedication(true)}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ paddingRight: "5px" }}
                    />
                    Medication
                  </button>
                </div>
              )}
            </div>
            {permissions.medication_active_table && (
              <>
                <StyledTable className="encounter-table table-responsive table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="left">Visit Reason/Case</TableCell> */}
                      <TableCell align="left" style={{ paddingLeft: "5px" }}>
                        Date
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Dose</TableCell>
                      <TableCell align="left">Unit</TableCell>
                      <TableCell align="left">Quantity</TableCell>
                      <TableCell align="left">Route</TableCell>
                      <TableCell align="left">Frequency</TableCell>
                      <TableCell align="left">Start Date</TableCell>
                      <TableCell align="left">End Date</TableCell>
                      <TableCell align="left">Quantity Left</TableCell>
                      <TableCell align="left">Refills Left</TableCell>
                      {permissions.medication_action_col && (
                        <TableCell align="left">Action</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <tr>
                        <td colSpan="12">
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : medications.active_medications &&
                      medications.active_medications.length > 0 ? (
                      medications.active_medications
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((medication, index) => (
                          <TableRow key={index}>
                            {console.log("medication", medication)}
                            <TableCell style={{ paddingLeft: "5px" }}>
                              {moment(medication.created_at)
                                .utc()
                                .format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>{medication.title}</TableCell>
                            <TableCell>{medication.dose}</TableCell>
                            <TableCell>{medication.dosage_unit}</TableCell>
                            <TableCell>{medication.quantity}</TableCell>
                            <TableCell>{medication.route}</TableCell>
                            <TableCell>{medication.frequency}</TableCell>

                            <TableCell style={{ paddingLeft: "5px" }}>
                              {moment(medication.begin_date)
                                // .utc()
                                .format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell style={{ paddingLeft: "5px" }}>
                              {medication.end_date
                                ? moment(medication.end_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : "N/A"}
                            </TableCell>
                            {/* <TableCell><IconButton onClick={()=>handleEditClick(medication)}><FontAwesomeIcon icon={faEdit}/></IconButton></TableCell> */}
                            <TableCell>{medication.quantity_left}</TableCell>
                            <TableCell>{medication.refills}</TableCell>
                            {permissions.medication_action_col && (
                              <TableCell align="left">
                                <div className="avatar dropdown">
                                  <div
                                    className=" ms-4 dropdown-toggle no-arrow-dropdown"
                                    data-bs-toggle="dropdown"
                                  >
                                    <a>
                                      <FontAwesomeIcon
                                        icon={faEllipsisV}
                                        className="  ms-2"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </a>
                                  </div>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton2"
                                  >
                                    {permissions.medication_edit_view && (
                                      <li>
                                        <a className="dropdown-item active">
                                          <p
                                            style={{
                                              color: "white",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleEditClick(medication)
                                            }
                                          >
                                            Edit
                                          </p>
                                        </a>
                                      </li>
                                    )}

                                    {permissions.medication_inactive && (
                                      <li>
                                        <span
                                          className="dropdown-item"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setStatusId(medication.id);
                                            setShow(true);
                                          }}
                                        >
                                          Marked As Inactive
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan="12" align="center">
                          <img
                            src={NoData}
                            alt="No data found"
                            style={{ width: "150px", margin: "20px 0" }}
                          />
                          <p>No active medications found.</p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </StyledTable>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={
                    medications.active_medications
                      ? medications.active_medications.length
                      : 0
                  }
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header className="allery-header">
                <Modal.Title>Discontinue Reason</Modal.Title>
              </Modal.Header>
              <Modal.Body className="allergy-body">
                <div className="form-floating mb-4 ms-1 w-100">
                  <input
                    type="date"
                    className="form-control w-100"
                    id="discountinue_date"
                    placeholder="discountinue_date"
                    value={discountinueDate}
                    name="discountinue_date"
                    onChange={(e) => setDiscountinueDate(e.target.value)}
                  />
                  <label htmlFor="discountinue_date">Discontinue Date</label>
                </div>

                <div className="form-floating mb-4 ms-1 w-100">
                  <textarea
                    type="text"
                    style={{ height: "6rem" }}
                    className="form-control w-100"
                    id="discountinue_reason"
                    placeholder="discountinue_reason"
                    value={discountinueReason}
                    name="discountinue_reason"
                    onChange={(e) => setDiscountinueReason(e.target.value)}
                  />
                  <label htmlFor="discountinue_reason">
                    Discontinue Reason
                  </label>
                </div>
              </Modal.Body>
              <Modal.Footer className="allergy-footer-btn">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={StatusChange}>
                  Save{" "}
                </Button>
              </Modal.Footer>
            </Modal>
            {permissions.medication_past_table && (
              <>
                <div className="medication-links d-flex align-items-center mt-3">
                  <h3>Past Medication</h3>
                </div>

                <StyledTable className="encounter-table table-responsive table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="left">Visit Reason/Case</TableCell> */}
                      <TableCell align="left" style={{ paddingLeft: "5px" }}>
                        Date
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Dose</TableCell>
                      <TableCell align="left">Unit</TableCell>
                      <TableCell align="left">Quantity</TableCell>
                      <TableCell align="left">Route</TableCell>
                      <TableCell align="left">Frequency</TableCell>
                      <TableCell align="left">Start Date</TableCell>
                      <TableCell align="left">End Date</TableCell>
                      <TableCell align="left">Quantity Left</TableCell>
                      <TableCell align="left">Refills Left</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <tr>
                        <td colSpan="12">
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : medications.inactive_medications &&
                      medications.inactive_medications.length > 0 ? (
                      medications.inactive_medications
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((medication, index) => (
                          <TableRow key={index}>
                            {console.log("medication", medication)}
                            <TableCell style={{ paddingLeft: "5px" }}>
                              {moment(medication.created_at)
                                .utc()
                                .format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell>{medication.title}</TableCell>
                            <TableCell>{medication.dose}</TableCell>
                            <TableCell>{medication.dosage_unit}</TableCell>
                            <TableCell>{medication.quantity}</TableCell>
                            <TableCell>{medication.route}</TableCell>
                            <TableCell>{medication.frequency}</TableCell>

                            <TableCell style={{ paddingLeft: "5px" }}>
                              {moment(medication.begin_date)
                                // .utc()
                                .format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell style={{ paddingLeft: "5px" }}>
                              {medication.end_date
                                ? moment(medication.end_date).format(
                                    "MM/DD/YYYY"
                                  )
                                : "N/A"}
                            </TableCell>
                            {/* <TableCell><IconButton onClick={()=>handleEditClick(medication)}><FontAwesomeIcon icon={faEdit}/></IconButton></TableCell> */}
                            <TableCell>{medication.quantity_left}</TableCell>
                            <TableCell>{medication.refills}</TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan="12" align="center">
                          <img
                            src={NoData}
                            alt="No data found"
                            style={{ width: "150px", margin: "20px 0" }}
                          />
                          <p>No Inactive medications found.</p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </StyledTable>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={
                    medications.inactive_medications
                      ? medications.inactive_medications.length
                      : 0
                  }
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // nextIconButtonProps={{ "aria-label": "Next Page" }}
                  // backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Medications;
