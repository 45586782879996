import React from "react";
import "../PatientProfile.css";
import axios from "axios";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { useSelector } from "react-redux";

const calculateDischargeDate = (admissionDate) => {
  const date = new Date(admissionDate);
  date.setDate(date.getDate() + 90);
  return date;
};

const getCurrentLocalDateTime = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate;
};

const initialValue = {
  admission_date: new Date(),
  discharge_date: calculateDischargeDate(new Date()),
  admission_location: "",
  room_no: "",
  patient_type: "",
  admission_type: "",
  admission_service: "",
  new_admission: false,
  pan_patient: false,
  patient_account_number: "",
  discharge_date: "",
  new_room: "",
  discharge_type: "",
  comments: "",
};
const formatDate = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return `${year}-${month}-${day}`;
};
const AdmissionForm = ({
  id,
  disableDischargeForm,
  handleCloseAdmission,
  disableAdmissionForm,
  selectedAdmission,
}) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [facality, setFacility] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [saveAdmissionLoading, setSaveAdmissionLoading] = useState(false);
  const [disabledAdmission, setDisabledAdmission] = useState(false);
  const [bedModal, setBedModal] = useState(false);
  const [loadingBeds, setLoadingbeds] = useState(false);
  const [vacentBeds, setVacentBeds] = useState([]);
  const [assignData, setAssignData] = useState("");
  const [assign, setAssign] = useState(null);
  const [showTransfer, setShowTranfer] = useState(false);

  const formattedSelectedAdmission = selectedAdmission
    ? {
        ...selectedAdmission,
        admission_date: formatDate(selectedAdmission.admission_date),
      }
    : {};
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...initialValue,
      ...formattedSelectedAdmission, // Populate with selected admission data if available
    },
    //   validationSchema:allergyValidation,
    onSubmit: async (values, action) => {
      try {
        setSaveAdmissionLoading(true);
        setDisabledAdmission(true);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        console.log("assignDatasubmt", assignData);

        const response = await axios.post(
          process.env.REACT_APP_ORIGIN_URL + `/store-admission-discharge`,
          {
            patient_id: id,

            bed_id: assignData.bed_id,
            ...values,
          },
          config
        );
        console.log(response);
        toast.success(response.data.message);
        handleCloseAdmission();
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
      } finally {
        setDisabledAdmission(false);
        setSaveAdmissionLoading(false);
      }
      action.resetForm();
    },
  });

  const getFacility = async () => {
    try {
      setOptionsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/patient-encounter-information/${id}`,
        config
      );
      setFacility(response.data);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setOptionsLoading(false);
    }
  };

  const getVacantBeds = async () => {
    try {
      setLoadingbeds(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/admission-vacant-beds`,
        config
      );
      setVacentBeds(response.data.data);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoadingbeds(false);
    }
  };

  const handleCloseBeds = async (bedId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/close-admission`,
        { bed_id: bedId },
        config
      );
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handleAssignBeds = async (bedId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/assign-bed`,
        {
          patient_id: id,
          bed_id: bedId,
        },
        config
      );
      toast.success(response.data.message);
      setAssignData(response.data.assigned_data);
      setAssign(response.data.bed_assigned);
      handleClose();
    } catch (error) {
      console.log("error", error.message);
      toast.error(error.message);
    }
  };

  const handleTransferBeds = async (bedId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/update-patient-bed`,
        {
          patient_id: id,
          bed_id: bedId,
        },
        config
      );
      toast.success(response.data.message);
      setAssignData(response.data.assigned_data);
      handleCloseTranfer();
    } catch (error) {
      console.log("error", error.message);
      toast.error(error.message);
    }
  };
  const handleClose = () => {
    setBedModal(false);
  };

  const handleShowModal = () => {
    setBedModal(true);
    getVacantBeds();
  };
  const handleShowTranfer = () => {
    setShowTranfer(true);
  };
  const handleCloseTranfer = () => {
    setShowTranfer(false);
  };
  useEffect(() => {
    getFacility();
  }, []);

  useEffect(() => {
    if (values.admission_date) {
      setFieldValue(
        "discharge_date",
        calculateDischargeDate(values.admission_date)
      );
    }
  }, [values.admission_date, setFieldValue]);
  useEffect(() => {
    setFieldValue("admission_date", getCurrentLocalDateTime());
  }, []);
  console.log("admission", selectedAdmission);
  console.log("vacantbeds", assignData);
  return (
    <div className="h-100">
      <div className="overflow-auto h-100">
        <div className="vital-heading">
          <div className="d-flex align-items-center">
            <h5>Admission Information </h5>
            <button
              type="button"
              className="btn btn-primary ms-auto"
              onClick={() => {
                handleCloseAdmission();
                handleCloseBeds(assignData.bed_id);
              }}
            >
              {" "}
              Back
            </button>
          </div>
        </div>
        <div className="admission-heading">
          <h5>Admission Information</h5>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <div className="row g-3">
            <div className="col-lg-3">
              <div className="form-floating mb-3">
                <FloatLabel>
                  <Calendar
                    // type="datetime-local"
                    // className="form-control"
                    // id="admission_date"
                    // name="admission_date"
                    // placeholder="Admission Date"
                    value={values.admission_date}
                    onChange={(e) => setFieldValue("admission_date", e.value)}
                    onBlur={handleBlur}
                    dateFormat="mm/dd/yy"
                    showIcon
                    disabled={disableAdmissionForm}
                  />
                  <label htmlFor="admission_date">Admission Date</label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="form-floating mb-3">
                <div>
                  {assign ? (
                    <button
                      type="button"
                      className="btn btn-primary "
                      style={{ color: "#fff", width: "50%" }}
                      onClick={() => {
                        handleShowTranfer();
                        getVacantBeds();
                      }}
                    >
                      Transfer Bed
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary "
                      style={{ color: "#fff", width: "50%" }}
                      onClick={handleShowModal}
                    >
                      Assign Bed
                    </button>
                  )}
                </div>
                <div>
                  {assign ? (
                    <div className="btn-assign">
                      {assignData.floor_name +
                        " , " +
                        assignData.room_name +
                        " , " +
                        assignData.bed_title}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!disableDischargeForm && (
          <>
            <div className="admission-heading">
              <h5>Discharge Information</h5>
            </div>
            <div className="admittion-bg">
              <div className="row g-3">
                <div className="col-lg-3">
                  <select
                    className="form-select"
                    id="discharge_type"
                    name="discharge_type"
                    aria-label="Floating label select example"
                    value={values.discharge_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disableDischargeForm}
                  >
                    <option value="">-Select-</option>
                    <option value="Discharge">Discharge</option>
                    <option value="transfer">Transfer</option>
                  </select>
                </div>
                <div className="col-lg-3">
                  <div className="form-floating mb-3">
                    <input
                      type="date"
                      className="form-control"
                      id="discharge_date"
                      name="discharge_date"
                      placeholder="Discharge Date"
                      value={values.discharge_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disableDischargeForm}
                    />
                    <label htmlFor="discharge_date">Discharge Date</label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="new_room"
                      name="new_room"
                      placeholder="New Room"
                      value={values.new_room}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disableDischargeForm}
                    />
                    <label htmlFor="new_room">New Room</label>
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-lg-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="comments"
                      name="comments"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disableDischargeForm}
                    ></textarea>
                    <label htmlFor="comments">Comments</label>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Modal
          show={bedModal}
          onHide={handleClose}
          dialogClassName="custom-modal-width"
        >
          <Modal.Header className="allery-header">
            <Modal.Title>Assign Bed</Modal.Title>
          </Modal.Header>
          <Modal.Body className="allergy-body">
            {loadingBeds ? (
              <div
                className="spinner-border text-primary"
                role="status"
                tyle={{
                  display: "flex", // Changed to flex for centering
                  justifyContent: "center", // Centers the spinner horizontally
                  alignItems: "center", // Centers the spinner vertically
                  height: "100px", // Gives a fixed height to properly center the spinner
                  width: "100%",
                }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              vacentBeds &&
              vacentBeds.map((beds, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className="btn btn-vacant "
                    style={{
                      flex: "1 1 calc(50% - 10px)", // Two buttons per row
                      margin: "5px 0", // Vertical spacing
                      marginLeft: "10px",
                    }}
                    onClick={() => handleAssignBeds(beds.id && beds.id)}
                  >
                    {beds.floor_name +
                      " , " +
                      beds.room_name +
                      " , " +
                      beds.bed_title}
                  </button>
                );
              })
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={showTransfer}
          onHide={handleCloseTranfer}
          dialogClassName="custom-modal-width"
        >
          <Modal.Header className="allery-header">
            <Modal.Title>Transfer Bed</Modal.Title>
          </Modal.Header>
          <Modal.Body className="allergy-body">
            {loadingBeds ? (
              <div
                className="spinner-border text-primary"
                role="status"
                tyle={{
                  display: "flex", // Changed to flex for centering
                  justifyContent: "center", // Centers the spinner horizontally
                  alignItems: "center", // Centers the spinner vertically
                  height: "100px", // Gives a fixed height to properly center the spinner
                  width: "100%",
                }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              vacentBeds &&
              vacentBeds.map((beds, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className="btn btn-vacant "
                    style={{
                      flex: "1 1 calc(50% - 10px)", // Two buttons per row
                      margin: "5px 0", // Vertical spacing
                    }}
                    onClick={() => handleTransferBeds(beds.id)}
                  >
                    {beds.floor_name +
                      " , " +
                      beds.room_name +
                      " , " +
                      beds.bed_title}
                  </button>
                );
              })
            )}
          </Modal.Body>
        </Modal>
        <div
          // className="vital-heading"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            // backgroundColor: "#E0EEFC",
          }}
        >
          <Button
            variant="secondary"
            onClick={() => {
              handleCloseAdmission();
              handleCloseBeds(assignData.bed_id);
            }}
          >
            Close
          </Button>
          <button
            type="button"
            className="btn btn-primary "
            style={{ marginRight: "0.3rem", marginLeft: "0.3rem" }}
            onClick={handleSubmit}
          >
            {selectedAdmission
              ? "Update"
              : saveAdmissionLoading
              ? "Processing.."
              : "Register"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdmissionForm;
