import React, { useState, useEffect } from "react";
import "../PatientProfile.css";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { vitalValidation } from "../../../../components/validation/RegistrationValidation";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";

const initialVitalValues = {
  blood_pressure: "",
  date: new Date(),
  weight_lbs: "",
  weight_oz: "",
  weight_kg: "",
  height_ft: "",
  height_in: "",
  height_cm: "",
  bmi_kg: "",
  bmi_in: "",
  bsa_cm2: "",
  weightUnit: "lbs",
  heightUnit: "ft",
  waistUnit: "cm",
  waist_cm: "",
  systolic: "",
  diastolic: "",
  position: "",
  cuff_size: "",
  cuff_location: "",
  cuff_time: "",
  fasting: "",
  postprandial: "",
  fasting_blood_sugar: "",
  blood_sugar_time: "",
  pulse_result: "",
  pulse_rhythm: "",
  pulse_time: "",
  body_temp_result_f: "",
  body_temp_result_c: "",
  body_temp_method: "",
  body_temp_time: "",
  respiration_result: "",
  respiration_pattern: "",
  respiration_time: "",
  saturation: "",
  oxygenation_method: "",
  device: "",
  oxygen_source_1: "",
  oxygenation_time_1: "",
  inhaled_o2_concentration: "",
  oxygen_flow: "",
  oxygen_source_2: "",
  oxygenation_time_2: "",
  peak_flow: "",
  oxygenation_time_3: "",
  office_test_blood_group: "",
  blood_group_date: "",
  office_test_pain_scale: "",
  pain_scale_date: "",
};

const RegisterVitals = ({
  handleCloseRegisterVitalClick,
  patient_id,
  vital,
}) => {
  // const calculateBMI = (weightKg, heightFt) => {
  //   const heightInMeters = heightFt * 12 * 0.0254;
  //   return (weightKg / (heightInMeters * heightInMeters)).toFixed(2);
  // };
  const [weightUnit, setWeightUnit] = useState("lbs");
  const [heightUnit, setHeightUnit] = useState("ft");
  const [waistUnit, setWaistUnit] = useState("cm");

  const calculateBMI = (weight, height) => {
    if (weightUnit === "kg" && heightUnit === "cm") {
      const heightInMeters = height / 100;
      return (weight / (heightInMeters * heightInMeters)).toFixed(2);
    } else if (weightUnit === "lbs" && heightUnit === "ft") {
      const heightInInches = height * 12;
      const heightInMeters = heightInInches * 0.0254;
      const weightInKg = weight * 0.453592;
      return (weightInKg / (heightInMeters * heightInMeters)).toFixed(2);
    }
    return "";
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: vital || initialVitalValues,
    validationSchema: vitalValidation,
    onSubmit: async (values, action) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        };
        const apiEndpoint = vital ? "/update-vital" : "/store-vital";
        const response = await axios.post(
          `${process.env.REACT_APP_ORIGIN_URL}${apiEndpoint}`,
          {
            patient_id: patient_id,
            ...values,
            // blood_pressure:values.blood_pressure,
            // date:values.date,
            // weight_lbs:values.weight_lbs,
            // weight_oz:values.weight_oz,
            // weight_kg:values.weight_kg,
            // height_ft:values.height_ft,
            // height_in:values.height_in,
            // height_cm:values.height_cm,
            // bmi_kg:values.bmi_kg,
            // bmi_in:values.bmi_in,
            // bsa_cm2:values.bsa_cm2,
            // waist_cm:values.waist_cm,
            // systolic:values.systolic,
            // diastolic:values.diastolic,
            // position:values.position,
            // cuff_size:values.cuff_size,
            // cuff_location:values.cuff_location,
            // cuff_time:values.cuff_time,
            // fasting:values.fasting,
            // postprandial:values.postprandial,
            // fasting_blood_sugar:values.fasting_blood_sugar,
            // blood_sugar_time:values.blood_sugar_time,
            // pulse_result:values.pulse_result,
            // pulse_rhythm:values.pulse_rhythm,
            // pulse_time:values.pulse_time,
            // body_temp_result_f:values.body_temp_result_f,
            // body_temp_result_c:values.body_temp_result_c,
            // body_temp_method:values.body_temp_method,
            // body_temp_time:values.body_temp_time,
            // respiration_result:values.respiration_result,
            // respiration_pattern:values.respiration_pattern,
            // respiration_time:values.respiration_time,
            // saturation:values.saturation,
            // oxygenation_method:values.oxygenation_method,
            // device:values.device,
            // oxygen_source_1:values.oxygen_source_1,
            // oxygenation_time_1:values.oxygenation_time_1,
            // inhaled_o2_concentration:values.inhaled_o2_concentration,
            // oxygen_flow:values.oxygen_flow,
            // oxygen_source_2:values.oxygen_source_2,
            // oxygenation_time_2:values.oxygenation_time_2,
            // peak_flow:values.peak_flow,
            // oxygenation_time_3:values.oxygenation_time_3,
            // office_test_blood_group:values.office_test_blood_group,
            // blood_group_date:values.blood_group_date,
            // office_test_pain_scale:values.office_test_pain_scale,
            // pain_scale_date:values.pain_scale_date
          },
          config
        );
        toast.success(response.data.message);
        handleCloseRegisterVitalClick();
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
      }
      action.resetForm();
    },
  });

  const handleWeightHeightChange = (e) => {
    console.log("handleWeightHeightChange called");
    const { name, value } = e.target;
    handleChange(e); // Handle generic formik changes

    // Calculate BMI only if weight or height fields change
    if (name === "weight_lbs" || name === "height_ft") {
      const newWeightKg = name === "weight_lbs" ? value : values.weight_lbs;
      const newHeightFt = name === "height_ft" ? value : values.height_ft;

      if (newWeightKg && newHeightFt) {
        const bmi = calculateBMI(newWeightKg, newHeightFt);
        console.log("bmi", bmi);
        setFieldValue("bmi_kg", bmi); // Update BMI field in formik state
      }
    }
  };

  useEffect(() => {
    if (vital) {
      resetForm({ values: vital });
    }
  }, [vital, resetForm]);
  console.log("vital", vital);
  return (
    <div>
      <div className="overflow-auto h-100">
        <div className="vital-heading ">
          <div className="d-flex align-items-center">
            <h5>Vitals</h5>

            <button
              type="button"
              className="btn btn-primary ms-auto"
              onClick={handleCloseRegisterVitalClick}
            >
              {" "}
              Back
            </button>
          </div>
        </div>
        <div className="vital-bg mt-2">
          <div className="row g-3">
            <div className="col-4">
              <h5 className="mb-3">Date</h5>
              <div className="form-floating">
                <FloatLabel>
                  <Calendar
                    value={values.date}
                    onChange={(e) => setFieldValue("date", e.value)}
                    onBlur={handleBlur}
                    dateFormat="mm/dd/yy"
                    showIcon
                  />
                  <label htmlFor="date">
                    Date{" "}
                    <span style={{ color: "red", fontSize: "13px" }}>*</span>
                  </label>
                </FloatLabel>
              </div>
              {errors.date && touched.date ? (
                <p style={{ color: "red" }}>{errors.date}</p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="vital-heading-bg">
          <h5>Measurement</h5>
        </div>
        <div className="p-3 vital-forms-bg">
          <div className="row g-3 align-items-center">
            {/* Weight */}
            <div className="col-4">
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <h5>Weight</h5>
                </div>
                <div>
                  <input
                    className="me-1"
                    type="radio"
                    id="weight_lbs"
                    name="weightUnit"
                    value="lbs"
                    checked={weightUnit === "lbs"}
                    onChange={(e) => {
                      setWeightUnit(e.target.value);
                      setFieldValue("weightUnit", e.target.value);
                    }}
                  />
                  <label className="me-2" htmlFor="weight_lbs">
                    lbs
                  </label>

                  <input
                    className="me-1"
                    type="radio"
                    id="weight_kg"
                    name="weightUnit"
                    value="kg"
                    checked={weightUnit === "kg"}
                    onChange={(e) => {
                      setWeightUnit(e.target.value);
                      setFieldValue("weightUnit", e.target.value);
                    }}
                  />
                  <label htmlFor="weight_kg">kg</label>
                </div>
              </div>
              <div className="form-floating w-100">
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  id="weight"
                  name="weight"
                  value={values.weight}
                  onChange={handleWeightHeightChange}
                />
                <label htmlFor="weight">{weightUnit}</label>
              </div>
            </div>

            {/* Height */}
            <div className="col-4">
              <div className="d-flex align-items-center">
                <div>
                  <h5 className="me-2">Height</h5>
                </div>
                <div>
                  <input
                    className="me-1"
                    type="radio"
                    id="height_ft"
                    name="heightUnit"
                    value="ft"
                    checked={heightUnit === "ft"}
                    onChange={(e) => {
                      setHeightUnit(e.target.value);
                      setFieldValue("heightUnit", e.target.value);
                    }}
                  />
                  <label className="me-2" htmlFor="height_ft">
                    ft
                  </label>

                  <input
                    className="me-1"
                    type="radio"
                    id="height_cm"
                    name="heightUnit"
                    value="cm"
                    checked={heightUnit === "cm"}
                    onChange={(e) => {
                      setHeightUnit(e.target.value);
                      setFieldValue("heightUnit", e.target.value);
                    }}
                  />
                  <label htmlFor="height_cm">cm</label>
                </div>
              </div>
              <div className="form-floating w-100">
                <input
                  type="text"
                  className="form-control border-0 shadow-sm"
                  id="height"
                  name="height"
                  value={values.height}
                  onChange={handleWeightHeightChange}
                />
                <label htmlFor="height">{heightUnit}</label>
              </div>
            </div>

            <div className="col-2">
              <div className="vital-cards-form ">
                <h5 className="me-2 mb-1">BMI</h5>
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    id="bmi_kg"
                    placeholder="name@example.com"
                    name="bmi_kg"
                    value={values.bmi_kg}
                    onChange={handleChange}
                  />
                  <label htmlFor="bmi_kg">kg/m2</label>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="vital-cards-form ">
                <h5 className="me-2 mb-1">BSA</h5>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    id="bsa_cm2"
                    placeholder="name@example.com"
                    name="bsa_cm2"
                    value={values.bsa_cm2}
                    onChange={handleChange}
                  />
                  <label htmlFor="bsa_cm2">cm2</label>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="vital-cards-form ">
                <div className="d-flex align-items-center">
                  <div>
                    <h5 className="me-2">Waist</h5>
                  </div>
                  <div className="d-flex">
                    <input
                      className="me-1"
                      type="radio"
                      name="waistUnit"
                      value="cm"
                      checked={waistUnit === "cm"}
                      onChange={() => setWaistUnit("cm")}
                    />
                    <label className="me-2">cm</label>
                    <input
                      className="me-1"
                      type="radio"
                      name="waistUnit"
                      value="inch"
                      checked={waistUnit === "inch"}
                      onChange={() => setWaistUnit("inch")}
                    />
                    <label>inch</label>
                  </div>
                </div>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control border-0 shadow-sm"
                    id={waistUnit === "cm" ? "waist_cm" : "waist_in"}
                    placeholder="Enter waist"
                    name={waistUnit === "cm" ? "waist_cm" : "waist_in"}
                    value={
                      waistUnit === "cm" ? values.waist_cm : values.waist_in
                    }
                    onChange={handleChange}
                  />
                  <label htmlFor="waist">{waistUnit}</label>
                </div>
              </div>
            </div>
            {/* 
            <div className="col-2">
              <div className="vital-cards-form d-flex align-items-center">
                <h5 className="me-2">Waist</h5>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    id="waist_cm"
                    placeholder="name@example.com"
                    name="waist_cm"
                    value={values.waist_cm}
                    onChange={handleChange}
                  />
                  <label htmlFor="waist_cm">cm</label>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="vital-heading-bg">
          <h5>Blood Pressure</h5>
        </div>
        <div className="vital-forms-bg">
          <div className="row g-3 ">
            <div className="col-xxl-2  col-lg-4 col-md-2">
              <div className="vital-cards-form">
                <h5 className="mb-3">Time</h5>
                <div className="form-floating w-100">
                  <input
                    className="form-control  border-0 shadow-sm"
                    type="time"
                    id="cuff_time"
                    name="cuff_time"
                    value={values.cuff_time}
                    onChange={handleChange}
                  />
                  <label htmlFor="cuff_time">Time</label>
                </div>
              </div>
            </div>
            <div className="col-xxl-2 col-lg-4 col-md-2">
              <div className="vital-cards-form">
                <h5 className="mb-3">Blood Pressure</h5>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    id="blood_pressure"
                    placeholder="name@example.com"
                    name="blood_pressure"
                    value={values.blood_pressure}
                    onChange={handleChange}
                  />
                  <label htmlFor="blood_pressure">Blood Pressure</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vital-heading-bg">
          <h5>Blood Sugar</h5>
        </div>
        <div className="vital-forms-bg">
          <div className="row align-items-center">
            <div className="col-lg-4 col-xxl-2">
              <div className="d-flex">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="fasting"
                    id="fasting"
                    value={values.fasting}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="fasting">
                    Fasting
                  </label>
                </div>

                <div className="form-check w-100 d-flex align-items-center">
                  <input
                    className="form-check-input ms-2"
                    type="radio"
                    name="postprandial"
                    id="postprandial"
                    value={values.postprandial}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label ms-2 text-nowrap"
                    htmlFor="postprandial"
                  >
                    Postprandial
                  </label>
                </div>
              </div>
            </div>

            <div className="col-xxl-3 col-lg-5 col-md-4">
              <div className="vital-forms-bg">
                <h5 className="mb-3">Blood Sugar</h5>
                <div className="form-floating w-100">
                  <input
                    className="form-control  border-0 shadow-sm"
                    type="text"
                    placeholder="name@example.com"
                    name="fasting_blood_sugar"
                    id="fasting_blood_sugar"
                    value={values.fasting_blood_sugar}
                    onChange={handleChange}
                  />
                  <label htmlFor="ffasting_blood_sugar">Blood Sugar</label>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Time</h5>
                <div className="form-floating w-100">
                  <input
                    className="form-control  border-0 shadow-sm"
                    type="time"
                    name="blood_sugar_time"
                    id="blood_sugar_time"
                    value={values.blood_sugar_time}
                    onChange={handleChange}
                  />
                  <label htmlFor="blood_sugar_time">Time</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vital-heading-bg">
          <h5>pulse</h5>
        </div>
        <div className="vital-forms-bg">
          <div className="row g-3">
            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Result</h5>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    placeholder="name@example.com"
                    name="pulse_result"
                    id="pulse_result"
                    value={values.pulse_result}
                    onChange={handleChange}
                  />
                  <label htmlFor="pulse_result">bpm</label>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Rhythm</h5>
                <div className="form-floating w-100">
                  <select
                    className="form-select  border-0 shadow-sm "
                    name="pulse_rhythm"
                    id="pulse_rhythm"
                    value={values.pulse_rhythm}
                    onChange={handleChange}
                  >
                    <option selected="">Select</option>
                    <option value="Regular Sinus Rhythm">
                      Regular Sinus Rhythm
                    </option>
                    <option value="Regularly Irregular">
                      Regularly Irregular
                    </option>
                    <option value="Irregularly Regular">
                      Irregularly Regular
                    </option>
                    <option value="rregularly Irregular">
                      Irregularly Irregular
                    </option>
                    <option value="Other">Other</option>
                  </select>
                  <label htmlFor="pulse_rhythm">Rhythm</label>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Time</h5>
                <div className="form-floating w-100">
                  <input
                    type="time"
                    className="form-control  border-0 shadow-sm"
                    placeholder="name@example.com"
                    name="pulse_time"
                    id="pulse_time"
                    value={values.pulse_time}
                    onChange={handleChange}
                  />
                  <label htmlFor="pulse_time">Time</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vital-heading-bg">
          <h5>Body Temperature</h5>
        </div>
        <div className="vital-forms-bg">
          <div className="row">
            <div className="col-lg-2 col-md-2">
              <div className="vital-cards-form">
                <h5 className="mb-3">Result</h5>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    placeholder="name@example.com"
                    name="body_temp_result_f"
                    id="body_temp_result_f"
                    value={values.body_temp_result_f}
                    onChange={handleChange}
                  />
                  <label htmlFor="body_temp_result_f">F</label>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <h5 className="mb-3">C</h5>
              <div className="vital-cards-form">
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    placeholder="name@example.com"
                    name="body_temp_result_c"
                    id="body_temp_result_c"
                    value={values.body_temp_result_c}
                    onChange={handleChange}
                  />
                  <label htmlFor="body_temp_result_c">C</label>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <h5 className="mb-3">Method</h5>
              <div className="vital-cards-form">
                <div className="form-floating w-100">
                  <select
                    className="form-select  border-0 shadow-sm "
                    name="body_temp_method"
                    id="body_temp_method"
                    value={values.body_temp_method}
                    onChange={handleChange}
                  >
                    <option selected="">Select</option>
                    <option value="Axillary">Axillary</option>
                    <option value="Ear">Ear</option>
                    <option value="Oral">Oral</option>
                    <option value="Rectal">Rectal</option>
                    <option value="Temporal">Temporal</option>
                  </select>
                  <label htmlFor="body_temp_method">Method</label>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <h5 className="mb-3">Time</h5>
              <div className="vital-cards-form">
                <div className="form-floating w-100">
                  <input
                    type="time"
                    className="form-control  border-0 shadow-sm"
                    name="body_temp_time"
                    id="body_temp_time"
                    value={values.body_temp_time}
                    onChange={handleChange}
                  />
                  <label htmlFor="body_temp_time">Time</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vital-heading-bg">
          <h5>Respiration</h5>
        </div>
        <div className="vital-forms-bg">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Result</h5>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    id="respiration_result"
                    name="respiration_result"
                    placeholder="name@example.com"
                    value={values.respiration_result}
                    onChange={handleChange}
                  />
                  <label htmlFor="respiration_result">rpm</label>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Pattern</h5>
                <div className="form-floating w-100">
                  <select
                    className="form-select  border-0 shadow-sm "
                    id="respiration_pattern"
                    name="respiration_pattern"
                    value={values.respiration_pattern}
                    onChange={handleChange}
                  >
                    <option selected="">Select</option>
                    <option value="Normal">Normal</option>
                    <option value="Labored">Labored</option>
                    <option value="Wheezing">Wheezing</option>
                    <option value="Tachypnea">Tachypnea</option>
                    <option value="Hyperventilation">Hyperventilation</option>
                    <option value="Bradypnea">Bradypnea</option>
                    <option value="Cheyne Strokes">Cheyne Strokes</option>
                    <option value="Sighing">Sighing</option>
                    <option value="Ataxic">Ataxic</option>
                    <option value="Obtructive">Obtructive</option>
                  </select>
                  <label htmlFor="respiration_pattern">Pattern</label>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Time</h5>
                <div className="form-floating w-100">
                  <input
                    type="time"
                    className="form-control  border-0 shadow-sm"
                    id="respiration_time"
                    name="respiration_time"
                    value={values.respiration_time}
                    onChange={handleChange}
                  />
                  <label htmlFor="respiration_time">Time</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vital-heading-bg">
          <h5>Oxygenation</h5>
        </div>
        <div className="vital-forms-bg">
          <div className="row g-3 mb-3">
            <div className="col-lg-2 col-md-2">
              <div className="vital-cards-form">
                <h5 className="mb-3">Saturation</h5>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    placeholder="name@example.com"
                    id="saturation"
                    name="saturation"
                    value={values.saturation}
                    onChange={handleChange}
                  />
                  <label htmlFor="saturation">%</label>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="vital-cards-form">
                <h5 className="mb-3">Method</h5>
                <div className="form-floating w-100">
                  <select
                    className="form-select  border-0 shadow-sm "
                    name="oxygenation_method"
                    id="oxygenation_method"
                    value={values.oxygenation_method}
                    onChange={handleChange}
                  >
                    <option selected="">Select</option>
                    <option value="Sp02">Sp02</option>
                    <option value="Sa02">Sa02</option>
                  </select>
                  <label htmlFor="oxygenation_method">Select</label>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="vital-cards-form">
                <h5 className="mb-3">Device</h5>
                <div className="form-floating w-100">
                  <input
                    type="text"
                    className="form-control  border-0 shadow-sm"
                    placeholder="name@example.com"
                    name="device"
                    id="device"
                    value={values.device}
                    onChange={handleChange}
                  />
                  <label htmlFor="device">Device</label>
                </div>
              </div>
            </div>
            <div className="col-xxl-2 col-md-2 col-lg-4">
              <div className="vital-cards-form">
                <h5 className="mb-3">Oxygen Source</h5>
                <div className="form-floating w-100">
                  <select
                    className="form-select  border-0 shadow-sm "
                    id="oxygen_source_1"
                    name="oxygen_source_1"
                    value={values.oxygen_source_1}
                    onChange={handleChange}
                  >
                    <option selected="">Select</option>
                    <option value="Room Air">Room Air</option>
                    <option value="Nasal Cannula">Nasal Cannula</option>
                    <option value="Oxygen Mask">Oxygen Mask</option>
                    <option value="Vent">Vent</option>
                    <option value="Trach Mask">Trach Mask</option>
                  </select>
                  <label htmlFor="oxygen_source_1">Select</label>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2">
              <div className="vital-cards-form">
                <h5 className="mb-3">Time</h5>
                <div className="form-floating w-100">
                  <input
                    type="time"
                    className="form-control  border-0 shadow-sm"
                    placeholder="name@example.com"
                    id="oxygenation_time_1"
                    name="oxygenation_time_1"
                    value={values.oxygenation_time_1}
                    onChange={handleChange}
                  />
                  <label htmlFor="oxygenation_time_1">Time</label>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-xxl-3 col-lg-5 col-md-3 ">
                <div className="vital-cards-form">
                  <h5 className="mb-3">Inhaled O2 Concentration</h5>
                  <div className="form-floating w-100">
                    <input
                      type="text"
                      className="form-control  border-0 shadow-sm"
                      placeholder="name@example.com"
                      id="inhaled_o2_concentration"
                      name="inhaled_o2_concentration"
                      value={values.inhaled_o2_concentration}
                      onChange={handleChange}
                    />
                    <label htmlFor="inhaled_o2_concentration">%</label>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-lg-4 col-md-2">
                <div className="vital-cards-form">
                  <h5 className="mb-3">Oxygen Flow</h5>
                  <div className="form-floating w-100">
                    <input
                      type="text"
                      className="form-control  border-0 shadow-sm"
                      placeholder="name@example.com"
                      id="oxygen_flow"
                      name="oxygen_flow"
                      value={values.oxygen_flow}
                      onChange={handleChange}
                    />
                    <label htmlFor="oxygen_flow">L/min</label>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3">
                <div className="vital-cards-form">
                  <h5 className="mb-3">Oxygen Source</h5>
                  <div className="form-floating w-100">
                    <select
                      className="form-select  border-0 shadow-sm "
                      id="oxygen_source_2"
                      name="oxygen_source_2"
                      value={values.oxygen_source_2}
                      onChange={handleChange}
                    >
                      <option selected="">Select</option>
                      <option value="Room Air">Room Air</option>
                      <option value="Nasal Cannula">Nasal Cannula</option>
                      <option value="Oxygen Mask">Oxygen Mask</option>
                      <option value="Vent">Vent</option>
                      <option value="Trach Mask">Trach Mask</option>
                    </select>
                    <label htmlFor="oxygen_source_2">Select</label>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-2">
                <div className="vital-cards-form">
                  <h5 className="mb-3">Time</h5>
                  <div className="form-floating w-100">
                    <input
                      type="time"
                      className="form-control  border-0 shadow-sm"
                      id="oxygenation_time_2"
                      name="oxygenation_time_2"
                      value={values.oxygenation_time_2}
                      onChange={handleChange}
                    />
                    <label htmlFor="oxygenation_time_2">Time</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-3">
              <div className="col-lg-2 col-md-2">
                <div className="vital-cards-form">
                  <h5 className="mb-3">Peak Flow</h5>
                  <div className="form-floating w-100">
                    <input
                      type="text"
                      className="form-control  border-0 shadow-sm"
                      placeholder="name@example.com"
                      id="peak_flow"
                      name="peak_flow"
                      value={values.peak_flow}
                      onChange={handleChange}
                    />
                    <label htmlFor="peak_flow">L/min</label>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-2">
                <div className="vital-cards-form">
                  <h5 className="mb-3">Time</h5>
                  <div className="form-floating w-100">
                    <input
                      type="time"
                      className="form-control  border-0 shadow-sm"
                      id="oxygenation_time_3"
                      name="oxygenation_time_3"
                      value={values.oxygenation_time_3}
                      onChange={handleChange}
                    />
                    <label htmlFor="oxygenation_time_3">Time</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vital-heading-bg">
          <h5>Office Tests</h5>
        </div>
        <div className="vital-forms-bg">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Blood Group</h5>
                <div className="form-floating w-100">
                  <select
                    className="form-select  border-0 shadow-sm "
                    id="office_test_blood_group"
                    name="office_test_blood_group"
                    value={values.office_test_blood_group}
                    onChange={handleChange}
                  >
                    <option selected="">Select</option>
                    <option value="A Positive">A Positive</option>
                    <option value="A Negative">A Negative</option>
                    <option value="B Positive">B Positive</option>
                    <option value="B Negative">B Negative</option>
                    <option value="O Positive">O Positive</option>
                    <option value="O Negative">O Negative</option>
                    <option value="AB Positive">AB Positive</option>
                    <option value="AB Negative">AB Negative</option>
                  </select>
                  <label htmlFor="office_test_blood_group">Select</label>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Test Date</h5>
                <div className="form-floating w-100">
                  <Calendar
                    value={values.blood_group_date}
                    onChange={(e) => setFieldValue("blood_group_date", e.value)}
                    onBlur={handleBlur}
                    dateFormat="mm/dd/yy"
                    showIcon
                    showTime
                    hourFormat="12"
                    hideOnDateTimeSelect="true"
                  />
                  {/* <label htmlFor="blood_group_date">Date</label> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Pain Scale</h5>
                <div className="form-floating w-100">
                  <select
                    className="form-select  border-0 shadow-sm "
                    id="office_test_pain_scale"
                    name="office_test_pain_scale"
                    value={values.office_test_pain_scale}
                    onChange={handleChange}
                  >
                    <option selected="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                  <label htmlFor="office_test_pain_scale">Select</label>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="vital-cards-form">
                <h5 className="mb-3">Test Date</h5>
                <div className="form-floating w-100">
                  <Calendar
                    value={values.pain_scale_date}
                    onChange={(e) => setFieldValue("pain_scale_date", e.value)}
                    onBlur={handleBlur}
                    dateFormat="mm/dd/yy"
                    showIcon
                    showTime
                    hourFormat="12"
                    hideOnDateTimeSelect="true"
                  />
                  {/* <label htmlFor="pain_scale_date">Date</label> */}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary mt-3"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default RegisterVitals;
