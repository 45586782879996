import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import "../PatientList.css";
import pp from "../../../assets/images/avatar.png";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
const ReVisit = ({ onPatientClick }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGetPatients = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/un-sign-patients",
        config
      );
      setPatients(response.data.data); // Accessing the 'data' property of the response
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPatients();
  }, []);
  //   const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //   const totalPages = Math.ceil(patients.length / patientsPerPage);
  //   console.log("patient", patients);
  const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 20, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 20, textTransform: "capitalize" } },
    },
  }));
  console.log("patientsss", patients);
  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            className="css-xn82ks-MuiTable-root"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="patient-info fixed-all-p">
              <TableRow>
                <TableCell align="left">Patient Name</TableCell>
                <TableCell align="left">Member Id</TableCell>
                <TableCell align="left">MRN No</TableCell>
                <TableCell align="left">Facility</TableCell>
                <TableCell align="left">Floor</TableCell>
                <TableCell align="left">Room</TableCell>
                <TableCell align="left">Bed</TableCell>

                <TableCell align="center">Admission Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <tr>
                  <td colSpan="12">
                    <div className="loading-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                patients
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((patient, index) => (
                    <TableRow hover key={index}>
                      <TableCell
                        align="left"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            src={
                              patient.profile_pic ===
                              "https://soulhousing-api.anchorstech.net/public/uploads/placeholder.jpg"
                                ? `https://i.pravatar.cc/150?img=${
                                    Math.floor(Math.random() * 21) + 50
                                  }`
                                : patient.profile_pic
                            }
                            alt="Admin"
                            className="rounded-circle"
                            width={40}
                            height={40}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <h6
                          style={{ cursor: "pointer" }}
                          className="p-2"
                          onClick={() => onPatientClick(patient)}
                          // onClick={() => openPatientDetail(patient)}
                        >
                          {patient.patient_full_name}
                        </h6>
                      </TableCell>
                      <TableCell align="left">{patient.medical_no}</TableCell>
                      <TableCell align="left">{patient.mrn_no}</TableCell>
                      <TableCell align="left">
                        {patient.patient_facility}
                      </TableCell>
                      <TableCell align="left">{patient.floor_name}</TableCell>
                      <TableCell align="left">{patient.room_name}</TableCell>
                      <TableCell align="left">{patient.bed_title}</TableCell>
                      <TableCell align="center">
                        {patient.admission_date
                          ? moment(patient.admission_date)
                              .utc()
                              .format("MM/DD/YYYY ")
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ px: 2 }}
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={patients.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default ReVisit;
