import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MentalExamization = ({
  sectionId,
  patientId,
  handleCloseMentalExamination,
  handleCloseMental,
}) => {
  const [initialData, setInitialData] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    appearance: {
      Kempt: false,
      Unkempt: false,
      Disheveled: false,
      PoorHygiene: false,
      Malodorous: false,
    },
    alert: {
      Yes: false,
      No: false,
    },
    behavior: {
      Normal: false,
      Coorperative: false,
      Restless: false,
      Agitated: false,
      Hostile: false,
      Suspicious: false,
      MotorRetardation: false,
    },
    speech: {
      Verbal: false,
      Normal: false,
      Hyper: false,
      Pressured: false,
      Slow: false,
      Soft: false,
      Slurred: false,
      Mumbled: false,
      Nonverbal: false,
    },
    mood: {
      Euthymic: false,
      Depressed: false,
      Anxious: false,
      Elevated: false,
      Irritable: false,
      Agitated: false,
    },
    affect: {
      Appropriate: false,
      Labile: false,
      Expansive: false,
      Anxious: false,
      Worrisome: false,
      Blunted: false,
      Flat: false,
      Constricted: false,
      Apathetic: false,
    },
    process: {
      Intact: false,
      Circumstantial: false,
      Tangential: false,
      Flight: false,
      Loose: false,
      Perseveration: false,
      Assess: false,
    },
    content: {
      Denied: false,
      Auditory: false,
      Visual: false,
      Tactile: false,
      Olfactory: false,
      Gustatory: false,
      Hypnogogic: false,
      Hypnopompic: false,
      Derealization: false,
      Depersonalization: false,
    },
    delusions: {
      Denied: false,
      Paranoid: false,
      Grandiose: false,
      Somantic: false,
      jealousy: false,
      Nihilistic: false,
    },
    suicidal_ideations: {
      No: false,
      Yes: false,
      Hx: false,
    },
    homicidal_ideations: {
      No: false,
      Yes: false,
      Hx: false,
    },
    aggressions: {
      No: false,
      Yes: false,
      Hx: false,
    },
    Memory_Immediate: {
      Intact: false,
      Adequate: false,
      Partially: false,
    },
    recent: {
      Intact: false,
      Adequate: false,
      Partially: false,
      Impaired: false,
    },
    retention_concentration: {
      Good: false,
      Fair: false,
      Poor: false,
      Hypervigilant: false,
    },
    impulse_control: {
      Adequate: false,
      Inadequate: false,
    },
    sleep: {
      Normal: false,
      Insomnia: false,
      Disturbed: false,
      Hypersomnia: false,
    },
    appetite: {
      Normal: false,
      Increased: false,
      Decreased: false,
      Refusing: false,
    },
    judgment: {
      Adequate: false,
      Moderately: false,
      Impaired: false,
    },
    insight: {
      Adequate: false,
      Moderately: false,
      Impaired: false,
    },
    // Add more categories as needed
  });

  useEffect(() => {
    handleInitialData();
  }, []);

  const handleInitialData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/get-details-mental/${sectionId}/${patientId}`,
        config
      );
      const data = response.data.data;

      // Set initial checkbox values based on API data
      setCheckboxValues({
        appearance: {
          Kempt: data.appearance.includes("Kempt"),
          Unkempt: data.appearance.includes("Unkempt"),
          Disheveled: data.appearance.includes("Disheveled"),
          PoorHygiene: data.appearance.includes("PoorHygiene"),
          Malodorous: data.appearance.includes("Malodorous"),
        },
        alert: {
          Yes: data.alert.includes("Yes"),
          No: data.alert.includes("No"),
        },
        behavior: {
          Normal: data.behavior.includes("Normal"),
          Cooperative: data.behavior.includes("Cooperative"),
          Restless: data.behavior.includes("Restless"),
          Agitated: data.behavior.includes("Agitated"),
          Hostile: data.behavior.includes("Hostile"),
          Suspicious: data.behavior.includes("Suspicious"),
          MotorRetardation: data.behavior.includes("MotorRetardation"),
        },
        speech: {
          Verbal: data.speech.includes("Verbal"),
          Normal_Rate: data.speech.includes("Normal_Rate"),
          Hyper_Verbal: data.speech.includes("Hyper_Verbal"),
          Pressured: data.speech.includes("Pressured"),
          Slow: data.speech.includes("Slow"),
          Soft: data.speech.includes("Soft"),
          Slurred: data.speech.includes("Slurred"),
          Mumbled: data.speech.includes("Mumbled"),
          Nonverbal: data.speech.includes("Nonverbal"),
        },
        mood: {
          Euthymic: data.mood.includes("Euthymic"),
          Depressed: data.mood.includes("Depressed"),
          Anxious: data.mood.includes("Anxious"),
          Elevated: data.mood.includes("Elevated"),
          Irritable: data.mood.includes("Irritable"),
          Agitated: data.mood.includes("Agitated"),
        },
        affect: {
          Appropriate: data.affect.includes("Appropriate"),
          Labile: data.affect.includes("Labile"),
          Expansive: data.affect.includes("Expansive"),
          Anxious: data.affect.includes("Anxious"),
          Worrisome: data.affect.includes("Worrisome"),
          Blunted: data.affect.includes("Blunted"),
          Flat: data.affect.includes("Flat"),
          Constricted: data.affect.includes("Constricted"),
          Apathetic: data.affect.includes("Apathetic"),
        },
        process: {
          Intact: data.process.includes("Intact"),
          Circumstantial: data.process.includes("Circumstantial"),
          Tangential: data.process.includes("Tangential"),
          Flight: data.process.includes("Flight"),
          Loose: data.process.includes("Loose"),
          Perseveration: data.process.includes("Perseveration"),
          Assess: data.process.includes("Assess"),
        },
        content: {
          Denied: data.content.includes("Denied"),
          Auditory: data.content.includes("Auditory"),
          Visual: data.content.includes("Visual"),
          Tactile: data.content.includes("Tactile"),
          Olfactory: data.content.includes("Olfactory"),
          Gustatory: data.content.includes("Gustatory"),
          Hypnogogic: data.content.includes("Hypnogogic"),
          Hypnopompic: data.content.includes("Hypnopompic"),
          Derealization: data.content.includes("Derealization"),
          Depersonalization: data.content.includes("Depersonalization"),
        },
        delusions: {
          Denied: data.delusions.includes("Denied"),
          Paranoid: data.delusions.includes("Paranoid"),
          Grandiose: data.delusions.includes("Grandiose"),
          Somantic: data.delusions.includes("Somantic"),
          Jealousy: data.delusions.includes("Jealousy"),
          Nihilistic: data.delusions.includes("Nihilistic"),
        },
        suicidal_ideations: {
          No: data.suicidal_ideations.includes("No"),
          Yes: data.suicidal_ideations.includes("Yes"),
          Hx: data.suicidal_ideations.includes("Hx"),
        },
        homicidal_ideations: {
          No: data.homicidal_ideations.includes("No"),
          Yes: data.homicidal_ideations.includes("Yes"),
          Hx: data.homicidal_ideations.includes("Hx"),
        },
        aggressions: {
          No: data.aggressions.includes("No"),
          Yes: data.aggressions.includes("Yes"),
          Hx: data.aggressions.includes("Hx"),
        },
        Memory_Immediate: {
          Intact: data.Memory_Immediate.includes("Intact"),
          Adequate: data.Memory_Immediate.includes("Adequate"),
          Partially: data.Memory_Immediate.includes("Partially"),
        },
        recent: {
          Intact: data.recent.includes("Intact"),
          Adequate: data.recent.includes("Adequate"),
          Partially: data.recent.includes("Partially"),
          Impaired: data.recent.includes("Impaired"),
        },
        retention_concentration: {
          Good: data.retention_concentration.includes("Good"),
          Fair: data.retention_concentration.includes("Fair"),
          Poor: data.retention_concentration.includes("Poor"),
          Hypervigilant: data.retention_concentration.includes("Hypervigilant"),
        },
        impulse_control: {
          Adequate: data.impulse_control.includes("Adequate"),
          Inadequate: data.impulse_control.includes("Inadequate"),
        },
        sleep: {
          Normal: data.sleep.includes("Normal"),
          Insomnia: data.sleep.includes("Insomnia"),
          Disturbed: data.sleep.includes("Disturbed"),
          Hypersomnia: data.sleep.includes("Hypersomnia"),
        },
        appetite: {
          Normal: data.appetite.includes("Normal"),
          Increased: data.appetite.includes("Increased"),
          Decreased: data.appetite.includes("Decreased"),
          Refusing: data.appetite.includes("Refusing"),
        },
        judgment: {
          Adequate: data.judgment.includes("Adequate"),
          Moderately: data.judgment.includes("Moderately"),
          Impaired: data.judgment.includes("Impaired"),
        },
        insight: {
          Adequate: data.insight.includes("Adequate"),
          Moderately: data.insight.includes("Moderately"),
          Impaired: data.insight.includes("Impaired"),
        },

        // affect
        // Add more categories as needed
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const isChecked = (value, category) => {
    return initialData[category] && initialData[category].includes(value);
  };

  const handleCheckboxChange = (value, category) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [category]: {
        ...prevValues[category],
        [value]: !prevValues[category][value],
      },
    }));
  };

  const handleUpdateCheckboxValues = async () => {
    try {
      setSaveLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      // Prepare the data to send via API
      const dataToUpdate = {
        appearance: Object.keys(checkboxValues.appearance).filter(
          (key) => checkboxValues.appearance[key]
        ),
        alert: Object.keys(checkboxValues.alert).filter(
          (key) => checkboxValues.alert[key]
        ),
        behavior: Object.keys(checkboxValues.behavior).filter(
          (key) => checkboxValues.behavior[key]
        ),
        speech: Object.keys(checkboxValues.speech).filter(
          (key) => checkboxValues.speech[key]
        ),
        mood: Object.keys(checkboxValues.mood).filter(
          (key) => checkboxValues.mood[key]
        ),
        affect: Object.keys(checkboxValues.affect).filter(
          (key) => checkboxValues.affect[key]
        ),
        process: Object.keys(checkboxValues.process).filter(
          (key) => checkboxValues.process[key]
        ),
        content: Object.keys(checkboxValues.content).filter(
          (key) => checkboxValues.content[key]
        ),
        delusions: Object.keys(checkboxValues.delusions).filter(
          (key) => checkboxValues.delusions[key]
        ),
        suicidal_ideations: Object.keys(
          checkboxValues.suicidal_ideations
        ).filter((key) => checkboxValues.suicidal_ideations[key]),
        homicidal_ideations: Object.keys(
          checkboxValues.homicidal_ideations
        ).filter((key) => checkboxValues.homicidal_ideations[key]),
        aggressions: Object.keys(checkboxValues.aggressions).filter(
          (key) => checkboxValues.aggressions[key]
        ),
        Memory_Immediate: Object.keys(checkboxValues.Memory_Immediate).filter(
          (key) => checkboxValues.Memory_Immediate[key]
        ),
        recent: Object.keys(checkboxValues.recent).filter(
          (key) => checkboxValues.recent[key]
        ),
        retention_concentration: Object.keys(
          checkboxValues.retention_concentration
        ).filter((key) => checkboxValues.retention_concentration[key]),
        impulse_control: Object.keys(checkboxValues.impulse_control).filter(
          (key) => checkboxValues.impulse_control[key]
        ),
        sleep: Object.keys(checkboxValues.sleep).filter(
          (key) => checkboxValues.sleep[key]
        ),
        appetite: Object.keys(checkboxValues.appetite).filter(
          (key) => checkboxValues.appetite[key]
        ),
        judgment: Object.keys(checkboxValues.judgment).filter(
          (key) => checkboxValues.judgment[key]
        ),
        insight: Object.keys(checkboxValues.insight).filter(
          (key) => checkboxValues.insight[key]
        ),
        // Add more categories as needed
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/update-details-psychiatric`,
        { id: sectionId, dataToUpdate },
        config
      );
      console.log("Updated successfully:", response.data);
      toast.success(response.data.message);
      handleCloseMentalExamination();
      // Optionally handle success feedback or state updates after successful API call
    } catch (error) {
      toast.error(error.message);
      console.log("Error updating:", error.message);
      // Handle error feedback if needed
    } finally {
      setSaveLoading(false);
    }
  };
  return (
    <div
      className="psychiatric-bg"
      style={{ background: "#ECF5FF", padding: "10px" }}
    >
      <div className="psychiatric-mental-headings mt-2">
        <h5>MENTAL STATUS EXAMINATION (MSE)</h5>
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Appearance</h5>
        {Object.keys(checkboxValues.appearance).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.appearance[value]}
              onChange={() => handleCheckboxChange(value, "appearance")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="d-flex align-items-center">
        <div className="psychiatric-mental-headings">
          {/* <h5>Alert</h5> */}
        </div>
        <div className="psychiatric-height ms-auto">
          <table>
            <tr>
              <th>Orientation:</th>
              <td>
                <div classNameName="vital-cards-htmlForm d-flex wound-height  align-items-center ">
                  <div></div>
                  <div>
                    <input type="text" className="" />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="psychiatric-mental-headings row g-3">
        <div className="psychiatric-mental-headings row g-3 mt-2">
          <h5>Alert</h5>
          {Object.keys(checkboxValues.alert).map((value) => (
            <div className="col-4" key={value}>
              <input
                type="checkbox"
                checked={checkboxValues.alert[value]}
                onChange={() => handleCheckboxChange(value, "alert")}
              />
              <label className="chek-margin-left"> {value} </label>
            </div>
          ))}
        </div>
      </div>
      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Behavior</h5>
        {Object.keys(checkboxValues.behavior).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.behavior[value]}
              onChange={() => handleCheckboxChange(value, "behavior")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>
      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Speech</h5>
        {Object.keys(checkboxValues.speech).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.speech[value]}
              onChange={() => handleCheckboxChange(value, "speech")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Mood</h5>
        {Object.keys(checkboxValues.mood).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.mood[value]}
              onChange={() => handleCheckboxChange(value, "mood")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Affect</h5>
        {Object.keys(checkboxValues.affect).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.affect[value]}
              onChange={() => handleCheckboxChange(value, "affect")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Thought process</h5>
        {Object.keys(checkboxValues.process).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.process[value]}
              onChange={() => handleCheckboxChange(value, "process")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Thought Content (Hallucinations)</h5>
        {Object.keys(checkboxValues.content).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.content[value]}
              onChange={() => handleCheckboxChange(value, "content")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Delusions</h5>
        {Object.keys(checkboxValues.delusions).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.delusions[value]}
              onChange={() => handleCheckboxChange(value, "delusions")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Suicidal Ideations</h5>
        {Object.keys(checkboxValues.suicidal_ideations).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.suicidal_ideations[value]}
              onChange={() => handleCheckboxChange(value, "suicidal_ideations")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Homicidal Ideations</h5>
        {Object.keys(checkboxValues.homicidal_ideations).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.homicidal_ideations[value]}
              onChange={() =>
                handleCheckboxChange(value, "homicidal_ideations")
              }
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Hx of Aggression</h5>
        {Object.keys(checkboxValues.aggressions).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.aggressions[value]}
              onChange={() => handleCheckboxChange(value, "aggressions")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Memory Immediate</h5>
        {Object.keys(checkboxValues.Memory_Immediate).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.Memory_Immediate[value]}
              onChange={() => handleCheckboxChange(value, "Memory_Immediate")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Recent</h5>
        {Object.keys(checkboxValues.recent).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.recent[value]}
              onChange={() => handleCheckboxChange(value, "recent")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Retention/Concentration</h5>
        {Object.keys(checkboxValues.retention_concentration).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.retention_concentration[value]}
              onChange={() =>
                handleCheckboxChange(value, "retention_concentration")
              }
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Impulse Control</h5>
        {Object.keys(checkboxValues.impulse_control).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.impulse_control[value]}
              onChange={() => handleCheckboxChange(value, "impulse_control")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Sleep</h5>
        {Object.keys(checkboxValues.sleep).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.sleep[value]}
              onChange={() => handleCheckboxChange(value, "sleep")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Appetite</h5>
        {Object.keys(checkboxValues.appetite).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.appetite[value]}
              onChange={() => handleCheckboxChange(value, "appetite")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Judgment</h5>
        {Object.keys(checkboxValues.judgment).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.judgment[value]}
              onChange={() => handleCheckboxChange(value, "judgment")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>

      <div className="psychiatric-mental-headings row g-3 mt-2">
        <h5>Insight</h5>
        {Object.keys(checkboxValues.insight).map((value) => (
          <div className="col-4" key={value}>
            <input
              type="checkbox"
              checked={checkboxValues.insight[value]}
              onChange={() => handleCheckboxChange(value, "insight")}
            />
            <label className="chek-margin-left"> {value} </label>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          background: "#ecf5ff",
          padding: "20px",
        }}
      >
        <Button
          type="button"
          variant="secondary"
          style={{ marginRight: "5px" }}
          onClick={handleCloseMentalExamination}
        >
          Cancel
        </Button>
        <button
          type="button"
          className="btn btn-primary "
          onClick={handleUpdateCheckboxValues}
          // disabled={signed}
        >
          {saveLoading ? "Processing..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default MentalExamization;
