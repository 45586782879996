import React, { useEffect, useState } from "react";
import "../PatientProfile.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
const PhysicalExam = ({
  sectionId,
  patientId,
  handleClosePhysicalExam,
  handleClosePhysical,
  signed,
}) => {
  console.log("ids", sectionId, patientId);
  const [selectAll, setSelectAll] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    appearance: false,
    skin: false,
    head: false,
    eyes: false,
    ears: false,
    nose: false,
    throat: false,
    neck: false,
    lungs: false,
    heart: false,
    abdomen: false,
    genitourinary: false,
    musculoskeletal: false,
    neurological: false,
    psychiatric: false,
    // cardiovascular: false,
    // system:false,
    // genitourinary: false,
    // endocrine: false,
    // hematologic_lymphatic: false,
    // allergic_immunologic: false,
  });
  const [updatedData, setUpdatedData] = useState({
    appearance: "",
    skin: "",
    head: "",
    eyes: "",
    ears: "",
    nose: "",
    throat: "",
    neck: "",
    lungs: "",
    heart: "",
    abdomen: "",
    genitourinary: "",
    musculoskeletal: "",
    neurological: "",
    psychiatric: "",
    // cardiovascular: "",
    // system:"",
    // chest_lungs: "",
    // endocrine: "",
    // hematologic_lymphatic: "",
    // allergic_immunologic: "",
  });

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setCheckboxes((prevCheckboxes) =>
      Object.keys(prevCheckboxes).reduce((acc, key) => {
        acc[key] = newSelectAll;
        return acc;
      }, {})
    );
  };
  const handleCheckboxChange = (name) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: !prevCheckboxes[name],
    }));
  };

  const handleTextareaChange = (name, event) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: event.target.value,
    }));
  };
  const handleInitialData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/get-details-physical/${sectionId}/${patientId}`,
        config
      );
      const data = response.data.data;
      setInitialData(data);
      setUpdatedData({
        appearance: data.appearance || "",
        skin: data.skin || "",
        head: data.head || "",
        eyes: data.eyes || "",
        ears: data.ears || "",
        nose: data.nose || "",
        throat: data.throat || "",
        neck: data.neck || "",
        lungs: data.lungs || "",
        heart: data.heart || "",
        abdomen: data.abdomen || "",
        genitourinary: data.genitourinary || "",
        musculoskeletal: data.musculoskeletal || "",
        neurological: data.neurological || "",
        psychiatric: data.psychiatric || "",
        // cardiovascular: data.cardiovascular || "",
        // system:data.system || "",
        // endocrine: data.endocrine || "",
        // hematologic_lymphatic: data.hematologic_lymphatic || "",
        // allergic_immunologic: data.allergic_immunologic || "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleInitialData();
  }, []);

  const handleUpdateData = async () => {
    try {
      setSaveLoading(true);
      const payload = {
        id: sectionId,
        ...updatedData,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/update-details-physical`,
        payload,
        config
      );
      if (handleClosePhysicalExam) {
        handleClosePhysicalExam();
      } else {
        handleClosePhysical();
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setSaveLoading(false);
    }
  };

  // useEffect(() => {
  //   setUpdatedData({
  //     general_appearance: initialData.general_appearance,
  //     skin: initialData.skin,
  //     head: initialData.head,
  //     eyes: initialData.eyes,
  //     ears: initialData.ears,
  //     nose: initialData.nose,
  //     mouth_throat: initialData.mouth_throat,
  //     neck: initialData.neck,
  //     chest_lungs: initialData.chest_lungs,
  //     cardiovascular: initialData.cardiovascular,
  //     abdomen: initialData.abdomen,
  //     genitourinary: initialData.genitourinary,
  //     musculoskeletal: initialData.musculoskeletal,
  //     neurological: initialData.neurological,
  //     psychiatric: initialData.psychiatric,
  //     endocrine: initialData.endocrine,
  //     hematologic_lymphatic: initialData.hematologic_lymphatic,
  //     allergic_immunologic: initialData.allergic_immunologic, // corrected typo
  //   });
  // }, [initialData]);
  console.log("initialvalue", initialData);
  return (
    <div className="visit-text-area h-100 ">
      <div className="chief-complaint mt-3">
        <div className="mb-2" style={{ display: "flex" }}>
          <h5>Physical Exam </h5>
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="selectAll"
              onChange={handleSelectAllChange}
              checked={selectAll}
              disabled={signed}
            />
            <label
              className="form-check-label "
              htmlFor="flexCheckDefault"
              style={{ marginLeft: "5px" }}
            >
              Select All
            </label>
          </div>
        </div>
        {Object.keys(updatedData).map((key) => (
          <div key={key}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <input
                  style={{ marginLeft: "15px" }}
                  className="form-check-input mt-3"
                  type="checkbox"
                  id={key}
                  name={key}
                  onChange={() => handleCheckboxChange(key)}
                  checked={checkboxes[key]}
                  disabled={signed}
                />
              </div>
            </div>

            <div className="form-floating mt-1">
              <textarea
                className="form-control"
                name={key}
                value={updatedData[key]}
                onChange={(event) => handleTextareaChange(key, event)}
                disabled={!checkboxes[key] || signed}
              ></textarea>
              <label>{key.replace(/_/g, " ")}</label>
            </div>

            {/* <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="skin"
              name="skin"
              onChange={() => handleCheckboxChange("skin")}
              checked={checkboxes.skin}
            />
          </div>
        </div> */}

            {/* <div className="form-floating mt-1">
          <textarea
            className="form-control"
            name="skin"
            placeholder="Leave a comment here"
            value={updatedData.skin}
            onChange={(event) =>
              handleTextareaChange("skin", event)
            }
            disabled={!checkboxes.skin}
          ></textarea>
          <label>Skin</label>
        </div> */}
          </div>
        ))}
        {/* <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="head"
              name="head"
              onChange={() => handleCheckboxChange("head")}
              checked={checkboxes.head}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="head"
            value={updatedData.head}
            onChange={(event) =>
              handleTextareaChange("head", event)
            }
            disabled={!checkboxes.head}
          ></textarea>
          <label>Head</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="eyes"
              name="eyes"
              onChange={() => handleCheckboxChange("eyes")}
              checked={checkboxes.eyes}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            name="eyes"
            placeholder="Leave a comment here"
            value={updatedData.eyes}
            onChange={(event) =>
              handleTextareaChange("eyes", event)
            }
            disabled={!checkboxes.eyes}
          ></textarea>
          <label>Eyes</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="ears"
              name="ears"
              onChange={() => handleCheckboxChange("ears")}
              checked={checkboxes.ears}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="ears"
            value={updatedData.ears}
            onChange={(event) =>
              handleTextareaChange("ears", event)
            }
            disabled={!checkboxes.ears}
          ></textarea>
          <label>Ears</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="nose"
              name="nose"
              onChange={() => handleCheckboxChange("nose")}
              checked={checkboxes.nose}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="nose"
            value={updatedData.nose}
            onChange={(event) =>
              handleTextareaChange("nose", event)
            }
            disabled={!checkboxes.nose}
          ></textarea>
          <label>nose</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="mouth_throat"
              name="mouth_throat"
              onChange={() => handleCheckboxChange("mouth_throat")}
              checked={checkboxes.mouth_throat}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="mouth_throat"
            value={updatedData.mouth_throat}
            onChange={(event) =>
              handleTextareaChange("mouth_throat", event)
            }
            disabled={!checkboxes.mouth_throat}
          ></textarea>
          <label>Mouth/Throat</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              name="neck"
              id="neck"
              onChange={() => handleCheckboxChange("neck")}
              checked={checkboxes.neck}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            value={updatedData.neck}
            name="neck"
            onChange={(event) =>
              handleTextareaChange("neck", event)
            }
            disabled={!checkboxes.neck}
          ></textarea>
          <label>Neck</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="chest_lungs"
              name="neck"
              onChange={() => handleCheckboxChange("chest_lungs")}
              checked={checkboxes.chest_lungs}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="neck"
            value={updatedData.chest_lungs}
            onChange={(event) =>
              handleTextareaChange("neck", event)
            }
            disabled={!checkboxes.chest_lungs}
          ></textarea>
          <label>Chest/Lungs</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="cardiovascular"
              name="cardiovascular"
              onChange={() => handleCheckboxChange("cardiovascular")}
              checked={checkboxes.cardiovascular}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="cardiovascular"
            value={updatedData.cardiovascular}
            onChange={(event) =>
              handleTextareaChange("cardiovascular", event)
            }
            disabled={!checkboxes.cardiovascular}
          ></textarea>
          <label>Cardiovascular</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="abdomen"
              name="cardiovascular"
              onChange={() => handleCheckboxChange("abdomen")}
              checked={checkboxes.abdomen}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            value={updatedData.abdomen}
            name="cardiovascular"
            onChange={(event) =>
              handleTextareaChange("cardiovascular", event)
            }
            disabled={!checkboxes.abdomen}
          ></textarea>
          <label>Abdomen</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="genitourinary"
              name="genitourinary"
              onChange={() => handleCheckboxChange("genitourinary")}
              checked={checkboxes.genitourinary}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="genitourinary"
            value={updatedData.genitourinary}
            onChange={(event) =>
              handleTextareaChange("genitourinary", event)
            }
            disabled={!checkboxes.genitourinary}
          ></textarea>
          <label>Genitourinary</label>
        </div>
        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="musculoskeletal"
              name="musculoskeletal"
              onChange={() => handleCheckboxChange("musculoskeletal")}
              checked={checkboxes.musculoskeletal}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            value={updatedData.musculoskeletal}
            name="musculoskeletal"
            onChange={(event) =>
              handleTextareaChange("musculoskeletal", event)
            }
            disabled={!checkboxes.musculoskeletal}
          ></textarea>
          <label>Musculoskeletal</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="neurological"
              name="neurological"
              onChange={() => handleCheckboxChange("neurological")}
              checked={checkboxes.neurological}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="neurological"
            value={updatedData.neurological}
            onChange={(event) =>
              handleTextareaChange("neurological", event)
            }
            disabled={!checkboxes.neurological}
          ></textarea>
          <label>Nurological</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="psychiatric"
              name="psychiatric"
              onChange={() => handleCheckboxChange("psychiatric")}
              checked={checkboxes.psychiatric}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            value={updatedData.psychiatric}
            name="psychiatric"
            onChange={(event) =>
              handleTextareaChange("psychiatric", event)
            }
            disabled={!checkboxes.psychiatric}
          ></textarea>
          <label>Psychiatric</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="endocrine"
            name="endocrine"
          
              onChange={() => handleCheckboxChange("endocrine")}
              checked={checkboxes.endocrine}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            name="endocrine"
            value={updatedData.endocrine}
            onChange={(event) =>
              handleTextareaChange("endocrine", event)
            }
            disabled={!checkboxes.endocrine}
          ></textarea>
          <label>Endocrine</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="hematologic_lymphatic"
              name="hematologic_lymphatic"
              onChange={() => handleCheckboxChange("hematologic_lymphatic")}
              checked={checkboxes.hematologic_lymphatic}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            value={updatedData.hematologic_lymphatic}
            name="hematologic_lymphatic"
            onChange={(event) =>
              handleTextareaChange("endocrine", event)
            }
            disabled={!checkboxes.hematologic_lymphatic}
          ></textarea>
          <label>Hematologic/Lymphatic</label>
        </div>

        <div
          className="mt-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="allergic_immunologic"
              name="allergic_immunologic"
              onChange={() => handleCheckboxChange("allergic_immunologic")}
              checked={checkboxes.allergic_immunologic}
            />
          </div>
        </div>

        <div className="form-floating mt-1">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            value={updatedData.allergic_immunologic}
            name="allergic_immunologic"
            onChange={(event) =>
              handleTextareaChange("allergic_immunologic", event)
            }
            disabled={!checkboxes.allergic_immunologic}
          ></textarea>
          <label>Allergic/Immunologic</label>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            background: "#ecf5ff",
            padding: "20px",
          }}
        >
          <Button
            type="button"
            variant="secondary"
            style={{ marginRight: "5px" }}
            onClick={handleClosePhysicalExam || handleClosePhysical}
          >
            Cancel
          </Button>
          <button
            type="button"
            className="btn btn-primary "
            onClick={handleUpdateData}
            disabled={signed}
          >
            {saveLoading ? "Processing..." : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhysicalExam;
