import React from "react";
import { useState, useEffect } from "react";
import "../PatientProfile.css";
import user from "../../../../assets/images/user.png";
import axios from "axios";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WarningLetter from "./WarningLetter";
import { WarningLetterValidation } from "../../../../components/validation/RegistrationValidation";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { useSelector } from "react-redux";
import NoData from "../../../../assets/images/no-data-found.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const getCurrentDateTime = () => {
  const now = new Date();
  return now.toISOString().slice(0, 16);
};
const initialValue = {
  date: new Date(),
  incident_date: "",
  incident_location: "",
  reason: "",
};
const Incidents = ({ details, handleCloseWarningIncident }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [incidents, setIncidents] = useState([]);
  const [showIncidents, setShowIncidents] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(true);
  const [media, setMedia] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getIncidents = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-warnings/${details.id}`,
        config
      );
      setIncidents(res.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: WarningLetterValidation,
    onSubmit: async (values, action) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        const incident = await axios.post(
          process.env.REACT_APP_ORIGIN_URL + "/warning-store",
          {
            patient_id: details.id,
            patient_name: details.patient_full_name,
            date: values.date,
            incident_date: values.incident_date,
            incident_location: values.incident_location,
            reason: values.reason,
            media: media,
          },
          config
        );

        toast.success(incident.message);
        getIncidents();
        handleClose();
      } catch (error) {
        toast.error(error.message);
        console.log(error.message);
      }
      action.resetForm();
    },
  });

  const handleCloseWarningLetter = () => {
    setShowIncidents(false);
    getIncidents();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setMedia(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    getIncidents();
  }, []);

  console.log("details", details);

  return (
    <div>
      {showIncidents ? (
        <WarningLetter
          data={selectedIncident}
          handleCloseWarningLetter={handleCloseWarningLetter}
        />
      ) : (
        <div>
          <div className="document-content d-flex align-items-center">
            <div className="avatar dropdown">
              <div
                className="admin-name ms-4 dropdown-toggle no-arrow-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <a className="all-doc">Warning Letter</a>
              </div>
            </div>

            <div className="ms-auto">
              {/* <Button
            type="button"
            variant="secondary" 
            onClick={handleCloseWarningIncident}
          >
            Close
          </Button> */}
              {permissions.warning_letter_add_button && (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginLeft: "1rem" }}
                  onClick={handleShow}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ paddingRight: "5px" }}
                  />
                  add
                </button>
              )}
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header className="allery-header">
              <Modal.Title>Warning Letter</Modal.Title>
            </Modal.Header>
            <Modal.Body className="allergy-body">
              <div className="row mt-2">
                <div className="col-6">
                  <div className="allery-feilds">
                    <h5 style={{ padding: "0.5rem" }}>Name</h5>
                    <div className="d-flex align-items-center">
                      <div class="form-floating mb-4 ms-1 w-100">
                        <input
                          type="text"
                          class="form-control w-100"
                          id="title"
                          placeholder="title"
                          name="title"
                          value={details.patient_full_name}
                          disabled={disable}
                        />
                        <label htmlFor="title">Name</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="allery-feilds">
                    <h5 style={{ padding: "0.5rem" }}>Date/Time</h5>
                    <div className="d-flex align-items-center">
                      <div class="form-floating mb-4 ms-1 w-100">
                        {/* <input
                      type="datetime-local"
                      class="form-control w-100"
                      id="date"
                      placeholder="date"
                      name="date"
                      value={values.date}
                      disabled={disable}
                    />
                    <label htmlFor="date">Select</label> */}
                        <FloatLabel>
                          <Calendar
                            value={values.date}
                            dateFormat="mm/dd/yy"
                            // showIcon
                            showTime
                            hourFormat="12"
                            disabled={disable}
                          />
                          <label htmlFor="date">Select</label>
                        </FloatLabel>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="allery-feilds">
                    <h5 style={{ padding: "0.5rem" }}>Staff Name</h5>
                    <div className="d-flex align-items-center">
                      <div class="form-floating mb-4 ms-1 w-100">
                        <input
                          type="text"
                          class="form-control w-100"
                          id="title"
                          placeholder="title"
                          name="title"
                          value={details.provider_full_name}
                          disabled={disable}
                        />
                        <label htmlFor="title">Staff Name</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="allery-feilds">
                    <h5 style={{ padding: "0.5rem" }}>
                      Incident Date/Time{" "}
                      <span style={{ color: "red", fontSize: "13px" }}>*</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div class="form-floating mb-4 ms-1 w-100">
                        {/* <input
                      type="datetime-local"
                      class="form-control w-100"
                      id="incident_date"
                      placeholder="incident_date"
                      name="incident_date"
                      value={values.incident_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="incident_date">Select</label> */}
                        <FloatLabel>
                          <Calendar
                            value={values.incident_date}
                            onChange={(e) =>
                              setFieldValue("incident_date", e.value)
                            }
                            onBlur={handleBlur}
                            dateFormat="mm/dd/yy"
                            showIcon
                            showTime
                            hourFormat="12"
                            hideOnDateTimeSelect="true"
                          />
                          <label htmlFor="incident_date">Select</label>
                        </FloatLabel>{" "}
                      </div>
                    </div>
                    {errors.incident_date && touched.incident_date ? (
                      <p style={{ color: "red" }}>{errors.incident_date}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col">
                  <div className="allery-feilds">
                    <h5 style={{ padding: "0.5rem" }}>Incident Location</h5>
                    <div className="d-flex align-items-center">
                      <div class="form-floating mb-4 ms-1 w-100">
                        <input
                          type="text"
                          class="form-control w-100"
                          id="incident_location"
                          placeholder="Incident Location"
                          name="incident_location"
                          value={values.incident_location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label htmlFor="incident_location">Location</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h5 style={{ padding: "0.5rem" }}>Description</h5>
              <div className="form-floating ">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="reason"
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                <label htmlFor="reason">Message</label>
              </div>
              <div className="mt-3">
                <input
                  className="form-control"
                  type="file"
                  id="warningMedia"
                  accept="image/*,video/*"
                  onChange={handleFileChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer className="allergy-footer-btn">
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>

              <Button
                variant="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          {permissions.warning_letter_table_view && (
            <div className="encounter-table  table-responsive">
              <table className="table">
                <thead>
                  <tr className="mb-2">
                    <th>Date</th>
                    <th>Incident</th>
                    <th>Incident Location</th>
                    <th>Subject</th>
                    {permissions.warning_letter_preview_col && <th>Preview</th>}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="12">
                        <div className="loading-spinner">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : incidents && incidents.length > 0 ? (
                    incidents.map((incident, index) => {
                      return (
                        <tr key={index}>
                          {console.log("incidents", incident)}
                          <td>
                            {" "}
                            {incident.date
                              ? moment(incident.date)
                                  .utc()
                                  .format("MM/DD/YYYY hh:mm:ss A")
                              : ""}
                          </td>
                          <td>
                            {" "}
                            {incident.incident_date
                              ? moment(incident.incident_date)
                                  .utc()
                                  .format("MM/DD/YYYY hh:mm:ss A")
                              : ""}
                          </td>
                          <td>{incident.incident_location}</td>
                          <td>{incident.subject}</td>
                          <td>
                            {permissions.warning_letter_preview_col && (
                              <button
                                className="btn btn-disabled"
                                onClick={() => {
                                  setSelectedIncident(incident);
                                  setShowIncidents(true);
                                }}
                              >
                                Preview
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        <img
                          src={NoData}
                          alt="No data found"
                          style={{ width: "150px", margin: "20px 0" }}
                        />
                        <p>No Warning Letter found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ToastContainer />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Incidents;
