import React from "react";
import logo from "../../assets/images/logo-blue.png"; // Adjust the path as necessary
import "./FaceCard.css"; // Adjust the path as necessary
import { Modal, Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import pp from "../../assets/images/avatar.png";
import moment from "moment";
const FaceCard = ({ details }) => {
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
          size: A4;
        }
      `,
    printOpenDelay: 1000,
    documentTitle: "Warning Letter",
  });
  console.log("printDetails", details);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="primary"
          onClick={handlePrint}
          style={{ marginRight: "1rem", marginTop: "0.5rem" }}
        >
          Print
        </Button>
      </div>
      <div className="container-warning-patient" ref={componentRef}>
        <div className="img-brand-soul">
          {/* <img src={logo} alt="Logo" /> */}
          <b>Facility Access</b>
        </div>
        <div className="soul-detail-bio">
          <ul>
            <li>{details.provider_full_name && details.provider_full_name}</li>
            <li>NPI# {details.provider_npi && details.provider_npi}</li>
            <li>Facility Access</li>
            <li>{details.address && details.address}</li>
          </ul>
        </div>
        <div className="clear-both"></div>
        <div className="datail-p-left ">
          <ul className="">
            <li>
              <strong>Patient Details</strong>
            </li>
            <li>
              <strong>Name:</strong>{" "}
              <span className="user-name">
                {details.title} {details.patient_full_name}
              </span>
            </li>
            <li>
              <strong>Email:</strong>{" "}
              <span className="user-name">{details.email}</span>
            </li>
            <li>
              <strong>Phone:</strong>{" "}
              <span className="user-name">{details.phone_no}</span>
            </li>
            <li>
              <strong>Medical Number:</strong>{" "}
              <span className="user-name">{details.medical_no}</span>
            </li>
            <li>
              <strong>Medical Dependency:</strong>{" "}
              <span className="user-name">{details.medical_dependency}</span>
            </li>
          </ul>
        </div>
        <div className="datail-p-right">
          {details.profile_pic ? (
            <img
              src={details.profile_pic}
              alt="Admin"
              // className="rounded-circle"
              width={80}
              height={80}
            />
          ) : (
            <img
              src={pp}
              alt="Admin"
              className="rounded-circle"
              width={80}
              height={80}
            />
          )}
        </div>

        <div className="clear-both mt-3"></div>
        <li>
          <strong>Medications:</strong>{" "}
          <span className="user-name">
            {details.medications &&
              details.medications.map((medication) => {
                return (
                  <div key={medication.id}>
                    <p>
                      {medication.title}; Take {medication.dose}{" "}
                      {medication.dose_unit} {medication.route}{" "}
                      {medication.frequency}; Qty:{medication.quantity}{" "}
                      {medication.refills}
                    </p>
                  </div>
                );
              })}
          </span>
        </li>

        <li className="mt-3">
          <strong>Allergies:</strong>{" "}
          <span className="user-name">
            {details.allergies &&
              details.allergies.map((allergy) => {
                return <div key={allergy.id}>{allergy.allergy}</div>;
              })}
          </span>
        </li>

        {/* <li className="mt-3">
          <strong>Encounter:</strong>{" "}
          <span className="user-name">
            {details.latest_encounter ? (
              <>
                <li>
                  <span style={{ fontWeight: "bold" }}>Date: </span>{" "}
                  
                  {details.latest_encounter.encounter_date
              ? moment(details.latest_encounter.encounter_date).utc().format("MM/DD/YYYY hh:mm:ss A")
              : ""}
                </li>{" "}
                <li><span style={{ fontWeight: "bold" }}>Encounter: </span> {details.latest_encounter.encounter_type_title}</li>{" "}
                <li><span style={{ fontWeight: "bold" }}>Speciality: </span> {details.latest_encounter.specialty_title}</li>
                <li><span style={{ fontWeight: "bold" }}>Reason: </span> {details.latest_encounter.reason}</li>
                <li><span style={{ fontWeight: "bold" }}>Provider Name: </span> {details.latest_encounter.provider_name}</li>{" "}
              </>
            ) : (
              ""
            )}
          </span>
        </li> */}
        <li style={{ marginTop: "1rem" }}>
          {" "}
          <strong>Problems:</strong>
        </li>
        <p>
          {details.problems &&
            details.problems.map((problem) => {
              return (
                <div
                  key={problem.id}
                  style={{ display: "flex", marginTop: "0.5rem" }}
                >
                  <strong>{problem.diagnosis}-</strong>
                  <p>{problem.name}</p>
                </div>
              );
            })}
        </p>
      </div>
    </>
  );
};

export default FaceCard;
