import React, { useEffect, useState } from "react";
import "../PatientProfile.css";
import { Calendar } from "primereact/calendar";
import axios from "axios";
import moment from "moment";
import { Provider } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DischargeForm = ({ details, handleDischargeFormClose }) => {
  const [dischargeTemp, setDischargeTemp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [dischargeDateTime, setDischargeDateTime] = useState(new Date());
  const [patientSignatureDate, setPatientSignatureDate] = useState(new Date());
  const [staffSignatureDate, setStaffSignatureDate] = useState(new Date());

  const handleDischargeTemp = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/discharge-form/${details.patient.id}`,
        config
      );
      const data = response.data.data.template;
      setDischargeTemp(data);
      const initialFormData = {};
      data.forEach((temp) => {
        initialFormData[temp.slug] = temp.content;
      });
      setFormData(initialFormData);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e, slug) => {
    setFormData({
      ...formData,
      [slug]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/discharge-patient`,
        {
          formData,
          provider_id: details.provider_id,
          patient_id: details.patient_id,
          admission_id: details.id,
          date_of_discharge: dischargeDateTime,
          patient_signature_date: patientSignatureDate,
          staff_signature_date: staffSignatureDate,
        },
        config
      );
      handleDischargeFormClose();
      toast.success(response.data.message);
      console.log("Form submitted successfully", response.data);
    } catch (error) {
      console.log("Error submitting form", error.message);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    handleDischargeTemp();
  }, []);

  console.log("dischargeTemp", details);
  return (
    <div>
      <div className="overflow-auto h-100">
        <div className="vital-heading">
          <div className="d-flex align-items-center">
            <h5>SOCIAL ORIENTED UNITED LIVING, LLC SOUL HOUSING</h5>
          </div>
        </div>
        <div
          className="admission-heading"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h5>PATIENT DISCHARGE FORM</h5>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-visit ms-auto"
              onClick={handleDischargeFormClose}
            >
              {" "}
              <i className="fas fa-arrow-left"></i>
            </button>
          </div>
        </div>
        <div className="admittion-bg-ff ">
          <div className="row g-3 align-items-center">
            <div className="col-lg-3">
              <div className="form-floating mb-3">
                <div>
                  {" "}
                  <h4>Patient Name : </h4>{" "}
                  <span className="lable-ff">
                    {" "}
                    {details.patient_name && details.patient_name}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-floating mb-3">
                <div style={{}}>
                  {" "}
                  <h4>Date of Birth : </h4>{" "}
                  <span className="lable-ff">
                    {" "}
                    {details.patient_date_of_birth
                      ? moment(details.patient_date_of_birth).format(
                          "MM/DD/YYYY"
                        )
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-floating mb-3">
                <div>
                  {" "}
                  <h4>Patient ID : </h4>{" "}
                  <span className="lable-ff">
                    {details.patient_medical_id && details.patient_medical_id}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-floating mb-3">
                <div>
                  {" "}
                  <h4>Date/Time of Discharge : </h4>{" "}
                  <span className="lable-ff">
                    {" "}
                    <Calendar
                      value={dischargeDateTime}
                      onChange={(e) => setDischargeDateTime(e.value)}
                      dateFormat="mm/dd/yy"
                      showIcon
                      showTime
                      hourFormat="12"
                      hideOnDateTimeSelect="true"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            {dischargeTemp &&
              dischargeTemp.map((temp, index) => (
                <div key={index} className="form-floating mb-3">
                  <label htmlFor={`textarea-${temp.slug}`}>
                    <h4>{temp.section}</h4>
                  </label>
                  <textarea
                    id={`textarea-${temp.slug}`}
                    className="form-control"
                    value={formData[temp.slug] || ""}
                    onChange={(e) => handleChange(e, temp.slug)}
                    rows="4"
                  />
                </div>
              ))}
          </div>
          <div className="row g-3">
            <div className="col-lg-3">
              <div className="form-floating mb-3">
                <div>
                  {" "}
                  <h4 className="mt-1">Patient Signature</h4>
                  <span className="ms-1">: _______________</span>
                </div>
                <div className="mt-2">
                  {" "}
                  <h4> Date : </h4>
                  <span>
                    <Calendar
                      value={patientSignatureDate}
                      onChange={(e) => setPatientSignatureDate(e.value)}
                      dateFormat="mm/dd/yy"
                      showIcon
                      showTime
                      hourFormat="12"
                      hideOnDateTimeSelect="true"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-3">
              <div className="form-floating mb-3">
                <div
                  className="mt-2"
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <h4>Staff Name : </h4>{" "}
                  <a> {details.staff_name && details.staff_name}</a>
                </div>
                <div
                  className="mt-2"
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <h4>Position : </h4>{" "}
                  <a> {details.position && details.position}</a>
                </div>

                <div
                  className="mt-2"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h4>Signature : _______________</h4>
                </div>
                <div className="mt-2">
                  {" "}
                  <h4> Date :</h4>
                  <span>
                    <Calendar
                      value={staffSignatureDate}
                      onChange={(e) => setStaffSignatureDate(e.value)}
                      dateFormat="mm/dd/yy"
                      showIcon
                      showTime
                      hourFormat="12"
                      hideOnDateTimeSelect="true"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <h4 className="mt-2">
              Socially Oriented United Living, LLC (Soul Housing)
            </h4>
            <div className="col-lg-3">
              <div className="form-floating ">
                <div
                  className="mt-2"
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <h4>Address : </h4>{" "}
                  <a className="ms-1"> {details.soul_housing_address}</a>
                </div>
                <div
                  className="mt-1"
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <h4>Phone : </h4>{" "}
                  <a className="ms-1"> {details.soul_housing_phone}</a>
                </div>

                <div
                  className="mt-2"
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  {" "}
                  <h4 style={{ display: "flex" }}>Website: </h4>
                  <a className="ms-1"> {details.website}</a>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-lg-12">
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit Discharge Form"}
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default DischargeForm;
