import React, { useEffect, useState } from "react";
import "../../patient detail/profile/PatientProfile.css";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import pp from "../../../assets/images/avatar.png";
import { Form, Col, Row, Button } from "react-bootstrap";
import { registrationValidation } from "../../../components/validation/RegistrationValidation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";

const initialValue = {
  // profile_pic:"",

  first_name: "",
  middle_name: "",
  last_name: "",
  social_security_no: "",
  medical_no: "",
  age: "",
  gender: "",
  switch: "",
  date_of_birth: "",
  referral_source_1: "",
  referral_organization: "",
  referral_company_name: "",
  referral_contact_name: "",
  referral_contact_number: "",
  referral_contact_email: "",
  financial_class: "",
  fin_class_name: "",
  doctor_name: "",
  // auth:"",
  admit_date: "",
  disch_date: "",
  pre_admit_date: "",
  nursing_station: "",
  contact_address: "",
  email: "",
  other_contact_name: "",
  other_contact_address: "",
  other_contact_country: "",
  other_contact_city: "",
  other_contact_state: "",
  other_contact_phone_no: "",
  other_contact_cell: "",
  other_email: "",
  relationship: "",
  medical_dependency: "",
  city: "",
  state: "",
  primary_language: "",
  phone_no: "",
  zip_code: "",
  country: "",
  status: "",
};
function RegistrationForm({ onFormSubmit }) {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [disableFields, setDisableFields] = useState(true);
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [facality, setFacility] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [loginDetails, setLoginDetails] = useState([]);
  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    // setFile(event.target.files[0]);
  };

  const validate = () => {
    let errors = {};

    // if (!values.title.trim()) {
    //   errors.title = "Title is required";
    // }
    if (!values.first_name.trim()) {
      errors.first_name = "First Name is required";
    }
    if (!values.last_name.trim()) {
      errors.last_name = "Last Name is required";
    }

    if (!values.gender.trim()) {
      errors.gender = "Gender is required";
    }
    // if (!values.date_of_birth.trim()) {
    //   errors.date_of_birth = "Date Of Birth is required";
    // }
    if (!values.medical_no.trim()) {
      errors.medical_no = "Medical no is required";
    }
    if (!values.nursing_station.trim()) {
      errors.nursing_station = "Facility is required";
    }
    // if (!values.marital_status.trim()) {
    //   errors.marital_status = "Marital Status is required";
    // }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      // Handle validation errors, e.g., display errors or prevent submission
      setErrors(validationErrors);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("profile_pic", file);
      formData.append("auth", loginDetails.provider_full_name);
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      // formData.append("profile_pic", file);
      // formData.append("title", values.title);
      // formData.append("first_name", values.first_name);
      // formData.append("middle_name", values.middle_name);
      // formData.append("last_name", values.last_name);
      // formData.append("social_security_no", values.social_security_no);
      // formData.append("medical_no", values.medical_no);
      // formData.append("age", values.age);
      // formData.append("gender", values.gender);
      // formData.append("date_of_birth", values.date_of_birth);
      // formData.append("race", values.race);
      // formData.append("ethnicity", values.ethnicity);
      // formData.append("marital_status", values.marital_status);
      // formData.append("referral_source_1", values.referral_source_1);
      // formData.append("referral_source_2", values.referral_source_2);
      // formData.append("financial_class", values.financial_class);
      // formData.append("fin_class_name", values.fin_class_name);
      // formData.append("doctor_name", values.doctor_name);
      // formData.append("auth", values.auth);
      // formData.append("account_no", values.account_no);
      // formData.append("admit_date", values.admit_date);
      // formData.append("disch_date", values.disch_date);
      // formData.append("adm_dx", values.adm_dx);
      // formData.append("resid_military", values.resid_military);
      // formData.append("pre_admit_date", values.pre_admit_date);
      // formData.append("service", values.service);
      // formData.append("nursing_station", values.nursing_station);
      // formData.append("occupation", values.occupation);
      // formData.append("employer", values.employer);
      // formData.append("email", values.email);
      // formData.append("other_contact_name", values.other_contact_name);
      // formData.append("other_contact_address", values.other_contact_address);
      // formData.append("other_contact_country", values.other_contact_country);
      // formData.append("other_contact_city", values.other_contact_city);
      // formData.append("other_contact_state", values.other_contact_state);
      // formData.append("other_contact_phone_no", values.other_contact_phone_no);
      // formData.append("other_contact_cell", values.other_contact_cell);
      // formData.append("relationship", values.relationship);
      // formData.append("medical_dependency", values.medical_dependency);
      // formData.append("city", values.city);
      // formData.append("state", values.state);
      // formData.append("language", values.language);
      // formData.append("phone_no",values.phone_no);
      // formData.append("zip_code", values.zip_code);
      // formData.append("country", values.country);
      // formData.append("status", values.status);

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/add-patient`,
        formData,
        config
      );
      // navigate('/patient_list');
      onFormSubmit();
      toast.success(response.data.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleGetStates = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/us-states",
        config
      );
      setStates(response.data);
      console.log("response", response);
      const california = response.data.find(
        (state) => state.name === "California"
      );
      if (california) {
        setValues((prevValues) => ({
          ...prevValues,
          state: california.id,
          other_contact_state: california.id,
        }));
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  async function uploadImage(event) {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setFile(base64);

    const object = {
      target: {
        value: base64,
      },
    };

    return base64;
  }

  function ageCalculator(userInput) {
    if (!userInput || userInput === "") {
      setValues((prevValues) => ({ ...prevValues, age: "" })); // Reset age if input is invalid
      return; // Handle invalid date input gracefully
    }
    const dob = new Date(userInput);
    const today = new Date();
    const ageDiff = today.getUTCFullYear() - dob.getUTCFullYear();
    const hasBirthdayPassed =
      today.getMonth() > dob.getMonth() ||
      (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate());
    const calculatedAge = hasBirthdayPassed ? ageDiff : ageDiff - 1;
    setValues((prevValues) => ({
      ...prevValues,
      age: calculatedAge.toString(),
    })); // Update state with calculated age
  }

  // function ageCalculator() {
  //   const userInput = values.date_of_birth;
  //   if (!userInput || userInput === "") {
  //     return; // Handle invalid date input gracefully
  //   } else {

  //     const dob = new Date(userInput);
  //     const today = new Date();
  //     const ageDiff = today.getUTCFullYear() - dob.getUTCFullYear();

  //     const calculatedAge = ageDiff;
  //     setValues((prevValues) => ({ ...prevValues, age: calculatedAge.toString() })); // Update state with calculated age
  //   }
  // }

  const handleDateChange = (e) => {
    const dob = e.value;
    setValues((prevValues) => ({ ...prevValues, date_of_birth: dob }));
    ageCalculator(dob);
  };

  const getLoginUserDetails = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/login-user-details`,
        config
      );
      setLoginDetails(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  // useEffect(() => {
  //     const phoneInput = document.getElementById('phone_no');
  //     const formatPhoneNumber = (e) => {
  //       var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  //       const formattedPhoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  //       // Update the state with the formatted phone number only if the input is not empty
  //       if (e.target.value.trim() !== '') {
  //         setValues({...values, phone_no: formattedPhoneNumber});
  //       }
  //     };
  //     phoneInput.addEventListener('blur', formatPhoneNumber);
  //     return () => {
  //       phoneInput.removeEventListener('blur', formatPhoneNumber);
  //     };
  //   }, []);
  const getFacility = async () => {
    try {
      setOptionsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/all-facilities",
        config
      );
      setFacility(response.data.data);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    handleGetStates();
    getFacility();
    getLoginUserDetails();
  }, []);
  console.log("loginUser", loginDetails);
  return (
    <div>
      <div className="main-patientinfo mapping-scroll">
        <h1>Demographics</h1>

        <div className="patient-info-bg ">
          <div className="row g-3">
            <div className="col-lg-6">
              <div className="personal-info">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3>Patient Information</h3>
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
                <div className="row g-3 mb-5">
                  {/* <div className="col-md">
                    <div className="form-floating">
                      <select
                        className="form-select w-100"
                        id="title"
                        name="title"
                        value={values.title }
                        onChange={handleChange}
                      >
                        <option value="">select</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Miss.">Miss.</option>

                      </select>
                      <label htmlFor="title" >Title </label>
                        Already comented
                       {errors.title && <p style={{ color: "red",marginTop:'5px' }}>{errors.title}</p>} 
                    </div>
                     {errors.title&& touched.title ? (
                      <p style={{ color: "red" }}>{errors.title}</p>
                    ) : null} 
                  </div> */}
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          errors.first_name ? "is-invalid" : ""
                        }`}
                        id="first_name"
                        placeholder="first name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                      <label htmlFor="first_name">
                        First Name{" "}
                        <span style={{ color: "red", fontSize: "13px" }}>
                          *
                        </span>
                      </label>
                      {errors.first_name && (
                        <p style={{ color: "red", marginTop: "5px" }}>
                          {errors.first_name}
                        </p>
                      )}
                    </div>
                    {/* {errors.first_name && touched.first_name ? (
                      <p style={{ color: "red" }}>{errors.first_name}</p>
                    ) : null} */}
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="middle_name"
                        placeholder="middle name"
                        name="middle_name"
                        value={values.middle_name}
                        onChange={handleChange}
                      />
                      <label htmlFor="middle_name">Middle Name</label>
                    </div>
                  </div>
                </div>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="last_name"
                        placeholder="last name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                      <label htmlFor="last_name">
                        Last Name{" "}
                        <span style={{ color: "red", fontSize: "13px" }}>
                          *
                        </span>
                      </label>
                      {errors.last_name && (
                        <p style={{ color: "red", marginTop: "5px" }}>
                          {errors.last_name}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="col-md">
                      <div className="form-floating">
                        <select
                          name="gender"
                          value={values.gender}
                          onChange={handleChange}
                          className={`form-select w-100 ${
                            errors.gender ? "is-invalid" : ""
                          }`}
                          id="gender"
                        >
                          <option value="">select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                        <label htmlFor="gender">
                          Gender{" "}
                          <span style={{ color: "red", fontSize: "13px" }}>
                            *
                          </span>
                        </label>
                        {errors.gender && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            {errors.gender}
                          </p>
                        )}
                      </div>
                      {/* {errors.gender && touched.gender ? (
                        <p style={{ color: "red" }}>{errors.gender}</p>
                      ) : null} */}
                    </div>

                    {/* {errors.ssn && touched.ssn ? (
                      <p style={{ color: "red" }}>{errors.age}</p>
                    ) : null} */}
                  </div>
                </div>
                <div className="row g-3 mb-5">
                  <div className="col-6">
                    <div className="form-floating">
                      <FloatLabel>
                        <Calendar
                          className="w-100 w-334-"
                          value={values.date_of_birth}
                          onChange={handleDateChange}
                          dateFormat="mm/dd/yy"
                          showIcon
                          // showTime
                          // hourFormat="12"
                        />
                        <label htmlFor="date_of_birth">
                          DOB{" "}
                          <span style={{ color: "red", fontSize: "13px" }}>
                            *
                          </span>
                        </label>
                      </FloatLabel>
                      {/* {errors.date_of_birth && <p style={{ color: "red", marginTop: "5px" }}>{errors.date_of_birth}</p>} */}
                    </div>
                    {/* {errors.date_of_birth && touched.date_of_birth ? (
                      <p style={{ color: "red" }}>{errors.date_of_birth}</p>
                    ) : null} */}
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="age"
                        placeholder="age"
                        name="age"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                        }}
                        value={values.age}
                        onChange={handleChange}
                        disabled={disableFields}
                      />
                      <label htmlFor="age">Age</label>
                    </div>
                  </div>
                </div>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={`form-control w-100 ${
                          errors.medical_no ? "is-invalid" : ""
                        }`}
                        id="medical_no"
                        placeholder="medical_no"
                        name="medical_no"
                        value={values.medical_no}
                        onChange={handleChange}
                      />
                      <label htmlFor="medical_no">
                        Member ID
                        <span style={{ color: "red", fontSize: "13px" }}>
                          *
                        </span>
                      </label>
                    </div>
                    {errors.medical_no && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        {errors.medical_no}
                      </p>
                    )}
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="primary_language"
                        placeholder="primary_language"
                        name="primary_language"
                        value={values.primary_language}
                        onChange={handleChange}
                      />
                      <label htmlFor="primary_language">
                        Primary Language{" "}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="financial_class"
                        placeholder="financial_class"
                        name="financial_class"
                        value={values.financial_class}
                        onChange={handleChange}
                      />
                      <label htmlFor="financial_class">Current Insurance</label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="fin_class_name "
                          placeholder="fin_class_name"
                          name="fin_class_name"
                          value={values.fin_class_name}
                          onChange={handleChange}
                        />
                        <label htmlFor="fin_class_name ">
                          Current Insurance No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="social_security_no "
                        placeholder="social security no"
                        name="social_security_no"
                        value={values.social_security_no}
                        onChange={handleChange}
                      />
                      <label htmlFor="social_security_no ">
                        Social Security No
                      </label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="medical_dependency"
                          placeholder="medical_dependency"
                          name="medical_dependency"
                          value={values.medical_dependency}
                          onChange={handleChange}
                        />
                        <label htmlFor="medical_dependency">
                          Medical Dependency
                        </label>
                      </div>
                      {/* {errors.medical_dependency && touched.medical_dependency ? (
                        <p style={{ color: "red" }}>{errors.medical_dependency}</p>
                      ) : null} */}
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="referral_source_1"
                        onChange={handleChange}
                        value={values.referral_source_1}
                        className="form-select w-100"
                        id="referral_source_1"
                      >
                        <option value="">select</option>
                        <option value="Organization">Organization</option>
                        <option value="Walk-In">Walk-In</option>

                        {/* <option value="Case Manager">Case Manager</option> */}
                      </select>
                      {/* <input
                        type="text"
                        className="form-control w-100"
                        id="referral_source_1"
                        placeholder="referral_source_1"
                        name="referral_source_1"
                        value={values.referral_source_1 }  
                        onChange={handleChange}
                       
                      /> */}
                      <label htmlFor="referral_source_1">
                        Referral Source 1
                      </label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="col-md">
                      <div className="form-floating">
                        {/* <input
                          type="text"
                          className="form-control w-100"
                          id="referral_source_2"
                          placeholder="referral_source_2"
                          name="referral_source_2"
                          value={values.referral_source_2 }
                          onChange={handleChange}
                         
                        /> */}

                        <select
                          name="referral_organization"
                          onChange={handleChange}
                          value={values.referral_organization}
                          className="form-select w-100"
                          id="referral_organization"
                        >
                          <option value="">select</option>
                          <option value="Hospital">Hospital</option>
                          <option value="Out-Reach">Out-Reach</option>

                          {/* <option value="Case Manager">Case Manager</option> */}
                        </select>
                        <label htmlFor="referral_organization">
                          Referral Organization
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="referral_company_name "
                        placeholder="referral_company_name"
                        name="referral_company_name"
                        value={values.referral_company_name}
                        onChange={handleChange}
                      />
                      <label htmlFor="referral_company_name">
                        Referral Company Name
                      </label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="referral_contact_name"
                          placeholder="referral_contact_name"
                          name="referral_contact_name"
                          value={values.referral_contact_name}
                          onChange={handleChange}
                        />
                        <label htmlFor="referral_contact_name">
                          Referral Contact Name
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="tel"
                        className="form-control w-100"
                        id="referral_contact_number "
                        placeholder="referral_contant_number"
                        name="referral_contact_number"
                        value={values.referral_contact_number}
                        onChange={handleChange}
                      />
                      <label htmlFor="resid_military ">
                        Referral Contact Number
                      </label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control w-100"
                          id="referral_contact_email "
                          placeholder="referral_contact_email"
                          name="referral_contact_email"
                          value={values.referral_contact_email}
                          onChange={handleChange}
                        />
                        <label htmlFor="referral_contact_email ">
                          Referral Contact Email
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5  ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="auth"
                        placeholder="auth"
                        name="auth"
                        value={loginDetails.provider_full_name}
                        // onChange={handleChange}
                        disabled={disableFields}
                      />
                      <label htmlFor="auth">Auth</label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="col-md">
                      <div className="form-floating">
                        {/* <input
                          type="text"
                          className="form-control w-100"
                          id="nursing_station"
                          placeholder="nursing_station"
                          name="nursing_station"
                          value={values.nursing_station }
                          onChange={handleChange}
                         
                        /> */}
                        <select
                          className="form-select"
                          id="nursing_station"
                          name="nursing_station"
                          aria-label="Floating label select example"
                          value={values.nursing_station}
                          onChange={handleChange}
                        >
                          <option value="">-Select-</option>

                          {optionsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            facality &&
                            facality.map((facilitie, index) => (
                              <option key={facilitie.id} value={facilitie.id}>
                                {facilitie.address}
                              </option>
                            ))
                          )}
                        </select>
                        <label htmlFor="nursing_station">
                          Facility{" "}
                          <span style={{ color: "red", fontSize: "13px" }}>
                            *
                          </span>
                        </label>
                        {errors.nursing_station && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            {errors.nursing_station}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5 ">
                  <div className="col-lg-6">
                    <div className="col-md">
                      <div className="form-floating">
                        <select
                          name="switch"
                          value={values.switch}
                          onChange={handleChange}
                          className={`form-select w-100 `}
                          id="switch"
                        >
                          <option value="">select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <label htmlFor="switch">
                          Does Patient Need to Swtich
                        </label>
                      </div>
                      {/* {errors.gender && touched.gender ? (
                        <p style={{ color: "red" }}>{errors.gender}</p>
                      ) : null} */}
                    </div>

                    {/* {errors.ssn && touched.ssn ? (
                      <p style={{ color: "red" }}>{errors.age}</p>
                    ) : null} */}
                  </div>

                  {/* <div className="col-md">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="adm_dx "
                          placeholder="adm_dx"
                          name="adm_dx"
                          value={values.adm_dx }
                          onChange={handleChange}
                         
                        />
                        <label htmlFor="adm_dx ">Adm.Dx</label>
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="resid_military "
                        placeholder="resid_military"
                        name="resid_military"
                        value={values.resid_military }
                        onChange={handleChange}
                       
                      />
                      <label htmlFor="resid_military ">Resid Military</label>
                    </div>
                  </div>

                  <div className="col-md">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="service "
                        placeholder="service"
                        name="service"
                        value={values.service }
                        onChange={handleChange}
                       
                      />
                      <label htmlFor="service ">Service</label>
                    </div>
                  </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="personal-info">
                <h3>Contact Information</h3>
                <div className="row g-3 mb-5">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="contact_address"
                        placeholder="address"
                        name="contact_address"
                        value={values.contact_address || ""}
                        onChange={handleChange}
                      />
                      <label htmlFor="contact_address">Address</label>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="city"
                        placeholder="city"
                        name="city"
                        value={values.city || ""}
                        onChange={handleChange}
                      />
                      <label htmlFor="city"> City </label>
                    </div>
                    {/* {errors.city && touched.city ? (
                      <p style={{ color: "red" }}>{errors.city}</p>
                    ) : null} */}
                  </div>
                </div>
                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        name="state"
                        onChange={handleChange}
                        value={values.state || ""}
                        className="form-select w-100 "
                        id="state"
                      >
                        <option value="">select</option>
                        {loading ? (
                          <option>Loading...</option>
                        ) : (
                          states &&
                          states.map((state, index) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))
                        )}
                      </select>
                      <label htmlFor="state">State </label>
                    </div>
                    {/* {errors.state && touched.state ? (
                      <p style={{ color: "red" }}>{errors.state}</p>
                    ) : null} */}
                  </div>

                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="zip_code"
                        placeholder="zip_code"
                        name="zip_code"
                        value={values.zip_code || ""}
                        onChange={handleChange}
                      />
                      <label htmlFor="zip_code">ZIP Code</label>
                    </div>
                  </div>
                </div>

                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="country"
                        name="country"
                        aria-label="Floating label select example"
                        value={values.country}
                        onChange={handleChange}
                      >
                        <option value="USA">USA</option>
                      </select>
                      {/* <input
                        type="text"
                        className="form-control w-100"
                        id="country"
                        placeholder="country"
                        name="country"
                        value={values.country || ""}
                        onChange={handleChange}
                       
                      /> */}
                      <label htmlFor="country">Country </label>
                    </div>
                    {/* {errors.country && touched.country ? (
                      <p style={{ color: "red" }}>{errors.country}</p>
                    ) : null} */}
                  </div>
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control w-100"
                        id="email"
                        placeholder="email"
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                  </div>
                </div>
                <div className="row g-3 mb-5 ">
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        type="tel"
                        className="form-control w-100"
                        id="phone_no"
                        placeholder="(555) 555-5555"
                        name="phone_no"
                        value={values.phone_no || ""}
                        onChange={handleChange}
                      />
                      <label htmlFor="phone_no">Phone No:</label>
                    </div>
                  </div>
                </div>
                <h5 className=" mb-3 ">Next of KIN </h5>
                <div className="row g-3 mb-5 ">
                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="other_contact_name"
                        placeholder="other_contact_name"
                        name="other_contact_name"
                        value={values.other_contact_name || ""}
                        onChange={handleChange}
                      />
                      <label htmlFor="other_contact_name">name</label>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control w-100"
                        id="other_contact_address"
                        placeholder="other_contact_address"
                        name="other_contact_address"
                        value={values.other_contact_address || ""}
                        onChange={handleChange}
                      />
                      <label htmlFor="other_contact_address">Address</label>
                    </div>
                  </div>

                  <div className="row g-3 ">
                    <div className="col-md">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="other_contact_country"
                          name="other_contact_country"
                          aria-label="Floating label select example"
                          value={values.other_contact_country}
                          onChange={handleChange}
                        >
                          <option value="USA">USA</option>
                        </select>
                        {/* <input
                          type="text"
                          className="form-control w-100"
                          id="other_contact_country"
                          placeholder="other_contact_country"
                          name="other_contact_country"
                          value={values.other_contact_country || ""}
                          onChange={handleChange}
                         
                        /> */}
                        <label htmlFor="other_contact_country">Country </label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="other_contact_state"
                          placeholder="other_contact_state"
                          name="other_contact_state"
                          value={values.other_contact_state || ""}
                          onChange={handleChange}
                        />
                        <label htmlFor="other_contact_state">State </label>
                      </div>
                    </div>
                  </div>

                  <div className="row g-3 ">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="other_contact_city"
                          placeholder="other_contact_city"
                          name="other_contact_city"
                          value={values.other_contact_city || ""}
                          onChange={handleChange}
                        />
                        <label htmlFor="other_contact_city"> City </label>
                      </div>
                    </div>

                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="tel"
                          className="form-control w-100"
                          id="other_contact_phone_no"
                          placeholder="(555) 555-5555"
                          name="other_contact_phone_no"
                          value={values.other_contact_phone_no || ""}
                          onChange={handleChange}
                        />
                        <label htmlFor="other_contact_phone_no">
                          Phone No:
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row g-3  ">
                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="relationship"
                          placeholder="relationship"
                          name="relationship"
                          value={values.relationship || ""}
                          onChange={handleChange}
                        />
                        <label htmlFor="relationship"> Relationship</label>
                      </div>
                    </div>

                    <div className="col-md">
                      <div className="form-floating">
                        <input
                          type="tel"
                          className="form-control w-100"
                          id="other_contact_cell"
                          placeholder="(555) 555-5555"
                          name="other_contact_cell"
                          value={values.other_contact_cell || ""}
                          onChange={handleChange}
                        />
                        <label htmlFor="other_contact_cell">Cell</label>
                      </div>
                    </div>
                  </div>

                  <div className="row g-3  ">
                    <div className="col-lg-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control w-100"
                          id="other_email"
                          placeholder="other_email"
                          name="other_email"
                          value={values.other_email || ""}
                          onChange={handleChange}
                        />
                        <label htmlFor="other_email">Email</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center p-user-img-h">
                  <Form.Label htmlFor="uploadpic">
                    {file ? (
                      <>
                        <img
                          className="rounded-circle"
                          style={{
                            width: "170px",
                            height: "170px",
                            cursor: "pointer",
                          }}
                          src={file}
                          alt=""
                        />
                      </>
                    ) : (
                      <img
                        className="rounded-circle"
                        src={pp}
                        alt=""
                        style={{
                          width: "170px",
                          height: "170px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Form.Label>

                  <Form.Control
                    type="file"
                    name="file"
                    defaultValue={values.profile_pic}
                    style={{ display: "none" }}
                    id="uploadpic"
                    onChange={async (e) => {
                      await uploadImage(e);
                    }}
                  />
                </div>
                <div className="w-100 text-center">
                  <p style={{ color: "black" }}>Upload Picture</p>
                </div>
                {/* <button type="button" onClick={handleSubmit}>Add</button> */}
                <div className="btn-signin ">
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                   
                    
                
                  >
                   Save
                  </button> */}
                  {/* <Link to="/patient_list">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ marginLeft: "0.5rem" }}
                    >
                      Cancel
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
}

export default RegistrationForm;
