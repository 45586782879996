import React from "react";
import { useState, useEffect } from "react";
import "../PatientProfile.css";
import user from "../../../../assets/images/user.png";
import axios from "axios";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { documentValidation } from "../../../../components/validation/RegistrationValidation";
import { IconButton } from "@mui/material";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux";
import NoData from "../../../../assets/images/no-data-found.png";

const getCurrentLocalDateTime = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate.toISOString().slice(0, 16);
};

const initialValue = {
  title: "",
  date: new Date(),
  type: "",
  description: "",
  file: "",
};

const Documents = ({ id }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [searchKeyword, setSearchKeyword] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);

  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
    setCurrentDocumentId(null);
  };
  const handleShow = () => setShow(true);

  const getDocuments = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/documents/${id}`,
        config
      );
      setDocuments(res.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: documentValidation,
    onSubmit: async (values, action) => {
      try {
        setSaveLoading(true);
        const formData = new FormData();
        formData.append("patient_id", id);
        formData.append("title", values.title);
        formData.append(
          "date",
          moment(values.date).format("YYYY-MM-DDTHH:mm:ss")
        );
        formData.append("type", values.type);
        formData.append("description", values.description);
        if (values.file) {
          formData.append("file", values.file); // Append the file to the form data
        }
        if (isEditing && currentDocumentId) {
          formData.append("document_id", currentDocumentId); // Append document_id to formData
        }

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data", // Ensure this header is set
          },
        };

        let document;
        if (isEditing && currentDocumentId) {
          document = await axios.post(
            process.env.REACT_APP_ORIGIN_URL + `/update-document`,
            formData, // Send formData directly
            config
          );
        } else {
          document = await axios.post(
            process.env.REACT_APP_ORIGIN_URL + "/store-document",
            formData,
            config
          );
        }

        toast.success(document.message);
        getDocuments();
        handleClose();
      } catch (error) {
        toast.error(error.message);
        console.log(error.message);
      } finally {
        setSaveLoading(false);
      }
      // action.resetForm();
    },
  });

  const handleDeleteDocument = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.delete(
        process.env.REACT_APP_ORIGIN_URL + `/document/${id}`,
        config
      );
      toast.success(response.message);
      getDocuments();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const base64File = await getBase64(file);
      setFieldValue("file", base64File); // Store the base64 string
    }
  };

  const generateAndOpenPDF = async (documentPdf) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/file-show`,
        { file_name: documentPdf }, // Replace with the actual structure to send
        config
      );
      console.log("res", res.data);
      // Extract base64 encoded PDF content from response
      const pdfData = res.data.file;
      const mimeType = res.data.mime_type;

      // Convert base64 to Blob
      const byteCharacters = atob(pdfData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      // Create URL and open in new tab
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.log("Error generating or opening PDF:", error.message);
      // Handle error as needed
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Get the base64 string without the prefix
      reader.onerror = (error) => reject(error);
    });
  };

  const handleEditDocument = (document) => {
    setCurrentDocumentId(document.id);
    setValues({
      title: document.title,
      date: new Date(document.date),
      type: document.type,
      description: document.description,
      file: null,
    });
    setIsEditing(true);
    handleShow();
  };

  useEffect(() => {
    getDocuments();
  }, []);
  console.log("document", documents);

  return (
    <div>
      <div className="document-content d-flex align-items-center">
        <div className="avatar dropdown">
          <h2 style={{ marginLeft: "1rem" }}>All Documents</h2>
        </div>

        {permissions.document_add_button && (
          <div className="ms-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShow}
            >
              <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
              Document
            </button>
          </div>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="allery-header">
          <Modal.Title>
            {isEditing ? "Edit Document" : "Add Document"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="allergy-body">
          <div className="row mt-2">
            <div className="col-6">
              <div className="allery-feilds">
                <h5 style={{ padding: "0.5rem" }}>
                  Title{" "}
                  <span style={{ color: "red", fontSize: "13px" }}>*</span>
                </h5>
                <div className="d-flex align-items-center">
                  <div class="form-floating mb-4 ms-1 w-100">
                    <input
                      type="text"
                      className={`form-control ${
                        errors.title && touched.title ? "is-invalid" : ""
                      }`}
                      id="title"
                      placeholder="title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="title">Title </label>
                    {errors.title && touched.title ? (
                      <p style={{ color: "red" }}>{errors.title}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="allery-feilds">
                <h5 style={{ padding: "0.5rem" }}>Date/Time</h5>
                <div className="d-flex align-items-center">
                  <div class="form-floating mb-4 ms-1 w-100">
                    <Calendar
                      value={values.date}
                      onChange={(e) => setFieldValue("date", e.value)}
                      onBlur={handleBlur}
                      dateFormat="mm/dd/yy"
                      showIcon
                      showTime
                      hourFormat="12"
                      hideOnDateTimeSelect="true"
                      className="calendar"
                    />
                    {/* <label htmlFor="date">Select</label> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="allery-feilds">
                <h5 style={{ padding: "0.5rem" }}>Report Type</h5>
                <div className="d-flex align-items-center">
                  <div class="form-floating mb-4 ms-1 w-100">
                    <input
                      type="text"
                      class="form-control w-100"
                      id="type"
                      placeholder="Report Type"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="type">Report Type</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 style={{ padding: "0.5rem" }}>Description</h5>
          <div className="form-floating ">
            <textarea
              className="form-control"
              placeholder="Leave a comment here"
              id="description"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            <label htmlFor="description">Message</label>
          </div>

          <div className="col">
            <div className="allery-feilds">
              <div className="d-flex align-items-center">
                <div class="mb-3">
                  <h5 for="formFile" class="form-label my-2 mt-4">
                    Reported
                  </h5>
                  <input
                    type="file"
                    className="form-control"
                    id="file"
                    name="file"
                    onChange={handleFileChange}
                    // onChange={(e) => setFieldValue("file", e.target.files[0])}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="allergy-footer-btn">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            {saveLoading ? "Processing..." : isEditing ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>

      {permissions.document_table_view && (
        <div className="encounter-table  table-responsive">
          <table className="table">
            <thead>
              <tr className="mb-2">
                <th>Title</th>
                <th>Type</th>
                <th>Description</th>
                <th>Uploaded by</th>
                <th>Date</th>
                <th>PDF</th>
                <th>Size</th>
                {permissions.document_table_action_col && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="12">
                    <div className="loading-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : documents && documents.length > 0 ? (
                documents.map((document, index) => {
                  return (
                    <tr key={index}>
                      {console.log("documents", document)}
                      <td>
                        {document.title}
                        {/* <span className="ms-3">{document.title}</span> */}
                      </td>
                      <td>{document.type}</td>
                      <td>{document.description}</td>
                      <td>{document.provider}</td>
                      <td>
                        {moment(document.date).utc().format("MM/DD/YYYY ")}
                      </td>
                      <td>
                        {" "}
                        {document.file && (
                          <IconButton
                            onClick={() => generateAndOpenPDF(document.file)}
                          >
                            <FontAwesomeIcon icon={faFilePdf} />
                          </IconButton>
                        )}
                      </td>
                      <td>{document.file_size}</td>
                      {permissions.document_table_action_col && (
                        <td>
                          {permissions.document_table_edit_button && (
                            <a
                              onClick={() => handleEditDocument(document)}
                              style={{ color: "blue", cursor: "pointer" }}
                            >
                              <i className="fas fa-edit"></i>
                            </a>
                          )}
                          {permissions.document_delete_button && (
                            <i
                              className="fa fa-trash"
                              style={{ color: "red", paddingLeft: "1rem" }}
                              onClick={() => handleDeleteDocument(document.id)}
                            ></i>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="12" align="center">
                    <img
                      src={NoData}
                      alt="No data found"
                      style={{ width: "150px", margin: "20px 0" }}
                    />
                    <p>No Documents found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default Documents;
