import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import "../PatientList.css";
import * as XLSX from "sheetjs-style"; // Import xlsx functions
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

const EncounterReport = ({ onPatientClick }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGetPatients = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/encounter-report",
        config
      );
      setPatients(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPatients();
  }, []);

  const exportToExcel = () => {
    const data = patients.map((patient) => ({
      "Patient Name": patient.patient_full_name,
      "Member ID": patient.medical_no,
      Facility: patient.facility_name,
      "Encounter Date": patient.encounter_date
        ? moment(patient.encounter_date).utc().format("MM/DD/YYYY ")
        : "",
      Specialty: patient.specialty_title,
      "Encounter Type": patient.encounter_type_title,
      Status: patient.status,
    }));

    // Create a new worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Set column widths
    worksheet["!cols"] = [
      { wch: 20 }, // Patient Name column
      { wch: 15 }, // Member ID column
      { wch: 25 }, // Facility column
      { wch: 25 }, // Encounter Date column
      { wch: 20 }, // Specialty column
      { wch: 20 }, // Encounter Type column
      { wch: 15 }, // Status column
    ];

    // Apply styles to the header row
    const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      const cell = worksheet[cellAddress];
      if (cell) {
        cell.s = {
          fill: {
            fgColor: { rgb: "14457b" }, // Blue background color
          },
          font: {
            bold: true,
            color: { rgb: "FFFFFF" }, // White text color
          },
          alignment: {
            horizontal: "center",
          },
        };
      }
    }
    // Apply styles to the data rows
    for (let R = headerRange.s.r + 1; R <= headerRange.e.r; R++) {
      for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellAddress];
        if (cell) {
          cell.s = {
            alignment: {
              horizontal: "center", // Align content of columns to the left
              // vertical: "center",
            },
          };
        }
      }
    }

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Encounter Report");

    // Write the Excel file
    XLSX.writeFile(workbook, "encounter_report.xlsx");
  };

  const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 20, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 20, textTransform: "capitalize" } },
    },
  }));

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "0",
          marginBottom: "5px",
          marginRight: "5px",
        }}
      >
        <button
          onClick={exportToExcel}
          style={{
            borderRadius: "5px",
            border: "2px solid #05bc33",
            backgroundColor: "#06db3b",
            color: "#fff",
          }}
        >
          Export{" "}
        </button>
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            className="css-xn82ks-MuiTable-root"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className="patient-info">
              <TableRow>
                <TableCell align="left">Patient Name</TableCell>
                <TableCell align="left">Member Id</TableCell>
                <TableCell align="left">MRN No</TableCell>
                <TableCell align="left">Facility</TableCell>
                <TableCell align="left">Encounter Date</TableCell>
                <TableCell align="left">Speciality </TableCell>
                <TableCell align="left">Encounter Type</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <tr>
                  <td colSpan="12">
                    <div className="loading-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                patients
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((patient, index) => (
                    <TableRow hover key={index}>
                      <TableCell
                        align="left"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="d-flex justify-content-center">
                          <img
                            src={
                              `https://i.pravatar.cc/150?img=${
                                Math.floor(Math.random() * 21) + 50
                              }`
                              //   patient.profile_pic ===
                              //   "https://soulhousing-api.anchorstech.net/public/uploads/placeholder.jpg"
                              //     ? `https://i.pravatar.cc/150?img=${
                              //         Math.floor(Math.random() * 21) + 50
                              //       }`
                              //     : patient.profile_pic
                            }
                            alt="Admin"
                            className="rounded-circle"
                            width={40}
                            height={40}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <h6
                          style={{ cursor: "pointer" }}
                          className="p-2"
                          onClick={() => onPatientClick(patient)}
                        >
                          {patient.patient_full_name}
                        </h6>
                      </TableCell>
                      <TableCell align="left">{patient.medical_no}</TableCell>
                      <TableCell align="left">{patient.mrn_no}</TableCell>
                      <TableCell align="left">
                        {patient.facility_name}
                      </TableCell>
                      <TableCell align="left">
                        {patient.encounter_date
                          ? moment(patient.encounter_date)
                              .utc()
                              .format("MM/DD/YYYY ")
                          : ""}
                      </TableCell>
                      <TableCell align="left">
                        {patient.specialty_title}
                      </TableCell>
                      <TableCell align="left">
                        {patient.encounter_type_title}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color:
                            patient.status === "Draft"
                              ? "#9e7918"
                              : patient.status === "Signed"
                              ? "green"
                              : "inherit",
                          fontWeight: "bold",
                        }}
                      >
                        {patient.status}
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ px: 2 }}
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={patients.length}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EncounterReport;
