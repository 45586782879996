import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Header from "../../components/header/Header";
import "./PatientList.css?123";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegistrationForm from "./registration/Registration";
import { faTimes, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import PatientDetail from "../patient detail/detail/PatientDetail";
import BedMapping from "./mapping/BedMapping";
import Settings from "../patient detail/setting/Settings";
import Maintenance from "../patient detail/maintainance/Maintainance";
import Operation from "../patient detail/operation/Operation";
import Mainpage from "../calling/MainPage";
import EncounterReport from "./report/EncounterReport";
import pp from "../../assets/images/avatar.png";
import MyPatients from "./registration/MyPatients";
import NewPatients from "./registration/NewPatients";
import Scheduling from "./Scheduling/Scheduling";
import ReVisit from "./registration/ReVisit";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useSelector } from "react-redux";

const PatientList = ({ onExport }) => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [facilityLoading, setFaciltiyLoading] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("patient"); // State to control active tab
  const [openPatientDetails, setOpenPatientDetails] = useState([]);
  const [settingsTabOpen, setSettingsTabOpen] = useState(false);
  const [addPatientTabOpen, setAddPatientTabOpen] = useState(false);
  const [bedMappingTab, setBedMappingTab] = useState(false);
  const [maintainanceTab, setMaintainanceTab] = useState(false);
  const [operationTab, setOperationTab] = useState(false);
  const [allPatientsTab, setAllPatientsTab] = useState(false);
  const [unassignedPatientTab, setUnassignedPatientTab] = useState(false);
  const [reVisitTab, setReVisitTab] = useState(false);
  const [videoCalling, setOpenVideoCalling] = useState(false);
  const [schedulingTab, setSchedulingTab] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hoveredPatient, setHoveredPatient] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [showNavLnks, setShowNAvLinks] = useState(true);
  // const [activeTab, setActiveTab] = useState('Demographics');
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const inputRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const patientsPerPage = 2;
  const getSearchResults = async (query) => {
    if (query.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/search-patient/${query}`,
        config
      );
      setSearchResults(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleInputChange = (value) => {
    setSearchInput(value);
    getSearchResults(value);
  };

  const getPatient = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/get-patients",
        config
      );
      setPatients(response.data.data); // Accessing the 'data' property of the response
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    // handleGetFacilities();
    getPatient();

    setActiveTab("allPatients");
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleOptionTabChange = (event) => {
    setActiveTab(event.target.value);
    handleRefreshPatient();
  };

  const openPatientDetail = (patient) => {
    console.log("openPatientDetail", patient);
    const existingIndex = openPatientDetails.findIndex(
      (item) => item.id === patient.id
    );

    if (existingIndex === -1) {
      // If patient tab does not exist, add it and set it as active
      setOpenPatientDetails([...openPatientDetails, patient]);
    }

    // Set the tab as active
    setActiveTab(patient.id);
    setSearchInput("");
  };

  const handleRefreshPatient = () => {
    getPatient();
  };

  const changeFacility = () => {
    console.log("chnageeeee");
    setSettingsTabOpen(false);
    setAddPatientTabOpen(false);
    setBedMappingTab(false);
    setMaintainanceTab(false);
    setOperationTab(false);
    setOpenVideoCalling(false);
    setOpenPatientDetails([]);
    setActiveTab("patient");
    // Optionally, you can refresh the patient list or perform other actions here
    getPatient();
  };
  const closePatientDetail = (patient) => {
    const updatedPatientDetails = openPatientDetails.filter(
      (item) => item.id !== patient.id
    );
    setOpenPatientDetails(updatedPatientDetails);
    if (updatedPatientDetails.length > 0) {
      // Set the active tab to the first remaining patient detail tab
      setActiveTab(updatedPatientDetails);
    } else {
      // Switch to the patient list tab if no patient details are open
      setActiveTab("patient");
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }
  const openSettingsTab = () => {
    setActiveTab("settings");

    setSettingsTabOpen(true);
  };

  const openPatientTab = () => {
    setActiveTab("registration");
    // Set the add patient tab as open
    setAddPatientTabOpen(true);
  };

  const openBedMappingTab = () => {
    // Set the add patient tab as open
    setActiveTab("bedMapping");
    setBedMappingTab(true);
  };

  const openMaintanceTab = () => {
    setActiveTab("maintainance");
    // Set the add patient tab as open
    setMaintainanceTab(true);
  };
  const openOperationTab = () => {
    // Set the add patient tab as open
    setActiveTab("operation");
    setOperationTab(true);
  };
  const openVideoCallingTab = () => {
    setActiveTab("videocalling");
    setOpenVideoCalling(true);
  };

  const openSchedulingTab = () => {
    setActiveTab("scheduling");
    setSchedulingTab(true);
  };

  const closeAddPatient = () => {
    setShowNAvLinks(false);
  };
  const handlePatientClick = (patient) => {
    openPatientDetail(patient);
  };
  console.log("patientsFacitlity", patients);

  return (
    <div>
      <Header
        handleOpenSettings={openSettingsTab}
        handleBedMappingTab={openBedMappingTab}
        handleOpenAddPatient={openPatientTab}
        handleMaintainanceTab={openMaintanceTab}
        handleOperationTab={openOperationTab}
        handleVideoCalling={openVideoCallingTab}
        handleScheduling={openSchedulingTab}
        closeAddPatient={closeAddPatient}
        changeFacility={changeFacility}
      />
      <section className="patient-list ">
        <div className="d-flex mb-2 ps-4 pt-2 pe-4 bg-patient-tab">
          <div className="tabs-container ">
            <button
              style={{
                paddingTop: "0.4rem",
                paddingBottom: "0.4rem",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
              type="button"
              className={`btn ${
                activeTab === "patient" ||
                activeTab === "MyPatient" ||
                activeTab === "NewPatient" ||
                activeTab === "ReVisit" ||
                activeTab === "allPatients"
                  ? "btn-success"
                  : "btn-outline-success"
              }`}
              onClick={() => {
                handleTabChange("patient");
                handleRefreshPatient();
                setShowNAvLinks(true);
              }}
            >
              Patients
            </button>
            {addPatientTabOpen && (
              <button
                style={{
                  marginLeft: "0.3rem",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: hoveredPatient
                    ? "tranparent"
                    : "1px solid transparent",
                }}
                type="button"
                className={`btn ${
                  activeTab === "registration"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => {
                  handleTabChange("registration");
                }}
                onMouseEnter={() => setHoveredPatient(true)}
                onMouseLeave={() => setHoveredPatient(false)}
              >
                Add Patient
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: hoveredPatient ? "#fff" : "#ba2748",
                    color: hoveredPatient ? "#000" : "#fff",
                  }}
                  icon={faTimes}
                  className="ms-2 fa-visit-icon"
                  onClick={() => setAddPatientTabOpen(false)}
                />
              </button>
            )}
            {maintainanceTab && (
              <button
                style={{
                  marginLeft: "0.3rem",

                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: hoveredPatient
                    ? "tranparent"
                    : "1px solid transparent",
                }}
                type="button"
                className={`btn ${
                  activeTab === "maintainance"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => handleTabChange("maintainance")}
                onMouseEnter={() => setHoveredPatient(true)}
                onMouseLeave={() => setHoveredPatient(false)}
              >
                Maintainance
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: hoveredPatient ? "#fff" : "#ba2748",
                    color: hoveredPatient ? "#000" : "#fff",
                  }}
                  icon={faTimes}
                  className="ms-2"
                  onClick={() => setMaintainanceTab(false)}
                />
              </button>
            )}
            {operationTab && (
              <button
                style={{
                  marginLeft: "0.3rem",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: hoveredPatient
                    ? "tranparent"
                    : "1px solid transparent",
                }}
                type="button"
                className={`btn ${
                  activeTab === "operation"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => handleTabChange("operation")}
                onMouseEnter={() => setHoveredPatient(true)}
                onMouseLeave={() => setHoveredPatient(false)}
              >
                Operation
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: hoveredPatient ? "#fff" : "#ba2748",
                    color: hoveredPatient ? "#000" : "#fff",
                  }}
                  icon={faTimes}
                  className="ms-2"
                  onClick={() => setOperationTab(false)}
                />
              </button>
            )}
            {videoCalling && (
              <button
                style={{
                  marginLeft: "0.3rem",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: hoveredPatient
                    ? "tranparent"
                    : "1px solid transparent",
                }}
                type="button"
                className={`btn ${
                  activeTab === "videocalling"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => handleTabChange("videocalling")}
                onMouseEnter={() => setHoveredPatient(true)}
                onMouseLeave={() => setHoveredPatient(false)}
              >
                Video Calling
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: hoveredPatient ? "#fff" : "#ba2748",
                    color: hoveredPatient ? "#000" : "#fff",
                  }}
                  icon={faTimes}
                  className="ms-2"
                  onClick={() => setOpenVideoCalling(false)}
                />
              </button>
            )}
            {bedMappingTab && (
              <button
                style={{
                  marginLeft: "0.3rem",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: hoveredPatient
                    ? "tranparent"
                    : "1px solid transparent",
                }}
                type="button"
                className={`btn ${
                  activeTab === "bedMapping"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => handleTabChange("bedMapping")}
                onMouseEnter={() => setHoveredPatient(true)}
                onMouseLeave={() => setHoveredPatient(false)}
              >
                Bed Mapping
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: hoveredPatient ? "#fff" : "#ba2748",
                    color: hoveredPatient ? "#000" : "#fff",
                  }}
                  icon={faTimes}
                  className="ms-2"
                  onClick={() => {
                    setBedMappingTab(false);
                  }}
                />
              </button>
            )}
            {schedulingTab && (
              <button
                style={{
                  marginLeft: "0.3rem",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: hoveredPatient
                    ? "tranparent"
                    : "1px solid transparent",
                }}
                type="button"
                className={`btn ${
                  activeTab === "scheduling"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => handleTabChange("scheduling")}
                onMouseEnter={() => setHoveredPatient(true)}
                onMouseLeave={() => setHoveredPatient(false)}
              >
                Scheduling
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: hoveredPatient ? "#fff" : "#ba2748",
                    color: hoveredPatient ? "#000" : "#fff",
                  }}
                  icon={faTimes}
                  className="ms-2"
                  onClick={() => {
                    setSchedulingTab(false);
                  }}
                />
              </button>
            )}
            {settingsTabOpen && ( // Render settings tab if it's open
              <button
                style={{
                  marginLeft: "0.5rem",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: hovered ? "tranparent" : "1px solid transparent",
                }}
                type="button"
                className={`btn  ${
                  activeTab === "settings"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => handleTabChange("settings")}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                Settings
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: hovered ? "#fff" : "#ba2748",
                    color: hovered ? "#000" : "#fff",
                  }}
                  icon={faTimes}
                  className="ms-2"
                  onClick={() => setSettingsTabOpen(false)}
                />
              </button>
            )}

            {openPatientDetails.map((patient) => (
              <button
                key={patient.id}
                className={`btn ${
                  activeTab === patient.id
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                style={{
                  marginLeft: "0.5rem",
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  border: hovered ? "tranparent" : "1px solid transparent",
                  backgroundColor:
                    activeTab === patient.id ? "#0f3258" : "#c8e3ff",
                  color: activeTab === patient.id ? "#fff" : "#000",
                }}
                onClick={() => handleTabChange(patient.id)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                {patient.patient_full_name}
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: hovered ? "#fff" : "#ba2748",
                    color: hovered ? "#000" : "#fff",
                  }}
                  icon={faTimes}
                  className="ms-2"
                  onClick={() => closePatientDetail(patient)}
                />
              </button>
            ))}
          </div>
          <div className="form-floating ms-auto"></div>
          <div className=" d-flex ms-auto ">
            <div className="input-group input-search rounded w-100 ">
              <input
                type="search"
                className="form-control rounded"
                placeholder="Search Patient"
                aria-label="Search"
                aria-describedby="search-addon"
                ref={inputRef}
                value={searchInput}
                onChange={(e) => handleInputChange(e.target.value)}
              />
              {searchInput ? null : (
                <i className="fas fa-search search-icon"></i>
              )}
            </div>
          </div>
          {searchInput && searchResults.length > 0 && (
            <ul className="search-results">
              {searchResults.map((patient) => (
                <li key={patient.id} onClick={() => openPatientDetail(patient)}>
                  {console.log("result", patient)}
                  {patient.first_name} {patient.last_name}
                </li>
              ))}
            </ul>
          )}
        </div>
        {activeTab === "bedMapping" && bedMappingTab && <BedMapping />}

        {/* {(activeTab === "patient" || showNavLnks === true) && (
          <nav>
            <div
              className="nav nav-tabs mb-2 all-patient-new-tab"
              id="nav-tab"
              role="tablist"
            >
           
              <button
                style={{
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  marginLeft: "10px",
                  border: "1px solid #5d5d5d",
                }}
                type="button"
                className={`btn ${
                  activeTab === "allPatients"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => {
                  handleTabChange("allPatients");
                  handleRefreshPatient();
                }}
              >
                All Patients
              </button> */}
        {/* )} */}
        {/* <button
              style={{
                paddingTop: "0.4rem",
                paddingBottom: "0.4rem",
                marginLeft: "10px",

                border: "1px solid #5d5d5d",
              }}
              type="button"
              className={`btn ${
                activeTab === "MyPatient"
                  ? "btn-success"
                  : "btn-outline-success"
              }`}
              onClick={() => {
                handleTabChange("MyPatient");
                handleRefreshPatient();
              }}
            >
              My Patients
            </button> */}
        {/* <button
                style={{
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  marginLeft: "10px",

                  border: "1px solid #5d5d5d",
                }}
                type="button"
                className={`btn ${
                  activeTab === "NewPatient"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => {
                  handleTabChange("NewPatient");
                  handleRefreshPatient();
                }}
              >
                Unassigned Patients
              </button>
              <button
                style={{
                  paddingTop: "0.4rem",
                  paddingBottom: "0.4rem",
                  marginLeft: "10px",

                  border: "1px solid #5d5d5d",
                }}
                type="button"
                className={`btn ${
                  activeTab === "ReVisit"
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => {
                  handleTabChange("ReVisit");
                  handleRefreshPatient();
                }}
              >
                Re-Visit
              </button>
              
            </div>
          </nav>
        )} */}
        {(activeTab === "patient" || showNavLnks === true) && (
          <div className="w-patient-add">
            <nav>
              <select
                value={activeTab}
                onChange={handleOptionTabChange}
                className="form-select mb-2 all-patient-new-tab"
              >
                <option value="allPatients">All Patients</option>
                {/* <option value="MyPatient">My Patients</option> */}
                {/* <option value="NewPatient">Unassigned Patients</option> */}
                <option value="ReVisit">Re-Visit</option>
                <option value="EncounterReport">Encounter Report </option>
                {/* Add more options here if needed */}
              </select>
            </nav>
          </div>
        )}
        {activeTab === "MyPatient" && (
          <MyPatients onPatientClick={handlePatientClick} />
        )}
        {activeTab === "NewPatient" && (
          <NewPatients onPatientClick={handlePatientClick} />
        )}
        {activeTab === "ReVisit" && (
          <ReVisit onPatientClick={handlePatientClick} />
        )}
        {activeTab === "EncounterReport" && (
          <EncounterReport onPatientClick={handlePatientClick} />
        )}

        {(activeTab === "patient" || activeTab === "allPatients") && (
          <>
            <div className="mx-2">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 540 }}>
                  <Table
                    className="css-xn82ks-MuiTable-root"
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="patient-info fixed-all-p">
                      <TableRow>
                        <TableCell align="left">Patient Name</TableCell>
                        <TableCell align="left">Member Id</TableCell>
                        <TableCell align="left">MRN No</TableCell>
                        <TableCell align="left">Facility</TableCell>
                        <TableCell align="left">Floor</TableCell>
                        <TableCell align="left">Room</TableCell>
                        <TableCell align="left">Bed</TableCell>
                        {/* <TableCell align="left">Location/Room#</TableCell> */}
                        <TableCell align="center">Admission Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <tr>
                          <td colSpan="12">
                            <div className="loading-spinner">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        patients
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((patient, index) => (
                            <TableRow
                              hover
                              tabIndex={-1}
                              key={index}
                              style={{
                                backgroundColor:
                                  patient.patient_bed_status === "hospitalized"
                                    ? "#8a1574"
                                    : patient.switch === "Yes"
                                    ? "#FC8EAC"
                                    : "inherit",
                                color:
                                  patient.patient_bed_status ===
                                    "hospitalized" || patient.switch === "Yes"
                                    ? "white"
                                    : "inherit",
                              }}
                            >
                              {console.log("status", patient)}
                              <TableCell
                                align="left"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color:
                                    patient.patient_bed_status ===
                                    "hospitalized"
                                      ? "white"
                                      : "inherit",
                                }}
                              >
                                <div className="d-flex justify-content-center p-user-img-h">
                                  <img
                                    src={
                                      patient.profile_pic ===
                                      "https://soulhousing-api.anchorstech.net/public/uploads/placeholder.jpg"
                                        ? `https://i.pravatar.cc/150?img=${
                                            Math.floor(Math.random() * 21) + 50
                                          }`
                                        : patient.profile_pic
                                    }
                                    alt="Admin"
                                    className="rounded-circle"
                                    width={40}
                                    height={40}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                <h6
                                  style={{ cursor: "pointer" }}
                                  className="p-2"
                                  onClick={() => {
                                    openPatientDetail(patient);
                                    handleTabChange(patient.id);
                                    setActiveTab(patient.id);
                                    setShowNAvLinks(false);
                                  }}
                                >
                                  {patient.patient_full_name}
                                </h6>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color:
                                    patient.patient_bed_status ===
                                    "hospitalized"
                                      ? "white"
                                      : "inherit",
                                }}
                              >
                                {patient.medical_no}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color:
                                    patient.patient_bed_status ===
                                    "hospitalized"
                                      ? "white"
                                      : "inherit",
                                }}
                              >
                                {patient.mrn_no}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color:
                                    patient.patient_bed_status ===
                                    "hospitalized"
                                      ? "white"
                                      : "inherit",
                                }}
                              >
                                {patient.patient_facility}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color:
                                    patient.patient_bed_status ===
                                    "hospitalized"
                                      ? "white"
                                      : "inherit",
                                }}
                              >
                                {patient.floor_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color:
                                    patient.patient_bed_status ===
                                    "hospitalized"
                                      ? "white"
                                      : "inherit",
                                }}
                              >
                                {patient.room_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  color:
                                    patient.patient_bed_status ===
                                    "hospitalized"
                                      ? "white"
                                      : "inherit",
                                }}
                              >
                                {patient.bed_title}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color:
                                    patient.patient_bed_status ===
                                    "hospitalized"
                                      ? "white"
                                      : "inherit",
                                }}
                              >
                                {patient.admission_date
                                  ? moment(patient.admission_date)
                                      .utc()
                                      .format("MM/DD/YYYY ")
                                  : ""}
                              </TableCell>
                            </TableRow>
                          ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={patients.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </>
        )}

        {activeTab === "registration" && addPatientTabOpen && (
          <RegistrationForm
            onFormSubmit={() => {
              setAddPatientTabOpen(false);
              handleTabChange("patient");
            }}
          />
        )}
        {activeTab &&
          openPatientDetails.find((detail) => detail.id === activeTab) && (
            <PatientDetail
              patient={openPatientDetails.find(
                (detail) => detail.id === activeTab
              )}
              onClose={() => closePatientDetail({ id: activeTab })}
            />
          )}
        {/* {openPatientDetails.map((patient, index) => (
          <div key={index} className={`patient-detail-tab ${activeTab === patient.id ? 'active' : ''}`}>
            {console.log("openPatientDetailsClik",activeTab)}
            {activeTab === patient.id && (
              <PatientDetail
                key={patient.id}
                patient={patient}
                onClose={() => closePatientDetail(patient)}
                // onRefresh={handleRefreshPatient()}
                // activeTab={activeTab}
              />
            )}
          </div>
        ))} */}

        {activeTab === "settings" && settingsTabOpen && <Settings />}
        {activeTab === "maintainance" && maintainanceTab && <Maintenance />}
        {activeTab == "operation" && operationTab && <Operation />}
        {activeTab == "videocalling" && videoCalling && <Mainpage />}
        {activeTab == "scheduling" && schedulingTab && <Scheduling />}
      </section>
    </div>
  );
};

const PatientDetailTab = ({ patient, onClose, onClick, activeTab }) => {
  const [hovered, setHovered] = useState(false);
  const isActive = activeTab === patient.id;

  return (
    <button
      style={{
        marginLeft: "0.5rem",
        paddingTop: "0.4rem",
        paddingBottom: "0.4rem",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        border: hovered ? "tranparent" : "1px solid transparent",
        backgroundColor: isActive ? "#0f3258" : "#c8e3ff",
        color: isActive ? "#fff" : "#000",
      }}
      className="btn btn-outline-success m-1"
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {`${patient.patient_full_name}`}
      <FontAwesomeIcon
        style={{
          borderRadius: "5px",
          padding: "1px 3px 1px 3px",
          backgroundColor: hovered ? "#fff" : "#ba2748",
          color: hovered ? "#000" : "#fff",
          color: isActive ? "#000" : "#fff",
          backgroundColor: isActive ? "#fff" : "#ba2748",
          backgroundColor: isActive == hovered ? "#ba2748" : "#fff",
          color: isActive == hovered ? "#fff" : "#000",
        }}
        // onMouseEnter={(e) => e.target.style.backgroundColor = 'white'}

        icon={faTimes}
        className="ms-2"
        onClick={onClose}
      />
    </button>
  );
};

export default PatientList;
