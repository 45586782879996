import React from 'react';
import './Card.css'
// Card component for rendering individual cards
import Spinner from 'react-bootstrap/Spinner';
const Card = ({ title, items,loading }) => {

  return (
    <div className="demographics-cards">
      <div className="basic-bg">
        <h5>{title}</h5>
      </div>
      <div className="basic-info">
        <ul>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" style={{ color: 'blue' }}/>
          </div>
        ) : (items.map((item, index) => (
            <li key={index}><a href={item.link}><span>{item.label}</span>{item.value}</a></li>
          ))
        )}
            {/* {title === "Most Recent Encounter" && (
            <li>
              <button className="btn-encounters-links button mb-2" style={{marginRight:'0.5rem'}}><a>Signed</a></button>
              <button className="btn-encounters-links button mb-2 me-2" style={{marginRight:'0.5rem'}}><a>View Last Signed Encounter</a></button>
              <button className="btn-encounters-links button mb-2" style={{marginRight:'0.5rem'}}><a>View</a></button>
              <button className="btn-encounters-links button mb-2" style={{marginRight:'0.5rem',marginTop:'0.5rem'}}><a>Follow-up Encounter</a></button>
              <button className="btn-encounters-links button" style={{marginRight:'0.5rem'}}><a>No Encounter</a></button>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};
export default Card
