import React from "react";
import "./ChangePin.css";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValue = {
  password: "",
  pin: "",
  confirm_pin: "",
};
const ChangePin = () => {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValue,
      onSubmit: async (values, action) => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          const pin = await axios.post(
            process.env.REACT_APP_ORIGIN_URL + "/set-pin",
            {
              password: values.password,
              pin: values.pin,
              confirm_pin: values.confirm_pin,
            },
            config
          );
          toast.success(pin.message);
        } catch (error) {
          console.log(error.message);
          toast.error(error.message);
        }
        action.resetForm();
      },
      validate: (values) => {
        const errors = {};

        // Check if password and confirm password match
        if (values.pin !== values.confirm_pin) {
          errors.confirm_pin = "Pin do not match";
        }

        return errors;
      },
    });
  return (
    <div className="password-forms settings-right ">
      <h5>Change pin</h5>
      <div className="row mt-4 g-3">
        <div className="col-xl-4">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="currentPassword"
              name="password"
              placeholder="confirm password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="currentPassword">Confirm Password</label>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="newPassword"
              placeholder="new pin"
              name="pin"
              value={values.pin}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="newPassword">New Pin</label>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control w-100 ${
                errors.confirm_pin && touched.confirm_pin ? "is-invalid" : ""
              }`}
              id="confirmNewPassword"
              placeholder="confirm pin"
              name="confirm_pin"
              value={values.confirm_pin}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="confirmNewPassword">Confirm Pin</label>
            {errors.confirm_pin && touched.confirm_pin && (
              <div className="invalid-feedback">{errors.confirm_pin}</div>
            )}
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary mt-3"
        onClick={handleSubmit}
      >
        Save Pin
      </button>
      <ToastContainer />
    </div>
  );
};

export default ChangePin;
