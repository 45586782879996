import React, { useState, useEffect } from "react";
import "../PatientProfile.css";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Button } from "react-bootstrap";
import { handleGetVital } from "../../../../components/services/VitalService";
import { encounterValidation } from "../../../../components/validation/RegistrationValidation";
import PsychatricEncounter from "./PsychatricEncounter";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";

const getCurrentLocalDateTime = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate;
};

const initialValues = {
  signed_at: new Date(),
  encounter_type: "",
  specialty: "",
  reason: "",
  location: "",
  provider_id_patient: "",
  attachment: "",
  skip: false,
  blood_pressure: "",
  height_in: "",
  weight_lbs: "",
  bmi_in: "",
  systolic: "",
  diastolic: "",
  body_temp_result_f: "",
  pulse_beats_in: "",
  resp_rate: "",
  head_in: "",
  waist_in: "",
  glucose: "",
};

const AddEncounter = ({
  handleCloseEncounterClick,
  id,
  onEncounterCreated,
  onEncounterCreatedFetch,
  editEncounter,
}) => {
  const calculateBMI = (weightLbs, heightIn) => {
    const heightInMeters = heightIn * 0.0254;
    const weightInKg = weightLbs * 0.453592;
    return (weightInKg / (heightInMeters * heightInMeters)).toFixed(2);
  };
  const [formData, setFormData] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [isPsychatricSelected, setIsPsychatricSelected] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  const getFormData = async () => {
    try {
      setOptionsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/patient-encounter-information/${id}`,
        config
      );
      setFormData(response.data);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setOptionsLoading(false);
    }
  };

  const getInitialEncounter = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-latest-vitals/${id}`,
        config
      );
      setInitialData(response.data.data);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    getFormData();
    getInitialEncounter();
  }, []);
  useEffect(() => {
    // getFormData();
    if (editEncounter) {
      try {
        setFieldValue("signed_at", new Date(editEncounter.signed_at));
        // setFieldValue("encounter_type", editEncounter.encounter_type_title);
        // setFieldValue("specialty", editEncounter.specialty_title);
        setFieldValue("reason", editEncounter.reason);
        setFieldValue("location", editEncounter.location);
        setFieldValue("provider_id_patient", editEncounter.provider_id_patient);
      } catch (e) {
        console.error("Invalid date", e);
      }

      // setFormData(initialEncounterData);
    }
    setFieldValue("blood_pressure", formData.blood_pressure);
  }, [editEncounter]);
  console.log("form", initialData);
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: encounterValidation,
    onSubmit: async (values, action) => {
      try {
        setSaveLoading(true);
        const formData = new FormData();
        formData.append("patient_id", id);
        formData.append("signed_at", values.signed_at);
        formData.append("encounter_type", values.encounter_type);
        formData.append("specialty", values.specialty);
        formData.append("reason", values.reason);
        formData.append("location", values.location);
        formData.append("provider_id_patient", values.provider_id_patient);

        formData.append("skip", values.skip);
        formData.append("blood_pressure", values.blood_pressure);
        formData.append("height_in", values.height_in);
        formData.append("weight_lbs", values.weight_lbs);
        formData.append("bmi_in", values.bmi_in);
        formData.append("systolic", values.systolic);
        formData.append("diastolic", values.diastolic);
        formData.append("body_temp_result_f", values.body_temp_result_f);
        formData.append("pulse_beats_in", values.pulse_beats_in);
        formData.append("resp_rate", values.resp_rate);
        formData.append("head_in", values.head_in);
        formData.append("waist_in", values.waist_in);
        formData.append("glucose", values.glucose);

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(
          process.env.REACT_APP_ORIGIN_URL + `/add-patient-encounter`,
          formData,
          config
        );

        toast.success(response.data.message);
        onEncounterCreated(response.data);
        onEncounterCreatedFetch();
        action.resetForm();
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
      } finally {
        setSaveLoading(false);
      }
    },
  });
  console.log("id", id);
  const handleUpdateSubmit = async () => {
    try {
      setSaveLoading(true);
      const updateData = {
        ...values,
        encounter_id: editEncounter.id,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/update-patient-encounter",
        updateData,
        config
      );

      toast.success(response.data.message);
      onEncounterCreated(response.data);
      onEncounterCreatedFetch();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setSaveLoading(false);
    }
  };
  useEffect(() => {
    if (values.specialty === "63") {
      // Assuming "63" is the ID for Psychatric specialty
      setIsPsychatricSelected(true);
    } else {
      setIsPsychatricSelected(false);
    }
  }, [values.specialty]);
  useEffect(() => {
    if (initialData) {
      setFieldValue("blood_pressure", initialData.blood_pressure || "");
      setFieldValue("height_in", initialData.height_in || "");
      setFieldValue("weight_lbs", initialData.weight_lbs || "");
      setFieldValue("bmi_in", initialData.bmi_in || "");
      setFieldValue("resp_rate", initialData.resp_rate || "");
      setFieldValue("glucose", initialData.glucose || "");
      setFieldValue("waist_in", initialData.waist_in || "");
      setFieldValue("body_temp_result_f", initialData.body_temp_result_f || "");
      setFieldValue("pulse_beats_in", initialData.pulse_beats_in || "");
      // Set other fields as needed from initialData
    }
  }, [initialData]);

  const handleWeightHeightChange = (e) => {
    const { name, value } = e.target;
    handleChange(e);

    if (name === "weight_lbs" || name === "height_in") {
      const newWeightLbs = name === "weight_lbs" ? value : values.weight_lbs;
      const newHeightIn = name === "height_in" ? value : values.height_in;

      if (newWeightLbs && newHeightIn) {
        const bmi = calculateBMI(newWeightLbs, newHeightIn);
        setFieldValue("bmi_in", bmi);
      }
    }
  };
  // After successfully creating encounter
  useEffect(() => {
    setFieldValue(" signed_at", getCurrentLocalDateTime());
  }, []);
  console.log("edit", editEncounter);
  console.log("info", id);
  return (
    <div>
      <div className="d-flex flex-column h-100 vital-heading">
        <button
          type="button"
          className="btn btn-primary ms-auto"
          onClick={handleCloseEncounterClick}
        >
          {" "}
          Back
        </button>
        <div className="encounter-links mt-2  ">
          <div className="row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating">
                {/* <input
         type="datetime-local"
         className="form-control "
         id="signed_at"
         name="signed_at"
         value={values.signed_at}
         onChange={handleChange}
         onBlur={handleBlur}
       /> */}
                <FloatLabel>
                  <Calendar
                    value={values.signed_at}
                    onChange={(e) => setFieldValue("signed_at", e.value)}
                    onBlur={handleBlur}
                    dateFormat="mm/dd/yy"
                    showIcon
                    showTime
                    hourFormat="12"
                    hideOnDateTimeSelect="true"
                  />
                  <label for="signed_at">Encounter Date Time</label>
                </FloatLabel>
              </div>
            </div>

            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="reason"
                  name="reason"
                  placeholder="visit reason"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="reason">Visit Reason</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating  border-0">
                <select
                  className="form-select"
                  id="provider_id_patient"
                  name="provider_id_patient"
                  aria-label="Floating label select example"
                  value={values.provider_id_patient}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {/* <option value="">-Select-</option> */}

                  {optionsLoading ? (
                    <option>Loading...</option>
                  ) : (
                    formData.provider &&
                    formData.provider.map((provider, index) => (
                      <option
                        key={provider.id}
                        value={provider.id}
                        selected={
                          provider.id === formData.loginProvider
                            ? "selected"
                            : null
                        }
                      >
                        {provider.name}
                      </option>
                    ))
                  )}
                </select>
                <label for="provider_id_patient">Provider</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating  border-0">
                <select
                  className="form-select"
                  id="specialty"
                  name="specialty"
                  aria-label="Floating label select example"
                  value={values.specialty}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //  disabled={editEncounter}
                >
                  <option value="">Select</option>

                  {optionsLoading ? (
                    <option>Loading...</option>
                  ) : (
                    formData.Specialty &&
                    formData.Specialty.map((specialty, index) => (
                      <option key={specialty.id} value={specialty.id}>
                        {specialty.title}
                      </option>
                    ))
                  )}
                </select>
                <label for="specialty">
                  Speciality{" "}
                  <span style={{ color: "red", fontSize: "13px" }}>*</span>
                </label>
              </div>
              {errors.specialty && touched.specialty ? (
                <p style={{ color: "red" }}>{errors.specialty}</p>
              ) : null}
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating  border-0">
                <select
                  className="form-select"
                  id="encounter_type"
                  name="encounter_type"
                  aria-label="Floating label select example"
                  value={values.encounter_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //  disabled={editEncounter}
                >
                  <option value="">Select</option>

                  {optionsLoading ? (
                    <option>Loading...</option>
                  ) : (
                    formData.encounter_type &&
                    formData.encounter_type.map((encounter, index) => (
                      <option key={encounter.id} value={encounter.id}>
                        {encounter.title}
                      </option>
                    ))
                  )}
                </select>
                <label for="encounter_type">
                  Encounter Type{" "}
                  <span style={{ color: "red", fontSize: "13px" }}>*</span>
                </label>
              </div>
              {errors.encounter_type && touched.encounter_type ? (
                <p style={{ color: "red" }}>{errors.encounter_type}</p>
              ) : null}
            </div>
            {/* <div className="col-3">
     <div className="form-floating">
     <select
         className="form-select"
         id="location"
         name="location"
         aria-label="Floating label select example"
         value={values.facilities}
         onChange={handleChange}
         onBlur={handleBlur}
       >
         <option value="">-Select-</option>

         {optionsLoading ? (
           <option>Loading...</option>
         ) : (
           formData.facilities &&
           formData.facilities.map((facilitie, index) => (
             <option key={facilitie.id} value={facilitie.id}>
               {facilitie.address}
             </option>
           ))
         )}
       </select>
       <label for="location">Location</label>
     </div>
   </div> */}
          </div>
          {!editEncounter && (
            <div>
              <div style={{ display: "flex" }}>
                <h5>Vital Signs:</h5>
                <div className="form-check ms-auto ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={() => setFieldValue("skip", !values.skip)}
                    checked={values.skip}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Skip Vital
                  </label>
                </div>
              </div>

              <div className="mt-2 row g-3 mb-3">
                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control "
                      id="blood_pressure"
                      name="blood_pressure"
                      placeholder="blood pressure"
                      value={values.blood_pressure}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="blood_pressure">Blood Pressure </label>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="pulse_beats_in"
                      name="pulse_beats_in"
                      placeholder="Pulse (beats/min)"
                      value={values.pulse_beats_in}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="pulse_beats_in">Pulse (beats/min) </label>
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="glucose"
                      name="glucose"
                      placeholder="Glucose"
                      value={values.glucose}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="glucose">Glucose</label>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="resp_rate"
                      name="resp_rate"
                      placeholder="Resp Rate (breaths/min)"
                      value={values.resp_rate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="resp_rate">Resp Rate (breaths/min) </label>
                  </div>
                </div>
              </div>

              <div className="row g-3 mb-3">
                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control "
                      id="height_in"
                      name="height_in"
                      placeholder="Height (in)"
                      value={values.height_in}
                      onChange={handleWeightHeightChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="height_in">Height (in)</label>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="weight_lbs"
                      name="weight_lbs"
                      placeholder="Weight (lb)"
                      value={values.weight_lbs}
                      onChange={handleWeightHeightChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="weight_lbs">Weight (lb)</label>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="bmi_in"
                      name="bmi_in"
                      placeholder="BMI Interp. BMI"
                      value={values.bmi_in}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="bmi_in">BMI Interp. BMI </label>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="body_temp_result_f"
                      name="body_temp_result_f"
                      placeholder="Temp (F)"
                      value={values.body_temp_result_f}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="body_temp_result_f">Temp (F)</label>
                  </div>
                </div>
              </div>

              <div className="row g-3 mb-3">
                <div className="col-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="waist_in"
                      name="waist_in"
                      placeholder="Waist (in)"
                      value={values.waist_in}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="waist_in">Waist (in)</label>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="encounter-buttoons d-flex">
            <Button variant="secondary" onClick={handleCloseEncounterClick}>
              Cancel
            </Button>
            <button
              type="button"
              onClick={editEncounter ? handleUpdateSubmit : handleSubmit}
              className="btn btn-primary ms-3"
            >
              {editEncounter
                ? saveLoading
                  ? "Processing..."
                  : "Update"
                : saveLoading
                ? "Processing..."
                : "Save"}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddEncounter;
