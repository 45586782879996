import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import NoData from "../../../assets/images/no-data-found.png";
import axios from "axios";
import "../profile/PatientProfile.css";
import { useFormik } from "formik";
import { IconButton } from "@mui/material";
import { noteValidation } from "../../../components/validation/RegistrationValidation";
const Notes = ({ id, details }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disbaleField, setDisableField] = useState(true);
  const [notes, setNotes] = useState([]);
  const [editMode, setEditMode] = useState(false); // Track if it's edit mode
  const [selectedNote, setSelectedNote] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setEditMode(false);
    setSelectedNote(null);
  };

  const getNotes = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-notepad/${id}`,
        config
      );
      setNotes(res.data.notepad);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      title: "",
      date: new Date(),
      note_desc: "",
      file: "",
    },
    validationSchema: noteValidation,
    onSubmit: async (values, action) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("patient_id", id);
        formData.append("title", values.title);
        formData.append("note_date", values.date);
        formData.append("node_desc", values.note_desc);
        if (values.file) {
          formData.append("file", values.file);
        }

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        // If edit mode, send update request
        if (editMode && selectedNote) {
          formData.append("id", selectedNote.id); // Assuming 'id' is the note identifier
          await axios.post(
            process.env.REACT_APP_ORIGIN_URL + "/update-notepad",
            formData,
            config
          );
          toast.success("Note updated successfully");
        } else {
          await axios.post(
            process.env.REACT_APP_ORIGIN_URL + "/store-notepad",
            formData,
            config
          );
          toast.success("Note added successfully");
        }

        handleClose();
        getNotes();
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
      resetForm();
    },
  });

  const generateAndOpenPDF = async (documentPdf) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/file-show`,
        { file_name: documentPdf }, // Replace with the actual structure to send
        config
      );
      console.log("res", res.data);
      // Extract base64 encoded PDF content from response
      const pdfData = res.data.file;
      const mimeType = res.data.mime_type;

      // Convert base64 to Blob
      const byteCharacters = atob(pdfData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      // Create URL and open in new tab
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.log("Error generating or opening PDF:", error.message);
      // Handle error as needed
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Get the base64 string without the prefix
      reader.onerror = (error) => reject(error);
    });
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const base64File = await getBase64(file);
      setFieldValue("file", base64File); // Store the base64 string
    }
  };
  const handleEdit = (note) => {
    setSelectedNote(note);
    setFieldValue("title", note.title);
    setFieldValue("date", new Date(note.date));
    setFieldValue("note_desc", note.node_desc);
    setEditMode(true);
    handleShow();
  };

  useEffect(() => {
    getNotes();
  }, []);
  console.log("details", notes);
  return (
    <div>
      <div className="document-content d-flex align-items-center">
        <div className="avatar dropdown">
          <div
            className="admin-name ms-4 dropdown-toggle no-arrow-dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <a className="all-doc">Notes</a>
            <a>
              <i className="fas fa-angle-down drop-icon  ms-2"></i>
            </a>
          </div>
        </div>

        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleShow}
          >
            <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
            Notes
          </button>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="allery-header">
            <Modal.Title>{editMode ? "Edit Note" : "Add Note"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="allergy-body">
            <div className="row mt-2">
              <div className="col-6">
                <div className="allery-feilds">
                  <h5 style={{ padding: "0.5rem" }}>
                    Title{" "}
                    <span style={{ color: "red", fontSize: "13px" }}>*</span>
                  </h5>
                  <div className="d-flex align-items-center">
                    <div class="form-floating mb-4 ms-1 w-100">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.title && touched.title ? "is-invalid" : ""
                        }`}
                        id="title"
                        placeholder="title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="title">Title </label>
                      {/* {errors.title && touched.title ? (
                      <p style={{ color: "red" }}>{errors.title}</p>
                    ) : null} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="allery-feilds">
                  <h5 style={{ padding: "0.5rem" }}>Date</h5>
                  <div className="d-flex align-items-center">
                    <div class="form-floating mb-4 ms-1 w-100">
                      <Calendar
                        value={values.date}
                        onChange={(e) => setFieldValue("date", e.value)}
                        onBlur={handleBlur}
                        dateFormat="mm/dd/yy"
                        showIcon
                      />
                      {/* <label htmlFor="date">Select</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-6">
                <div className="allery-feilds">
                  <h5 style={{ padding: "0.5rem" }}>
                    Patient Name{" "}
                    <span style={{ color: "red", fontSize: "13px" }}>*</span>
                  </h5>
                  <div className="d-flex align-items-center">
                    <div class="form-floating mb-4 ms-1 w-100">
                      <input
                        type="text"
                        className="form-control"
                        id="patient_name"
                        placeholder="patient_name"
                        name="patient_name"
                        value={details.patient_full_name}
                        disabled={disbaleField}
                      />
                      <label htmlFor="patient_name">Patient Name </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="allery-feilds">
                  <h5 style={{ padding: "0.5rem" }}>Provider Name</h5>
                  <div className="d-flex align-items-center">
                    <div class="form-floating mb-4 ms-1 w-100">
                      <input
                        type="text"
                        className="form-control"
                        id="provider_full_name"
                        placeholder="provider_full_name"
                        name="provider_full_name"
                        value={details.provider_full_name}
                        disabled={disbaleField}
                      />
                      <label htmlFor="provider_full_name">Provider Name </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5 style={{ padding: "0.5rem" }}>Description</h5>
            <div className="form-floating ">
              <textarea
                style={{ height: "5rem" }}
                className="form-control"
                placeholder="Leave a comment here"
                id="note_desc"
                name="note_desc"
                value={values.note_desc}
                onChange={handleChange}
                onBlur={handleBlur}
              ></textarea>
              <label htmlFor="note_desc">Message</label>
            </div>

            <div className="col">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div class="mb-3">
                    <h5 for="formFile" class="form-label my-2 mt-4">
                      Reported
                    </h5>
                    <input
                      type="file"
                      className="form-control"
                      id="file"
                      name="file"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="allergy-footer-btn">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              variant="primary"
              onClick={() => {
                handleSubmit();
              }}
            >
              {editMode ? "Update" : "Save"}
              {/* {Loading ? "Processing..." : isEditing ? "Update" : "Save"} */}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="encounter-table  table-responsive">
        <table className="table">
          <thead>
            <tr className="mb-2">
              <th>No</th>
              <th>Title</th>
              <th>Date</th>
              {/* <th>Message</th> */}
              <th>Uploaded by</th>
              <th>PDF</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="12">
                  <div className="loading-spinner">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : notes && notes.length > 0 ? (
              notes.map((note, index) => {
                return (
                  <tr key={index}>
                    {console.log("notes", note)}
                    <td>{index + 1}</td>
                    <td>
                      {note.title}
                      {/* <span className="ms-3">{document.title}</span> */}
                    </td>
                    <td>{moment(note.date).utc().format("MM/DD/YYYY ")}</td>

                    {/* <td>{note.node_desc}</td> */}
                    <td>{note.provider_name}</td>
                    <td>
                      {" "}
                      <IconButton onClick={() => generateAndOpenPDF(note.file)}>
                        <FontAwesomeIcon icon={faFilePdf} />
                      </IconButton>
                    </td>

                    <td>
                      <a style={{ color: "blue", cursor: "pointer" }}>
                        <i
                          className="fas fa-edit"
                          onClick={() => handleEdit(note)}
                        ></i>
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="12" align="center">
                  <img
                    src={NoData}
                    alt="No data found"
                    style={{ width: "150px", margin: "20px 0" }}
                  />
                  <p>No Notes found.</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Notes;
