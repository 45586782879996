import React, { useState, useId, useEffect } from "react";
import "../PatientProfile.css";
import RegisterVitals from "./RegisterVitals";
import axios from "axios";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentsDollar, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux";
import NoData from "../../../../assets/images/no-data-found.png";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { FloatLabel } from "primereact/floatlabel";
const initialDateValue = {
  from_date: "",
  to_date: "",
};
const Vital = ({ id }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [showRegisterVital, setShowRegisterVital] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vitals, setVitals] = useState([]);
  const [dateWiseVitals, setDateWiseVitals] = useState([]);
  const [showWiseVitalsData, setShowWiseVitalsData] = useState(false);
  const [editVital, setEditVital] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleRegisterVitalClick = () => {
    setShowRegisterVital(true);
  };
  const handleCloseRegisterVitalClick = () => {
    setShowRegisterVital(false);
    handleGetVital();
  };

  const handleGetVital = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/vitals/${id}`,
        config
      );
      console.log("vitals", response.data.data);
      setVitals(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: initialDateValue,
    onSubmit: async (values, action) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await axios.post(
          process.env.REACT_APP_ORIGIN_URL + `/search-vital`,
          {
            from_date: values.from_date,
            to_date: values.to_date,
            patient_id: id,
          },
          config
        );
        console.log("datevitals", response.data.data);
        setDateWiseVitals(response.data.data);
      } catch (error) {
        console.log(error.message);
      }
      // action.resetForm()
    },
  });

  const handleDeleteVitals = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.delete(
        process.env.REACT_APP_ORIGIN_URL + `/delete-vital/${id}`,
        config
      );
      toast.success(response.message);
      handleGetVital();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleEditVital = (vital) => {
    setEditVital(vital);
    setShowRegisterVital(true);
  };
  useEffect(() => {
    handleGetVital();
  }, []);

  const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 20, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 20, textTransform: "capitalize" } },
    },
  }));
  return (
    <div>
      <div className="overflow-auto h-100">
        {showRegisterVital && (
          <RegisterVitals
            handleCloseRegisterVitalClick={handleCloseRegisterVitalClick}
            patient_id={id}
            vital={editVital}
          />
        )}
        {!showRegisterVital && (
          <div>
            <div className="vital-heading ">
              <div style={{ display: "flex" }}>
                <h5>Vitals</h5>
              </div>
            </div>
            <div className="vital-bg">
              <div className="row align-items-center">
                {permissions.vitals_table_view && (
                  <>
                    <div className="col-3">
                      <div className="form-floating">
                        {/* <input
                      type="date"
                      name="from_date"
                      className="form-control w-100"
                      id="from_date"
                      placeholder="name@example.com"
                      value={values.from_date}
                      onChange={handleChange}
                    /> */}
                        <FloatLabel>
                          <Calendar
                            value={values.from_date}
                            onChange={(e) =>
                              setFieldValue("from_date", e.value)
                            }
                            onBlur={handleBlur}
                            dateFormat="mm/dd/yy"
                            showIcon
                          />
                          <label htmlFor="from_date">From Date</label>
                        </FloatLabel>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-floating">
                        <FloatLabel>
                          <Calendar
                            value={values.to_date}
                            onChange={(e) => setFieldValue("to_date", e.value)}
                            onBlur={handleBlur}
                            dateFormat="mm/dd/yy"
                            showIcon
                          />
                          <label htmlFor="to_date">To Date</label>
                        </FloatLabel>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-3">
                  {permissions.vitals_table_view && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleSubmit();
                        setShowWiseVitalsData(true);
                      }}
                    >
                      Search
                    </button>
                  )}

                  {permissions.vitals_add_button && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleRegisterVitalClick}
                      style={{ marginLeft: "0.5rem" }}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
            {permissions.vitals_table_view && (
              <>
                <StyledTable>
                  <TableHead className="patient-info">
                    <TableRow>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Blood Pressure</TableCell>
                      <TableCell align="left">Height</TableCell>
                      <TableCell align="left">Weight</TableCell>
                      <TableCell align="left">BMI</TableCell>
                      <TableCell align="left">Systolic</TableCell>
                      <TableCell align="left">Diastolic</TableCell>
                      {permissions.vitals_table_action && (
                        <TableCell align="left">Action</TableCell>
                      )}
                      {/* <TableCell align="left">Temp</TableCell>
                  <TableCell align="left">Pulse</TableCell>
                  <TableCell align="left">Resp Rate</TableCell>
                  <TableCell align="left">Head</TableCell>
                  <TableCell align="left">Waist</TableCell>
                  <TableCell align="left">Glucose</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <tr>
                        <td colSpan="12">
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : showWiseVitalsData ? (
                      dateWiseVitals &&
                      dateWiseVitals
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((vital, index) => (
                          <TableRow key={index}>
                            {console.log("vitalssss", vital)}
                            <TableCell>
                              {" "}
                              {vital.date
                                ? moment(vital.date)
                                    .utc()
                                    .format("MM/DD/YYYY hh:mm:ss A")
                                : ""}
                            </TableCell>
                            <TableCell>
                              {vital.blood_pressure && vital.blood_pressure}
                            </TableCell>
                            <TableCell>
                              {vital.height_in && vital.height_in}
                            </TableCell>
                            <TableCell>
                              {vital.weight_lbs && vital.weight_lbs}
                            </TableCell>
                            <TableCell>
                              {vital.bmi_in && vital.bmi_in}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {vital.systolic && vital.systolic}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {vital.diastolic && vital.diastolic}
                            </TableCell>
                            {permissions.vitals_table_action && (
                              <TableCell>
                                <div style={{ display: "flex" }}>
                                  {permissions.vitals_table_edit_button && (
                                    <li>
                                      <a onClick={() => handleEditVital(vital)}>
                                        <i className="fas fa-edit"></i>
                                      </a>
                                    </li>
                                  )}
                                  {permissions.vitals_table_delete_button && (
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        color: "red",
                                        paddingLeft: "1rem",
                                      }}
                                      onClick={() =>
                                        handleDeleteVitals(vital.id)
                                      }
                                    ></i>
                                  )}
                                </div>
                              </TableCell>
                            )}
                            {/* <TableCell>{vital.body_temp_result_f && vital.body_temp_result_f}</TableCell>
                      <TableCell>{vital.pulse_beats_in && vital.pulse_beats_in}</TableCell>
                      <TableCell> {vital. resp_rate && vital. resp_rate} </TableCell>
                      <TableCell>{vital. head_in && vital. head_in}</TableCell>
                      <TableCell>{vital. waist_in && vital. waist_in}</TableCell>
                      <TableCell>{vital. glucose && vital. glucose}</TableCell> */}
                          </TableRow>
                        ))
                    ) : vitals && vitals.length > 0 ? (
                      vitals
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((vital, index) => (
                          <TableRow key={index}>
                            {console.log("vitalssss", vital)}
                            <TableCell>
                              {vital.date
                                ? moment(vital.date)
                                    .utc()
                                    .format("MM/DD/YYYY hh:mm:ss A")
                                : ""}
                            </TableCell>
                            <TableCell>
                              {vital.blood_pressure && vital.blood_pressure}
                            </TableCell>
                            <TableCell>
                              {vital.height_in && vital.height_in}
                            </TableCell>
                            <TableCell>
                              {vital.weight_lbs && vital.weight_lbs}
                            </TableCell>
                            <TableCell>
                              {vital.bmi_in && vital.bmi_in}
                            </TableCell>
                            <TableCell>
                              {vital.systolic && vital.systolic}
                            </TableCell>
                            <TableCell>
                              {vital.diastolic && vital.diastolic}
                            </TableCell>
                            {permissions.vitals_table_action && (
                              <TableCell>
                                <div style={{ display: "flex" }}>
                                  {permissions.vitals_table_edit_button && (
                                    <li>
                                      <a onClick={() => handleEditVital(vital)}>
                                        <i className="fas fa-edit"></i>
                                      </a>
                                    </li>
                                  )}
                                  {permissions.vitals_table_delete_button && (
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        color: "red",
                                        paddingLeft: "1rem",
                                      }}
                                      onClick={() =>
                                        handleDeleteVitals(vital.id)
                                      }
                                    ></i>
                                  )}
                                </div>
                              </TableCell>
                            )}
                            {/* <TableCell>{vital.body_temp_result_f && vital.body_temp_result_f}</TableCell>
                          <TableCell>{vital.pulse_beats_in && vital.pulse_beats_in}</TableCell>
                          <TableCell>{vital. resp_rate && vital. resp_rate} </TableCell>
                          <TableCell>{vital. head_in && vital. head_in}</TableCell>
                          <TableCell>{vital. waist_in && vital. waist_in}</TableCell>
                          <TableCell>{vital. glucose && vital. glucose}</TableCell> */}
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan="12" align="center">
                          <img
                            src={NoData}
                            alt="No data found"
                            style={{ width: "150px", margin: "20px 0" }}
                          />
                          <p>No Vitals found.</p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </StyledTable>

                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={vitals.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // nextIconButtonProps={{ "aria-label": "Next Page" }}
                  // backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
              </>
            )}
            {/* <div className="vital-table">
              <div className="table-responsive">
                <table>
                  <thead className="vital-table-headings overflow-auto h-100">
                    <tr>
                      <th>Date</th>
                      <th>Blood Pressure</th>
                      <th>Height</th>
                      <th>Weight</th>
                      <th>BMI</th>
                      <th>Systolic</th>
                      <th>Diastolic</th>
                      <th>Temp</th>
                      <th>Pulse</th>
                      <th>Resp Rate</th>
                      <th>Head</th>
                      <th>Waist</th>
                      <th>Glucose</th>
                     
                    </tr>
                  </thead>
                  <tbody className="vital-tbody">
                    {loading ? (
                      <tr>
                        <td colSpan="12">
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : showWiseVitalsData ? (
                      dateWiseVitals &&
                      dateWiseVitals.map((vital, index) => (
                        <tr key={index}>
                        {console.log("vitalssss",vital)}
                      <td>{vital.date && vital.date}</td>
                      <td>{vital.blood_pressure && vital.blood_pressure}</td>
                      <td>{vital.height_in && vital.height_in}</td>
                      <td>{vital.weight_lbs&& vital.weight_lbs}</td>
                      <td>{vital.bmi_in && vital.bmi_in}</td>
                      <td> {vital.systolic && vital.systolic}</td>               
                      <td>  {vital.diastolic && vital.diastolic}</td>
                      <td>{vital.body_temp_result_f && vital.body_temp_result_f}</td>
                      <td>{vital.pulse_beats_in && vital.pulse_beats_in}</td>
                      <td> {vital. resp_rate && vital. resp_rate} </td>
                      <td>{vital. head_in && vital. head_in}</td>
                      <td>{vital. waist_in && vital. waist_in}</td>
                      <td>{vital. glucose && vital. glucose}</td>
                    </tr>
                      ))
                    ) : (
                      vitals &&
                      vitals.map((vital, index) => (
                        <tr key={index}>
                            {console.log("vitalssss",vital)}
                          <td>{vital.date && vital.date}</td>
                          <td>{vital.blood_pressure && vital.blood_pressure}</td>
                          <td>{vital.height_in && vital.height_in}</td>
                          <td>{vital.weight_lbs&& vital.weight_lbs}</td>
                          <td>{vital.bmi_in && vital.bmi_in}</td>
                          <td>{vital.systolic && vital.systolic}</td>               
                          <td>{vital.diastolic && vital.diastolic}</td>
                          <td>{vital.body_temp_result_f && vital.body_temp_result_f}</td>
                          <td>{vital.pulse_beats_in && vital.pulse_beats_in}</td>
                          <td>{vital. resp_rate && vital. resp_rate} </td>
                          <td>{vital. head_in && vital. head_in}</td>
                          <td>{vital. waist_in && vital. waist_in}</td>
                          <td>{vital. glucose && vital. glucose}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Vital;
