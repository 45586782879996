import React, { useState, useEffect } from "react";
import "../PatientProfile.css";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Modal, Button } from "react-bootstrap";
import { handleGetVital } from "../../../../components/services/VitalService";
import { encounterValidation } from "../../../../components/validation/RegistrationValidation";

const initialValues = {
  signed_at: new Date().toISOString().slice(0, 16),
  history_of_present_illness: "",
  allergies: "",
  diagnosis: "",
  assessments: "",
  procedures: "",
  medications: "",
  care_plan: "",
  patient_instructions: "",
};
const PsychatricEncounter = ({
  handleCloseEncounterClick,
  id,
  onEncounterCreated,
  onEncounterCreatedFetch,
  editEncounter,
}) => {
  const [formData, setFormData] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  const getFormData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/patient-encounter-information/${id}`,
        config
      );
      setFormData(response.data);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);
  useEffect(() => {
    // getFormData();
    if (editEncounter) {
      try {
        setFieldValue(
          "signed_at",
          new Date(editEncounter.signed_at).toISOString().slice(0, 16)
        );
        setFieldValue("encounter_type", editEncounter.encounter_type_title);
        setFieldValue("specialty", editEncounter.specialty_title);
        setFieldValue("reason", editEncounter.reason);
        setFieldValue("location", editEncounter.location);
        setFieldValue("provider_id_patient", editEncounter.provider_id_patient);
      } catch (e) {
        console.error("Invalid date", e);
      }
    }
  }, [editEncounter]);
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: encounterValidation,
    onSubmit: async (values, action) => {
      try {
        const formData = new FormData();
        formData.append("patient_id", id);
        formData.append("signed_at", values.signed_at);
        formData.append("encounter_type", values.encounter_type);
        formData.append("specialty", values.specialty);
        formData.append("reason", values.reason);
        formData.append("location", values.location);
        formData.append("provider_id_patient", values.provider_id_patient);
        formData.append("height_in", values.height_in);
        formData.append("weight_lbs", values.weight_lbs);
        formData.append("bmi_in", values.bmi_in);
        formData.append("systolic", values.systolic);
        formData.append("diastolic", values.diastolic);
        formData.append("body_temp_result_f", values.body_temp_result_f);
        formData.append("pulse_beats_in", values.pulse_beats_in);
        formData.append("resp_rate", values.resp_rate);
        formData.append("head_in", values.head_in);
        formData.append("waist_in", values.waist_in);
        formData.append("skip", values.skip);
        formData.append("glucose", values.glucose);
        formData.append("blood_pressure", values.blood_pressure);
        formData.append("attachment", values.attachment);

        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(
          process.env.REACT_APP_ORIGIN_URL + `/add-patient-encounter`,
          formData,
          config
        );

        toast.success(response.data.message);
        onEncounterCreated(response.data);
        onEncounterCreatedFetch();
        action.resetForm();
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
      }
    },
  });

  const handleUpdateSubmit = async () => {
    try {
      const updateData = {
        ...values,
        encounter_id: editEncounter.id,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/update-patient-encounter",
        updateData,
        config
      );

      toast.success(response.data.message);
      onEncounterCreated(response.data);
      onEncounterCreatedFetch();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  // After successfully creating encounter
  console.log("edit", editEncounter);
  console.log("info", id);
  return (
    <div>
      <div className="d-flex flex-column h-100">
        <div className="encounter-links mt-2  ">
          <div className="row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="datetime-local"
                  className="form-control "
                  id="signed_at"
                  name="signed_at"
                  value={values.signed_at}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="signed_at">Encounter Date Time</label>
              </div>
            </div>

            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id=" history_of_present_illness"
                  name=" history_of_present_illness"
                  placeholder=" history_of_present_illness"
                  value={values.history_of_present_illness}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for=" history_of_present_illness">
                  History Of Present Illness
                </label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating  border-0">
                <input
                  type="text"
                  className="form-control"
                  id="allergies"
                  name="allergies"
                  placeholder="allergies"
                  value={values.allergies}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="allergies">Allergies</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating  border-0">
                <input
                  type="text"
                  className="form-control"
                  id="diagnosis"
                  name="diagnosis"
                  placeholder="diagnosis"
                  value={values.diagnosis}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="diagnosis">Diagnosis</label>
              </div>
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating  border-0">
                <input
                  type="text"
                  className="form-control"
                  id="assessments"
                  name="assessments"
                  placeholder="assessments"
                  value={values.assessments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="assessments">Assessments</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="procedures"
                  name="procedures"
                  placeholder="aprocedures"
                  value={values.procedures}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="procedures">Procedures</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id=" medications"
                  name=" medications"
                  placeholder=" medications"
                  value={values.medications}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for=" medications"> Medications</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="care_plan"
                  name="care_plan"
                  placeholder="care_plan"
                  value={values.care_plan}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="care_plan">Care Plan</label>
              </div>
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating  border-0">
                <input
                  type="text"
                  className="form-control"
                  id="patient_instructions"
                  name="patient_instructions"
                  placeholder="patient_instructions"
                  value={values.patient_instructions}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="patient_instructions">Patient Instructions</label>
              </div>
            </div>
          </div>
          <div className="encounter-buttoons d-flex">
            <Button variant="secondary" onClick={handleCloseEncounterClick}>
              Cancel
            </Button>
            <button
              type="button"
              onClick={editEncounter ? handleUpdateSubmit : handleSubmit}
              className="btn btn-primary ms-3"
            >
              {editEncounter ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PsychatricEncounter;
