import React, { useState, useEffect } from "react";
import "../detail/PatientDetail.css";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp as faThumbsUpSolid } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as faThumbsUpRegular } from "@fortawesome/free-regular-svg-icons";
import { faComment as faCommentRegular } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import profile from "../../../assets/images/avatar.png";
import { faImage } from "@fortawesome/free-solid-svg-icons";
const Maintenance = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [page, setPage] = useState(1);
  const [showAddComment, setShowAddComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [showRemaining, setShowRemaining] = useState(true);
  const [showRemainingComments, setShowRemainingComments] = useState(true);
  const [hideRemainingCommentsButton, setHideRemainingCommentsButton] =
    useState(true);
  const [storeLikeId, setStoreLikeId] = useState(0);
  const [storeCommentId, setStoreCommentId] = useState(0);
  const [likeOffset, setLikeOffSet] = useState(0);
  const [commentOffset, setCommentOffset] = useState(0);
  const [showAllLikes, setShowAllLikes] = useState(true);
  const [showAllComments, setShowAllComments] = useState(true);
  const [likes, setLikes] = useState([]);
  const [remainingLikesButton, setRemainingLikesButton] = useState(true);
  const [likesModalVisible, setLikesModalVisible] = useState(false);
  const [remainingLikes, setRaimainingLikes] = useState([]);
  const [remainingComments, setRaimainingComments] = useState([]);
  const [patientDetails, setPatientDetails] = useState({});
  const [loadingCast, setLoadingCast] = useState(false);
  const [media, setMedia] = useState(null);
  const [tweet, setTweet] = useState("");

  const handleCloseCommentModal = () => setShowCommentsModal(false);
  const handleShowCommentModal = () => setShowCommentsModal(true);

  const getAllTweets = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/tweets?page=${page}`,
        config
      );
      if (page === 1) {
        setData(response.data.data);
      } else {
        setData((prevData) => [...prevData, ...response.data.data]);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLike = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].liked = !newData[index].liked;
      return newData;
    });
  };

  const handleCommentSubmit = async (tweetId) => {
    if (!newComment.trim()) return;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/comment-post",
        {
          tweet_id: tweetId,
          comment: newComment,
        },
        config
      );
      if (response) {
        setNewComment("");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const getAllComments = async (tweetId, newOffset) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/comments",
        {
          params: {
            post_id: tweetId,
            offset: newOffset,
          },
        },
        config
      );
      if (newOffset === 0) {
        setComments(response.data);
      } else {
        setComments((prevComments) => [...prevComments, ...response.data]);
      }
      setCommentOffset(newOffset);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  const getRemainingComments = async (tweetId, newOffset) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/comments",
        {
          params: {
            post_id: tweetId,
            offset: newOffset,
          },
        },
        config
      );
      setRaimainingComments(response.data);
      setCommentOffset(newOffset);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleLike = async (tweetId, index) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/like-post",
        {
          tweet_id: tweetId,
          action: isLiked ? "unlike" : "like",
        },
        config
      );
      if (response.status === 200) {
        setIsLiked(!isLiked);
        setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);
        setData((prevData) => {
          const newData = [...prevData];
          newData[index].is_liked = !newData[index].is_liked;
          return newData;
        });
      }
    } catch (error) {
      console.error("Error liking the tweet:", error);
    }
  };

  const getAllLikes = async (tweetId, newOffset) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/likes",
        {
          params: {
            post_id: tweetId,
            offset: newOffset,
          },
        },
        config
      );
      if (newOffset === 0) {
        setLikes(response.data);
      } else {
        setLikes((prevLikes) => [...prevLikes, ...response.data]);
      }
      setLikeOffSet(newOffset);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  const getRemainingLikes = async (tweetId, newOffset) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/likes",
        {
          params: {
            post_id: tweetId,
            offset: newOffset,
          },
        },
        config
      );
      setRaimainingLikes(response.data);
      setLikeOffSet(newOffset);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBroadcast = async () => {
    try {
      setLoadingCast(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const payload = {
        body: tweet,
        media: media,
      };

      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/store-tweet",
        payload,
        config
      );

      setTweet("");
      setShowPostModal(false);
      getAllTweets();
      setMedia(null);
    } catch (error) {
      console.error("Error posting the tweet:", error);
    } finally {
      setLoadingCast(false);
    }
  };

  const handleViewAllComments = async (tweetId) => {
    await getRemainingComments(tweetId, commentOffset + 5);
  };
  const handleViewAllLikes = async (tweetId) => {
    await getRemainingLikes(tweetId, likeOffset + 5);
  };
  const handleResetLikes = async (tweetId) => {
    await getAllLikes(tweetId, 0);
    await getRemainingLikes(0);
  };

  const handlePatientDetails = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/login-user-details",
        config
      );
      setPatientDetails(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    handlePatientDetails();
  }, []);
  useEffect(() => {
    getAllTweets();
  }, [page]);

  const handleLoadMoreTweets = async () => {
    setPage((prevPage) => prevPage + 1);
    await getAllTweets(page + 1);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setMedia(reader.result);
    };
    reader.readAsDataURL(file);
  };
  console.log("details", patientDetails);
  return (
    <div className="d-flex justify-content-center maintenance-scroll">
      <div className="user-maintenance-positions">
        <div class="user-maintenance-content">
          <div className="user-maintanance-bg">
            <div className="maintenance-img w-10 me-2 img-maintenance-p">
              <img
                style={{ width: "70px" }}
                src={patientDetails.image && patientDetails.image}
                alt="profile"
              />
            </div>
          </div>
          <div className="maintenance-user-bio text-center mt-5 mb-3">
            <h5>
              {patientDetails.first_name && patientDetails.first_name}{" "}
              {patientDetails.middle_name && patientDetails.middle_name}{" "}
              {patientDetails.last_name && patientDetails.last_name}
            </h5>
            <p>New Patient</p>
          </div>
          <div className="border-bottom"></div>
          <div className="maintenance-links">
            <ul>
              <li>Profile viewers</li>
              <li>View al analytics</li>
            </ul>
          </div>
          <div className="border-bottom"></div>
          <div className="maintenance-links">
            <ul>
              <li>Achieve your goals with Premium</li>
              <li>
                <span className="me-2">
                  <i class="fas fa-file-medical"></i>
                </span>
                Try Premium for PKR0
              </li>
            </ul>
          </div>
          <div className="border-bottom"></div>
          <div className="maintenance-links">
            <ul>
              <li className="mb-0">
                <span className="me-2">
                  <i class="fas fa-bookmark"></i>
                </span>
                Saved items
              </li>
            </ul>
          </div>
          <div className="border-bottom"></div>

          <div className="maintenance-links">
            <ul>
              <li>Recent</li>
              <li>
                <span className="me-2">
                  <i class="fas fa-file-medical"></i>
                </span>
                Freelancers - Home
              </li>
              <li>
                <span className="me-2">
                  <i class="fas fa-file-medical"></i>
                </span>
                Creative Writers, Assists & Design
              </li>
              <li>
                <span className="me-2">
                  <i class="fas fa-file-medical"></i>
                </span>
                Designers Talk
              </li>
              <li>
                <span className="me-2">
                  <i class="fas fa-file-medical"></i>
                </span>
                Designing Tips
              </li>
              <li>
                <span className="me-2">
                  <i class="fas fa-file-medical"></i>
                </span>
                Artificial Intelligence
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="maintenance-bg overflow-auto h-100">
        <div className="maintenance-container ">
          <div className="maintenance-bg-profile">
            <div className="d-flex align-items-center">
              <div className="maintenance-img w-10 me-2">
                <img style={{ width: "60px" }} src={profile} alt="profile" />
              </div>
              <div className="form-floating w-100">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="comments"
                  name="comments"
                  style={{ height: "60px" }}
                  value={tweet}
                  onChange={(e) => setTweet(e.target.value)}
                ></textarea>
                <label htmlFor="comments">Start a post</label>
              </div>
            </div>
            <div>
              <div className="mb-3  me-2">
                <input
                  className="form-control d-none"
                  type="file"
                  id="tweetMedia"
                  accept="image/*,video/*"
                  onChange={handleFileChange}
                />
                <div className="d-flex align-items-center  mt-4">
                  <button
                    type="button"
                    style={{ color: "white", backgroundColor: "#2ae973" }}
                    className="btn"
                    onClick={() =>
                      document.getElementById("tweetMedia").click()
                    }
                  >
                    <FontAwesomeIcon icon={faImage} />
                  </button>
                  <h6 style={{ marginLeft: "10px" }}>Media</h6>
                  <div className="ms-auto">
                    <Button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleBroadcast}
                    >
                      {loadingCast ? "Proceesing..." : "Broadcast"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={showPostModal} onHide={() => setShowPostModal(false)}>
            <Modal.Header className="allery-header">
              <Modal.Title>Broadcast</Modal.Title>
            </Modal.Header>
            <Modal.Body className="allergy-body">
              <div className="form-floating mb-5">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="comments"
                  name="comments"
                  style={{ height: "100px" }}
                  value={tweet}
                  onChange={(e) => setTweet(e.target.value)}
                ></textarea>
                <label htmlFor="comments">Comments</label>
              </div>
              <div className="mb-3">
                <label htmlFor="tweetMedia" className="form-label">
                  Select Image or Video
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="tweetMedia"
                  accept="image/*,video/*"
                  onChange={handleFileChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer className="allergy-footer-btn">
              <Button
                type="button"
                variant="secondary"
                onClick={() => setShowPostModal(false)}
              >
                Close
              </Button>
              <Button
                type="button"
                className="btn btn-primary"
                onClick={handleBroadcast}
              >
                Broadcast
              </Button>
            </Modal.Footer>
          </Modal>

          {loading && page === 1 ? (
            <div
              className="spinner-border text-primary"
              role="status"
              style={{ display: "block", padding: "10px" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            data &&
            data.map((tweet, index) => (
              <>
                <div className="border-maintenance mt-3 rounded-1 " key={index}>
                  <div className="d-flex p-3 align-items-center">
                    <div className="maintenance-img">
                      <img
                        style={{ width: "60px" }}
                        src={tweet.user && tweet.user.avatar}
                        alt="profile"
                      />
                    </div>
                    <div>
                      <ul className="ms-3 d-flex">
                        <li>
                          <h5>{tweet.user.name} </h5>
                        </li>
                        <li className="ms-1">
                          <h5 style={{ color: "gray" }}>
                            {" "}
                            @{tweet.user.username}
                          </h5>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h5 className="mb-3 ps-3">{tweet.body}</h5>
                  <div className="tweet-maintenance d-flex justify-content-center">
                    {tweet.file_type === "image" && (
                      <img
                        style={{ objectFit: "cover" }}
                        src={tweet.file_path}
                        alt="Tweet media"
                      />
                    )}
                    {tweet.file_type === "video" && (
                      <video src={tweet.file_path} controls />
                    )}
                  </div>

                  <div className="mt-3 ps-3">
                    <FontAwesomeIcon
                      icon={
                        tweet.is_liked ? faThumbsUpSolid : faThumbsUpRegular
                      }
                      onClick={() => {
                        handleLike(index);
                        toggleLike(tweet.id, index);
                      }}
                      style={{
                        cursor: "pointer",
                        color: tweet.is_liked ? "yellow" : "blue",
                      }}
                    />

                    <FontAwesomeIcon
                      icon={faCommentRegular}
                      onClick={() => setShowAddComment((prev) => !prev)}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      flip="horizontal"
                    />
                  </div>
                  <div className="mt-3 ps-3">
                    <h5
                      onClick={() => {
                        setStoreLikeId(tweet.id);
                        getAllLikes(tweet.id, 0);
                        setLikesModalVisible(true);
                      }}
                    >
                      {tweet.total_likes && tweet.total_likes} likes
                    </h5>
                    <p
                      className="mt-3 mb-2"
                      onClick={() => {
                        setStoreCommentId(tweet.id);
                        handleShowCommentModal();
                        getAllComments(tweet.id, 0);
                      }}
                    >
                      View all {tweet.comments && tweet.comments} comments
                    </p>
                  </div>
                  {showAddComment && (
                    <div className=" p-3 d-flex justify-content-center align-items-center">
                      <textarea
                        className="form-control"
                        placeholder="Leave a comment here"
                        id="comments"
                        name="comments"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        style={{ height: "50px" }}
                      ></textarea>
                      <button
                        type="button"
                        onClick={() => handleCommentSubmit(tweet.id)}
                        className="btn btn-primary m-3 "
                      >
                        Submit
                      </button>
                    </div>
                  )}
                  <Modal
                    show={showCommentsModal}
                    onHide={handleCloseCommentModal}
                  >
                    <Modal.Header className="allery-header">
                      <Modal.Title>Comments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="allergy-body">
                      {showAllComments &&
                        (loading ? (
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          comments.latest_comments &&
                          comments.latest_comments.map((comment) => (
                            <div key={comment.id}>
                              {console.log("comment", comment)}
                              <div className="d-flex mb-3  align-items-center">
                                <div className="avatar text-center">
                                  <img
                                    src={
                                      comment.user.image && comment.user.image
                                    }
                                    alt="profile"
                                  />
                                </div>
                                <div>
                                  <ul className="d-flex ms-2">
                                    <li>
                                      <h5>
                                        {comment.user.name && comment.user.name}{" "}
                                      </h5>
                                    </li>
                                    <li className="ms-1">
                                      <h5 style={{ color: "gray" }}>
                                        {" "}
                                        @
                                        {comment.user_name && comment.user_name}
                                      </h5>
                                    </li>
                                  </ul>
                                  <li className="ms-2">
                                    {comment.comment && comment.comment}{" "}
                                  </li>
                                </div>
                              </div>
                            </div>
                          ))
                        ))}
                      {!showAllComments &&
                        (loading ? (
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          remainingComments.latest_comments &&
                          remainingComments.latest_comments.map((comment) => (
                            <div key={comment.id}>
                              {console.log("remainingcComment", comment)}

                              <div className="d-flex mb-3  align-items-center">
                                <div className="avatar text-center">
                                  <img
                                    src={
                                      comment.user.image && comment.user.image
                                    }
                                    alt="profile"
                                  />
                                </div>
                                <div>
                                  <ul className="d-flex ms-2">
                                    <li>
                                      <h5>
                                        {comment.user.name && comment.user.name}{" "}
                                      </h5>
                                    </li>
                                    <li className="ms-1">
                                      <h5 style={{ color: "gray" }}>
                                        {" "}
                                        @
                                        {comment.user_name && comment.user_name}
                                      </h5>
                                    </li>
                                    <li className="ms-1">{} </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))
                        ))}
                      {!hideRemainingCommentsButton && (
                        <p
                          onClick={() => {
                            handleViewAllComments(storeCommentId);
                            setShowRemaining(true);
                          }}
                        >
                          View remaining{" "}
                          {remainingComments.remaining_comments || 0} comments
                        </p>
                      )}
                      {hideRemainingCommentsButton && (
                        <p
                          onClick={() => {
                            // handleViewAllComments();
                            // setHideRemaining(false);
                            getRemainingComments(storeCommentId, offset + 5);
                            setShowAllComments(false);
                            setHideRemainingCommentsButton(false);
                            setShowRemaining(true);
                          }}
                        >
                          View remaining {comments.remaining_comments || 0}{" "}
                          comments
                        </p>
                      )}
                    </Modal.Body>
                    <Modal.Footer className="allergy-footer-btn">
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setStoreCommentId(0);
                          handleCloseCommentModal();
                          getAllComments(storeCommentId, 0);
                          getRemainingComments(storeCommentId, 0);
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Likes Modal */}

                  <Modal
                    show={likesModalVisible}
                    onHide={() => setLikesModalVisible(false)}
                  >
                    <Modal.Header className="allery-header">
                      <Modal.Title>Likes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="allergy-body">
                      {showAllLikes &&
                        (loading ? (
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          likes.latest_likes &&
                          likes.latest_likes.map((like) => (
                            <div key={like.id}>
                              <div className="d-flex mb-3  align-items-center">
                                <div className="avatar text-center">
                                  <img
                                    src={like.user.image && like.user.image}
                                    alt="profile"
                                  />
                                </div>
                                <div>
                                  <ul className="d-flex ms-2">
                                    <li>
                                      <h5>
                                        {like.user.name && like.user.name}{" "}
                                      </h5>
                                    </li>
                                    <li className="ms-1">
                                      <h5 style={{ color: "gray" }}>
                                        {" "}
                                        @{like.user_name && like.user_name}
                                      </h5>
                                    </li>
                                    <li className="ms-1">{} </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))
                        ))}

                      {!showAllLikes &&
                        (loading ? (
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          remainingLikes.latest_likes &&
                          remainingLikes.latest_likes.map((like) => (
                            <div key={like.id}>
                              {console.log("likes", like)}

                              <div className="d-flex mb-3  align-items-center">
                                <div className="avatar text-center">
                                  <img
                                    src={like.user.image && like.user.image}
                                    alt="profile"
                                  />
                                </div>
                                <div>
                                  <ul className="d-flex ms-2">
                                    <li>
                                      <h5>
                                        {like.user.name && like.user.name}{" "}
                                      </h5>
                                    </li>
                                    <li className="ms-1">
                                      <h5 style={{ color: "gray" }}>
                                        {" "}
                                        @{like.user_name && like.user_name}
                                      </h5>
                                    </li>
                                    <li className="ms-1">{} </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))
                        ))}
                      {!remainingLikesButton && (
                        <p
                          onClick={() => {
                            handleViewAllLikes(storeLikeId);
                            setShowRemaining(true);
                          }}
                        >
                          View remaining {remainingLikes.remaining_likes || 0}{" "}
                          likes
                        </p>
                      )}
                      {remainingLikesButton && (
                        <p
                          onClick={() => {
                            handleViewAllLikes(storeLikeId);
                            setShowAllLikes(false);
                            setRemainingLikesButton(false);
                          }}
                        >
                          View remaining {likes.remaining_likes || 0} likes
                        </p>
                      )}
                    </Modal.Body>
                    <Modal.Footer className="allergy-footer-btn">
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setStoreLikeId(0);
                          setLikesModalVisible(false);
                          handleResetLikes(storeLikeId);
                          getAllLikes(storeLikeId, 0);
                          getRemainingLikes(storeLikeId, 0);
                          setShowAllLikes(true);
                        }}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </>
            ))
          )}
          {loading && (
            <div className="loading-spinner">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            <Button
              variant="link"
              style={{ color: "#14457b" }}
              onClick={handleLoadMoreTweets}
            >
              Load More
            </Button>
          </div>
          <button
            className="btn rounded-circle floating-button"
            onClick={() => setShowPostModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} style={{ color: "#14457b" }} />
          </button>
        </div>
      </div>
      <div className="user-maintenance-positions-right">
        <div class="notification-bg">
          <div class="d-flex justify-content-center">
            <div class="noti-btn ">
              <h5 class="text-center">Jobs to do</h5>
            </div>
          </div>
          <div class="my-3">
            <div className="maintenance-img me-2 d-flex align-items-center">
              <div>
                <img style={{ width: "50px" }} src={profile} alt="profile" />
              </div>
              <div>
                <ul class="ms-3">
                  <li>
                    <h5>Tap Broken at room 105</h5>
                  </li>
                  <li class="my-1">
                    Lorem Ipsum&nbsp;is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </li>
                </ul>
              </div>
            </div>

            <div className="text-center d-flex align-items-center justify-content-center">
              <button class="btn btn-notification align-items-center">
                Respond
              </button>
            </div>
          </div>
          <div class="my-3">
            <div className="maintenance-img me-2 d-flex align-items-center">
              <div>
                <img style={{ width: "50px" }} src={profile} alt="profile" />
              </div>
              <div>
                <ul class="ms-3">
                  <li>
                    <h5>Tap Broken at room 105</h5>
                  </li>
                  <li class="my-1">
                    Lorem Ipsum&nbsp;is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </li>
                </ul>
              </div>
            </div>

            <div className="text-center">
              <button class="btn btn-notification">Respond</button>
            </div>
          </div>
          <div class=" my-3">
            <div className="maintenance-img me-2 d-flex align-items-center ">
              <div>
                <img style={{ width: "50px" }} src={profile} alt="profile" />
              </div>
              <div>
                <ul class="ms-3">
                  <li>
                    <h5>Tap Broken at room 105</h5>
                  </li>
                  <li class="my-1">
                    Lorem Ipsum&nbsp;is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </li>
                </ul>
              </div>
            </div>

            <div className="text-center">
              <button class="btn btn-notification">Respond</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
