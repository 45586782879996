import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import Logo from "../../assets/images/header-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  faSearch,
  faBell,
  faBed,
  faPlus,
  faAngleDown,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate } from "react-router-dom";
import PatientModal from "../modal/AddPatientModal";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faToolbox } from "@fortawesome/free-solid-svg-icons";
import profile from "../../assets/images/avatar.png";
import axios from "axios";
const Header = ({
  handleOpenSettings,
  handleOpenAddPatient,
  handleBedMappingTab,
  handleMaintainanceTab,
  handleOperationTab,
  handleVideoCalling,
  handleScheduling,
  closeAddPatient,
  changeFacility,
}) => {
  const [show, setShow] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(true);
  const [image, setImage] = useState(null);
  const [loginDetails, setLoginDetails] = useState(null);
  const [facilityLoading, setFaciltiyLoading] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  // const roles = useSelector((state) => state.rolesPermissions.roles);
  // const permissions = useSelector((state) => state.rolesPermissions.permissions);
  // const [searchInputs, setSearchInputs] = useState({});
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Extracting roles and permissions from Redux store
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );
  console.log("permissions", permissions);

  const handleAddPatient = () => {
    window.location.href = "/registration_form";
  };

  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove the token from local storage
    navigate("/"); // Navigate to the login page
  };
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 915);
    };

    // Initial check
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getProfileImage = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const image = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/login-user-details`,
        config
      );
      setImage(image.data.image);
      setLoginDetails(image.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleGetFacilities = async () => {
    try {
      setFaciltiyLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/login-user-facility",
        config
      );
      setFacilityList(response.data.data.user_facilities);
      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setFaciltiyLoading(false);
    }
  };
  const handleFacilityChange = async (event) => {
    const facilityId = event.target.value;
    if (facilityId) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await axios.post(
          process.env.REACT_APP_ORIGIN_URL + "/update-login-facility",
          { facility_id: facilityId },
          config
        );
        console.log("Facility updated:", response.data);
        changeFacility();
      } catch (error) {
        console.log("Error updating facility:", error.message);
      }
    }
  };
  useEffect(() => {
    handleGetFacilities();
    getProfileImage();
  }, []);
  console.log("image", loginDetails);
  return (
    <header>
      <div className="header-sec-two d-flex align-items-center gap-2">
        <div className="brand-logo-patient " style={{ width: "15%" }}>
          {/* <img src={Logo} alt="Soul Housing Logo" /> */}
          <h1 style={{ color: "white", fontSize: "20px" }}>Facility Access</h1>
        </div>

        <div className="facility-down ">
          <div className="form-floating ">
            {permissions.add_patient && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleOpenAddPatient();
                  closeAddPatient();
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                <span className="header-icon-ff" style={{ color: "#14457b" }}>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                {isWideScreen && (
                  <span
                    className="d-none d-xl-inline"
                    style={{ paddingLeft: "3px" }}
                  >
                    Intake
                  </span>
                )}
              </button>
            )}

            {permissions.bed_mapping_view && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleBedMappingTab();
                  closeAddPatient();
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                <span
                  className="bed-icon-map header-icon-ff"
                  style={{ color: "#14457b" }}
                >
                  <FontAwesomeIcon icon={faBed} />
                </span>
                {isWideScreen && (
                  <span
                    className="d-none d-xl-inline "
                    style={{ paddingLeft: "5px" }}
                  >
                    Bed Mapping
                  </span>
                )}
              </button>
            )}
            {permissions.maintenance && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleMaintainanceTab();
                  closeAddPatient();
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                <span
                  className="bed-icon-map header-icon-ff"
                  style={{ color: "#14457b" }}
                >
                  <FontAwesomeIcon icon={faToolbox} />
                </span>
                {isWideScreen && (
                  <span
                    className="d-none d-xl-inline "
                    style={{ paddingLeft: "5px" }}
                  >
                    Maintanance
                  </span>
                )}
              </button>
            )}

            {permissions.operation && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleOperationTab();
                  closeAddPatient();
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                <span
                  className="bed-icon-map header-icon-ff"
                  style={{ color: "#14457b" }}
                >
                  <FontAwesomeIcon icon={faCog} />
                </span>
                {isWideScreen && (
                  <span
                    className="d-none d-xl-inline "
                    style={{ paddingLeft: "5px" }}
                  >
                    Operation
                  </span>
                )}
              </button>
            )}

            {permissions.video_calling && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  handleVideoCalling();
                  closeAddPatient();
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                <span
                  className="bed-icon-map header-icon-ff"
                  style={{ color: "#14457b" }}
                >
                  <FontAwesomeIcon icon={faVideo} />
                </span>
                {isWideScreen && (
                  <span
                    className="d-none d-xl-inline "
                    style={{ paddingLeft: "5px" }}
                  >
                    Video Calling
                  </span>
                )}
              </button>
            )}

            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                handleScheduling();
                closeAddPatient();
              }}
              style={{ marginLeft: "0.5rem" }}
            >
              <span
                className="bed-icon-map header-icon-ff"
                style={{ color: "#14457b" }}
              >
                <FontAwesomeIcon icon={faCalendar} />
              </span>
              {isWideScreen && (
                <span
                  className="d-none d-xl-inline "
                  style={{ paddingLeft: "5px" }}
                >
                  Scheduling
                </span>
              )}
            </button>

            <select
              // className="btn btn-danger"
              style={{
                fontSize: "12px",
                borderRadius: "5px",
                marginLeft: "0.5rem",
                height: "2rem",
              }}
              onChange={handleFacilityChange}
            >
              {facilityLoading ? (
                <option>Loading...</option>
              ) : (
                facilityList &&
                facilityList.map((facilitie, index) => (
                  <option key={facilitie.id} value={facilitie.id}>
                    {facilitie.facility_name}
                  </option>
                ))
              )}
            </select>
          </div>
        </div>

        {/* Button trigger modal */}

        {/* Your dropdown content */}
        <div className="d-flex align-items-center ms-auto">
          <div className="bell-icon">
            <div className="d-flex justify-content-center p-user-img-h">
              {image ? (
                <img
                  src={image}
                  alt="Admin"
                  className="rounded-circle"
                  width={30}
                />
              ) : (
                <img
                  src={profile}
                  alt="Admin"
                  className="rounded-circle"
                  width={30}
                />
              )}
            </div>
          </div>

          <div className="avatar dropdown">
            <div
              className="admin-name ms-4 dropdown-toggle no-arrow-dropdown"
              data-bs-toggle="dropdown"
            >
              <a
                style={{ color: "white", cursor: "pointer", fontSize: "13px" }}
              >
                {loginDetails && loginDetails.provider_full_name
                  ? loginDetails.provider_full_name
                  : ""}
              </a>
              <a>
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="drop-icon  ms-2"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              {permissions.setting && (
                <li>
                  <a
                    className="dropdown-item active"
                    onClick={handleOpenSettings}
                  >
                    <p style={{ color: "white", cursor: "pointer" }}>
                      Settings
                    </p>
                  </a>
                </li>
              )}
              <li>
                <hr className="dropdown-divider"></hr>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                >
                  Logout
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Render the modal component */}
      <PatientModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        registrationForm={<Link to="/registration_form" />}
      />
    </header>
  );
};

export default Header;
