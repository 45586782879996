import React, { useState, useEffect } from "react";
import "../PatientProfile.css";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { allergyValidation } from "../../../../components/validation/RegistrationValidation";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { useSelector } from "react-redux";
import NoData from "../../../../assets/images/no-data-found.png";

const getCurrentLocalDateTime = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate.toISOString().slice(0, 16);
};

const initialValue = {
  allergy_type: "",
  chronicity_id: "",
  severity_id: "",
  reaction_id: "",
  type_id: "",
  onset: new Date(),
  allergy: "",
  comments: "",
};

const Allergies = ({ id, handleAllergiesCreated }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [allergies, setAllergies] = useState([]);
  const [saveAllergyLoading, setSaveAllergyLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: allergyValidation,
    onSubmit: async (values, action) => {
      try {
        setSaveAllergyLoading(true);
        setDisabledSave(true);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const response = await axios.post(
          process.env.REACT_APP_ORIGIN_URL + `/store-allergy`,
          {
            patient_id: id,
            chronicity_id: values.chronicity_id,
            severity_id: values.severity_id,
            allergy_type: values.type_id,
            status_id: values.status_id,
            allergy: values.allergy,
            reaction_id: values.reaction_id,
            onset_date: values.onset,
            comments: values.comments,
          },
          config
        );
        console.log(response);
        toast.success(response.message);
        getAllergies();
        handleClose();
        handleAllergiesCreated();
      } catch (error) {
        console.log(error.message);
        toast.error(error.message);
      } finally {
        setSaveAllergyLoading(false);
        setDisabledSave(false);
      }
      action.resetForm();
    },
  });
  const getOptions = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/list-options`,
        config
      );
      setOptions(res.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const deleteAllergy = async (id) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.delete(
        process.env.REACT_APP_ORIGIN_URL + `/delete-allergy/${id}`,
        config
      );

      getAllergies();
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAllergies = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/allergies/${id}`,
        config
      );
      setAllergies(res.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllergies();
    getOptions();
  }, []);
  useEffect(() => {
    setFieldValue(" onset", getCurrentLocalDateTime());
  }, []);
  console.log("options", options);
  console.log("allergy", allergies);
  return (
    <div>
      <div className="Allergy-heading d-flex">
        <h5>Allergy</h5>
        <div className="allergy-btn ms-auto">
          {/* Button trigger modal */}
          {permissions.allergies_add && (
            <button
              type="button"
              className="btn btn-primary "
              style={{ marginRight: "0.3rem" }}
              onClick={handleShow}
            >
              <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
              Allergy
            </button>
          )}
          {/* Modal */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header className="allery-header">
              <Modal.Title>Add Allergy</Modal.Title>
            </Modal.Header>
            <Modal.Body className="allergy-body">
              <div className="row mt-2">
                <div className="col-6">
                  <div className="allery-feilds">
                    <h5 style={{ padding: "0.5rem" }}>
                      Allergy Type{" "}
                      <span style={{ color: "red", fontSize: "13px" }}>*</span>
                    </h5>
                    <div className="d-flex align-items-center ms-auto">
                      <div className="form-floating mb-4 ms-1 w-100">
                        <select
                          className="form-select"
                          id="type_id"
                          name="type_id"
                          aria-label="Floating label select example"
                          value={values.type_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">-Select-</option>

                          {optionsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            options.Type &&
                            options.Type.map((type, index) => (
                              <option key={type.id} value={type.id}>
                                {type.title}
                              </option>
                            ))
                          )}
                        </select>
                        <label for="type_id">Type</label>
                        {errors.type_id && touched.type_id ? (
                          <p style={{ color: "red" }}>{errors.type_id}</p>
                        ) : null}
                      </div>
                    </div>
                    <h5 style={{ padding: "0.5rem " }}>
                      Reaction{" "}
                      <span style={{ color: "red", fontSize: "13px" }}>*</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="form-floating mb-4 ms-1 w-100">
                        <select
                          className="form-select"
                          id="reaction_id"
                          name="reaction_id"
                          aria-label="Floating label select example"
                          value={values.reaction_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">-Select-</option>

                          {optionsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            options.Reaction &&
                            options.Reaction.map((Reaction, index) => (
                              <option key={Reaction.id} value={Reaction.id}>
                                {Reaction.title}
                              </option>
                            ))
                          )}
                        </select>
                        <label for="reaction_id">Reaction</label>
                        {errors.reaction_id && touched.reaction_id ? (
                          <p style={{ color: "red" }}>{errors.reaction_id}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="allery-feilds">
                    <h5 style={{ padding: "0.5rem" }}>Onset</h5>
                    <div className="d-flex align-items-center">
                      <div className="form-floating mb-4 ms-1 w-100">
                        {/* <input
                          type="datetime-local"
                          className="form-control w-100"
                          id="onset"
                          placeholder="onset"
                          value={values.onset}
                          name="onset"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        /> */}
                        <FloatLabel>
                          <Calendar
                            value={values.onset}
                            onChange={(e) => setFieldValue("onset", e.value)}
                            onBlur={handleBlur}
                            dateFormat="mm/dd/yy"
                            showIcon
                            showTime
                            hourFormat="12"
                            hideOnDateTimeSelect="true"
                          />
                          <label htmlFor="onset">Onset</label>
                        </FloatLabel>
                      </div>
                    </div>
                    <h5 style={{ padding: "0.5rem" }}>
                      Severity{" "}
                      <span style={{ color: "red", fontSize: "13px" }}>*</span>
                    </h5>
                    <div className="d-flex align-items-center ms-auto">
                      <div className="form-floating ms-1 w-100">
                        <select
                          className="form-select"
                          id="severity_id"
                          name="severity_id"
                          aria-label="Floating label select example"
                          value={values.severity_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">-Select-</option>

                          {optionsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            options.Severity &&
                            options.Severity.map((severity, index) => (
                              <option key={severity.id} value={severity.id}>
                                {severity.title}
                              </option>
                            ))
                          )}
                        </select>
                        <label for="severity_id">Severity</label>
                        {errors.severity_id && touched.severity_id ? (
                          <p style={{ color: "red" }}>{errors.severity_id}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="allery-feilds ">
                    <h5 style={{ padding: "0.5rem " }}>Allergy </h5>
                    <div className="d-flex align-items-center">
                      <div className="form-floating mb-4 ms-1 w-100">
                        <input
                          type="text"
                          className="form-control w-100"
                          id="allergy"
                          placeholder="allergy"
                          value={values.allergy}
                          name="allergy"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <label for="allergy">Allergy</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="allery-feilds mt-3"></div>
                </div>
              </div>
              <div className="form-floating mt-2 alerrgy-text-area">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  name="comments"
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                <label htmlFor="comments">Comments</label>
              </div>
            </Modal.Body>
            <Modal.Footer className="allergy-footer-btn">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                disabled={disabledSave}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {saveAllergyLoading ? "Processing.." : "Save"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      {/* <div className="Allergies-links mb-2  d-flex align-items-center">
        <div className="input-group input-search rounded">
          <input
            type="search"
            className="form-control rounded border-0"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-addon"
          />
          <a>
            <i className="fas fa-search search-icon"></i>
          </a>
        </div>
        <div className="form-check ms-3">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            No known drug allergy
          </label>
        </div>
      </div> */}
      {permissions.allergies_view && (
        <div className="encounter-table table-responsive mt-3">
          <table className="table">
            <thead>
              <tr className="mb-2">
                <th>Allergy Type</th>
                <th>Allergy</th>
                <th>Severity</th>
                <th>Reaction</th>
                <th>Onset</th>
                <th>Comments</th>
                {permissions.allergies_table_action && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="12">
                    <div className="loading-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : allergies && allergies.length > 0 ? (
                allergies.map((allergy, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {allergy.allergy_type && allergy.allergy_type.title}
                      </td>
                      <td>{allergy.allergy && allergy.allergy}</td>
                      <td>{allergy.severity && allergy.severity.title}</td>
                      <td>{allergy.reaction && allergy.reaction.title}</td>

                      <td>
                        {allergy.onset_date
                          ? moment(allergy.onset_date)
                              .utc()
                              .format("MM/DD/YYYY hh:mm:ss A")
                          : ""}
                      </td>
                      <td>{allergy.comments}</td>
                      {permissions.allergies_table_action && (
                        <td>
                          {permissions.allergies_edit && (
                            <a
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={(handleEditAllergy) => allergy}
                            >
                              <i className="fas fa-edit"></i>
                            </a>
                          )}
                          {permissions.allergies_delete && (
                            <i
                              className="fa fa-trash"
                              style={{ color: "red", paddingLeft: "0.85rem" }}
                              onClick={() => deleteAllergy(allergy.id)}
                            ></i>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="12" align="center">
                    <img
                      src={NoData}
                      alt="No data found"
                      style={{ width: "150px", margin: "20px 0" }}
                    />
                    <p>No Allergies found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default Allergies;
