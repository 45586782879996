import React, { useEffect, useState, useRef } from "react";
import "../PatientProfile.css";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import PhysicalExam from "./PhysicalExam";
import ReviewSystem from "./ReviewSystem";
// import { handleGetVital } from "../../../../components/services/VitalService";
import WoundEncounter from "./WoundEncounter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlu,
  faPlus,
  faTimes,
  faNotesMedical,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import MentalExamization from "./MentalExamization";
import moment from "moment";
import { useSelector } from "react-redux";
const initialValue = {
  diagnosis: "",
  name: "",
  code: "",
  description: "",
  category: "",
  assessment_input: "",
  value_id: "",
};
const VisitReason = ({
  encounter,
  encounterData,
  handleCloseNewEncounter,
  handleCloseVisitNotesClick,
  onEncounterCreatedFetch,
  id,
  signed,
  handleCloseEncounterClick,
}) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [visitReason, setVisitReason] = useState([]);
  const [textareaValues, setTextareaValues] = useState({});
  const [assessmenttextareaValues, setAssessmentTextareaValues] = useState({});
  const [encounterDataValues, setEncounterDataValues] = useState({});
  const [showPhysicalExam, setShowPhysicalExam] = useState(false);
  const [showReviewSystem, setShowReviewSytem] = useState(false);

  const [physical, setPhysical] = useState(false);
  const [review, setReview] = useState(false);
  const [show, setShow] = useState(false);
  const [showProcedure, setShowProcedure] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState([]);
  const [assessmentLoading, setAssessmentLoading] = useState(false);
  const [procedureLoading, setProcedureLoading] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [createdSectionId, setCreatedSectionId] = useState(null);
  const [createdPatientId, setCreatedPatientId] = useState(null);
  const [assessmentSectionId, setAssessmentSectionId] = useState(null);
  const [procedureSectionId, setProcedureSectionId] = useState(null);
  const [showMentalExamination, setShowMentalExamination] = useState(false);
  const [showMental, setShowMental] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [getAddedData, setGetAddedData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseProcedure = () => setShowProcedure(false);
  const handleShowProcedure = () => setShowProcedure(true);
  const inputRef = useRef(null);
  const procedureRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [procedureSearchInput, setProcedureSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [procedureResults, setProcedureResults] = useState({});
  const [signTrigger, setSignTrigger] = useState(false);
  const [prevData, setPrevData] = useState(true);

  const textareaRefs = useRef([]);

  const getSearchResults = async (query) => {
    if (query.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      setAssessmentLoading(true);

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/search-code/${query}`,
        config
      );
      setSearchResults(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setAssessmentLoading(false);
    }
  };

  const getSearchProcedure = async (query) => {
    if (query.trim() === "") {
      setProcedureResults([]);
      return;
    }
    try {
      setProcedureLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN_URL}/cpt-codes/${query}`,
        config
      );
      setProcedureResults(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setProcedureLoading(false);
    }
  };

  const getVisitReasons = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/patient-encounter-notes/${encounter.id}`,
        config
      );
      setVisitReason(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLinkButtonClick = async (code, index) => {
    try {
      const postData = {
        code: code,
        assessment_input: assessmenttextareaValues[index],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/assessment-notes",
        postData,
        config
      );
      toast.success("Assessment input linked successfully");
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to link assessment input");
    }
  };

  const handleGetAddedData = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/get-patient-encounter/${encounterData.encounter_id}`,
        config
      );
      setGetAddedData(response.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTextareaChange = (event, sectionId) => {
    setTextareaValues((prevState) => ({
      ...prevState,
      [sectionId]: event.target.value,
    }));
  };

  const handleCreateTextareaChange = (event, sectionId) => {
    setEncounterDataValues((prevState) => ({
      ...prevState,
      [sectionId]: event.target.value,
    }));
  };

  console.log("signed", signed);
  const handleSubmitVisit = async (
    sectionId,
    sectionType,
    assessmentInputValue,
    valueId
  ) => {
    try {
      const postData = {
        patient_id: encounter.patient_id,
        encounter_id: encounter.id,
        sections: [
          {
            section_title: visitReason.find(
              (reason) => reason.section_id === sectionId
            )?.section_title,
            section_text: textareaValues[sectionId],
            assessment_input: assessmentInputValue || "",
            sorting_order: sectionId,
            value_id: valueId || "",
            section_type_2: sectionType,
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/add-patient-encounter-notes",
        postData,
        config
      );
      // Optionally, you can handle success, e.g., show a success message or update state
    } catch (error) {
      console.log(error.message);
      // Optionally, you can handle error, e.g., show an error message
    }
  };
  const handleSubmitCreateVisit = async (
    sectionId,
    sectionType,
    assessmentInputValue,
    valueId,
    sortingOrder
  ) => {
    try {
      const postData = {
        patient_id: encounterData.encounter_details.patient_id,
        encounter_id: encounterData.encounter_id,
        sections: [
          {
            section_title: encounterData.new_sections.find(
              (reason) => reason.section_id === sectionId
            )?.section_title,
            section_text: encounterDataValues[sectionId],
            assessment_input: assessmentInputValue || " ",
            sorting_order: sectionId,
            value_id: valueId || "",
            section_type_2: sectionType,
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/add-patient-encounter-notes",
        postData,
        config
      ); // Replace 'YOUR_POST_ENDPOINT' with your actual endpoint
      // Optionally, you can handle success, e.g., show a success message or update state
    } catch (error) {
      console.log(error.message);
      // Optionally, you can handle error, e.g., show an error message
    }
  };

  const handleSubmitCreateVisitAssess = async (
    sectionId,
    sectionType,
    assessmentInputValue,
    valueId,
    sortingOrder
  ) => {
    try {
      const postData = {
        patient_id: encounterData.encounter_details.patient_id,
        encounter_id: encounterData.encounter_id,
        sections: [
          {
            section_title: encounterData.new_sections.find(
              (reason) => reason.section_id === sectionId
            )?.section_title,
            // section_text: encounterDataValues[sectionId],
            section_text: "",
            assessment_input: assessmentInputValue || " ",
            sorting_order: sortingOrder,
            value_id: valueId || "",
            section_type_2: sectionType,
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/add-patient-encounter-notes",
        postData,
        config
      ); // Replace 'YOUR_POST_ENDPOINT' with your actual endpoint
      // Optionally, you can handle success, e.g., show a success message or update state
    } catch (error) {
      console.log(error.message);
      // Optionally, you can handle error, e.g., show an error message
    }
  };

  const handleEncounterStatus = async (id) => {
    try {
      setSaveLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/encounter-status-update`,
        { encounter_id: id },
        config
      );
      toast.success(response.data.message);
      handleCloseVisitNotesClick();
    } catch (error) {
      console.log(error.message);
      console.log(error.message);
    } finally {
      setSaveLoading(false);
    }
  };

  const handleEncounterCreateStatus = async (id) => {
    try {
      setSaveLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/encounter-status-update`,
        { encounter_id: id },
        config
      );
      toast.success(response.message);
      handleCloseVisitNotesClick();
    } catch (error) {
      console.log(error.message);
      console.log(error.message);
    } finally {
      setSaveLoading(false);
    }
  };
  useEffect(() => {
    const initialValues = {};
    visitReason.forEach((reason) => {
      initialValues[reason.section_id] = reason.section_text || ""; // Use default value or empty string if undefined
    });
    setTextareaValues(initialValues);
  }, [visitReason]);

  useEffect(() => {
    if (!encounter && getAddedData && getAddedData.new_sections) {
      const initialValues = {};
      getAddedData.new_sections.forEach((reason) => {
        console.log("testreason", reason);
        initialValues[reason.section_id] = reason.section_text || ""; // Use default value or empty string if undefined
      });
      setEncounterDataValues(initialValues);
    }
  }, [encounter, getAddedData]);
  console.log("encountervalue", encounterDataValues);

  useEffect(() => {
    getVisitReasons();

    handleGetAddedData();
  }, []);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValue,
    onSubmit: async (values, action) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        if (values.code && values.description) {
          // If code and description are provided, submit procedure code
          await axios.post(
            `${process.env.REACT_APP_ORIGIN_URL}/add-cpt/note-section`,
            {
              patient_id: id,
              code: values.code,
              description: values.description,
              procedure_id: procedureSectionId,
            },
            config
          );
          getVisitReasons();
          handleGetAddedData();
          toast.success("Procedure code submitted successfully");
        } else {
          // Otherwise, submit form data
          console.log("value_id", values.value_id);
          const response = await axios.post(
            process.env.REACT_APP_ORIGIN_URL + `/add-problem/note-section`,
            {
              patient_id: id,
              diagnosis: values.diagnosis,
              name: values.cd_description,
              assessment_section_id: assessmentSectionId,
              assessment_input: values.assessment_input,
              value_id: values.value_id,
            },
            config
          );
          toast.success(response.message);
          const newProblem = {
            section_id: response.data.data.assessment_section_id,
            section_title: values.name,
            section_text: `${values.diagnosis} ${values.name}`,
          };
          setVisitReason((prevVisitReason) => {
            const newVisitReason = [...prevVisitReason, newProblem];
            setTextareaValues((prevTextareaValues) => ({
              ...prevTextareaValues,
              [newProblem.section_id]: newProblem.section_text,
            }));
            return newVisitReason;
          });
          getVisitReasons();
          handleGetAddedData();
        }
      } catch (error) {
        toast.error(error.message);
      }
      action.resetForm();
    },
  });

  const handlePhysicalExamClick = (sectionId, patientId) => {
    setSelectedSectionId(sectionId);
    setSelectedPatientId(patientId);
    setShowPhysicalExam(true);
  };
  const handlePhysicalClick = (sectionId, patientId) => {
    setCreatedSectionId(sectionId);
    setCreatedPatientId(patientId);
    setPhysical(true);
  };
  const handleReviewClick = (sectionId, patientId) => {
    setCreatedSectionId(sectionId);
    setCreatedPatientId(patientId);
    setReview(true);
  };
  const handleReviewSystemClick = (sectionId, patientId) => {
    setSelectedSectionId(sectionId);
    setSelectedPatientId(patientId);
    setShowReviewSytem(true);
  };

  const handleMentalSystemClick = (sectionId, patientId) => {
    setSelectedSectionId(sectionId);
    setSelectedPatientId(patientId);
    setShowMentalExamination(true);
  };
  const handleMentalClick = (sectionId, patientId) => {
    setSelectedSectionId(sectionId);
    setSelectedPatientId(patientId);
    setShowMental(true);
  };

  const handleClosePhysicalExam = () => {
    setShowPhysicalExam(false);
    getVisitReasons();
  };

  const handleClosePhysical = () => {
    console.log("physical exa, hit");
    setPhysical(false);
    handleGetAddedData();
  };
  const handleCloseMental = () => {
    setShowMental(false);
    handleGetAddedData();
  };
  const handleCloseReviewSystem = () => {
    setShowReviewSytem(false);
    getVisitReasons();
  };
  const handleCloseReview = () => {
    setReview(false);
    handleGetAddedData();
  };

  const handleInputChange = (value) => {
    setSearchInput(value);
    getSearchResults(value);
  };

  const handleProcedureInputChange = (value) => {
    setProcedureSearchInput(value);
    getSearchProcedure(value);
  };
  const handleSearchResultClick = (result) => {
    console.log("resultttt", result);
    setFieldValue("diagnosis", `${result.code} `);
    setFieldValue("cd_description", result.description);
    setFieldValue("assessment_input", result.assessment_input);
    setFieldValue("value_id", result.id);
    setSelectedAssessment((prev) =>
      Array.isArray(prev) ? [...prev, result] : [result]
    );
    setSearchInput("");
    const updatedTextareaValues = {
      ...textareaValues,
      [selectedSectionId]: textareaValues[selectedSectionId]
        ? `${textareaValues[selectedSectionId]}\n${result.code} ${result.description}`
        : `${result.code} ${result.description}`,
    };
    setTextareaValues(updatedTextareaValues);
    setEncounterDataValues(updatedTextareaValues);
  };

  const handleSearchResult = async (result) => {
    try {
      console.log("clickResult", result);
      // Update selectedAssessment state immediately
      setSelectedAssessment((prev) =>
        Array.isArray(prev) ? [...prev, result] : [result]
      );

      // Prepare the data for submission
      const postData = {
        patient_id: id,
        diagnosis: result.code,
        assessment_section_id: assessmentSectionId,
        name: result.description,
        assessment_input: result.assessment_input || "", // You can update this if you want to send an input initially
        value_id: result.id,
      };

      // Config for Axios request
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      // Send the request silently
      await axios.post(
        `${process.env.REACT_APP_ORIGIN_URL}/add-problem/note-section`,
        postData,
        config
      );

      // Optionally, you can update the textarea values or perform additional state updates here
      const updatedTextareaValues = {
        ...textareaValues,
        [selectedSectionId]: textareaValues[selectedSectionId]
          ? `${textareaValues[selectedSectionId]}\n${result.code} ${result.description}`
          : `${result.code} ${result.description}`,
      };
      setTextareaValues(updatedTextareaValues);
      setEncounterDataValues(updatedTextareaValues);
    } catch (error) {
      console.error("Error submitting assessment:", error);
      toast.error("Failed to submit assessment");
    }
  };

  // Update the handleSearchResultClick to use the new function
  const handleNewSearchResultClick = (result) => {
    handleSearchResult(result);
    setSearchInput(""); // Clear the search input
  };

  // Use the updated handleSearchResultClick in your form
  const renderSearchResults = () => {
    if (searchInput && searchResults.length > 0) {
      return (
        <div style={{ position: "relative" }}>
          <ul
            className="search-diagnosis mb-2 w-50 "
            style={{ position: "absolute", left: 0, top: "0", zIndex: 99999 }}
          >
            {searchResults.map((result) => (
              <li
                key={result.id}
                style={{ display: "flex", cursor: "pointer" }}
                onClick={() => handleNewSearchResultClick(result)}
              >
                {result.code} {result.description}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };
  const handleRemoveAssessment = (indexToRemove) => {
    setSelectedAssessment((prevAssessments) =>
      prevAssessments.filter((_, index) => index !== indexToRemove)
    );
  };
  const renderSelectedAssessments = (reason) => {
    if (selectedAssessment && selectedAssessment.length > 0) {
      return (
        <div className="selected-assessments">
          {selectedAssessment.map((section, index) => (
            <div key={index} style={{ display: "flex", marginTop: "0.5rem" }}>
              <span style={{ width: "40%" }}>
                {section.code} {section.description}
              </span>
              {console.log("selectedSection", section)}
              <textarea
                style={{
                  height: "5rem",
                  width: "30rem",
                  borderRadius: "7px",
                  borderColor: "#c0c2c5",
                  marginLeft: "0.5rem",
                }}
                // value={
                //   assessmenttextareaValues[
                //     index
                //   ] || section.assessment_input
                // }
                value={
                  assessmenttextareaValues[index] !== undefined
                    ? assessmenttextareaValues[index]
                    : section.assessment_input
                }
                onChange={(e) =>
                  handleAssessmentTextareaChange(index, e.target.value)
                }
                // onBlur={(e) =>
                //   handleSubmitCreateVisitAssess(
                //     selectedSectionId,
                //     "section",
                //     e,
                //     section.id,
                //     reason.section_id
                //   )
                // }
                onBlur={(e) =>
                  handleCreateTextareaBlur(
                    selectedSectionId,
                    "section",
                    e,
                    section.id,
                    reason.section_id
                  )
                }
              />
              <a
                style={{
                  cursor: "pointer",
                  color: "#57a4f1",
                  justifyContent: "center",
                  textDecoration: "underline",
                  alignItems: "center",
                  display: "flex",
                  marginLeft: "0.3rem",
                  fontSize: "16px",
                }}
                // className="btn visit-btn-wound"
                onClick={() => handleLinkButtonClick(section.code, index)}
                className="fw-bold"
              >
                Save
              </a>
              <button
                type="button"
                className="btn text-center align-items-center visit-btn-wound"
                onClick={() =>
                  handleAssessmentCreateDel(section.id, reason.section_id)
                }
                // onClick={handleRemoveAssessment(index)}
              >
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: "#ba2748",
                    color: "#fff",
                  }}
                  icon={faTimes}
                  className="fa-visit-icon"
                />
              </button>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const handleCreateTextareaBlur = (
    selectedSectionId,
    e,
    section,
    reason,
    index
  ) => {
    const value = assessmenttextareaValues[index] || "";
    // Update the state or trigger an API call with the updated (possibly empty) value
    handleSubmitCreateVisitAssess(
      selectedSectionId,
      "section",
      e.target.value,
      section.id,
      reason.section_id,
      reason.section_type_2,
      value,
      section.value_id
    );
  };

  const handleAssessmentCreateDel = async (valueId, sectionId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      console.log("ids", valueId, sectionId);

      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN_URL}/delete-assessment-note/${sectionId}/${valueId}`,
        config
      );

      // After successfully deleting the assessment, update the state
      if (response.status === 200) {
        setSelectedAssessment((prevState) =>
          prevState.filter((assessment) => assessment.id !== valueId)
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAssessmentTextareaChange = (index, value) => {
    setAssessmentTextareaValues((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleProcedureResultClick = (result) => {
    setFieldValue("code", `${result.code} `);
    setFieldValue("description", result.description);
    setSearchInput("");
    setProcedureSearchInput("");
    const updatedTextareaValues = {
      ...textareaValues,
      [selectedSectionId]: `${result.code} ${result.description}`,
    };
    setTextareaValues(updatedTextareaValues);
    setEncounterDataValues(updatedTextareaValues);
  };

  const handleAssessmentDel = async (valueId, sectionId) => {
    try {
      console.log("valueeee", valueId);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/delete-assessment-note/${sectionId}/${valueId}`,
        config
      );
      toast.success(response.data.message);
      getVisitReasons();
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };
  const handleTextareaBlur = (reason, section, index) => {
    const value = assessmenttextareaValues[index] || "";
    // Update the state or trigger an API call with the updated (possibly empty) value
    handleSubmitVisit(
      reason.section_id,
      reason.section_type_2,
      value,
      section.value_id
    );
  };

  const handleCloseMentalExamination = () => {
    setShowMentalExamination(false);
    getVisitReasons();
  };
  console.log("visitReason", visitReason);
  console.log("visit", encounterData);
  console.log("encounterAddedData", getAddedData);

  console.log("onEncounterCreatedFetch(", onEncounterCreatedFetch);
  return (
    <>
      <div
        className="active fade overflow-hidden h-100 m-1 show tab-pane"
        id="jhonsmith"
        role="tabpanel"
        aria-labelledby="jhonsmith-tab"
      >
        {encounter ? (
          <>
            <div className="patient-header">
              <div className="row row-cols-7  align-items-center">
                <div className="col">
                  <div className="user-visit d-flex align-items-center ">
                    <img src="images/Ellipse 2.png" alt="" />
                    <div className="user-visit-detail ms-3">
                      <ul>
                        <li className="fw-bold">
                          {encounter.patient_full_name}
                        </li>
                        <li>
                          {encounter.gender},{" "}
                          {moment(encounter.date_of_birth).format("MM/DD/YYYY")}{" "}
                        </li>
                        <li>
                          <span className="fw-bold">MRN:</span>
                          {encounter.mrn_no}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="visit-info d-flex align-items-center visit-border-left">
                    <p>
                      {encounter.provider_name} (
                      {encounter.encounter_type_title})
                    </p>
                    <div className="ms-auto">
                      <ul>
                        <li>
                          <span className="fw-bold">DOS:</span>{" "}
                          {moment(encounter.encounter_date).format(
                            "MM/DD/YYYY"
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="visit-border-left d-flex flex-column justify-content-center ">
                    <h5>Speciality</h5>
                    <p>{encounter.specialty_title}</p>
                    <h5>Encounter Code</h5>
                    <p>99345; Home/res Visit New Patien</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="visit-reason-links d-flex mt-4 ">
                <div className="d-flex">
                  <i className="fas fa-circle me-2"></i>
                  <h5>Follow Up {encounter.encounter_type_title}</h5>
                </div>
                <button
                  type="button"
                  className="btn btn-visit ms-auto"
                  onClick={handleCloseVisitNotesClick}
                >
                  {" "}
                  Back
                </button>
              </div>

              {showPhysicalExam ? (
                <PhysicalExam
                  sectionId={selectedSectionId}
                  patientId={selectedPatientId}
                  handleClosePhysicalExam={handleClosePhysicalExam}
                  signed={signed}
                />
              ) : showReviewSystem ? (
                <ReviewSystem
                  sectionId={selectedSectionId}
                  patientId={selectedPatientId}
                  handleCloseReviewSystem={handleCloseReviewSystem}
                  signed={signed}
                />
              ) : showMentalExamination ? (
                <MentalExamization
                  sectionId={selectedSectionId}
                  patientId={selectedPatientId}
                  handleCloseMentalExamination={handleCloseMentalExamination}
                  signed={signed}
                />
              ) : loading ? (
                <tr>
                  <td colSpan="12">
                    <div className="loading-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                visitReason &&
                visitReason.map((reason, index) => {
                  console.log("listReason", reason);
                  return (
                    <div key={index}>
                      <div className="visit-text-area">
                        <div className="chief-complaint mt-3">
                          <div className="form-floating">
                            {reason.section_slug === "assessments" && (
                              <div>
                                <div
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#1768B8",
                                      color: "white",
                                      border: "none",
                                      borderRadius: "3px",
                                    }}
                                    onClick={() => {
                                      handleShow();
                                      setAssessmentSectionId(reason.section_id);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                      icon={faPlus}
                                    />
                                  </button>
                                </div>

                                {show && (
                                  <form className="form-diagnosis">
                                    <div className="d-flex">
                                      <div className="input-group input-search rounded w-50">
                                        <input
                                          type="text"
                                          style={{
                                            border: "1px solid #dee2e6",
                                          }}
                                          className="rounded w-100"
                                          id="diagnosis"
                                          placeholder="Search diagnosis"
                                          aria-label="Search"
                                          aria-describedby="search-addon"
                                          ref={inputRef}
                                          value={searchInput}
                                          name="diagnosis"
                                          onChange={(e) =>
                                            handleInputChange(e.target.value)
                                          }
                                          disabled={signed}
                                        />
                                        {searchInput ? null : (
                                          <i className="fas fa-search search-icon"></i>
                                        )}
                                      </div>
                                      <button
                                        type="button"
                                        className="btn text-center visit-btn-wound"
                                        onClick={handleClose}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            padding: "1px 3px 1px 3px",
                                            backgroundColor: "#ba2748",
                                            color: "#fff",
                                          }}
                                          icon={faTimes}
                                          className="fa-visit-icon"
                                        />
                                      </button>
                                      {assessmentLoading ? (
                                        <div className="loading-spinner">
                                          <div
                                            className="spinner-border text-primary"
                                            role="status"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {assessmentLoading ? (
                                      " "
                                    ) : (
                                      <div
                                        style={{
                                          zIndex: 99999,
                                          position: "absolute",
                                          width: "100%",
                                        }}
                                      >
                                        {searchInput &&
                                          searchResults.length > 0 && (
                                            <ul className="search-diagnosis mb-2 w-50">
                                              {searchResults.map((result) => (
                                                <React.Fragment key={result.id}>
                                                  <li
                                                    style={{
                                                      display: "flex",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      handleSearchResultClick(
                                                        result
                                                      );
                                                      handleSubmit();
                                                    }}
                                                  >
                                                    {result.code}{" "}
                                                    {result.description}
                                                  </li>
                                                </React.Fragment>
                                              ))}
                                            </ul>
                                          )}
                                      </div>
                                    )}
                                  </form>
                                )}

                                <div className="selected-assessments">
                                  {console.log("sectionReason", reason)}
                                  {reason.assessment_notes &&
                                    reason.assessment_notes.map(
                                      (section, index) => (
                                        <div key={index}>
                                          {console.log("sectiontext", section)}
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: "0.5rem",
                                            }}
                                          >
                                            <span style={{ width: "40%" }}>
                                              {section.Code}{" "}
                                              {section.Description}
                                            </span>
                                            <textarea
                                              style={{
                                                height: "5rem",
                                                width: "30rem",
                                                borderRadius: "7px",
                                                borderColor: "#c0c2c5",
                                                marginLeft: "0.5rem",
                                              }}
                                              // defaultValue={
                                              //   section.assessment_input
                                              // }
                                              value={
                                                assessmenttextareaValues[
                                                  index
                                                ] !== undefined
                                                  ? assessmenttextareaValues[
                                                      index
                                                    ]
                                                  : section.assessment_input
                                              }
                                              onChange={(e) =>
                                                handleAssessmentTextareaChange(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                              onBlur={() =>
                                                handleTextareaBlur(
                                                  reason,
                                                  section,
                                                  index
                                                )
                                              }
                                              // onBlur={(e) =>
                                              //   handleSubmitVisit(
                                              //     reason.section_id,
                                              //     reason.section_type_2,
                                              //     e.target.value,
                                              //     section.value_id
                                              //   )
                                              // }
                                            />
                                            <button
                                              type="button"
                                              className="btn  text-center align-items-center visit-btn-wound"
                                              onClick={() =>
                                                handleAssessmentDel(
                                                  section.value_id,
                                                  reason.section_id
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  borderRadius: "5px",
                                                  fontSize: "18px",
                                                  padding: "1px 3px 1px 3px",
                                                  backgroundColor: "#ba2748",
                                                  color: "#fff",
                                                }}
                                                icon={faTimes}
                                                className="fa-visit-icon"
                                              />
                                            </button>
                                            <button
                                              type="button"
                                              className="btn  text-center align-items-center visit-btn-wound"
                                              onClick={() =>
                                                handleLinkButtonClick(
                                                  section.Code,
                                                  index
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  borderRadius: "5px",
                                                  fontSize: "20px",
                                                  padding: "1px 3px 1px 3px",
                                                  // backgroundColor: "#ba2748",
                                                  // color: "#fff",
                                                }}
                                                icon={faFloppyDisk}
                                                className="fa-visit-icon"
                                              />
                                              {/* style={{cursor:"pointer",color:"#57a4f1",justifyContent:'center',textDecoration:"underline",alignItems:"center",display:"flex",marginLeft:"0.3rem",fontSize:"16px"}} */}

                                              {/* className="fw-bold" */}

                                              {/* Save */}
                                            </button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            )}
                            {reason.section_slug === "procedure" && (
                              <div>
                                <div
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#1768B8",
                                      color: "white",
                                      border: "none",
                                      borderRadius: "3px",
                                    }}
                                    onClick={() => {
                                      handleShowProcedure();
                                      setProcedureSectionId(reason.section_id);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ borderRadius: "5px" }}
                                      icon={faPlus}
                                    />
                                  </button>
                                </div>
                                {showProcedure && (
                                  <form className="form-diagnosis">
                                    <div className="d-flex">
                                      <div className="input-group input-search rounded w-50">
                                        <input
                                          type="text"
                                          style={{
                                            border: "1px solid #dee2e6",
                                          }}
                                          className="rounded w-100"
                                          id="procedure"
                                          placeholder="Search procedure"
                                          aria-label="Search"
                                          aria-describedby="search-addon"
                                          ref={procedureRef}
                                          value={procedureSearchInput}
                                          name="procedure"
                                          onChange={(e) =>
                                            handleProcedureInputChange(
                                              e.target.value
                                            )
                                          }
                                          disabled={signed}
                                        />
                                        {procedureSearchInput ? null : (
                                          <i className="fas fa-search search-icon"></i>
                                        )}
                                      </div>
                                      <button
                                        type="button"
                                        className="btn text-center visit-btn-wound"
                                        onClick={handleCloseProcedure}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            padding: "1px 3px 1px 3px",
                                            backgroundColor: "#ba2748",
                                            color: "#fff",
                                          }}
                                          icon={faTimes}
                                          className="fa-visit-icon"
                                        />
                                      </button>
                                    </div>
                                    {procedureLoading ? (
                                      <div className="loading-spinner">
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      procedureSearchInput &&
                                      procedureResults.length > 0 && (
                                        <ul className="search-diagnosis mb-2 w-50">
                                          {procedureResults.map((result) => (
                                            <React.Fragment key={result.id}>
                                              <li
                                                style={{
                                                  display: "flex",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  handleProcedureResultClick(
                                                    result
                                                  );
                                                  handleSubmit();
                                                }}
                                              >
                                                {console.log(
                                                  "resultProcedure",
                                                  result
                                                )}
                                                {result.code}
                                                {result.description}
                                              </li>
                                            </React.Fragment>
                                          ))}
                                        </ul>
                                      )
                                    )}
                                  </form>
                                )}
                              </div>
                            )}
                            {reason.section_type === true ? (
                              <WoundEncounter
                                sectionId={reason.section_id}
                                patientId={encounter.patient_id}
                                woundData={visitReason}
                                triggerSign={signTrigger}
                                id={encounter.id}
                              />
                            ) : reason.section_slug === "review-of-systems" ||
                              reason.section_slug === "physical-exam" ||
                              reason.section_slug ===
                                "mental_status_examination" ? (
                              <div
                                className="visit-text-area-bg"
                                style={{ position: "relative" }}
                              >
                                <div
                                  style={{ marginTop: "2rem" }}
                                  dangerouslySetInnerHTML={{
                                    __html: textareaValues[reason.section_id],
                                  }}
                                ></div>
                                <button
                                  className="edit-reason"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      reason.section_slug ===
                                      "review-of-systems"
                                    ) {
                                      handleReviewSystemClick(
                                        reason.section_id,
                                        encounter.patient_id
                                      );
                                    } else if (
                                      reason.section_slug ===
                                      "mental_status_examination"
                                    ) {
                                      handleMentalSystemClick(
                                        reason.section_id,
                                        encounter.patient_id
                                      );
                                    } else {
                                      handlePhysicalExamClick(
                                        reason.section_id,
                                        encounter.patient_id
                                      );
                                    }
                                  }}
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                              </div>
                            ) : (
                              <textarea
                                className="form-control mt-2"
                                placeholder="Leave a comment here"
                                id={`textarea-${reason.section_id}`}
                                name={reason.section_id}
                                value={textareaValues[reason.section_id]}
                                onChange={(event) =>
                                  handleTextareaChange(event, reason.section_id)
                                }
                                onBlur={() =>
                                  handleSubmitVisit(
                                    reason.section_id,
                                    reason.section_type_2
                                  )
                                }
                                disabled={signed}
                              ></textarea>
                            )}
                            <label htmlFor={`textarea-${reason.section_id}`}>
                              {reason.section_title}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </>
        ) : (
          <>
            <div className="patient-header">
              <div className="row row-cols-7 align-items-center">
                <div className="col">
                  <div className="user-visit d-flex align-items-center ">
                    <img src="images/Ellipse 2.png" alt="" />
                    <div className="user-visit-detail ms-3">
                      <ul>
                        <li className="fw-bold">
                          {encounterData.encounter_details.patient_full_name}
                        </li>
                        <li>
                          {encounterData.encounter_details.gender},{" "}
                          {moment(
                            encounterData.encounter_details.date_of_birth
                          ).format("MM/DD/YYYY")}{" "}
                        </li>
                        <li>
                          <span className="fw-bold">MRN:</span>
                          {encounterData.encounter_details.mrn_no}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="visit-info d-flex align-items-center visit-border-left">
                    {console.log("encounterData", encounterData)}
                    <p>
                      {encounterData.encounter_details.provider_name} (
                      {encounterData.encounter_details.encounter_type_title})
                    </p>
                    <div className="ms-auto">
                      <ul>
                        <li>
                          <span className="fw-bold">DOS:</span>{" "}
                          {moment(
                            encounterData.encounter_details.encounter_date
                          ).format("MM/DD/YYYY")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="visit-border-left d-flex flex-column justify-content-center ">
                    <h5>Speciality</h5>
                    <p>{encounterData.encounter_details.specialty_title}</p>
                    <h5>Encounter Code</h5>
                    <p>99345; Home/res Visit New Patient</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="visit-reason-links d-flex mt-4 ">
                <div className="d-flex">
                  <i className="fas fa-circle me-2"></i>
                  <h5>
                    Follow Up{" "}
                    {encounterData.encounter_details.encounter_type_title}
                  </h5>
                </div>
                <button
                  type="button"
                  className="btn btn-visit ms-auto"
                  // onClick={handleCloseVisitNotesClick}
                  onClick={handleCloseEncounterClick}
                >
                  {" "}
                  Back
                </button>
              </div>

              {physical ? (
                <PhysicalExam
                  sectionId={createdSectionId}
                  patientId={createdPatientId}
                  handleClosePhysical={handleClosePhysical}
                />
              ) : review ? (
                <ReviewSystem
                  sectionId={createdSectionId}
                  patientId={createdPatientId}
                  handleCloseReview={handleCloseReview}
                />
              ) : showMental ? (
                <MentalExamization
                  sectionId={selectedSectionId}
                  patientId={selectedPatientId}
                  handleCloseMentalExamination={handleCloseMental}
                  signed={signed}
                />
              ) : loading ? (
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{ display: "block", padding: "10px" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                getAddedData.new_sections &&
                getAddedData.new_sections.map((reason, index) => {
                  console.log("createReason", reason);
                  return (
                    <>
                      <div key={index}>
                        <div className="visit-text-area ">
                          <div className="chief-complaint mt-2">
                            {reason.section_slug === "assessments" && (
                              <div>
                                <div
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#1768B8",
                                      color: "white",
                                      border: "none",
                                      borderRadius: "3px",
                                    }}
                                    onClick={() => {
                                      handleShow();
                                      setAssessmentSectionId(reason.section_id);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                      icon={faPlus}
                                    />
                                  </button>
                                </div>

                                {show && (
                                  <form className="form-diagnosis">
                                    <div className="d-flex">
                                      <div className="input-group input-search rounded w-50">
                                        <input
                                          type="text"
                                          style={{
                                            border: "1px solid #dee2e6",
                                          }}
                                          className="rounded w-100"
                                          id="diagnosis"
                                          placeholder="Search diagnosis"
                                          aria-label="Search"
                                          aria-describedby="search-addon"
                                          ref={inputRef}
                                          value={searchInput}
                                          name="diagnosis"
                                          onChange={(e) =>
                                            handleInputChange(e.target.value)
                                          }
                                          disabled={signed}
                                        />
                                        {searchInput ? null : (
                                          <i className="fas fa-search search-icon"></i>
                                        )}
                                      </div>

                                      <button
                                        type="button"
                                        className="btn text-center visit-btn-wound"
                                        onClick={handleClose}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            padding: "1px 3px 1px 3px",
                                            backgroundColor: "#ba2748",
                                            color: "#fff",
                                          }}
                                          icon={faTimes}
                                          className="fa-visit-icon"
                                        />
                                      </button>
                                    </div>

                                    {/* {renderSearchResults()} */}
                                    {searchInput &&
                                      searchResults.length > 0 && (
                                        <div style={{ position: "relative" }}>
                                          <ul
                                            className="search-diagnosis mb-2 w-50"
                                            style={{
                                              position: "absolute",
                                              left: 0,
                                              top: "0",
                                              zIndex: 99999,
                                            }}
                                          >
                                            {searchResults.map((result) => (
                                              <li
                                                key={result.id}
                                                style={{
                                                  display: "flex",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleNewSearchResultClick(
                                                    result
                                                  )
                                                }
                                              >
                                                {result.code}{" "}
                                                {result.description}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}

                                    {assessmentLoading && (
                                      <div className="loading-spinner">
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </form>
                                )}
                                <div className="selected-assessments">
                                  {selectedAssessment.length > 0 && (
                                    <>
                                      <div className="selected-assessments">
                                        {selectedAssessment.map(
                                          (section, index) => (
                                            <div
                                              key={index}
                                              style={{
                                                display: "flex",
                                                marginTop: "0.5rem",
                                              }}
                                            >
                                              <span style={{ width: "40%" }}>
                                                {section.code}{" "}
                                                {section.description}
                                              </span>
                                              <textarea
                                                style={{
                                                  height: "5rem",
                                                  width: "30rem",
                                                  borderRadius: "7px",
                                                  borderColor: "#c0c2c5",
                                                  marginLeft: "0.5rem",
                                                }}
                                                value={
                                                  assessmenttextareaValues[
                                                    index
                                                  ] !== undefined
                                                    ? assessmenttextareaValues[
                                                        index
                                                      ]
                                                    : section.assessment_input
                                                }
                                                onChange={(e) =>
                                                  handleAssessmentTextareaChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  handleCreateTextareaBlur(
                                                    selectedSectionId,
                                                    e,
                                                    section,
                                                    reason,
                                                    index
                                                  )
                                                }
                                              />
                                              <button
                                                type="button"
                                                className="btn text-center align-items-center visit-btn-wound"
                                                onClick={() =>
                                                  handleAssessmentCreateDel(
                                                    section.id,
                                                    reason.section_id
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  style={{
                                                    borderRadius: "5px",
                                                    fontSize: "14px",
                                                    padding: "1px 3px 1px 3px",
                                                    backgroundColor: "#ba2748",
                                                    color: "#fff",
                                                  }}
                                                  icon={faTimes}
                                                  className="fa-visit-icon"
                                                />
                                              </button>
                                              <button
                                                type="button"
                                                className="btn text-center align-items-center visit-btn-wound"
                                                // style={{
                                                //   cursor: "pointer",
                                                //   color: "#57a4f1",
                                                //   justifyContent: "center",
                                                //   textDecoration: "underline",
                                                //   alignItems: "center",
                                                //   display: "flex",
                                                //   marginLeft: "0.3rem",
                                                //   fontSize: "16px",
                                                // }}
                                                onClick={() =>
                                                  handleLinkButtonClick(
                                                    section.code,
                                                    index
                                                  )
                                                }
                                                // className="fw-bold"
                                              >
                                                <FontAwesomeIcon
                                                  style={{
                                                    borderRadius: "5px",
                                                    fontSize: "20px",
                                                    padding: "1px 3px 1px 3px",
                                                    // backgroundColor: "#ba2748",
                                                    // color: "#fff",
                                                  }}
                                                  icon={faFloppyDisk}
                                                  className="fa-visit-icon"
                                                />
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                                {/* {renderSelectedAssessments(reason)} */}
                                <div className="selected-assessments">
                                  {console.log("sectionReason", reason)}
                                  {/* {reason.assessment_notes &&
                                    reason.assessment_notes.map(
                                      (section, index) => (
                                        <div key={index}>
                                          {console.log("sectiontext", section)}
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: "0.5rem",
                                            }}
                                          >
                                            <span style={{ width: "40%" }}>
                                              {section.Code}{" "}
                                              {section.Description}
                                            </span>
                                            <textarea
                                              style={{
                                                height: "5rem",
                                                width: "30rem",
                                                borderRadius: "7px",
                                                borderColor: "#c0c2c5",
                                                marginLeft: "0.5rem",
                                              }}
                                              // defaultValue={
                                              //   section.assessment_input
                                              // }
                                              value={
                                                assessmenttextareaValues[
                                                  index
                                                ] || section.assessment_input
                                              }
                                              onChange={(e) =>
                                                handleAssessmentTextareaChange(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                              onBlur={(e) =>
                                                handleSubmitCreateVisit(
                                                  reason.section_id,
                                                  reason.section_type_2,
                                                  e.target.value,
                                                  section.value_id
                                                )
                                              }
                                            />
                                            <a
                                              style={{cursor:"pointer",color:"#57a4f1",justifyContent:'center',textDecoration:"underline",alignItems:"center",display:"flex",marginLeft:"0.3rem",fontSize:"16px"}}
                                              onClick={() =>
                                                handleLinkButtonClick(
                                                  section.value_id,
                                                  index
                                                )
                                              }
                                                 className="fw-bold"
                                            >
                                              Save
                                            </a>
                                            <button
                                              type="button"
                                              className="btn  text-center align-items-center visit-btn-wound"
                                              onClick={() =>
                                                handleAssessmentCreateDel(
                                                  section.value_id,
                                                  reason.section_id,
                                                  reason.encounter_id
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                style={{
                                                  borderRadius: "5px",
                                                  fontSize: "14px",
                                                  padding: "1px 3px 1px 3px",
                                                  backgroundColor: "#ba2748",
                                                  color: "#fff",
                                                }}
                                                icon={faTimes}
                                                className="fa-visit-icon"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      )
                                    )} */}
                                </div>
                              </div>
                            )}
                            {reason.section_slug === "procedure" && (
                              <div>
                                <div
                                  className="mb-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#1768B8",
                                      color: "white",
                                      border: "none",
                                      borderRadius: "3px",
                                    }}
                                    onClick={() => {
                                      handleShowProcedure();
                                      setProcedureSectionId(reason.section_id);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ borderRadius: "5px" }}
                                      icon={faPlus}
                                    />
                                  </button>
                                </div>
                                {showProcedure && (
                                  <form className="form-diagnosis">
                                    <div className="d-flex">
                                      <div className="input-group input-search rounded w-50">
                                        <input
                                          type="text"
                                          style={{
                                            border: "1px solid #dee2e6",
                                          }}
                                          className="rounded w-100"
                                          id="procedure"
                                          placeholder="Search procedure"
                                          aria-label="Search"
                                          aria-describedby="search-addon"
                                          ref={procedureRef}
                                          value={procedureSearchInput}
                                          name="procedure"
                                          onChange={(e) =>
                                            handleProcedureInputChange(
                                              e.target.value
                                            )
                                          }
                                          disabled={signed}
                                        />
                                        {procedureSearchInput ? null : (
                                          <i className="fas fa-search search-icon"></i>
                                        )}
                                      </div>
                                      <button
                                        type="button"
                                        className="btn text-center visit-btn-wound"
                                        onClick={handleCloseProcedure}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            padding: "1px 3px 1px 3px",
                                            backgroundColor: "#ba2748",
                                            color: "#fff",
                                          }}
                                          icon={faTimes}
                                          className="fa-visit-icon"
                                        />
                                      </button>
                                    </div>
                                    {procedureLoading ? (
                                      <div className="loading-spinner">
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      procedureSearchInput &&
                                      procedureResults.length > 0 && (
                                        <ul className="search-diagnosis mb-2 w-50">
                                          {procedureResults.map((result) => (
                                            <React.Fragment key={result.id}>
                                              <li
                                                style={{
                                                  display: "flex",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  handleProcedureResultClick(
                                                    result
                                                  );
                                                  handleSubmit();
                                                }}
                                              >
                                                {result.code}{" "}
                                                {result.description}
                                              </li>
                                            </React.Fragment>
                                          ))}
                                        </ul>
                                      )
                                    )}
                                  </form>
                                )}
                              </div>
                            )}

                            <div className="form-floating">
                              {reason.section_type === true ? (
                                <WoundEncounter
                                  sectionId={reason.section_id}
                                  patientId={
                                    getAddedData.encounter_details.patient_id
                                  }
                                  triggerSign={signTrigger}
                                  id={getAddedData.encounter_id}
                                  prevData={getAddedData.new_sections}
                                  state={prevData}
                                />
                              ) : reason.section_slug === "review-of-systems" ||
                                reason.section_slug === "physical-exam" ||
                                reason.section_slug ===
                                  "mental_status_examination" ? (
                                <div
                                  className="visit-text-area-bg"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    style={{ marginTop: "2rem" }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        encounterDataValues[reason.section_id],
                                    }}
                                  ></div>
                                  <button
                                    className="edit-reason"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      background: "none",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (
                                        reason.section_slug ===
                                        "review-of-systems"
                                      ) {
                                        handleReviewClick(
                                          reason.section_id,
                                          getAddedData.encounter_details
                                            .patient_id
                                        );
                                      } else if (
                                        reason.section_slug ===
                                        "mental_status_examination"
                                      ) {
                                        // Add this check if there is a specific condition for handleAssessmentClick
                                        handleMentalClick(
                                          reason.section_id,
                                          getAddedData.encounter_details
                                            .patient_id
                                        );
                                      } else {
                                        handlePhysicalClick(
                                          reason.section_id,
                                          getAddedData.encounter_details
                                            .patient_id
                                        );
                                      }
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </button>
                                </div>
                              ) : (
                                <textarea
                                  className="form-control mt-2"
                                  placeholder="Leave a comment here"
                                  id={`textarea-${reason.section_id}`}
                                  name={reason.section_id}
                                  value={encounterDataValues[reason.section_id]}
                                  onChange={(event) =>
                                    handleCreateTextareaChange(
                                      event,
                                      reason.section_id
                                    )
                                  }
                                  onBlur={() =>
                                    handleSubmitCreateVisit(
                                      reason.section_id,
                                      reason.section_type_2
                                    )
                                  }
                                  disabled={signed}
                                ></textarea>
                              )}
                              <label htmlFor={`textarea-${reason.section_id}`}>
                                {reason.section_title}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </div>
          </>
        )}

        {!showPhysicalExam &&
          !showReviewSystem &&
          !physical &&
          !review &&
          !showMentalExamination &&
          !showMental && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                background: "#ecf5ff",
                padding: "20px",
              }}
            >
              {!encounter && (
                <Button
                  variant="secondary"
                  style={{ marginRight: "5px" }}
                  onClick={handleCloseEncounterClick}
                >
                  Save as Draft
                </Button>
              )}
              {encounter && (
                <Button
                  variant="secondary"
                  style={{ marginRight: "5px" }}
                  onClick={handleCloseVisitNotesClick}
                >
                  Save As Draft
                </Button>
              )}
              {encounter && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    handleEncounterStatus(encounter.id);
                    setSignTrigger(true);
                  }}
                >
                  {saveLoading ? "Processing..." : "Sign"}
                </button>
              )}
              {!encounter && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    handleEncounterCreateStatus(encounterData.encounter_id);
                    setSignTrigger(true);
                  }}
                >
                  {saveLoading ? "Processing..." : "Sign"}
                </button>
              )}
            </div>
          )}

        <ToastContainer />
      </div>
    </>
  );
};

export default VisitReason;
