import React, { useEffect, useState } from "react";
import "../PatientProfile.css";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pp from "../../../../assets/images/avatar.png";
import { Calendar } from "primereact/calendar";
import { Form, Col, Row, Button } from "react-bootstrap";
import { FloatLabel } from "primereact/floatlabel";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { handleGetPatientDetails } from "../../../../components/services/VitalService";

const Demographics = ({ details, onRefresh, handleGetSidePatient }) => {
  console.log("details", details);
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );
  const [show, setShow] = useState(false);
  const [disableFields, setDisableFields] = useState(true);
  const [disableAuth, setDisabledAuth] = useState(true);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [file, setfile] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const [patientDetail, setPatientDetail] = useState({
    id: details.id,
    profile_pic: details.profile_pic,

    switch: details.switch,
    first_name: details.first_name,
    middle_name: details.middle_name,
    last_name: details.last_name,
    social_security_no: details.social_security_no,
    medical_no: details.medical_no,
    age: details.age,
    gender: details.gender,
    date_of_birth: new Date(details.date_of_birth),

    referral_source_1: details.referral_source_1,
    referral_organization: details.referral_organization,
    referral_company_name: details.referral_company_name,
    referral_contact_name: details.referral_contact_name,
    referral_contact_email: details.referral_contact_email,
    referral_contact_number: details.referral_contact_number,
    financial_class: details.financial_class,
    fin_class_name: details.fin_class_name,
    doctor_name: details.doctor_name,
    auth: details.auth,
    account_no: details.account_no,
    admit_date: details.admit_date,
    disch_date: details.disch_date,

    pre_admit_date: details.pre_admit_date,
    contact_address: details.contact_address,
    nursing_station: details.nursing_station,

    email: details.email,
    other_contact_name: details.other_contact_name,
    other_contact_address: details.other_contact_address,
    other_contact_country: details.other_contact_country,
    other_contact_city: details.other_contact_city,
    other_contact_state: details.other_contact_state,
    other_contact_phone_no: details.other_contact_phone_no,
    other_contact_cell: details.other_contact_cell,
    other_email: details.other_email,
    relationship: details.relationship,
    medical_dependency: details.medical_dependency,
    primarylanguage: details.primarylanguage,
    city: details.city,
    state: details.state,
    email: details.email,
    phone_no: details.phone_no,
    zip_code: details.zip_code,
    country: details.country,
    status: details.status,
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const calculateAge = (dob) => {
    const birthDate = moment(dob);
    const age = moment().diff(birthDate, "years");
    return age;
  };
  const handleInput = (e) => {
    let name, value;
    if (e.target) {
      name = e.target.id;
      value = e.target.value;
    } else {
      name = "date_of_birth";
      value = e.value;
    }

    if (name === "date_of_birth") {
      const age = calculateAge(value);
      setPatientDetail((prevState) => ({
        ...prevState,
        [name]: value,
        age: age,
      }));
    } else {
      setPatientDetail((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaveLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const { data } = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/update-patient`,
        patientDetail,
        config
      );

      toast.success(data.message);
      handleGetSidePatient();
      setDisableFields(true);
    } catch (error) {
      toast.error(error.message);
      console.log("error", error.message);
    } finally {
      setSaveLoading(false);
    }
  };
  const handleBlacklist = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const { data } = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/blacklist-patient`,
        { patient_id: details.id },
        config
      );

      toast.success(data.message);
      handleClose();
    } catch (error) {
      toast.error(error.message);
      console.log("error", error.message);
    }
  };

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  async function uploadImage(event) {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setfile(base64);
    setPatientDetail({ ...patientDetail, profile_pic: base64 });
    const object = {
      target: {
        value: base64,
      },
    };

    return base64;
  }

  const handleGetStates = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/us-states",
        config
      );
      setStates(response.data);
      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetStates();
  }, []);
  return (
    <div className="d-flex flex-column flex-fill h-100 overflow-hidden ">
      <div className="tab-content" id="nav-tabContent">
        {/* demographics tab start */}
        <div
          className="tab-pane fade show active overflow-auto h-100"
          id="nav-Demographics"
          role="tabpanel"
          aria-labelledby="nav-Demographics-tab"
          tabIndex="0"
        >
          <div className="demo-main-patient ">
            <div className="patient-info-bg ">
              <div className="row g-3">
                <div className="col-lg-6">
                  <div className="personal-info">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3>Patient Information</h3>
                      {disableFields ? (
                        <button
                          className="btn btn-primary mb-4 "
                          onClick={() => {
                            setDisableFields(false);
                          }}
                        >
                          {" "}
                          Edit
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary mb-3 "
                          onClick={handleSubmit}
                        >
                          {saveLoading ? "Processing..." : "Save"}
                        </button>
                      )}
                    </div>

                    <div className="row g-3 mb-3">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="first_name"
                            placeholder="first name"
                            name="first_name"
                            value={patientDetail.first_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="first_name">First Name</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="middle_name"
                            placeholder="middle name"
                            name="middle_name"
                            value={patientDetail.middle_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="middle_name">Middle Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-3">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="last_name"
                            placeholder="last name"
                            name="last_name"
                            value={patientDetail.last_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="last_name">Last Name</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="gender"
                            value={patientDetail.gender}
                            onChange={handleInput}
                            className="form-select w-100"
                            id="gender"
                            disabled={disableFields}
                          >
                            <option value="" selected>
                              {patientDetail.gender}
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          <label htmlFor="gender">Gender</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-3">
                      <div className="col-md">
                        <div className="form-floating">
                          <FloatLabel>
                            <Calendar
                              id="date_of_birth"
                              value={patientDetail.date_of_birth}
                              onChange={handleInput}
                              disabled={disableFields}
                              dateFormat="mm/dd/yy"
                              showIcon
                            />
                            <label htmlFor="date_of_birth">DOB</label>
                          </FloatLabel>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="age"
                            placeholder="age"
                            name="age"
                            value={patientDetail.age}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="age">Age</label>
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-3">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="medical_no"
                            placeholder="medical_no"
                            name="medical_no"
                            value={patientDetail.medical_no}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="medical_no">Member ID</label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="primarylanguage"
                            placeholder="Primarylanguage"
                            name="primarylanguage"
                            value={patientDetail.primarylanguage}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="primarylanguage">
                            Primary Language
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row g-3 mb-3 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="financial_class"
                            placeholder="financial_class"
                            name="financial_class"
                            value={patientDetail.financial_class}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="financial_class">
                            Current Insurance
                          </label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="fin_class_name"
                              placeholder="fin_class_name"
                              name="fin_class_name"
                              value={patientDetail.fin_class_name}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="fin_class_name">
                              Current Insurance No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="row g-3 mb-3">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="social_security_no"
                            placeholder="social_security_no"
                            name="social_security_no"
                            value={patientDetail.social_security_no}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="social_security_no">
                            Social Security No
                          </label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="medical_dependency"
                              placeholder="medical_dependency"
                              name="medical_dependency"
                              value={patientDetail.medical_dependency}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="medical_dependency">
                              Medical Dependency
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row g-3 mb-3 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="referral_source_1"
                            onChange={handleInput}
                            value={patientDetail.referral_source_1}
                            className="form-select w-100"
                            id="referral_source_1"
                            disabled={disableFields}
                          >
                            <option value="">
                              {patientDetail.referral_source_1}
                            </option>
                            <option value="Hospital">Organization</option>
                            <option value="Walk-In">Walk-In</option>
                          </select>
                          {/* <input
                            type="text"
                            className="form-control w-100"
                            id="referral_source_1"
                            placeholder="referral_source_1"
                            name="referral_source_1"
                            value={patientDetail.referral_source_1}
                            onChange={handleInput}
                            disabled={disableFields}
                          /> */}
                          <label htmlFor="referral_source_1">
                            Referral Source 1
                          </label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="col-md">
                          <div className="form-floating">
                            <select
                              name="referral_organization"
                              onChange={handleInput}
                              value={patientDetail.referral_organization}
                              className="form-select w-100"
                              id="referral_organization"
                              disabled={disableFields}
                            >
                              <option value="">
                                {patientDetail.referral_organization
                                  ? patientDetail.referral_organization
                                  : "Select"}
                              </option>
                              <option value="Hospital">Hospital</option>
                              <option value="Walk-In">Out-Reach</option>
                            </select>
                            <label htmlFor="referral_organization">
                              Referral Organization
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row g-3 mb-5 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="referral_company_name"
                            placeholder="referral_company_name"
                            name="referral_company_name"
                            value={patientDetail.referral_company_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="referral_company_name">
                            Referral Company Name
                          </label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="referral_contact_name"
                              placeholder="referral_contact_name"
                              name="referral_contact_name"
                              value={patientDetail.referral_contact_name}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="referral_contact_name ">
                              Referral Contact Name
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row g-3 mb-5 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="tel"
                            className="form-control w-100"
                            id="referral_contact_number "
                            placeholder="referral_contant_number"
                            name="referral_contact_number"
                            value={patientDetail.referral_contact_number}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="resid_military ">
                            Referral Contact Number
                          </label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control w-100"
                              id="referral_contact_email "
                              placeholder="referral_contact_email"
                              name="referral_contact_email"
                              value={patientDetail.referral_contact_email}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="referral_contact_email ">
                              Referral Contact Email
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="row g-3 mb-3 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="doctor_name"
                            placeholder="doctor_name"
                            name="doctor_name"
                            value={patientDetail.doctor_name}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="doctor_name">Doctor Name</label>
                        </div>
                      </div>

                      <div className="col-md">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="npp"
                              placeholder="npp"
                              name="npp"
                              value={patientDetail.npp}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="npp">NPI</label>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="row g-3 mb-3 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="auth"
                            placeholder="auth"
                            name="auth"
                            value={patientDetail.auth}
                            // onChange={handleInput}
                            disabled={disableAuth}
                          />
                          <label htmlFor="auth">Auth</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="nursing_station"
                              placeholder="nursing_station"
                              name="nursing_station"
                              value={patientDetail.nursing_station}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="nursing_station">Facility</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row g-3 mb-3 ">
                      <div className="col-lg-6">
                        <div className="col-md">
                          <div className="form-floating">
                            <select
                              name="switch"
                              value={patientDetail.switch}
                              onChange={handleInput}
                              className={`form-select w-100 `}
                              id="switch"
                              disabled={disableFields}
                            >
                              <option value="">select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <label htmlFor="switch">
                              Does Patient Need to Swtich
                            </label>
                          </div>
                          {/* {errors.gender && touched.gender ? (
                        <p style={{ color: "red" }}>{errors.gender}</p>
                      ) : null} */}
                        </div>

                        {/* {errors.ssn && touched.ssn ? (
                      <p style={{ color: "red" }}>{errors.age}</p>
                    ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Second column */}
                <div className="col-lg-6">
                  <div className="personal-info">
                    <h3>Contact Information</h3>
                    <div className="row g-3 mb-3 ">
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="contact_address"
                            placeholder="contact_address"
                            name="contact_address"
                            value={patientDetail.contact_address}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="contact_address">Address</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="city"
                            placeholder="city"
                            name="city"
                            value={patientDetail.city}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="city">City</label>
                        </div>
                      </div>
                    </div>

                    <div className="row g-3">
                      <div className="col-md">
                        <div className="form-floating">
                          <select
                            name="state"
                            value={patientDetail.state}
                            onChange={handleInput}
                            className="form-select w-100 "
                            id="state"
                            disabled={disableFields}
                          >
                            <option value="" disabled>
                              {loading ? "Loading states..." : "Select State"}
                            </option>
                            {states.map((state) => (
                              <option key={state.id} value={state.name}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="state">State</label>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control w-100"
                            id="zip_code"
                            placeholder="zip_code"
                            name="zip_code"
                            value={patientDetail.zip_code}
                            onChange={handleInput}
                            disabled={disableFields}
                          />
                          <label htmlFor="zip_code">ZIP Code</label>
                        </div>
                      </div>
                      <div className="row g-3  ">
                        <div className="col-md">
                          <div className="form-floating">
                            <select
                              name="country"
                              value={patientDetail.country}
                              onChange={handleInput}
                              className="form-select w-100 "
                              id="country"
                              disabled={disableFields}
                            >
                              <option value="" selected>
                                {patientDetail.country}
                              </option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Canada">Canada</option>
                              <option value="USA">USA</option>
                            </select>
                            <label htmlFor="country">Country</label>
                          </div>
                        </div>
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control w-100"
                              id="email"
                              placeholder="email"
                              name="email"
                              value={patientDetail.email}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="email">Email</label>
                          </div>
                        </div>
                      </div>
                      <div className="row g-3 mb-5 ">
                        <div className="col-6">
                          <div className="form-floating">
                            <input
                              type="tel"
                              className="form-control w-100"
                              id="phone_no"
                              placeholder="(555) 555-5555"
                              name="phone_no"
                              value={patientDetail.phone_no}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="phone_no">Phone No:</label>
                          </div>
                        </div>
                      </div>
                      <h5>Next of KIN</h5>
                      <div className="row g-3 mb-5 ">
                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="other_contact_name"
                              placeholder="other_contact_name"
                              name="other_contact_name"
                              value={patientDetail.other_contact_name}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="other_contact_name">name</label>
                          </div>
                        </div>

                        <div className="col-md">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="other_contact_address"
                              placeholder="other_contact_address"
                              name="other_contact_address"
                              value={patientDetail.other_contact_address}
                              onChange={handleInput}
                              disabled={disableFields}
                            />
                            <label htmlFor="other_contact_address">
                              Address
                            </label>
                          </div>
                        </div>

                        <div className="row g-3 ">
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control w-100"
                                id="other_contact_country"
                                placeholder="other_contact_country"
                                name="other_contact_country"
                                value={patientDetail.other_contact_country}
                                onChange={handleInput}
                                disabled={disableFields}
                              />
                              <label htmlFor="other_contact_country">
                                Country
                              </label>
                            </div>
                          </div>
                          <div className="col-md">
                            <div className="form-floating">
                              <select
                                name="state"
                                value={patientDetail.other_contact_state}
                                onChange={handleInput}
                                className="form-select w-100 "
                                id="state"
                                disabled={disableFields}
                              >
                                <option value="" disabled>
                                  {loading
                                    ? "Loading states..."
                                    : "Select State"}
                                </option>
                                {states.map((state) => (
                                  <option key={state.id} value={state.name}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                              <label htmlFor="state">State</label>
                            </div>
                          </div>
                        </div>

                        <div className="row g-3 ">
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control w-100"
                                id="other_contact_city"
                                placeholder="other_contact_city"
                                name="other_contact_city"
                                value={patientDetail.other_contact_city}
                                onChange={handleInput}
                                disabled={disableFields}
                              />
                              <label htmlFor="other_contact_city"> City</label>
                            </div>
                          </div>

                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="tel"
                                className="form-control w-100"
                                id="other_contact_phone_no"
                                placeholder="(555) 555-5555"
                                name="other_contact_phone_no"
                                value={patientDetail.other_contact_phone_no}
                                onChange={handleInput}
                                disabled={disableFields}
                              />
                              <label htmlFor="other_contact_phone_no">
                                Phone No:
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row g-3  ">
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control w-100"
                                id="relationship"
                                placeholder="relationship"
                                name="relationship"
                                value={patientDetail.relationship}
                                onChange={handleInput}
                                disabled={disableFields}
                              />
                              <label htmlFor="relationship">
                                {" "}
                                Relationship
                              </label>
                            </div>
                          </div>

                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="tel"
                                className="form-control w-100"
                                id="other_contact_cell"
                                placeholder="(555) 555-5555"
                                name="other_contact_cell"
                                value={patientDetail.other_contact_cell}
                                onChange={handleInput}
                                disabled={disableFields}
                              />
                              <label htmlFor="other_contact_cell">Cell</label>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3  ">
                          <div className="col-md">
                            <div className="form-floating">
                              <input
                                type="email"
                                className="form-control w-100"
                                id="other_email"
                                placeholder="other_email"
                                name="other_email"
                                value={patientDetail.other_email}
                                onChange={handleInput}
                                disabled={disableFields}
                              />
                              <label htmlFor="other_email">Email</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center p-user-img-h">
                        <Form.Label htmlFor="uploadpic">
                          {patientDetail.profile_pic ? (
                            <img
                              src={patientDetail.profile_pic}
                              alt="Admin"
                              className="rounded-circle"
                              width={140}
                              height={140}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <img
                              src={pp}
                              alt="Admin"
                              className="rounded-circle"
                              width={140}
                              height={140}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </Form.Label>
                        {/* <input type="file" onChange={uploadImage} disabled={disableFields} /> */}
                        <Form.Control
                          type="file"
                          name="file"
                          style={{ display: "none" }}
                          id="uploadpic"
                          onChange={uploadImage}
                          disabled={disableFields}
                        />
                      </div>
                      <div className="w-100 text-center">
                        <p style={{ color: "black" }}>Upload Picture</p>
                      </div>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        {permissions.patient_info_edit_button && (
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handleSubmit}
                          >
                            {saveLoading ? "Processing..." : "Save"}
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{ backgroundColor: "black" }}
                          onClick={handleShow}
                        >
                          Blacklist
                        </button>
                      </div>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Body className="allergy-body">
                          Are you sure you want to blacklist this patient?
                        </Modal.Body>
                        <Modal.Footer className="allergy-footer-btn">
                          <Button variant="primary" onClick={handleBlacklist}>
                            Yes
                          </Button>
                          <Button variant="secondary" onClick={handleClose}>
                            No
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Demographics;
