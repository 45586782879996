import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./PatientProfile.css";
import Profile from "../../../assets/images/user.png";
import Summary from "./summary/Summary";
import Tabs from "./demographics/Tabs";
import Problems from "./problem/Problems";
import Medications from "./medication/Medications";
import Allergies from "./allergies/Allergies";

import Documents from "./documents/Documents";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Encounters from "./encounters/Encounters";
import axios from "axios";
import Vital from "./vital/Vitals";
import Incidents from "./incidents/Incidents";
import IncidentTabs from "./incidents/IncidentTabs";
import FaceCard from "../../../components/pdf/FaceCard";
import { Modal, Button } from "react-bootstrap";
import pp from "../../../assets/images/avatar.png";
import {
  VitalProvider,
  useVital,
} from "../../../components/context/VitalContext";
import {
  handleGetVital,
  handleGetAllergies,
  handleGetMedications,
  handleGetPatientDetails,
} from "../../../components/services/VitalService";
import { jsPDF } from "jspdf";
import moment from "moment";
import Logo from "../../../assets/images/logo-blue.png";
import Admission from "./admission/Admission";
import MedicalIncident from "./incidents/MedicalIncident";
import { useSelector } from "react-redux";
import Notes from "../notes/Notes";
// import Incidents from './incidents/Incidents';
function PatientProfile({ patient }) {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );
  // var patient = useLocation();
  var patientDetails = patient;
  console.log("doctor Details", patientDetails);
  const [activeTab, setActiveTab] = useState("summary");
  const [allergies, setAllergies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLeftNav, setShowLeftNav] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [vitals, setVitals] = useState([]);
  const [patientSideDetails, setPatientSideDetails] = useState([]);

  const { vital, fetchVitals } = useVital();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 992); // Bootstrap's lg breakpoint
  const [showModal, setShowModal] = useState(false);
  const [showFaceCard, setShowFaceCard] = useState(false);
  const [medications, setMedications] = useState([]);
  const [patientDetail, setPatientDetail] = useState({
    first_name: patientDetails.first_name,
    middle_name: patientDetails.middle_name,
    last_name: patientDetails.last_name,
    nick_name: patientDetails.nick_name,
    suffix: patientDetails.suffix,
    ssn: patientDetails.ssn,
    gender: patientDetails.gender,
    date_of_birth: patientDetails.date_of_birth,
    general_identity: patientDetails.general_identity,
    other: patientDetails.other,
    location: patientDetails.location,
    pharmacy: patientDetails.pharmacy,
    address_1: patientDetails.address_1,
    address_2: patientDetails.address_2,
    city: patientDetails.city,
    state: patientDetails.state,
    email: patientDetails.email,
    phone_no: patientDetails.phone_no,
    zip_code: patientDetails.zip_code,
    country: patientDetails.country,
    status: patientDetails.status,
  });

  const handleResize = () => {
    setIsWideScreen(window.innerWidth >= 992);
    if (window.innerWidth >= 992) {
      setShowLeftNav(true);
    } else {
      setShowLeftNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // getMedications()
    // getAllergies()
    // handleGetVital()
    handleGetVital(patientDetails.id)
      .then((data) => setVitals(data))
      .catch((error) => console.log(error));
    handleGetAllergies(patientDetails.id)
      .then((data) => setAllergies(data))
      .catch((error) => console.log(error));
    handleGetMedications(patientDetails.id)
      .then((data) => setMedications(data))
      .catch((error) => console.log(error));
    handleGetPatientDetails(patientDetails.id)
      .then((data) => setPatientSideDetails(data))
      .catch((error) => console.log(error));
    fetchVitals(patientDetails.id);
  }, [patientDetails.id]);
  const toggleLeftNav = () => {
    setShowLeftNav(!showLeftNav);
    setShowNav(!showNav);
  };

  const handleEncounterCreated = () => {
    handleGetVital(patientDetails.id)
      .then((data) => setVitals(data))
      .catch((error) => console.log(error));
  };

  const handleAllergiesCreated = () => {
    handleGetAllergies(patientDetails.id)
      .then((data) => setAllergies(data))
      .catch((error) => console.log(error));
  };

  const handleMedicationCreated = () => {
    handleGetMedications(patientDetails.id)
      .then((data) => setMedications(data))
      .catch((error) => console.log(error));
  };

  const handleGetSidePatient = () => {
    handleGetPatientDetails(patientDetails.id)
      .then((data) => setPatientSideDetails(data))
      .catch((error) => console.log(error));
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };
  const handlePrintClick = () => {
    // generatePDF(); // Generate the PDF when the print button is clicked
    handleCloseModal(); // Close the modal
  };
  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = () => {
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };
  const handleTabClick = (tabName) => {
    if (tabName === activeTab) {
      // Trigger re-render by updating the key for the active tab
      setActiveTab("");
      setTimeout(() => setActiveTab(tabName), 0);
    } else {
      setActiveTab(tabName);
    }
  };

  console.log("getAllergies", medications);
  console.log("vitals", vitals);
  console.log("PatientSide", patientSideDetails);
  return (
    <>
      <div
        className="tablet-menu-icon text-center fs-5 rounded-1"
        onClick={toggleLeftNav}
      >
        <i className="fas fa-bars"></i>
      </div>
      <div className="active d-flex fade h-100 m-1 overflow-hidden show ">
        {/* left section start */}

        <div className="d-flex flex-fill ">
          {isWideScreen || showLeftNav ? (
            <div className="main-left problem-container">
              <div className="left-nav">
                <div className="patient-data d-flex">
                  <div className="user-img" onClick={handleImageClick}>
                    <img
                      src={patientDetails.profile_pic || pp}
                      alt=""
                      width={60}
                      height={60}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      className="mt-2 fw-bold "
                      style={{
                        color: "red",
                        backgroundColor: "yellow",
                        width: "5rem",
                        paddingLeft: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      {patientSideDetails.medical_dependency &&
                        patientSideDetails.medical_dependency}
                    </p>
                    {/* <p>{patientDetails.admission_date_result.color && patientDetails.admission_date_result.color === 'green' ? <p style={{color:"green",fontWeight:"bolder",backgroundColor:"yellow",width:'5rem',paddingLeft:"5px"}}>{patientDetails.admission_date_result.remaining_days && patientDetails.admission_date_result.remaining_days}</p> : <p style={{color:"red",fontWeight:"bolder",backgroundColor:"yellow",width:'5rem',paddingLeft:"5px"}}>{patientDetails.admission_date_result.exceeded_days && patientDetails.admission_date_result.exceeded_days}</p> }</p> */}
                  </div>
                  <div className="user-detail ms-5" style={{ color: "white" }}>
                    <h5>
                      {patientSideDetails.patient_full_name &&
                        patientSideDetails.patient_full_name}
                    </h5>
                    <span>
                      <a style={{ color: "#00d700" }}>Active</a>
                    </span>

                    <p>
                      {patientSideDetails.gender && patientSideDetails.gender}
                    </p>
                    <p>
                      {" "}
                      {patientSideDetails.date_of_birth
                        ? moment(patientSideDetails.date_of_birth).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                      {patientSideDetails.age
                        ? ` , ${patientSideDetails.age} Y`
                        : ""}
                    </p>

                    <p>
                      {patientSideDetails.email && patientSideDetails.email}
                    </p>
                    <ul className="d-flex user-icon">
                      <li>
                        <a
                          style={{ fontSize: "24px" }}
                          className={` ${
                            activeTab === "facecard" ? "active" : ""
                          }`}
                          onClick={() => setActiveTab("facecard")}
                          id="facecard-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#facecard"
                          type="button"
                          role="tab"
                          aria-controls="facecard"
                          aria-selected={activeTab === "facecard"}
                        >
                          <i className="fas fa-id-card"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {/* Allergies */}
                        Current Vitals
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div style={{ display: "flex" }}>
                          {/* <h5>{vitals[0] && vitals[0].create_date}</h5> */}
                          <h5>
                            {" "}
                            {vitals[0]
                              ? moment(vitals[0].date)
                                  .utc()
                                  .format("MM/DD/YYYY hh:mm:ss A")
                              : ""}
                          </h5>
                        </div>
                        <ul style={{ marginTop: "5px" }}>
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">BP:</span>&nbsp;
                            <span>
                              {vitals[0] && vitals[0].blood_pressure}
                            </span>{" "}
                          </li>
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">Height (in):</span>&nbsp;
                            <span>{vitals[0] && vitals[0].height_in}</span>{" "}
                          </li>
                          <li
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">Weight (lb):</span>
                            <span>{vitals[0] && vitals[0].weight_lbs} </span>
                          </li>
                          <li
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">BMI Interp:</span>
                            <span>{vitals[0] && vitals[0].bmi_in}</span>{" "}
                          </li>
                          {/* <li style={{ marginTop: '10px' }}><span className="fw-bold">Systolic (mmHg):</span> {vitals[0] && vitals[0].systolic}</li> */}
                          {/* <li style={{ marginTop: '10px' }}><span className="fw-bold">Diastlic (mmHg):</span> {vitals[0] && vitals[0].diastolic}</li> */}
                          <li
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">Temp (F):</span>{" "}
                            <span>
                              {vitals[0] && vitals[0].body_temp_result_f} °F
                            </span>
                          </li>
                          <li
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">Pulse (beats/min):</span>{" "}
                            <span>{vitals[0] && vitals[0].pulse_beats_in}</span>
                          </li>
                          <li
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">
                              Resp Rate (breaths/min):
                            </span>
                            <span>{vitals[0] && vitals[0].resp_rate} </span>
                          </li>
                          {/* <li style={{ marginTop: '10px' }}><span className="fw-bold">Head (in):</span> {vitals[0] && vitals[0]. head_in} </li> */}
                          <li
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">Waist (in):</span>
                            <span>{vitals[0] && vitals[0].waist_in}</span>
                          </li>
                          <li
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="fw-bold">Glucose:</span>
                            <span>{vitals[0] && vitals[0].glucose}</span>{" "}
                          </li>
                        </ul>
                        {allergies.length > 0 ? (
                          <h4 className="fw-bold mt-2">Allergies</h4>
                        ) : (
                          ""
                        )}
                        {allergies &&
                          allergies.map((allergy) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  color: "red",
                                }}
                              >
                                <h6>{allergy.allergy}-</h6>
                                <span style={{ fontStyle: "italic" }}>
                                  {allergy.allergy_type.title}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        Medication
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {medications.active_medications &&
                          medications.active_medications.map((medication) => {
                            return (
                              <div key={medication.id}>
                                {console.log("medicationsssss", medication)}
                                <p>{`${
                                  medication.formatted_data
                                    ? medication.formatted_data
                                    : ""
                                } `}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <Modal
            show={showImageModal}
            onHide={handleCloseImageModal}
            size="lg"
            centered
          >
            <Modal.Body className="text-center">
              <img
                src={patientDetails.profile_pic || pp}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </Modal.Body>
          </Modal>
        </div>

        <div className="d-flex flex-column flex-fill  right-sec">
          <div className="right-linkstabs d-flex align-items-center">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {permissions.summary_tab && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "summary" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("summary")}
                    id="summary-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#summary"
                    type="button"
                    role="tab"
                    aria-controls="summary"
                    aria-selected={activeTab === "summary"}
                  >
                    Summary
                  </button>
                </li>
              )}
              {permissions.demographics_tab && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "Demographics" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("Demographics")}
                    id="Demographics-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Demographics"
                    type="button"
                    role="tab"
                    aria-controls="Demographics"
                    aria-selected={activeTab === "Demographics"}
                  >
                    Demographics
                  </button>
                </li>
              )}
              {permissions.admission_tab && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "admission" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("admission")}
                    id="admission-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#admission"
                    type="button"
                    role="tab"
                    aria-controls="admission"
                    aria-selected={activeTab === "admission"}
                  >
                    Admission
                  </button>
                </li>
              )}
              {permissions.problems_tab && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "Problems" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("Problems")}
                    id="Problems-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Problems"
                    type="button"
                    role="tab"
                    aria-controls="Problems"
                    aria-selected={activeTab === "Problems"}
                  >
                    Problems
                  </button>
                </li>
              )}
              {permissions.allergies && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "Allergies" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("Allergies")}
                    id="Allergies-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Allergies"
                    type="button"
                    role="tab"
                    aria-controls="Allergies"
                    aria-selected={activeTab === "Allergies"}
                  >
                    Allergies
                  </button>
                </li>
              )}
              {permissions.medication && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "Medications" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("Medications")}
                    id="Medications-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Medications"
                    type="button"
                    role="tab"
                    aria-controls="Medications"
                    aria-selected={activeTab === "Medications"}
                  >
                    Medications
                  </button>
                </li>
              )}
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "Encounters" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Encounters")}
                  id="Encounters-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Encounters"
                  type="button"
                  role="tab"
                  aria-controls="Encounters"
                  aria-selected={activeTab === "Encounters"}
                >
                  Encounters
                </button>
              </li>
              {permissions.documents_tab && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "Documents" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("Documents")}
                    id="Documents-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Documents"
                    type="button"
                    role="tab"
                    aria-controls="Documents"
                    aria-selected={activeTab === "Documents"}
                  >
                    Documents
                  </button>
                </li>
              )}
              {permissions.vitals_tab && (
                <li>
                  <button
                    className={`nav-link ${
                      activeTab === "Vital" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("Vital")}
                    id="Vital-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Vital"
                    type="button"
                    role="tab"
                    aria-controls="Vital"
                    aria-selected={activeTab === "Vital"}
                  >
                    Vitals
                  </button>
                </li>
              )}

              {permissions.medical_incident_tab && (
                <li>
                  <button
                    className={`nav-link ${
                      activeTab === "MedicalIncidents" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("MedicalIncidents")}
                    id="MedicalIncidents-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#MedicalIncidents"
                    type="button"
                    role="tab"
                    aria-controls="MedicalIncidents"
                    aria-selected={activeTab === "MedicalIncidents"}
                  >
                    Medical Incident
                  </button>
                </li>
              )}
              {permissions.warning_letter_tab && (
                <li>
                  <button
                    className={`nav-link ${
                      activeTab === "WarningLetter" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("WarningLetter")}
                    id="WarningLetter-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#WarningLetter"
                    type="button"
                    role="tab"
                    aria-controls="WarningLetter"
                    aria-selected={activeTab === "WarningLetter"}
                  >
                    Warning Letter
                  </button>
                </li>
              )}

              <li>
                <button
                  className={`nav-link ${
                    activeTab === "Notes" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Notes")}
                  id="Notes-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Notes"
                  type="button"
                  role="tab"
                  aria-controls="Notes"
                  aria-selected={activeTab === "Notes"}
                >
                  Notes
                </button>
              </li>
            </ul>
          </div>

          {activeTab === "summary" && <Summary id={patientDetails.id} />}
          {activeTab === "Demographics" && (
            <Tabs
              patientDetails={patientDetails}
              handleGetSidePatient={handleGetSidePatient}
            />
          )}
          {activeTab === "Problems" && <Problems id={patientDetails.id} />}
          {activeTab === "Allergies" && (
            <Allergies
              id={patientDetails.id}
              handleAllergiesCreated={handleAllergiesCreated}
            />
          )}
          {activeTab === "Medications" && (
            <Medications
              id={patientDetails.id}
              handleMedicationCreated={handleMedicationCreated}
            />
          )}
          {activeTab === "Encounters" && (
            <Encounters
              id={patientDetails.id}
              onEncounterCreatedFetch={handleEncounterCreated}
            />
          )}
          {activeTab === "Notes" && (
            <Notes id={patientDetails.id} details={patientDetails} />
          )}
          {activeTab === "Documents" && <Documents id={patientDetails.id} />}
          {activeTab === "Vital" && <Vital id={patientDetails.id} />}
          {activeTab === "MedicalIncidents" && (
            <MedicalIncident details={patientDetails} />
          )}
          {activeTab === "incidents" && (
            <IncidentTabs details={patientDetails} />
          )}
          {activeTab === "WarningLetter" && (
            <Incidents details={patientDetails} />
          )}
          {activeTab === "facecard" && <FaceCard details={patientDetails} />}
          {activeTab === "admission" && <Admission id={patientDetails.id} />}
        </div>
      </div>
      {showModal && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header className="allery-header">
            <Modal.Title>Download PDF</Modal.Title>
          </Modal.Header>
          <Modal.Body className="allergy-body">
            <p>Click the button below to download the PDF:</p>
          </Modal.Body>
          <Modal.Footer className="allergy-footer-btn">
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>

            <Button variant="primary" onClick={handlePrintClick}>
              Print
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Conditionally render Summary component based on active tab */}
    </>
  );
}

export default PatientProfile;
