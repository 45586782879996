import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const VitalContext = createContext();

export const useVital = () => useContext(VitalContext);

export const VitalProvider = ({ children, patientId }) => {
  const [vitals, setVitals] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchVitals = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.get(`https://soulhousing-api.anchorstech.net/api/vitals/${patientId}`, config);
      setVitals(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVitals();
  }, [patientId]);
  console.log("fetchvital",vitals)

  return (
    <VitalContext.Provider value={{ vitals, loading, fetchVitals }}>
      {children}
    </VitalContext.Provider>
  );
};
