import React, { useState } from "react";
import "./ManageMap.css";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Preview from "./Preview";
// import ReactTooltip from "react-tooltip";

const initialValue = {
  floor_title: "",
  room_title: "",
  beds: [{ patient_id: 4 }],
};

const FacilityManagement = () => {
  const [floors, setFloors] = useState([
    {
      floor_title: "",
      rooms: [
        {
          room_title: "",
          beds: [{ bed_title: "", comments: "" }],
        },
      ],
    },
  ]);

  const [showFloorModal, setShowFloorModal] = useState(false);
  const [showRoomModal, setShowRoomModal] = useState(false);
  const [showBedModal, setShowBedModal] = useState(false);
  const [modalInputValue, setModalInputValue] = useState("");
  const [currentFloorIndex, setCurrentFloorIndex] = useState(null);
  const [currentRoomIndex, setCurrentRoomIndex] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleFloorModalClose = () => setShowFloorModal(false);
  const handleRoomModalClose = () => setShowRoomModal(false);
  const handleBedModalClose = () => setShowBedModal(false);

  const handleFloorModalShow = () => setShowFloorModal(true);
  const handleRoomModalShow = (floorIndex) => {
    setCurrentFloorIndex(floorIndex);
    setShowRoomModal(true);
  };
  const handleBedModalShow = (floorIndex, roomIndex) => {
    setCurrentFloorIndex(floorIndex);
    setCurrentRoomIndex(roomIndex);
    setShowBedModal(true);
  };

  const addFloor = () => {
    const newFloor = {
      floor_title: modalInputValue,
      rooms: [
        {
          room_title: "",
          beds: [{ bed_title: "", comments: "" }],
        },
      ],
    };
    setFloors([...floors, newFloor]);
    handleFloorModalClose();
    setModalInputValue("");
  };

  const addRoom = () => {
    const newFloors = [...floors];
    newFloors[currentFloorIndex].rooms.push({
      room_title: modalInputValue,
      beds: [{ bed_title: "", comments: "" }],
    });
    setFloors(newFloors);
    handleRoomModalClose();
    setModalInputValue("");
  };

  const addBed = () => {
    const newFloors = [...floors];
    newFloors[currentFloorIndex].rooms[currentRoomIndex].beds.push({
      bed_title: modalInputValue,
      comments: "",
    });
    setFloors(newFloors);
    handleBedModalClose();
    setModalInputValue("");
  };

  const handleBedChange = (e, floorIndex, roomIndex, bedIndex) => {
    const newFloors = [...floors];
    newFloors[floorIndex].rooms[roomIndex].beds[bedIndex].comments =
      e.target.value;
    setFloors(newFloors);
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValue,
      onSubmit: async (values, action) => {
        try {
          setProcessing(true);
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };

          // Sending floor and room numbers as floor_title and room_title
          const floorData = floors.map((floor, floorIndex) => {
            return {
              floor_title: floor.floor_title || `Floor ${floorIndex + 1}`,
              rooms: floor.rooms.map((room, roomIndex) => {
                return {
                  room_title: room.room_title || `Room ${roomIndex + 1}`,
                  beds: room.beds.map((bed) => ({
                    patient_id: 3,
                    bed_title: bed.bed_title,
                    comments: bed.comments,
                  })),
                };
              }),
            };
          });

          const res = await axios.post(
            process.env.REACT_APP_ORIGIN_URL + "/add-floor-rooms",
            floorData,
            config
          );
          toast.success(res.data.message);
          action.resetForm();
        } catch (error) {
          toast.error(error.message);
          console.log("error", error.message);
        } finally {
          setProcessing(false);
        }
      },
    });

  const removeFloor = (floorIndex) => {
    const newFloors = floors.filter((_, index) => index !== floorIndex);
    setFloors(newFloors);
  };

  const removeRoom = (floorIndex, roomIndex) => {
    const newFloors = [...floors];
    newFloors[floorIndex].rooms = newFloors[floorIndex].rooms.filter(
      (_, index) => index !== roomIndex
    );
    setFloors(newFloors);
  };

  const removeBed = (floorIndex, roomIndex, bedIndex) => {
    const newFloors = [...floors];
    newFloors[floorIndex].rooms[roomIndex].beds = newFloors[floorIndex].rooms[
      roomIndex
    ].beds.filter((_, index) => index !== bedIndex);
    setFloors(newFloors);
  };

  const handleModalFormSubmit = (e, action) => {
    e.preventDefault();
    action();
  };
  return (
    <div className="settings-right flex-fill">
      <div className="tab-content h-100" id="v-pills-tabContent">
        <div
          className="active fade h-100 overflow-auto show tab-pane"
          id="v-pills-myprofile"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab"
        >
          <div className="myprofile-bg">
            <h5 className="mb-3">Facility Management</h5>
            <div className="mt-3">
              <ul
                className="nav nav-tabs"
                id="myTab"
                role="tablist"
                style={{ borderBottom: "none" }}
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active mb-md-2"
                    id="preview-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#preview"
                    type="button"
                    role="tab"
                    aria-controls="preview"
                    aria-selected="false"
                  >
                    Preview
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="manage-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#manage"
                    type="button"
                    role="tab"
                    aria-controls="manage"
                    aria-selected="true"
                  >
                    Manage
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade"
                  id="manage"
                  role="tabpanel"
                  aria-labelledby="manage-tab"
                >
                  <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={handleFloorModalShow}
                  >
                    Add Floor
                  </button>
                  {floors &&
                    floors.map((floor, floorIndex) => (
                      <div key={floorIndex}>
                        <div className="mt-3">
                          <div className="map-bg d-flex align-items-center">
                            <h5>
                              Floor:{" "}
                              {floor.floor_title ||
                                `Floor No # ${floorIndex + 1}`}
                            </h5>
                            <div className="d-flex align-items-center ms-auto">
                              <button
                                type="button"
                                className="add_room btn btn-danger"
                                onClick={() => handleRoomModalShow(floorIndex)}
                              >
                                Add Room
                              </button>
                              <button
                                type="button"
                                className="btn ms-auto text-center visit-btn-wound"
                                onClick={() => removeFloor(floorIndex)}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    borderRadius: "5px",
                                    fontSize: "14px",
                                    padding: "1px 3px 1px 3px",
                                    backgroundColor: "#ba2748",
                                    color: "#fff",
                                  }}
                                  icon={faTimes}
                                  className="fa-visit-icon "
                                />
                              </button>
                            </div>
                          </div>
                          {floor.rooms.map((room, roomIndex) => (
                            <div
                              key={roomIndex}
                              className="mt-2 room-bg-content"
                            >
                              <div className="d-flex">
                                <h6>
                                  Room:{" "}
                                  {room.room_title ||
                                    `Room No # ${roomIndex + 1}`}
                                </h6>
                                <button
                                  type="button"
                                  className="btn ms-auto text-center visit-btn-wound"
                                  onClick={() =>
                                    removeRoom(floorIndex, roomIndex)
                                  }
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      borderRadius: "5px",
                                      fontSize: "14px",
                                      padding: "1px 3px 1px 3px",
                                      backgroundColor: "#ba2748",
                                      color: "#fff",
                                    }}
                                    icon={faTimes}
                                    className="fa-visit-icon "
                                  />
                                </button>
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  handleBedModalShow(floorIndex, roomIndex)
                                }
                              >
                                Add Bed
                              </button>

                              <div className="row">
                                {room.beds.map((bed, bedIndex) => (
                                  <div key={bedIndex} className="col-md-4">
                                    <div className="form-floating mt-2 d-flex">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id={`bed-${floorIndex}-${roomIndex}-${bedIndex}`}
                                        value={bed.comments}
                                        onChange={(e) =>
                                          handleBedChange(
                                            e,
                                            floorIndex,
                                            roomIndex,
                                            bedIndex
                                          )
                                        }
                                        disabled={disable}
                                      />
                                      <label
                                        htmlFor={`bed-${floorIndex}-${roomIndex}-${bedIndex}`}
                                      >
                                        {bed.bed_title || `Bed ${bedIndex + 1}`}
                                      </label>
                                      <button
                                        type="button"
                                        className="btn ms-auto text-center visit-btn-wound"
                                        onClick={() =>
                                          removeBed(
                                            floorIndex,
                                            roomIndex,
                                            bedIndex
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            padding: "1px 3px 1px 3px",
                                            backgroundColor: "#ba2748",
                                            color: "#fff",
                                          }}
                                          icon={faTimes}
                                          className="fa-visit-icon "
                                        />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  <button
                    type="button"
                    className="btn btn-primary mt-3"
                    onClick={handleSubmit}
                  >
                    {processing ? "Processing..." : "Submit"}
                  </button>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="preview"
                  role="tabpanel"
                  aria-labelledby="preview-tab"
                >
                  <Preview />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

      {/* Modals */}
      <Modal show={showFloorModal} onHide={handleFloorModalClose}>
        <Modal.Header className="allery-header">
          <Modal.Title>Add Floor</Modal.Title>
        </Modal.Header>
        <Modal.Body className="allergy-body">
          <Form>
            <Form.Group controlId="floorName">
              <Form.Label>Floor Name</Form.Label>
              <Form.Control
                type="text"
                value={modalInputValue}
                onChange={(e) => setModalInputValue(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFloorModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={(e) => handleModalFormSubmit(e, addFloor)}
          >
            Add Floor
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRoomModal} onHide={handleRoomModalClose}>
        <Modal.Header className="allery-header">
          <Modal.Title>Add Room</Modal.Title>
        </Modal.Header>
        <Modal.Body className="allergy-body">
          <Form>
            <Form.Group controlId="roomName">
              <Form.Label>Room Name</Form.Label>
              <Form.Control
                type="text"
                value={modalInputValue}
                onChange={(e) => setModalInputValue(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRoomModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="button "
            onClick={(e) => handleModalFormSubmit(e, addRoom)}
          >
            Add Room
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBedModal} onHide={handleBedModalClose}>
        <Modal.Header className="allery-header">
          <Modal.Title>Add Bed</Modal.Title>
        </Modal.Header>
        <Modal.Body className="allergy-body">
          <Form>
            <Form.Group controlId="bedName">
              <Form.Label>Bed Name</Form.Label>
              <Form.Control
                type="text"
                value={modalInputValue}
                onChange={(e) => setModalInputValue(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleBedModalClose}>
            Close
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={(e) => handleModalFormSubmit(e, addBed)}
          >
            Add Bed
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FacilityManagement;
