import React, { useEffect, useState, useRef } from "react";
import "../PatientList.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Card, Modal, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "react-bootstrap/Alert";
import moment from "moment";
import { faTimes, faEdit, faEject } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@mui/material";
import rootShouldForwardProp from "@mui/material/styles/rootShouldForwardProp";

const BedMapping = () => {
  const [floors, setFloors] = useState([]);
  const [roomsAndBeds, setRoomsAndBeds] = useState([]);
  const [activeFloor, setActiveFloor] = useState(null);
  const [loadingBeds, setLoadingBeds] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vacantLoading, setVacantLoading] = useState(false);
  const [showVacantBeds, setShowVacantBeds] = useState(false);
  const [showUnpreparedBeds, setShowUnpreparedBeds] = useState(false);
  const [searchInputs, setSearchInputs] = useState({});
  const [searchResults, setSearchResults] = useState({});
  const [vacantSearchResults, setVacantSearchResults] = useState({});
  const [totalBeds, setTotalBeds] = useState("");
  const [totalOccupiedBeds, setTotalOccupiedBeds] = useState("");
  const [totalVacantBeds, setTotalVacantBeds] = useState("");
  const [activeInputCard, setActiveInputCard] = useState(null);
  const [signed, setSigned] = useState("");
  const [show, setShow] = useState(true);
  const [VacantShow, setVacantShow] = useState(false);
  const [assignedId, setAssignedId] = useState(null);
  const [lastClickTime, setLastClickTime] = useState(0);
  const [vacantBeds, setVacantBeds] = useState([]);
  const [vacantInputs, setVacantInputs] = useState({});

  const [vacantAssignId, setVacantAssignId] = useState(null);
  const [floorId, setFloorsID] = useState(null);
  const [ejectModal, setEjectModal] = useState(false);
  const [checkboxModal, setCheckboxModal] = useState(false);
  const [bedId, setBedId] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [checkboxBedId, setCheckboxBedId] = useState(null);
  const [checkboxPatientId, setCheckboxPatientId] = useState(null);
  const [floorTotalBeds, setFloorTotalBeds] = useState("");
  const [floorTotalRooms, setFloorTotalRooms] = useState("");
  const [mainFloorName, setMainFloorName] = useState("");
  const [floorVacantBeds, setFloorVacantBeds] = useState("");
  const [floorOccupiedBeds, setFloorOccupiedBeds] = useState("");
  const [floorPendingBeds, setFloorPendingBeds] = useState("");
  const [floorUnpreparedBeds, setFloorUnpreparedBeds] = useState("");
  const [totalFloors, setTotalFloors] = useState(" ");
  const [totalRooms, setTotalRooms] = useState("");
  const [totalUnpreparedBeds, setTotalUnpreparedBeds] = useState("");
  const [totalPendingBeds, setTotalPendingBeds] = useState("");
  const [facilityName, setFacilityName] = useState("");
  const [checkBedStatus, setCheckBedStatus] = useState("");
  const [reOccupied, setReoccupied] = useState(false);
  const [reCheckBox, setReCHeckBox] = useState("");
  const [rePatient, setRePatient] = useState("");
  const [unpreparedBeds, setUnpreparedBeds] = useState([]);

  const inputRef = useRef(null);
  const vacantRef = useRef(null);

  const handleClose = () => {
    setEjectModal(false);
  };

  const handleReClose = () => {
    setReoccupied(false);
  };
  const getFloors = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/floors",
        config
      );
      console.log("response", response);
      setFloors(response.data.data);
      setMainFloorName(response.data.data[0].floor_name);
      setActiveFloor(response.data.data[0]);
      getRoomsAndBeds(response.data.data[0].id);

      const initialFloor = response.data.data[0];
      setTotalBeds(response.data.totals.total_beds_count);
      setTotalFloors(response.data.totals.total_floors_count);
      setTotalOccupiedBeds(response.data.totals.occupied_beds_count);
      setTotalVacantBeds(response.data.totals.vacant_beds_count);
      setTotalRooms(response.data.totals.total_rooms_count);
      setFacilityName(response.data.totals.facility_name);
      setTotalPendingBeds(response.data.totals.pending_beds_count);
      setTotalUnpreparedBeds(response.data.totals.unprepared_beds_count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getTotalSummary = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/floors",
        config
      );

      const initialFloor = response.data.data[0];
      setTotalBeds(initialFloor.total_beds_count);
      setTotalOccupiedBeds(initialFloor.occupied_beds_count);
      setTotalVacantBeds(initialFloor.vacant_beds_count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getRoomsAndBeds = async (id) => {
    try {
      setLoadingBeds(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/map-rooms-beds/${id}`,
        config
      );
      console.log("totalFloorBeds", response);
      setRoomsAndBeds(response.data.data.floor);
      setFloorTotalBeds(response.data.data.floor.total_beds_current_floor);
      setFloorTotalRooms(response.data.data.floor.total_rooms_current_floor);
      setFloorVacantBeds(
        response.data.data.floor.total_vacant_beds_current_floor
      );
      setFloorOccupiedBeds(
        response.data.data.floor.total_occupied_beds_current_floor
      );
      setFloorPendingBeds(
        response.data.data.floor.total_pending_beds_current_floor
      );
      setFloorUnpreparedBeds(
        response.data.data.floor.total_unprepared_beds_current_floor
      );
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoadingBeds(false);
    }
  };

  const getSearchResult = async (bedId) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/search-patient/${searchInputs[bedId]}`,
        config
      );
      setSearchResults({
        ...searchResults,
        [bedId]: response.data.data,
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const getVacantSearchResult = async (bedId) => {
    try {
      setVacantLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL +
          `/search-patient/${vacantInputs[bedId]}`,
        config
      );
      setVacantSearchResults({
        ...vacantSearchResults,
        [bedId]: response.data.data,
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      setVacantLoading(false);
    }
  };

  const handleAssignBed = async (patientId, bedId) => {
    try {
      const currentDateAndTime = new Date();
      const futureDate = new Date(
        currentDateAndTime.getTime() + 90 * 24 * 60 * 60 * 1000
      );
      const formattedCurrentDateTime = currentDateAndTime.toISOString();
      const formattedFutureDate = futureDate.toISOString();
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/assign-bed`,
        {
          patient_id: patientId,
          bed_id: bedId,
          occupied_at: formattedCurrentDateTime,
          booked_till: formattedFutureDate,
        },
        config
      );
      console.log("post", response);

      setSigned(response.data);
      setAssignedId(patientId);
      getRoomsAndBeds(activeFloor.id);
      getTotalSummary();
      if (response.data.status === "1") {
        setShow(true);
      }
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  const handleAssignVacantBed = async (patientId, bedId) => {
    try {
      const currentDateAndTime = new Date();
      const futureDate = new Date(
        currentDateAndTime.getTime() + 90 * 24 * 60 * 60 * 1000
      );
      const formattedCurrentDateTime = currentDateAndTime.toISOString();
      const formattedFutureDate = futureDate.toISOString();
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/assign-bed`,
        {
          patient_id: patientId,
          bed_id: bedId,
          occupied_at: formattedCurrentDateTime,
          booked_till: formattedFutureDate,
        },
        config
      );
      console.log("post", response);

      setSigned(response.data);
      setVacantInputs({});
      setVacantAssignId(patientId);
      getRoomsAndBeds(activeFloor.id);
      getTotalSummary();
      if (response.data.status === "1") {
        setVacantShow(true);
      }
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  const handleVacantBeds = async (floorId) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-vacant-beds/${1}`,
        config
      );
      setVacantBeds(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUnpreparedBeds = async (floorId) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-unprepared-beds/${2}`,
        config
      );
      setUnpreparedBeds(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleTransferBed = async (bedId, patientId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/update-patient-bed",
        {
          bed_id: bedId,
          patient_id: assignedId,
        },
        config
      );
      toast.success(response.data.message);
      getRoomsAndBeds(activeFloor.id);
      handleVacantBeds();
      setShow(false);
      setSearchInputs({});
      getTotalSummary();
      console.log("siggnedId", assignedId);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };
  const handleTransferVacantBed = async (bedId, patientId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/update-patient-bed",
        {
          bed_id: bedId,
          patient_id: assignedId,
        },
        config
      );
      toast.success(response.data.message);
      getRoomsAndBeds(activeFloor.id);
      handleVacantBeds();
      setVacantShow(false);
      setVacantInputs({});
      getTotalSummary();
      console.log("siggnedId", assignedId);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleCheckboxClick = (bedId, patientId) => {
    setCheckboxModal(true);
  };

  const handleReCheckboxClick = (bedId, patientId) => {
    setReoccupied(true);
  };

  const handleCloseCheckboxModal = () => {
    setCheckboxModal(false);
  };

  // console.log("vancantBeds",vacantBeds)
  useEffect(() => {
    getFloors();
  }, []);

  useEffect(() => {
    Object.keys(searchInputs).forEach((bedId) => {
      getSearchResult(bedId);
    });
  }, [searchInputs]);

  const handleFloorClick = (floor) => {
    setActiveFloor(floor);
    getRoomsAndBeds(floor.id);
  };
  const handleVacantInputChange = (e, bedId) => {
    const { value } = e.target;
    setVacantInputs((prevInputs) => ({
      ...prevInputs,
      [bedId]: value,
    }));
    console.log("input", value);
  };
  useEffect(() => {
    // Trigger search results API call when vacantInputs changes
    if (Object.keys(vacantInputs).length > 0) {
      const bedId =
        Object.keys(vacantInputs)[Object.keys(vacantInputs).length - 1];
      getVacantSearchResult(bedId);
    }
  }, [vacantInputs]);

  const handleInputChange = (e, bedId) => {
    setSearchInputs({
      ...searchInputs,
      [bedId]: e.target.value,
    });
    setShow(false);
    setActiveInputCard(bedId);
  };

  const handleVancantInputChange = (e, bedId) => {
    setSearchInputs({
      ...searchInputs,
      [bedId]: e.target.value,
    });
    setShow(false);
    setActiveInputCard(bedId);
  };
  const handleClickOutside = (event) => {
    const now = new Date().getTime();
    // Check if it's a double click
    if (now - lastClickTime < 300) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Double click occurred outside the input, reset input value
        setSearchInputs({
          ...searchInputs,
          [activeInputCard]: "",
        });
      }
    }
    // Update last click time
    setLastClickTime(now);
  };

  const handleDischargeBed = async (patientId, bedId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/discharge-patient-bed`,
        {
          patient_id: patientId,
          bed_id: bedId,
        },
        config
      );
      console.log("post", response);

      getRoomsAndBeds(activeFloor.id);
      getTotalSummary();
      setEjectModal(false);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  const handleReassingBed = async (patientId, bedId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/reassign-patient-bed`,
        {
          patient_id: patientId,
          bed_id: bedId,
        },
        config
      );
      console.log("post", response);

      getRoomsAndBeds(activeFloor.id);
      getTotalSummary();
      setEjectModal(false);
      setReoccupied(false);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  const handleHospitalizedBed = async (patientId, bedId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/hospitalized-patient-bed`,
        {
          patient_id: patientId,
          bed_id: bedId,
        },
        config
      );
      console.log("post", response);

      getRoomsAndBeds(activeFloor.id);
      getTotalSummary();
      setEjectModal(false);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };
  const handleCheckboxBed = async (bedId, patientId) => {
    try {
      console.log("patientId", patientId);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/unprepared-to-vacant`,
        {
          patient_id: checkboxPatientId,
          bed_id: checkboxBedId,
        },
        config
      );
      console.log("post", response);

      getRoomsAndBeds(activeFloor.id);
      getTotalSummary();
      setCheckboxModal(false);
      handleUnpreparedBeds();
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    // Attach event listener when component mounts
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Detach event listener when component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, [activeInputCard, lastClickTime]); // Re-run effect when activeInputCard or lastClickTime changes

  return (
    <div className="mapping-scroll">
      {!showVacantBeds && (
        <div className="preview-links mb-2">
          <ul
            className="nav nav-tabs  "
            id="myTab"
            role="tablist"
            style={{ borderBottom: "none" }}
          >
            {loading ? (
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ display: "block", padding: "10px" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              floors &&
              floors.map((floor, index) => (
                <li
                  className="nav-item"
                  key={index}
                  role="presentation"
                  style={{ marginRight: "10px" }}
                >
                  {console.log("totalFloors", floor)}
                  <button
                    className={`btn ${
                      activeFloor === floor
                        ? "btn-success"
                        : "btn-outline-success"
                    }`}
                    onClick={() => {
                      handleFloorClick(floor);
                      getRoomsAndBeds(floor.id);
                      setTotalBeds(floor.total_beds_count);
                      setTotalOccupiedBeds(floor.occupied_beds_count);
                      setTotalVacantBeds(floor.vacant_beds_count);
                      setMainFloorName(floor.floor_name);
                      // setFloorsID(floor.id)
                    }}
                    type="button"
                    role="tab"
                    aria-controls={`floor${index + 1}-tab-pane`}
                    aria-selected={activeFloor === floor ? "true" : "false"}
                    style={{ padding: "6px", width: "100px" }}
                  >
                    {floor.floor_name}
                  </button>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
      <div className="tab-content" id="myTabContent">
        <div>
          <Accordion>
            <Accordion.Item>
              <Accordion.Header>Summary</Accordion.Header>
              <Accordion.Body>
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                ></div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <div>
                      <h4>
                        Floors in {facilityName}: <span> {totalFloors}</span>
                      </h4>
                    </div>

                    <div>
                      <h4>
                        Rooms in {facilityName}: <span> {totalRooms}</span>
                      </h4>
                    </div>

                    <div>
                      <h4>
                        Beds in {facilityName}: <span>{totalBeds}</span>
                      </h4>
                    </div>
                    <div style={{ marginTop: "0.3rem" }}>
                      <h4>
                        {" "}
                        Vacant Beds in {facilityName}:{" "}
                        <span style={{ color: "#72c05d" }}>
                          {" "}
                          {totalVacantBeds}
                        </span>
                      </h4>
                    </div>
                    <div style={{ marginTop: "0.3rem" }}>
                      <h4>
                        Occupied Beds in {facilityName}:
                        <span style={{ color: "#df5959" }}>
                          {" "}
                          {totalOccupiedBeds}
                        </span>{" "}
                      </h4>
                    </div>
                    <div>
                      <h4>
                        Pending Beds in {facilityName}:{" "}
                        <span style={{ color: "#c0ac4a" }}>
                          {" "}
                          {totalPendingBeds}
                        </span>
                      </h4>
                    </div>
                    <div>
                      <h4>
                        Unprepared Beds in {facilityName}:{" "}
                        <span style={{ color: "#fc893d" }}>
                          {" "}
                          {totalUnpreparedBeds}
                        </span>
                      </h4>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4>
                        Rooms In {mainFloorName}:{" "}
                        <span> {floorTotalRooms} </span>
                      </h4>
                    </div>

                    <div>
                      <h4>
                        Beds In {mainFloorName}: <span> {floorTotalBeds} </span>
                      </h4>
                    </div>

                    <div>
                      <h4>
                        Vacant Beds In {mainFloorName}:{" "}
                        <span style={{ color: "#72c05d" }}>
                          {" "}
                          {floorVacantBeds}{" "}
                        </span>
                      </h4>
                    </div>

                    <div>
                      <h4>
                        Occupied Beds In {mainFloorName}:{" "}
                        <span style={{ color: "#df5959" }}>
                          {" "}
                          {floorOccupiedBeds}{" "}
                        </span>
                      </h4>
                    </div>

                    <div>
                      <h4>
                        Pending Beds In {mainFloorName}:{" "}
                        <span style={{ color: "#c0ac4a" }}>
                          {" "}
                          {floorPendingBeds}{" "}
                        </span>
                      </h4>
                    </div>

                    <div>
                      <h4>
                        Unprepared Beds In {mainFloorName}:{" "}
                        <span style={{ color: "#fc893d" }}>
                          {" "}
                          {floorUnpreparedBeds}{" "}
                        </span>
                      </h4>
                    </div>
                  </div>

                  <div>
                    {" "}
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="vacantSwitch"
                        checked={showVacantBeds}
                        onChange={() => {
                          setShowVacantBeds(!showVacantBeds);
                          handleVacantBeds();
                        }}
                      />
                      <label
                        style={{ fontSize: "14px" }}
                        className="form-check-label  ms-2"
                        htmlFor="vacantSwitch"
                      >
                        Vacant
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="unpreparedSwitch"
                        checked={showUnpreparedBeds}
                        onChange={() => {
                          setShowUnpreparedBeds(!showUnpreparedBeds);
                          handleUnpreparedBeds();
                        }}
                      />
                      <label
                        style={{ fontSize: "14px" }}
                        className="form-check-label  ms-2"
                        htmlFor="unpreparedSwitch"
                      >
                        Unprepared
                      </label>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="floor-1-bg">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              }}
            >
              {console.log("showUnprepared", showVacantBeds)}
              {showVacantBeds
                ? vacantBeds &&
                  vacantBeds.map((vacant, bedIndex) => (
                    <div key={bedIndex}>
                      <Accordion>
                        {console.log("vanctssssssss", vacant)}
                        <Card className="card">
                          <Accordion.Item>
                            <Accordion.Header>
                              {vacant.floor_name} {vacant.room_name}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Card.Body>
                                <Card>
                                  <Card.Header
                                    style={{
                                      backgroundColor: "#72c05d",
                                      color: "#fff",
                                    }}
                                  >
                                    Bed {vacant.bed_title}
                                  </Card.Header>
                                  <Card.Body>
                                    <div className="input-group input-search rounded w-100 ">
                                      <input
                                        type="search"
                                        className="form-control rounded"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="search-addon"
                                        ref={vacantRef}
                                        value={vacantInputs[vacant.id] || ""}
                                        onChange={(e) =>
                                          handleVacantInputChange(e, vacant.id)
                                        }
                                      />
                                      {vacantInputs[vacant.id] ? null : (
                                        <i className="fas fa-search search-icon"></i>
                                      )}
                                    </div>
                                    {/* {console.log("vanct",vacant.beds.id)} */}
                                    {loading &&
                                    activeInputCard === vacant.id ? (
                                      <div
                                        className="spinner-border text-primary"
                                        role="status"
                                        style={{
                                          display: "block",
                                          padding: "5px",
                                        }}
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      !vacantLoading &&
                                      vacantInputs[vacant.id] &&
                                      vacantSearchResults[vacant.id] &&
                                      vacantSearchResults[vacant.id].map(
                                        (result, index) => {
                                          return (
                                            <>
                                              {console.log(
                                                "vacantResult",
                                                result
                                              )}
                                              <Modal show={VacantShow}>
                                                <Modal.Header className="allery-header">
                                                  <Modal.Title>
                                                    {" "}
                                                    Pateint Already Assigned{" "}
                                                  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                  <p
                                                    style={{
                                                      padding: "15px",
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    It seems this patient is
                                                    already assigned. Would you
                                                    like to transfer the patient
                                                    to a chosen bed?{" "}
                                                  </p>
                                                </Modal.Body>
                                                <hr></hr>
                                                <Modal.Footer className="allergy-footer-btn">
                                                  <button
                                                    className="btn btn-primary me-2"
                                                    onClick={() =>
                                                      setVacantShow(false)
                                                    }
                                                  >
                                                    Close
                                                  </button>{" "}
                                                  <button
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                      handleTransferVacantBed(
                                                        vacant.id,
                                                        result.id
                                                      )
                                                    }
                                                  >
                                                    Tranfer Bed
                                                  </button>
                                                </Modal.Footer>
                                              </Modal>
                                              {/* )} */}
                                              <ul
                                                key={index}
                                                onClick={() =>
                                                  handleAssignVacantBed(
                                                    result.id,
                                                    vacant.id
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",

                                                  padding: "5px",

                                                  backgroundColor: "#ecf5ff",
                                                }}
                                              >
                                                <div className="bed-user-name p-2">
                                                  <li>
                                                    <strong>
                                                      {result.patient_full_name &&
                                                        result.patient_full_name}
                                                    </strong>
                                                  </li>
                                                  <li>
                                                    {result.email &&
                                                      result.email}
                                                  </li>
                                                  <li>
                                                    (
                                                    {result.mrn_no &&
                                                      result.mrn_no}
                                                    )
                                                  </li>
                                                  <li>
                                                    (
                                                    {result.medical_no &&
                                                      result.medical_no}
                                                    )
                                                  </li>
                                                  <li>
                                                    (
                                                    {result.medical_dependency &&
                                                      result.medical_dependency}
                                                    )
                                                  </li>
                                                </div>
                                                <hr className="mb-0 mt-1"></hr>
                                              </ul>
                                            </>
                                          );
                                        }
                                      )
                                    )}
                                  </Card.Body>
                                </Card>
                              </Card.Body>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Card>
                      </Accordion>
                    </div>
                  ))
                : showUnpreparedBeds
                ? unpreparedBeds &&
                  unpreparedBeds.map((unprepared, bedIndex) => (
                    <div key={bedIndex}>
                      <Accordion>
                        {console.log("unprepared", unprepared)}
                        <Card className="card">
                          <Accordion.Item>
                            <Accordion.Header>
                              {unprepared.floor_name} {unprepared.room_name}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Card.Body>
                                <Card>
                                  <Card.Header
                                    style={{
                                      backgroundColor: "#fc893d",
                                      color: "#fff",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      Bed {unprepared.bed_title}
                                      <div>
                                        <input
                                          type="checkbox"
                                          onClick={() => {
                                            handleCheckboxClick();
                                            setCheckboxBedId(unprepared.id);
                                            setCheckboxPatientId(
                                              unprepared.patient.id
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body>
                                    <div>
                                      <strong>Un Prepared</strong>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Card.Body>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Card>
                      </Accordion>
                    </div>
                  ))
                : roomsAndBeds.rooms &&
                  roomsAndBeds.rooms.map((room, roomIndex) => (
                    <div key={roomIndex}>
                      <Accordion>
                        <Card className="card">
                          <Accordion.Item>
                            <Accordion.Header>
                              {room.room_name}
                            </Accordion.Header>
                            <Accordion.Body>
                              <Card.Body>
                                <ul
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    gap: "10px",
                                  }}
                                >
                                  {room.beds.map((bed, bedIndex) => {
                                    console.log("bedssss", bed);
                                    const bedStatus = bed.status && bed.status;
                                    let color = " ";

                                    if (bedStatus === "occupied") {
                                      color = "#df5959";
                                    } else if (bedStatus === "pending") {
                                      color = "#c0ac4a";
                                    } else if (bedStatus === "vacant") {
                                      color = "#72c05d";
                                    } else if (bedStatus === "unprepared") {
                                      color = "#fc893d";
                                    } else if (bedStatus === "hospitalized") {
                                      color = "#8a1574";
                                    }

                                    return (
                                      <>
                                        <Card className="card" key={bedIndex}>
                                          <Card.Header
                                            style={{
                                              backgroundColor: color,
                                              color: "#fff",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              Bed {bed.bed_title}
                                              <div>
                                                {bedStatus === "unprepared" &&
                                                  bed.patient && (
                                                    <input
                                                      type="checkbox"
                                                      onClick={() => {
                                                        handleCheckboxClick();
                                                        setCheckboxBedId(
                                                          bed.id
                                                        );
                                                        setCheckboxPatientId(
                                                          bed.patient.id
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                {bedStatus === "hospitalized" &&
                                                  bed.patient && (
                                                    <input
                                                      type="checkbox"
                                                      onClick={() => {
                                                        handleReCheckboxClick();
                                                        setCheckboxBedId(
                                                          bed.id
                                                        );
                                                        setCheckboxPatientId(
                                                          bed.patient.id
                                                        );
                                                        setRePatient(
                                                          bed.patient.first_name
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                {(bedStatus === "occupied" ||
                                                  bedStatus ===
                                                    "hospitalized") && (
                                                  <FontAwesomeIcon
                                                    style={{
                                                      borderRadius: "5px",
                                                      fontSize: "14px",
                                                      padding:
                                                        "1px 3px 1px 3px",
                                                      // backgroundColor: "#17a2b8",
                                                      color: "#fff",
                                                      cursor: "pointer",
                                                      marginLeft: "10px",
                                                    }}
                                                    icon={faEject}
                                                    className="fa-visit-icon"
                                                    // onClick={() =>
                                                    //   handleDischargeBed(
                                                    //     bed.patient.id,
                                                    //     bed.id
                                                    //   )
                                                    // }
                                                    onClick={() => {
                                                      setEjectModal(true);
                                                      setBedId(bed.id);
                                                      setPatientId(
                                                        bed.patient.id
                                                      );
                                                      setCheckBedStatus(
                                                        bedStatus
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </Card.Header>
                                          <Card.Body>
                                            <Card.Text>
                                              {bedStatus === "vacant" && (
                                                <>
                                                  <div className="input-group input-search rounded w-100 ">
                                                    <input
                                                      type="search"
                                                      className="form-control rounded"
                                                      placeholder="Search"
                                                      aria-label="Search"
                                                      aria-describedby="search-addon"
                                                      ref={inputRef}
                                                      value={
                                                        searchInputs[bed.id] ||
                                                        ""
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          e,
                                                          bed.id
                                                        )
                                                      }
                                                    />
                                                    {searchInputs[
                                                      bed.id
                                                    ] ? null : (
                                                      <i className="fas fa-search search-icon"></i>
                                                    )}
                                                  </div>
                                                  <div>
                                                    {loading &&
                                                    activeInputCard ===
                                                      bed.id ? (
                                                      <div
                                                        className="spinner-border text-primary"
                                                        role="status"
                                                        style={{
                                                          display: "block",
                                                          padding: "5px",
                                                        }}
                                                      >
                                                        <span className="visually-hidden">
                                                          Loading...
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      !loading &&
                                                      searchInputs[bed.id] &&
                                                      searchResults[bed.id] &&
                                                      searchResults[bed.id].map(
                                                        (result, index) => {
                                                          return (
                                                            <>
                                                              {/* {console.log("result",result)}
                                                                 {signed.status === "1" && show 
                                             && ( */}
                                                              <Modal
                                                                show={show}
                                                              >
                                                                <Modal.Header className="allery-header">
                                                                  <Modal.Title>
                                                                    {" "}
                                                                    Pateint
                                                                    Already
                                                                    Assigned{" "}
                                                                  </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                  <p
                                                                    style={{
                                                                      padding:
                                                                        "15px",
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                  >
                                                                    It seems
                                                                    this patient
                                                                    is already
                                                                    assigned.
                                                                    Would you
                                                                    like to
                                                                    transfer the
                                                                    patient to a
                                                                    chosen bed?{" "}
                                                                  </p>
                                                                </Modal.Body>
                                                                <hr></hr>
                                                                <Modal.Footer className="allergy-footer-btn">
                                                                  <button
                                                                    className="btn btn-primary me-2"
                                                                    onClick={() =>
                                                                      setShow(
                                                                        false
                                                                      )
                                                                    }
                                                                  >
                                                                    Close
                                                                  </button>{" "}
                                                                  <button
                                                                    className="btn btn-primary"
                                                                    onClick={() =>
                                                                      handleTransferBed(
                                                                        bed.id
                                                                      )
                                                                    }
                                                                  >
                                                                    Tranfer Bed
                                                                  </button>
                                                                </Modal.Footer>
                                                              </Modal>
                                                              {/* )} */}
                                                              <ul
                                                                key={index}
                                                                onClick={() =>
                                                                  handleAssignBed(
                                                                    result.id,
                                                                    bed.id
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",

                                                                  padding:
                                                                    "5px",

                                                                  backgroundColor:
                                                                    "#ecf5ff",
                                                                }}
                                                              >
                                                                <div className="bed-user-name p-2">
                                                                  <li>
                                                                    <strong>
                                                                      {result.patient_full_name &&
                                                                        result.patient_full_name}
                                                                    </strong>
                                                                  </li>
                                                                  <li>
                                                                    {result.email &&
                                                                      result.email}
                                                                  </li>
                                                                  <li>
                                                                    (
                                                                    {result.mrn_no &&
                                                                      result.mrn_no}
                                                                    )
                                                                  </li>
                                                                  <li>
                                                                    (
                                                                    {result.medical_no &&
                                                                      result.medical_no}
                                                                    )
                                                                  </li>
                                                                  <li>
                                                                    (
                                                                    {result.medical_dependency &&
                                                                      result.medical_dependency}
                                                                    )
                                                                  </li>
                                                                </div>
                                                                <hr className="mb-0 mt-1"></hr>
                                                              </ul>
                                                            </>
                                                          );
                                                        }
                                                      )
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                              {(bedStatus === "occupied" ||
                                                bedStatus === "pending" ||
                                                bedStatus ===
                                                  "hospitalized") && (
                                                <div>
                                                  <strong>
                                                    {`${
                                                      bed.patient.first_name &&
                                                      bed.patient.first_name
                                                    } ${
                                                      bed.patient.last_name &&
                                                      bed.patient.last_name
                                                    }`}
                                                  </strong>
                                                </div>
                                              )}
                                              {(bedStatus === "occupied" ||
                                                bedStatus === "pending" ||
                                                bedStatus ===
                                                  "hospitalized") && (
                                                <div>
                                                  <strong>
                                                    {bed.patient.gender &&
                                                      bed.patient.gender}
                                                    ,
                                                  </strong>{" "}
                                                  {moment(
                                                    bed.patient.date_of_birth &&
                                                      bed.patient.date_of_birth
                                                  )
                                                    .utc()
                                                    .format("MM/DD/YYYY")}
                                                </div>
                                              )}
                                              {(bedStatus === "occupied" ||
                                                bedStatus === "pending" ||
                                                bedStatus ===
                                                  "hospitalized") && (
                                                <div>
                                                  <strong>MRN:</strong>{" "}
                                                  {bed.patient.mrn_no &&
                                                    bed.patient.mrn_no}
                                                </div>
                                              )}
                                              {(bedStatus === "occupied" ||
                                                bedStatus === "pending" ||
                                                bedStatus ===
                                                  "hospitalized") && (
                                                <div>
                                                  <strong>Medical Id:</strong>{" "}
                                                  {bed.patient.medical_no &&
                                                    bed.patient.medical_no}
                                                </div>
                                              )}
                                              {(bedStatus === "occupied" ||
                                                bedStatus === "pending" ||
                                                bedStatus ===
                                                  "hospitalized") && (
                                                <div>
                                                  <strong>
                                                    Medical Dependency:
                                                  </strong>{" "}
                                                  {bed.patient
                                                    .medical_dependency &&
                                                    bed.patient
                                                      .medical_dependency}
                                                </div>
                                              )}
                                              {bedStatus === "unprepared" && (
                                                <div>
                                                  <strong>Un Prepared</strong>
                                                </div>
                                              )}
                                            </Card.Text>
                                          </Card.Body>
                                        </Card>
                                      </>
                                    );
                                  })}
                                </ul>
                              </Card.Body>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Card>
                      </Accordion>
                    </div>
                  ))}

              <Modal show={ejectModal} onHide={handleClose}>
                <Modal.Body className="allergy-body">
                  Are you sure you want to discharge the patient from this bed?
                </Modal.Body>
                <Modal.Footer className="allergy-footer-btn">
                  <Button variant="secondary" onClick={handleClose}>
                    No
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => handleDischargeBed(patientId, bedId)}
                  >
                    Discharge
                  </Button>
                  {checkBedStatus !== "hospitalized" && (
                    <Button
                      variant="primary"
                      onClick={() => handleHospitalizedBed(patientId, bedId)}
                    >
                      Hospitalized
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>

              <Modal show={reOccupied} onHide={handleReClose}>
                <Modal.Body className="allergy-body">
                  Are you sure you want to Re Assign this bed to {rePatient}?
                </Modal.Body>
                <Modal.Footer className="allergy-footer-btn">
                  <Button variant="secondary" onClick={handleReClose}>
                    No
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => handleReassingBed(patientId, bedId)}
                  >
                    Re Assign
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={checkboxModal} onHide={handleCloseCheckboxModal}>
                <Modal.Body className="allergy-body">
                  Is the bed ready for the next patient?
                </Modal.Body>
                <Modal.Footer className="allergy-footer-btn">
                  <Button
                    variant="secondary"
                    onClick={handleCloseCheckboxModal}
                  >
                    No
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() =>
                      handleCheckboxBed(checkboxBedId, checkboxPatientId)
                    }
                  >
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>

              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BedMapping;
