import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/header/Header";
import MyProfile from "./profile/MyProfile";
import Security from "./security/Security";
import ChangePassword from "./password/ChangePassword";
import ChangePin from "./pin/ChangePin";
import FacilityManagement from "./management/FacilityManagement";
import PatientProfile from "../profile/PatientProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../detail/PatientDetail.css";
const Settings = () => {
  //   var patient = useLocation();
  //   var patientDetails = patient.state.patients;

  const [activeTab, setActiveTab] = useState("myprofile"); // State to manage active tab

  // Function to handle tab click
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className="main-div">
      {/* <Header /> */}
      <div className="body-patient-detail container-fluid flex-fill  ">
        <div className="mt-4">
          <div className="clear">
            <div className="d-flex flex-fill h-100">
              <div className="main-left-settings">
                <div className="left-nav ">
                  <div className="setting-bg">
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills me-3 w-100"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className={`nav-link ${
                            activeTab === "myprofile" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("myprofile")}
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-myprofile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected={activeTab === "myprofile"}
                        >
                          My Profile
                        </button>
                        {/* <button
                              className={`nav-link ${
                                activeTab === "security" ? "active" : ""
                              }`}
                              onClick={() => handleTabClick("security")}
                              id="v-pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-Security"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-profile"
                              aria-selected={activeTab === "security"}
                            >
                              Security
                            </button> */}
                        <button
                          className={`nav-link ${
                            activeTab === "changepassword" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("changepassword")}
                          id="v-pills-messages-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-ChangePassword"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected={activeTab === "changepassword"}
                        >
                          Change Password
                        </button>
                        <button
                          className={`nav-link ${
                            activeTab === "changepin" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("changepin")}
                          id="v-pills-settings-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-ChangePin"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-settings"
                          aria-selected={activeTab === "changepin"}
                        >
                          Change Pin
                        </button>
                        <button
                          className={`nav-link ${
                            activeTab === "faciltymanagement" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("faciltymanagement")}
                          id="v-pills-settings-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-FaciltyManagement"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-settings"
                          aria-selected={activeTab === "faciltymanagement"}
                        >
                          Facilty Management
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Render MyProfile component if activeTab is 'myprofile' */}
              {activeTab === "myprofile" && (
                <div className="scroll-view">
                  <MyProfile />
                </div>
              )}

              {/* Render other components for other tabs similarly */}
              {/* {activeTab === "security" && (
                    <div className="scroll-view">
                      <Security />
                    </div>
                  )} */}
              {activeTab === "changepassword" && (
                <div className="scroll-view">
                  <ChangePassword />
                </div>
              )}
              {activeTab === "changepin" && (
                <div className="scroll-view">
                  <ChangePin />
                </div>
              )}
              {activeTab === "faciltymanagement" && (
                <div className="scroll-view">
                  <FacilityManagement />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
