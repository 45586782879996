import React from "react";
import { useState, useEffect } from "react";
import Logo from "../../../../assets/images/logo-black.png";
import "./WarningLetter.css";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
const WarningLetter = ({ data, handleCloseWarningLetter }) => {
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
          size: A4;
        }
      `,
    printOpenDelay: 1000,
    documentTitle: "Warning Letter",
  });
  console.log("data", data);
  return (
    <div>
      <div
        className="document-content d-flex"
        style={{ justifyContent: "flex-end" }}
      >
        <Button variant="secondary" onClick={handleCloseWarningLetter}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handlePrint}
          style={{ marginLeft: "1rem" }}
        >
          Print
        </Button>
      </div>
      <div ref={componentRef}>
        <div className="document-content d-flex align-items-center">
          <div className="container-warning">
            <div className="warning-content">
              <div className="brand-logo-warning">
                {/* <img style={{ width: '20%' }} src={Logo} alt="Logo" /> */}
                <b>Facility Access</b>
              </div>
              <h2>Warning Letter</h2>
            </div>
            <p>Facility Access</p>
            <p>{data.soul_housing_address}</p>
            <div>
              <p>
                <strong>via: </strong>Hand delivery to{" "}
                <input
                  type="text"
                  value={data.patient_name}
                  style={{
                    border: "none",
                    borderBottom: "1px solid #000",
                  }}
                  placeholder="Name"
                />{" "}
                <strong>(Name)</strong> on{" "}
                <input
                  type="text"
                  value={moment(data.date)
                    .utc()
                    .format("MM/DD/YYYY hh:mm:ss A")}
                  style={{
                    border: "none",
                    borderBottom: "1px solid #000",
                  }}
                  placeholder="Date"
                />
              </p>
              <p>
                <strong>(Date:) </strong>By{" "}
                <input
                  type="text"
                  value={data.provider_full_name}
                  style={{
                    border: "none",
                    borderBottom: "1px solid #000",
                  }}
                  placeholder="Staff Name"
                />{" "}
                <strong>(Staff Name)</strong>
              </p>
              <p>
                Dear{" "}
                <input
                  type="text"
                  value={data.patient_name}
                  style={{
                    border: "none",
                    borderBottom: "1px solid #000",
                    width: "80%",
                  }}
                  placeholder="Name"
                />
                <strong>(Name):</strong>{" "}
              </p>
              <p>
                Soul Housing Management has been informed about an incident on
              </p>
              <p>
                <input
                  type="text"
                  value={moment(data.incident_date)
                    .utc()
                    .format("MM/DD/YYYY hh:mm:ss A")}
                  style={{
                    border: "none",
                    borderBottom: "1px solid #000",
                    width: "70%",
                  }}
                  placeholder="Date"
                />
                <strong>(Date):</strong> which took place
              </p>
              <p>
                <strong>in/at </strong>{" "}
                <input
                  type="text"
                  value={data.incident_location}
                  style={{
                    border: "none",
                    borderBottom: "1px solid #000",
                    width: "60%",
                  }}
                  placeholder="Location"
                />{" "}
                <strong>(Location): in which you</strong>{" "}
              </p>
              <textarea
                type="text"
                value={data.reason}
                style={{
                  border: "none",
                  borderBottom: "1px solid #000",
                  width: "100%",
                }}
                placeholder="Description of the incident/behavior/event"
              ></textarea>
              <div className="warning-content">
                <h2>Description Of Incident/Behavior/Event</h2>
              </div>
              <p>
                In accordance with our policies, such behavior is not acceptable
                and you are now therefore formally warned that if there is any
                repeat of this or similar behavior at any time in the future,
                Soul Housing, in line with your signed "Intake Form - Intake
                Letter, Facility Rules, and Drug Screen/Test Agreement," has the
                authority to involuntarily discharge you from our facility and
                care.
              </p>
              <p>
                We are committed to ensuring everyone is treated with respect
                and dignity and this includes our staff as well as all residents
                and visitors.
              </p>
              <p>
                <strong>Regards,</strong>
              </p>
              <p>
                <input
                  type="text"
                  value={data.provider_full_name}
                  style={{
                    border: "none",
                    borderBottom: "1px solid #000",
                    width: "50%",
                  }}
                  placeholder="Staff Name"
                />
              </p>
              <p>
                <strong>Staff Name</strong>
              </p>

              <p>
                <input
                  type="text"
                  value={moment(data.date)
                    .utc()
                    .format("MM/DD/YYYY hh:mm:ss A")}
                  style={{
                    border: "none",
                    borderBottom: "1px solid #000",
                    width: "50%",
                  }}
                  placeholder="Date"
                />
              </p>
              <p>
                <strong>Date</strong>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          {data.file_type === "image" && (
            <img src={data.file_path} alt="Tweet media" />
          )}
          {data.file_type === "video" && (
            <video src={data.file_path} controls />
          )}
        </div>
      </div>
      <div>
        {/* {data.file_type === "video" && (
                    <video src={data.file_path} controls />
                  )} */}
      </div>
    </div>
  );
};

export default WarningLetter;
