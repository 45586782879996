import React from "react";
import "./WarningLetter.css";
import logo from "../../../../assets/images/logo-blue.png";
import { useReactToPrint } from "react-to-print";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
const MedicalLetter = ({ data, handleCloseMedicalLetter }) => {
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
          size: A4;
        }
      `,
    printOpenDelay: 1000,
    documentTitle: "Warning Letter",
  });
  console.log("medicaldata", data);
  return (
    <div className="document-content">
      <div
        className="document-content d-flex"
        style={{ justifyContent: "flex-end" }}
      >
        <Button variant="secondary" onClick={handleCloseMedicalLetter}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handlePrint}
          style={{ marginLeft: "1rem" }}
        >
          Print
        </Button>
      </div>
      <div ref={componentRef}>
        <div className="container-warning">
          <div className="img-brand-soul">
            {/* <img src={logo} alt="Logo" /> */}
            <b>Facility Access</b>
          </div>
          <div className="soul-detail-bio">
            <ul>
              <li>{data.provider_full_name && data.provider_full_name}</li>
              <li>NPI# {data.provider_npi && data.provider_npi}</li>
              <li>Facility Access</li>
              <li>{data.soul_housing_address && data.soul_housing_address}</li>
            </ul>
          </div>
          <div className="clear-both"></div>
          <div className="content-left-soul">
            <ul>
              <li>
                <strong>Medical Letter</strong>
              </li>
              <li>
                <strong>Name:</strong>{" "}
                <span className="user-name">{data.patient_name}</span>
              </li>
              <li>
                <strong>Date/Time:</strong>{" "}
                <span className="user-name">
                  {moment(data.date).utc().format("MM/DD/YYYY")}
                </span>
              </li>
              <li>
                <strong>Staff Name:</strong>{" "}
                <span className="user-name">{data.provider_full_name}</span>
              </li>
              <li>
                <strong>Incidient Date/Time:</strong>{" "}
                <span className="user-name">
                  {moment(data.incident_date).utc().format("MM/DD/YYYY")}
                </span>
              </li>
            </ul>
          </div>

          <div className="clear-both"></div>
          <div>
            <ul>
              <li>
                <strong>Incidient Location :</strong>
                <br></br> <span>{data.incident_location}</span>
              </li>
              <li style={{ marginTop: "0.5rem" }}>
                <strong>Discription :</strong> <br></br>
                <span>{data.reason}</span>
              </li>
            </ul>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          {data.file_type === "image" && (
            <img src={data.file_path} alt="Tweet media" />
          )}
          {data.file_type === "video" && (
            <video src={data.file_path} controls />
          )}
        </div>
      </div>
    </div>
  );
};

export default MedicalLetter;
