import React, { useState } from "react";
// import "../../profile/PatientProfile.css";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changePassword } from "../../../../components/validation/RegistrationValidation";
const initialValue = {
  old_password: "",
  password: "",
  password_confirmation: "",
};
const ChangePassword = () => {
  const [error, setError] = useState("");
  const { values, touched, errors, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues: initialValue,
      validationSchema: changePassword,
      onSubmit: async (values, action) => {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          const password = await axios.post(
            process.env.REACT_APP_ORIGIN_URL + "/change-password",
            {
              old_password: values.old_password,
              password: values.password,
              password_confirmation: values.password_confirmation,
            },
            config
          );
          toast.success(password.message);
        } catch (error) {
          console.log(error.message);
          toast.error(error.message);
        }
      },
      validate: (values) => {
        const errors = {};

        // Check if password and confirm password match
        if (values.password !== values.password_confirmation) {
          errors.password_confirmation = "Passwords do not match";
        }

        return errors;
      },
    });

  return (
    <>
      <div className="password-forms settings-right ">
        <h5>Change Password</h5>

        <div className="row mt-4 g-3">
          <div className="col-xl-6">
            <div className="form-floating">
              <input
                type="password"
                name="old_password"
                className="form-control w-100"
                id="currentPassword"
                placeholder="Current Password"
                value={values.old_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="currentPassword">Current Password</label>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="form-floating">
              <input
                type="password"
                name="password"
                className="form-control w-100"
                id="newPassword"
                placeholder="New Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="newPassword">New Password</label>
            </div>
            {errors.password && touched.password ? (
              <p style={{ color: "red" }}>{errors.password}</p>
            ) : null}
          </div>
          <div className="col-xl-6">
            <div className="form-floating">
              <input
                type="password"
                name="password_confirmation"
                className={`form-control w-100 ${
                  errors.password_confirmation && touched.password_confirmation
                    ? "is-invalid"
                    : ""
                }`}
                id="confirmNewPassword"
                placeholder="Confirm New Password"
                value={values.password_confirmation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label htmlFor="confirmNewPassword">Confirm New Password</label>
              {errors.password_confirmation &&
                touched.password_confirmation && (
                  <div className="invalid-feedback">
                    {errors.password_confirmation}
                  </div>
                )}
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary mt-3"
          onClick={handleSubmit}
        >
          Save Password
        </button>
      </div>
      <ToastContainer />
    </>
  );
};

export default ChangePassword;
