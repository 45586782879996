import React, { useState, useEffect } from "react";
import "../PatientProfile.css";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import { handleGetVital } from "../../../../components/services/VitalService";
import { medicationValidation } from "../../../../components/validation/RegistrationValidation";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";

const getCurrentLocalDateTime = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate.toISOString().slice(0, 16);
};

const initialValues = {
  title: "",
  strength: "",
  begin_date: new Date(),
  end_date: "",
  referred_by: "",
  dosage_unit: "",
  route: "",
  frequency: "",
  refills: "",
  quantity: "",
  dose: "",
  quantity_left: "",
  additional_sig: "",
};
const AddMedication = ({
  id,
  handleCloseMedicationClick,
  handleCloseEditMedicationClick,
  medication,
  handleMedicationCreated,
}) => {
  const [formData, setFormData] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  //   const getFormData = async () => {
  //     try {
  //       const config = {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       };
  //       const response = await axios.get(
  //         process.env.REACT_APP_ORIGIN_URL +`/patient-encounter-information/${id}`,
  //         config
  //       );
  //       setFormData(response.data);
  //     } catch (error) {
  //       console.log("error", error.message);
  //     }
  //   };

  //   useEffect(() => {
  //     getFormData();
  //   }, []);

  const getProvider = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/all-providers`,
        config
      );
      setProviders(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    touched,
    resetForm,
  } = useFormik({
    initialValues: medication || initialValues,
    validationSchema: medicationValidation,
    onSubmit: async (values, action) => {
      try {
        setSaveLoading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        };
        const endpoint = medication
          ? `${process.env.REACT_APP_ORIGIN_URL}/update-medication`
          : `${process.env.REACT_APP_ORIGIN_URL}/add-medication`;

        // Log the endpoint to check its value
        console.log("Endpoint:", endpoint);

        const response = await axios.post(
          endpoint,
          {
            ...values,
            patient_id: id,
          },
          config
        );

        console.log("Response:", response);
        toast.success(response.data.message);

        handleMedicationCreated();
        if (medication) {
          handleCloseEditMedicationClick();
        } else {
          handleCloseMedicationClick();
        }
      } catch (error) {
        console.log("Error:", error.message);
        toast.error(error.message);
      } finally {
        setSaveLoading(false);
      }
      action.resetForm();
    },
  });

  // After successfully creating encounter
  const handleCancelClick = () => {
    resetForm();
    if (medication) {
      handleCloseEditMedicationClick();
    } else {
      handleCloseMedicationClick();
    }
  };

  useEffect(() => {
    getProvider();
  }, []);
  useEffect(() => {
    setFieldValue(" begin_date", getCurrentLocalDateTime());
  }, []);
  console.log("info", medication);
  return (
    <div>
      <div className="d-flex flex-column h-100">
        <div className="encounter-links mt-2  ">
          <div className="d-flex">
            <h3>Add Medication</h3>
            <button
              type="button"
              className="btn btn-primary ms-auto "
              onClick={handleCancelClick}
            >
              {" "}
              Back
            </button>
          </div>
          <div className="row g-3 mb-3 mt-3">
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.title && touched.title ? "is-invalid" : ""
                  }`}
                  id="title"
                  name="title"
                  placeholder="Name"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="title">
                  Name <span style={{ color: "red", fontSize: "13px" }}>*</span>
                </label>
              </div>
              {errors.title && touched.title ? (
                <p style={{ color: "red" }}>{errors.title}</p>
              ) : null}
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.dose && touched.dose ? "is-invalid" : ""
                  }`}
                  id="dose"
                  name="dose"
                  placeholder="dose"
                  value={values.dose}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="dose">
                  Dose <span style={{ color: "red", fontSize: "13px" }}>*</span>
                </label>
              </div>
              {errors.dose && touched.dose ? (
                <p style={{ color: "red" }}>{errors.dose}</p>
              ) : null}
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${
                    errors.dosage_unit && touched.dosage_unit
                      ? "is-invalid"
                      : ""
                  }`}
                  id="dosage_unit"
                  name="dosage_unit"
                  placeholder="Unit (mg)"
                  value={values.dosage_unit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="dosage_unit">
                  Unit (mg){" "}
                  <span style={{ color: "red", fontSize: "13px" }}>*</span>
                </label>
              </div>
              {errors.dosage_unit && touched.dosage_unit ? (
                <p style={{ color: "red" }}>{errors.dosage_unit}</p>
              ) : null}
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  placeholder="quantity"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="quantity">Quantity</label>
              </div>
            </div>
            {/* <div className="col-3">
              <div className="form-floating  border-0">
                <input
                  type="text"
                  className="form-control"
                  id="strength"
                  name="strength"
                  placeholder="strength"
                  value={values.strength}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label for="strength">Strength <span style={{ color: "red", fontSize: "13px" }}>*</span></label>
              </div>
              {errors.strength && touched.strength ? (
                      <p style={{ color: "red" }}>{errors.strength}</p>
                    ) : null}
            </div> */}
          </div>

          <div className=" row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="route"
                  name="route"
                  aria-label="Floating label select example"
                  value={values.route}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {/* <option value="">-Select-</option> */}

                  <option value="Orally">Oral</option>
                  <option value="Injection">Injection</option>
                  <option value="Sublingual">Sublingual</option>
                  <option value="Buccal">Buccal</option>
                  <option value="Rectal">Rectal</option>
                  <option value="Vaginal">Vaginal</option>
                  <option value="Ocular">Ocular</option>
                  <option value="Otic">Otic</option>
                  <option value="Nasal">Nasal</option>
                  <option value="Intravenous">Intravenous</option>
                  <option value="Intramuscular">Intramuscular</option>
                </select>
                <label htmlFor="route">Route</label>
              </div>
            </div>

            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="frequency"
                  name="frequency"
                  placeholder="frequency"
                  value={values.frequency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="frequency">Frequency</label>
              </div>
            </div>

            <div className="col-3">
              <div className="form-floating">
                <FloatLabel>
                  <Calendar
                    value={values.begin_date}
                    onChange={(e) => setFieldValue("begin_date", e.value)}
                    onBlur={handleBlur}
                    dateFormat="mm/dd/yy"
                    showIcon
                    showTime
                    hourFormat="12"
                    hideOnDateTimeSelect="true"
                  />
                  <label htmlFor="begin_date">
                    Start{" "}
                    <span style={{ color: "red", fontSize: "13px" }}>*</span>
                  </label>
                </FloatLabel>
              </div>
              {errors.begin_date && touched.begin_date ? (
                <p style={{ color: "red" }}>{errors.begin_date}</p>
              ) : null}
            </div>
            <div className="col-3">
              <div className="form-floating">
                <FloatLabel>
                  <Calendar
                    value={values.end_date}
                    onChange={(e) => setFieldValue("end_date", e.value)}
                    onBlur={handleBlur}
                    dateFormat="mm/dd/yy"
                    showIcon
                    showTime
                    hourFormat="12"
                    hideOnDateTimeSelect="true"
                  />
                  <label htmlFor="end_date">End Date </label>
                </FloatLabel>
              </div>
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="refills"
                  name="refills"
                  placeholder="Refills Left"
                  value={values.refills}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="refills">Refills Left</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="quantity_left"
                  name="quantity_left"
                  placeholder="Quantity Left"
                  value={values.quantity_left}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="quantity_left">Quantity Left</label>
              </div>
            </div>

            <div className="col-3">
              <div className="form-floating  border-0">
                <select
                  className="form-select"
                  id="referred_by"
                  name="referred_by"
                  aria-label="Floating label select example"
                  value={values.referred_by}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {/* <option value="">-Select-</option> */}

                  {providers &&
                    providers.map((provider, index) => (
                      <option
                        key={provider.id}
                        value={provider.id}
                        selected={
                          provider.id === formData.loginProvider
                            ? "selected"
                            : null
                        }
                      >
                        {provider.name}
                      </option>
                    ))}
                </select>
                <label for="referred_by">Referred By </label>
              </div>
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div>
              <div className="form-floating">
                <textarea
                  type="text"
                  style={{ height: "6rem" }}
                  className="form-control"
                  id="additional_sig"
                  name="additional_sig"
                  placeholder="additional_sig"
                  value={values.additional_sig}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="additional_sig">Additional Sig</label>
              </div>
            </div>
          </div>

          <div className="encounter-buttoons d-flex">
            <Button variant="secondary" onClick={handleCancelClick}>
              Cancel
            </Button>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary ms-3"
            >
              {medication
                ? saveLoading
                  ? "Processing..."
                  : "Update"
                : saveLoading
                ? "Processing.."
                : "Save"}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddMedication;
