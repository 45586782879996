import React, { useEffect, useState } from "react";
import "../PatientProfile.css";
import axios from "axios";
import moment from "moment";
import AddEncounter from "./AddEncounter";
import { Link } from "react-router-dom";
import VisitReason from "./VisitReason";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPlus } from "@fortawesome/free-solid-svg-icons"; // Import faFilePdf icon
import EditEncounter from "./EditEncounter";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux";
import NoData from "../../../../assets/images/no-data-found.png";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
const Encounters = ({ id, onEncounterCreatedFetch }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAddEncounter, setShowAddEncounter] = useState(false);
  const [encounters, setEncounters] = useState([]);
  const [selectedEncounter, setSelectedEncounter] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [newlyCreatedEncounter, setNewlyCreatedEncounter] = useState(null);
  const [closeVisitNotes, setCloseVisitNotes] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [signed, setSigned] = useState(false);
  const [editEncounter, setEditEncounter] = useState(null);
  const [showEditEncounter, setShowEditEncounter] = useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const patientsPerPage = 2;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getEncounters = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/patient-encounter/${id}`,
        config
      );
      setEncounters(res.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const getpdf = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-pdf/${id}`,
        config
      );
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getEncounters();
  }, []);

  const handleAddEncounterClick = () => {
    setShowAddEncounter(true);
    setEditEncounter(null);
  };

  const handleCloseEncounterClick = () => {
    setSelectedEncounter(null);
    setNewlyCreatedEncounter(null);
    setShowAddEncounter(false);
    setShowEditEncounter(false);
    getEncounters();
  };

  const handleDraftClick = (encounter) => {
    console.log("Draft clicked for encounter:", encounter);
    if (encounter.status === "assign") {
      setSigned(true);
    } else {
      setSigned(false);
    }
    setSelectedEncounter(encounter);
  };

  const handleNewEncounterCreated = (newEncounterData) => {
    setNewlyCreatedEncounter(newEncounterData);
  };

  const handleEditClick = (encounter) => {
    setEditEncounter(encounter);
    setShowEditEncounter(true);
  };

  const handleCloseVisitNotesClick = () => {
    setSelectedEncounter(null);
    setNewlyCreatedEncounter(null);
    getEncounters();
  };
  const generateAndOpenPDF = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-pdf/${id}`,
        // { file_name: encounterPdf }, // Replace with the actual structure to send
        config
      );
      console.log("res", res.data);
      // Extract base64 encoded PDF content from response
      const pdfData = res.data.file;
      const mimeType = res.data.mime_type;

      // Convert base64 to Blob
      const byteCharacters = atob(pdfData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      // Create URL and open in new tab
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.log("Error generating or opening PDF:", error.message);
      // Handle error as needed
    }
  };
  const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
  }));
  console.log("Rendered Encounters component");

  console.log("encounter", encounters);
  return (
    <div>
      {newlyCreatedEncounter ? (
        <VisitReason
          encounterData={newlyCreatedEncounter}
          handleCloseNewEncounter={() => setNewlyCreatedEncounter(null)}
          handleCloseVisitNotesClick={handleCloseVisitNotesClick}
          onEncounterCreatedFetch={onEncounterCreatedFetch}
          signed={signed}
          id={id}
          handleCloseEncounterClick={handleCloseEncounterClick}
        />
      ) : selectedEncounter ? (
        <VisitReason
          encounter={selectedEncounter}
          handleClose={() => setSelectedEncounter(null)}
          handleCloseVisitNotesClick={handleCloseVisitNotesClick}
          onEncounterCreatedFetch={onEncounterCreatedFetch}
          signed={signed}
          id={id}
          handleCloseEncounterClick={handleCloseEncounterClick}
        />
      ) : (
        <div>
          {!showAddEncounter && !showEditEncounter && (
            <>
              <div className="encounter-heading">
                <div className="d-flex align-items-center">
                  <h5>Encounter</h5>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleAddEncounterClick}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ paddingRight: "5px" }}
                      />
                      Encounter
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          {showAddEncounter && (
            <AddEncounter
              handleCloseEncounterClick={handleCloseEncounterClick}
              onEncounterCreated={handleNewEncounterCreated}
              id={id}
              onEncounterCreatedFetch={onEncounterCreatedFetch}
              editEncounter={editEncounter}
            />
          )}
          {showEditEncounter && (
            <EditEncounter
              id={id}
              editEncounter={editEncounter}
              handleCloseEncounterClick={handleCloseEncounterClick}
            />
          )}

          {!showAddEncounter && !showEditEncounter && (
            <div>
              <>
                <StyledTable className="encounter-table table-responsive table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ paddingLeft: "5px" }}>
                        No
                      </TableCell>
                      <TableCell align="left">Encounter Date</TableCell>
                      {/* <TableCell align="left">Visit Reason/Case</TableCell> */}
                      <TableCell align="left">Encounter Type/ID</TableCell>
                      <TableCell align="left">Speciality</TableCell>
                      <TableCell align="left">Provider</TableCell>
                      {/* <TableCell align="left">Location</TableCell> */}
                      <TableCell align="left">PDF</TableCell>
                      <TableCell align="left">Note Status</TableCell>
                      <TableCell align="left">Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <tr>
                        <td colSpan="12">
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : encounters && encounters.length > 0 ? (
                      encounters
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((encounter, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {" "}
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            {console.log("encounterpdf", encounter)}
                            <TableCell style={{ paddingLeft: "5px" }}>
                              {moment(encounter.encounter_date)
                                .utc()
                                .format("MM/DD/YYYY")}
                            </TableCell>
                            {/* <TableCell>{encounter.reason}</TableCell> */}
                            <TableCell>
                              {encounter.encounter_type_title}
                            </TableCell>
                            <TableCell>{encounter.specialty_title}</TableCell>
                            <TableCell>{encounter.provider_name}</TableCell>
                            {/* <TableCell>{encounter.location}</TableCell> */}
                            <TableCell>
                              {/* PDF icon and click handler */}

                              <IconButton
                                onClick={() =>
                                  // getpdf(encounter.id)
                                  generateAndOpenPDF(encounter.id)
                                }
                              >
                                <FontAwesomeIcon icon={faFilePdf} />
                              </IconButton>
                            </TableCell>
                            <TableCell>
                              <button
                                className={`btn ${
                                  encounter.status === "draft"
                                    ? "btn-draft"
                                    : "btn-disabled"
                                } `}
                                onClick={() => handleDraftClick(encounter)}
                              >
                                {encounter.status === "draft"
                                  ? "Draft"
                                  : "Signed"}
                              </button>
                            </TableCell>
                            <TableCell>
                              {encounter.status === "draft" && (
                                <li>
                                  <a onClick={() => handleEditClick(encounter)}>
                                    <i className="fas fa-edit"></i>
                                  </a>
                                </li>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan="12" align="center">
                          <img
                            src={NoData}
                            alt="No data found"
                            style={{ width: "150px", margin: "20px 0" }}
                          />
                          <p>No Encounter found.</p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </StyledTable>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={encounters.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // nextIconButtonProps={{ "aria-label": "Next Page" }}
                  // backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
              </>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Encounters;
