import * as Yup from "yup";

const registrationValidation = Yup.object({
  title: Yup.string().min(2).required("Please Enter Title  "),
  first_name: Yup.string().min(2).required("Please Enter  First Name "),
  gender: Yup.string().required("Please Enter  Gender "),
  date_of_birth: Yup.string().required("Please Enter  DOB "),
  medical_no: Yup.string().required("Please Enter  Martial ID"),
  language: Yup.string().min(2).required("Please Enter  Language"),
  doctor_name: Yup.string().min(2).required("Please Enter  Doctor Name"),
  medical_dependency: Yup.string()
    .min(2)
    .required("Please Enter  Medical Dependency"),
  admit_date: Yup.string().required("Please Enter  Admit Date"),
  country: Yup.string().min(2).required("Please Enter  Country"),
  state: Yup.string().required("Please Enter  State"),
  city: Yup.string().min(2).required("Please Enter  City"),
});

const changePassword = Yup.object({
  password: Yup.string().min(8).required("Please enter Password"),
});

const searchModal = Yup.object({
  first_name: Yup.string().min(2).required("Please enter your First name"),
  last_name: Yup.string().min(2).required("Please enter your Last name"),
  gender: Yup.string().required("Please enter your Gender"),
});

const medicationValidation = Yup.object({
  title: Yup.string().min(2).required("Please Enter Name"),
  // strength:Yup.string().min(2).required('Please Enter Strength'),
  begin_date: Yup.string().min(2).required("Please Enter Start Date"),

  dose: Yup.string().min(2).required("Please Enter Dose"),
  dosage_unit: Yup.string().min(2).required("Please Enter Unit"),
});

// const insuranceValidation = Yup.object({
//     group_name:Yup.string().min(2).required('Please Enter Group Name'),
//     policy_no:Yup.string().min(2).required('Please Enter Policy No'),
//     policy:Yup.string().min(2).required('Please Enter Policy'),
//     policy_holder:Yup.string().min(2).required('Please Enter Policy Holder'),
//     ins_phone:Yup.string().min(2).required('Please Enter Ins Phone'),
// })

const problemValidation = Yup.object({
  cd_description: Yup.string().min(2).required("Please Enter Name"),
  diagnosis: Yup.string().required("Please Enter ICD-10"),
});

const allergyValidation = Yup.object({
  type_id: Yup.string().required("Please Enter Allerg Type"),
  severity_id: Yup.string().required("Please Enter  Severity"),
  reaction_id: Yup.string().required("Please Enter Reaction"),
});

const encounterValidation = Yup.object({
  specialty: Yup.string().required("Please Enter Speciality"),
  encounter_type: Yup.string().required("Please Enter Encounter Type"),
});

const noteValidation = Yup.object({
  title: Yup.string().required("Please Enter Title"),
});

const documentValidation = Yup.object({
  title: Yup.string().required("Please Enter Title"),
});
const vitalValidation = Yup.object({
  date: Yup.string().required("Please Enter Date"),
});

const WarningLetterValidation = Yup.object({
  incident_date: Yup.string().required("Please Enter Date"),
});

const MedicalLetterValidation = Yup.object({
  incident_date: Yup.string().required("Please Enter Date"),
});

export {
  registrationValidation,
  changePassword,
  searchModal,
  medicationValidation,
  problemValidation,
  allergyValidation,
  encounterValidation,
  noteValidation,
  documentValidation,
  vitalValidation,
  WarningLetterValidation,
  MedicalLetterValidation,
};
