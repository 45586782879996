import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../components/header/Header";
import MyProfile from "../setting/profile/MyProfile";
import Security from "../setting/security/Security";
import ChangePassword from "../setting/password/ChangePassword";
import ChangePin from "../setting/pin/ChangePin";
import FacilityManagement from "../setting/management/FacilityManagement";
import PatientProfile from "../profile/PatientProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./PatientDetail.css";
import { VitalProvider } from "../../../components/context/VitalContext";
const PatientDetail = ({ patient }) => {
  // var patient = useLocation();
  var patientDetails = patient;
  console.log("details", patientDetails);
  const [activeTab, setActiveTab] = useState("myprofile"); // State to manage active tab

  // Function to handle tab click
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className="main-div">
      {/* <Header /> */}
      {/* <div className="body-patient-detail container-fluid flex-fill  main-div "> */}
      {/* <div className="d-flex flex-column h-100 overflow-hidden"> */}
      {/* <div className="patient-detail-tabs"> */}
      {/* <ul
              className="nav nav-tabs my-3 border-0"
              id="myTab"
              role="tablist"
            > */}
      {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link active me-2"
                  id="patient-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#MyPatients"
                  type="button"
                  role="tab"
                  aria-controls="Mypatient"
                  aria-selected="true"
                >
                  My Patients{" "}
                  &nbsp;&nbsp;<FontAwesomeIcon className="ml-2" icon={faTimes} />
                </button>
              </li> */}
      {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link "
                  id="jhonsmith-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#jhonsmith"
                  type="button"
                  role="tab"
                  aria-controls="jhonsmith"
                  aria-selected="false"
                >
                  {patientDetails.first_name + " " + patientDetails.last_name} &nbsp;&nbsp; <FontAwesomeIcon icon={faTimes} />
                </button>
              </li> */}

      {/* </ul> */}
      {/* <hr></hr> */}
      {/* </div> */}

      {/* MyPatients tab start */}
      {/* <div
            className="flex-fill h-100 overflow-hidden tab-content"
            id="myTabContent"
          >
            <div
              className="tab-pane fade h-100"
              id="MyPatients"
              role="tabpanel"
              aria-labelledby="patient-tab"
            ></div>
            <div
              className="tab-pane fade h-100"
              id="jhonsmith"
              role="tabpanel"
              aria-labelledby="jhonsmith-tab"
            >
            </div>
              

        
           
          </div> */}
      {/* </div> */}
      <VitalProvider>
        <PatientProfile patient={patientDetails} />
      </VitalProvider>
      {/* </div> */}
    </div>
  );
};

export default PatientDetail;
