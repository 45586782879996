// services/vitalService.js

import axios from "axios";

export const handleGetVital = async (patientId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await axios.get(
      `https://soulhousing-api.anchorstech.net/api/vitals/${patientId}`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

export const handleGetAllergies = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_ORIGIN_URL + `/allergies/${id}`,
      config
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const handleGetMedications = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_ORIGIN_URL + `/get-medication/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handleGetPatientDetails = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const response = await axios.get(
      process.env.REACT_APP_ORIGIN_URL + `/updated-patient-details/${id}`,
      config
    );
    return response.data.patient;
  } catch (error) {
    throw error;
  }
};
