import React, { useState, useEffect } from "react";
import "../PatientProfile.css";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
// import { insuranceValidation } from "../../../../components/validation/RegistrationValidation";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useSelector } from "react-redux";
import NoData from "../../../../assets/images/no-data-found.png";
import { Calendar } from "primereact/calendar";

const Insurance = ({ id, details }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [insuranceData, setInsuranceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [states, setStates] = useState([]);
  const [disableFields, setDisableFields] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // To track if editing
  const [currentInsurance, setCurrentInsurance] = useState(null);

  const handleClose = () => {
    setShow(false);
    setIsEditMode(false); // Reset to add mode on close
  };
  const handleShow = () => {
    setShow(true);
    setIsEditMode(false); // Reset to add mode
  };

  const getInsuranceData = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-insurance/${id}`,
        config
      );
      setInsuranceData(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (insurance) => {
    setShow(true);
    setIsEditMode(true); // Set to edit mode
    setCurrentInsurance(insurance); // Set the selected insurance data
    // Populate form fields with selected insurance data
    setFieldValue("group_name", insurance.group_name || "");
    setFieldValue("authorization_no", insurance.authorization_no || "");
    setFieldValue("date", new Date(insurance.auth_date));
    setFieldValue("state", insurance.state || "");
    setFieldValue("ins_phone", insurance.ins_phone || "");
    setFieldValue("policy_holder_dob", insurance.policy_holder_dob || "");
    setFieldValue("age", insurance.age || "");
  };

  const { values, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        group_name: "Health Net",
        date: new Date(),
        authorization_no: details.authorization_no || "",
        state: "",
        ins_phone: "",
        policy_holder_dob: "",
        age: "",
      },
      onSubmit: async (values, action) => {
        try {
          setSaveLoading(true);
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          const url = isEditMode
            ? `${process.env.REACT_APP_ORIGIN_URL}/update-insurance`
            : `${process.env.REACT_APP_ORIGIN_URL}/add-insurance`;
          const data = {
            patient_id: id,
            group_name: values.group_name,
            member_id: details.medical_no,
            authorization_no: values.authorization_no,
            auth_date: values.date,
            state: values.state,
            ins_phone: values.ins_phone,
            policy_holder_dob: details.date_of_birth,
            age: details.age,
          };
          if (isEditMode) {
            data.insurance_id = currentInsurance.id; // Add insurance ID for editing
          }

          const response = await axios.post(url, data, config);
          toast.success(response.data.message);
          handleClose();
          getInsuranceData();
        } catch (error) {
          console.log(error.message);
          toast.error(error.message);
        } finally {
          setSaveLoading(false);
        }
        action.resetForm();
      },
    });

  const handleGetStates = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/us-states",
        config
      );
      setStates(response.data);
      console.log("response", response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetStates();
    getInsuranceData();
  }, []);
  useEffect(() => {
    if (states.length > 0 && !values.state) {
      setFieldValue("state", states[0].id); // Set the first state value as default
    }
  }, [states, values.state]);
  console.log("data", insuranceData);
  console.log("Insurance Details", details);
  return (
    <div>
      <div className="insurance-heading  d-flex align-items-center ">
        {permissions.insurance_table && <h5>Insurances</h5>}
        {permissions.insurance_add_button && (
          <ul className="ms-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShow}
            >
              Add New
            </button>
          </ul>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="allery-header">
          <Modal.Title>
            {isEditMode ? "Edit Insurance" : "Add Insurance"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="allergy-body">
          <div className="row ">
            <div className="col-6">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div className="form-floating mb-4 ms-1 w-100">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="group_name"
                      placeholder="group_name"
                      value={values.group_name}
                      name="group_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disableFields}
                    />
                    <label for="group_name">
                      Group Name{" "}
                      {/* <span style={{ color: "red", fontSize: "13px" }}>*</span> */}
                    </label>
                    {/* {errors.group_name && touched.group_name ? (
                      <p style={{ color: "red" }}>{errors.group_name}</p>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div className="form-floating mb-4 ms-1 w-100">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="medical_no"
                      placeholder="medical_no"
                      value={details.medical_no}
                      name="medical_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disableFields}
                    />
                    <label htmlFor="medical_no">
                      Member Id{" "}
                      {/* <span style={{ color: "red", fontSize: "13px" }}>*</span> */}
                    </label>
                    {/* {errors.policy_no && touched.policy_no ? (
                      <p style={{ color: "red" }}>{errors.policy_no}</p>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-6">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div className="form-floating mb-4 ms-1 w-100">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="authorization_no"
                      placeholder="authorization_no"
                      value={values.authorization_no}
                      name="authorization_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label for="authorization_no">
                      Authorization No
                      {/* <span style={{ color: "red", fontSize: "13px" }}>*</span> */}
                    </label>
                    {/* {errors.policy && touched.policy ? (
                      <p style={{ color: "red" }}>{errors.policy}</p>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div className="form-floating mb-4 ms-1 w-100">
                    <Calendar
                      value={values.date}
                      onChange={(e) => setFieldValue("date", e.value)}
                      dateFormat="mm/dd/yy"
                      showIcon
                      className="calendar"
                      disabled={disableFields}
                    />

                    {/* {errors.policy_holder && touched.policy_holder ? (
                      <p style={{ color: "red" }}>{errors.policy_holder}</p>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-6">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div className="form-floating mb-4 ms-1 w-100">
                    <select
                      name="state"
                      onChange={(e) => setFieldValue("state", e.target.value)}
                      value={values.state}
                      className="form-select w-100 "
                      id="floatingSelectGrid"
                    >
                      {loading ? (
                        <option>Loading...</option>
                      ) : (
                        states &&
                        states.map((state, index) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))
                      )}
                    </select>
                    <label for="state">State</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div className="form-floating mb-4 ms-1 w-100">
                    <input
                      type="tel"
                      className="form-control w-100"
                      id="ins_phone"
                      placeholder="(555) 555-5555"
                      name="ins_phone"
                      value={values.ins_phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="ins_phone">
                      Phone No{" "}
                      {/* <span style={{ color: "red", fontSize: "13px" }}>*</span> */}
                    </label>
                    {/* {errors.ins_phone && touched.ins_phone ? (
                      <p style={{ color: "red" }}>{errors.ins_phone}</p>
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-6">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div className="form-floating mb-4 ms-1 w-100">
                    <input
                      type="date"
                      className="form-control w-100"
                      id="floatingInputGrid"
                      placeholder="policy_holder_dob"
                      name="policy_holder_dob"
                      value={details.date_of_birth}
                      disabled={disableFields}
                    />
                    <label for="policy_holder_dob">Policy Holder DOB</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="allery-feilds">
                <div className="d-flex align-items-center">
                  <div className="form-floating mb-4 ms-1 w-100">
                    <input
                      type="text"
                      className="form-control w-100"
                      id="floatingInputGrid"
                      placeholder="age"
                      name="age"
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, parseInt(e.target.value))
                      //     .toString()
                      //     .slice(0, 3);
                      // }}
                      value={details.age}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      disabled={disableFields}
                    />
                    <label htmlFor="age">Age</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="allergy-footer-btn">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleSubmit();
              //   handleClose();
            }}
          >
            {saveLoading ? "Processing..." : isEditMode ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      {permissions.insurance_table && (
        <div className="encounter-table table-responsive">
          <table className="table">
            <thead>
              <tr className="mb-2">
                <th>No </th>
                <th>Group Name</th>
                <th>Member Id</th>
                <th>Authorization No</th>
                <th>Date</th>
                <th>State</th>
                <th>Phone No</th>
                <th>Policy Holder DOB</th>
                <th>Age</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="12">
                    <div className="loading-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : insuranceData && insuranceData.length > 0 ? (
                insuranceData.map((insurance, index) => {
                  return (
                    <tr className="mb-2" key={index}>
                      <td>{index + 1}</td>
                      <td>{insurance.group_name && insurance.group_name}</td>
                      <td>{insurance.member_id && insurance.member_id}</td>
                      <td>
                        {insurance.authorization_no &&
                          insurance.authorization_no}
                      </td>
                      <td>
                        {" "}
                        {insurance.auth_date
                          ? moment(insurance.auth_date)
                              .utc()
                              .format("MM/DD/YYYY")
                          : ""}
                      </td>
                      <td>{insurance.name && insurance.name}</td>
                      <td>{insurance.ins_phone && insurance.ins_phone}</td>
                      <td>
                        {insurance.policy_holder_dob
                          ? moment(insurance.policy_holder_dob)
                              .utc()
                              .format("MM/DD/YYYY")
                          : ""}
                      </td>
                      <td>{insurance.age && insurance.age}</td>
                      <td>
                        <i
                          className="fas fa-edit"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleEditClick(insurance)}
                        ></i>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="12" align="center">
                    <img
                      src={NoData}
                      alt="No data found"
                      style={{ width: "150px", margin: "20px 0" }}
                    />
                    <p>No Insurance found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <ToastContainer />
      {/* </div> */}
    </div>
  );
};

export default Insurance;
