import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Modal, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Scheduling = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [appointmentName, setAppointmentName] = useState("");
  const [appointmentJob, setAppointmentJob] = useState("");
  const [selectedMemberName, setSelectedMemberName] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [appointmentEndTime, setAppointmentEndTime] = useState("");
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [operationData, setOperationData] = useState([]);
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [getAssignData, setGetAssignData] = useState([]);

  const handleDateClick = (arg) => {
    setSelectedDate(arg.dateStr);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleScheduleAppointment = async () => {
    const appointmentData = {
      member_id: selectedMemberId,
      name: selectedMemberName,
      description: appointmentJob,
      time: appointmentTime,
      end_time: appointmentEndTime,
      date: selectedDate,
    };

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/store-scheduling",
        appointmentData,
        config
      );
      toast.success(response.data.message);
      // alert("Appointment scheduled successfully");
      handleCloseModal();
      getCalenderData();
    } catch (error) {
      console.error("Error scheduling appointment:", error);
      // alert("Failed to schedule appointment");
    }
  };

  const getUserData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/get-maintenance-operation-team",
        config
      );

      setOperationData(response.data.data.operation_team);
      setMaintenanceData(response.data.data.maintenance_team);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCalenderData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/get-scheduling",
        config
      );

      setGetAssignData(
        response.data.data.map(
          (item) => (
            console.log("item", item),
            {
              id: item.id,
              title: item.title,
              start: `${item.date}T${item.time}`, // Combine date and start time
              end: item.end_time ? `${item.date}T${item.end_time}` : null,

              description: item.description,
              status: item.status,
              color: "#3788d8", // Default color if not provided
            }
          )
        )
      );
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
    setAppointmentName(""); // Reset appointment name when team changes
    setSelectedMemberId("");
    setSelectedMemberName("");
  };
  const handleMemberChange = (e) => {
    const selectedMember = JSON.parse(e.target.value);
    console.log("member", selectedMember);
    setSelectedMemberId(selectedMember.id);
    setSelectedMemberName(selectedMember.name);
  };

  useEffect(() => {
    getUserData();
    getCalenderData();
  }, []);
  const renderEventContent = (eventInfo) => {
    return (
      <div>
        <b>
          {eventInfo.timeText} {eventInfo.event.title}
        </b>

        <div>{`Job:${eventInfo.event.extendedProps.description}`}</div>
        <div>{`Status: ${eventInfo.event.extendedProps.status}`}</div>
      </div>
    );
  };

  console.log("operationDara", operationData);
  console.log("maintenanceData", operationData);
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        dateClick={handleDateClick}
        selectable={true}
        events={getAssignData}
        eventContent={renderEventContent}
      />

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="allery-header">
          <Modal.Title>Schedule Job</Modal.Title>
        </Modal.Header>
        <Modal.Body className="allergy-body">
          <Form>
            <Form.Group controlId="formSelectTeam">
              <Form.Label>Team</Form.Label>
              <Form.Control
                as="select"
                value={selectedTeam}
                onChange={handleTeamChange}
              >
                <option value="">Select a Team</option>
                <option value="operation">Operation Team</option>
                <option value="maintenance">Maintenance Team</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formSelectName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                as="select"
                value={selectedMemberId}
                onChange={handleMemberChange}
              >
                <option value="">
                  {selectedMemberName ? selectedMemberName : "select a name"}
                </option>
                {selectedTeam === "operation" &&
                  operationData.map((member, index) => (
                    <option key={index} value={JSON.stringify(member)}>
                      {member.name}
                    </option>
                  ))}
                {selectedTeam === "maintenance" &&
                  maintenanceData.map((member, index) => (
                    <option key={index} value={JSON.stringify(member)}>
                      {member.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formAppointmentName">
              <Form.Label>Job</Form.Label>
              <Form.Control
                as="textarea"
                rows={3} // Adjust the number of rows as needed
                placeholder="Enter job description"
                value={appointmentJob}
                onChange={(e) => setAppointmentJob(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formAppointmentTime">
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                type="time"
                value={appointmentTime}
                onChange={(e) => setAppointmentTime(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formAppointmentEndTime">
              <Form.Label>End Time</Form.Label>
              <Form.Control
                type="time"
                value={appointmentEndTime}
                onChange={(e) => setAppointmentEndTime(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleScheduleAppointment}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Scheduling;
