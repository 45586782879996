import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk for fetching roles and permissions
export const fetchRolesPermissions = createAsyncThunk(
  "rolesPermissions/fetchRolesPermissions",
  async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    };
    // const response = await axios.get(process.env.REACT_APP_ORIGIN_URL +'/roles-permissions',config);
    // console.log("response",response)
    // return response.data.data;
  }
);

const rolesPermissionsSlice = createSlice({
  name: "rolesPermissions",
  initialState: {
    roles: [],
    permissions: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRolesPermissions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRolesPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = action.payload.roles;
        state.permissions = action.payload.permissions;
      })
      .addCase(fetchRolesPermissions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default rolesPermissionsSlice.reducer;
