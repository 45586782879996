import React, { useState, useEffect } from "react";
import "../PatientProfile.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WoundEncounter = ({
  handleCloseRegisterVitalClick,
  patientId,
  woundData,
  onSignClick,
  triggerSign,
  id,
  prevData,
  state,
}) => {
  const [wounds, setWounds] = useState([
    {
      location: "",
      width: "",
      type: "",
      width_cm: "",
      length_cm: "",
      depth_cm: "",
      area_cm: "",
      exudate_amount: "",
      exudate_type: "",
      granulation_tissue: "",
      fibrous_tissue: "",
      necrotic_tissue: "",
      wound_bed: "",

      undermining: "",
      tunneling: "",
      sinus_tract_cm: "",
      exposed_structure: "",
      periwound_color: "",
      wound_edges: "",
      epithelialization: "",
      pain_level: "",
      odar: "",
      infection: "",
      clinical_signs_of_infection: "",
      wound_duration: "",
      images: [],
    },
  ]);

  const [initialWound, setInitialWound] = useState({
    right_dp: "",
    right_pt: "",
    left_dp: "",
    left_pt: "",
    right_temp: "",
    left_temp: "",
    right_hair: "",
    left_hair: "",
    right_prick: "",
    left_prick: "",
    right_touch: "",
    left_touch: "",
    right_mono: "",
    left_mono: "",
    other_factor: [],
    patient_education: [],
    patient_id: patientId,
    encounter_id: id,
  });

  const addWound = () => {
    setWounds([
      ...wounds,
      {
        location: "",
        width: "",
        type: "",
        width_cm: "",
        length_cm: "",
        depth_cm: "",
        area_cm: "",
        exudate_amount: "",
        exudate_type: "",
        granulation_tissue: "",
        fibrous_tissue: "",
        necrotic_tissue: "",
        wound_bed: "",
        undermining: "",
        tunneling: "",
        sinus_tract_cm: "",
        exposed_structure: "",
        periwound_color: "",
        wound_edges: "",
        epithelialization: "",
        pain_level: "",
        odar: "",
        infection: "",
        clinical_signs_of_infection: "",
        wound_duration: "",

        images: [],
      },
    ]);
  };

  // useEffect(() => {
  //   if (
  //     woundData &&
  //     woundData.some((item) => item.section_slug === "wound_evaluation")
  //   ) {
  //     const woundEvaluation = woundData.find(
  //       (item) => item.section_slug === "wound_evaluation"
  //     );
  //     if (woundEvaluation.wound_details || woundEvaluation.wound) {
  //       setWounds(woundEvaluation.wound_details || []);
  //       setInitialWound(woundEvaluation.wound || initialWound);
  //     } else {
  //       setWounds([
  //         {
  //           location: "",
  //           width: "",
  //           type: "",
  //           width_cm: "",
  //           length_cm: "",
  //           depth_cm: "",
  //           area_cm: "",
  //           exudate_amount: "",
  //           exudate_type: "",
  //           granulation_tissue: "",
  //           fibrous_tissue: "",
  //           necrotic_tissue: "",
  //           wound_bed: "",
  //           undermining: "",
  //           tunneling: "",
  //           sinus_tract_cm: "",
  //           exposed_structure: "",
  //           periwound_color: "",
  //           wound_edges: "",
  //           epithelialization: "",
  //           pain_level: "",
  //           odar:"",
  //           infection: "",
  //           clinical_signs_of_infection: "",
  //           wound_duration: "",
  //           images: [],
  //         },
  //       ]);
  //     }
  //   }
  // }, [woundData]);

  useEffect(() => {
    if (state) {
      const woundEvaluationSection = prevData.find(
        (section) => section.section_slug === "wound_evaluation"
      );

      if (woundEvaluationSection) {
        setWounds(woundEvaluationSection.wound_details || []);
        setInitialWound(woundEvaluationSection.wound || {});
      } else {
        setWounds([
          {
            location: "",
            width: "",
            type: "",
            width_cm: "",
            length_cm: "",
            depth_cm: "",
            area_cm: "",
            exudate_amount: "",
            exudate_type: "",
            granulation_tissue: "",
            fibrous_tissue: "",
            necrotic_tissue: "",
            wound_bed: "",
            undermining: "",
            tunneling: "",
            sinus_tract_cm: "",
            exposed_structure: "",
            periwound_color: "",
            wound_edges: "",
            epithelialization: "",
            pain_level: "",
            odar: "",
            infection: "",
            clinical_signs_of_infection: "",
            wound_duration: "",
            images: [],
          },
        ]);
        setInitialWound({
          right_dp: "",
          right_pt: "",
          left_dp: "",
          left_pt: "",
          right_temp: "",
          left_temp: "",
          right_hair: "",
          left_hair: "",
          right_prick: "",
          left_prick: "",
          right_touch: "",
          left_touch: "",
          right_mono: "",
          left_mono: "",
          other_factor: [],
          patient_education: [],
          patient_id: patientId,
          encounter_id: id,
        });
      }
    } else if (
      woundData &&
      woundData.some((item) => item.section_slug === "wound_evaluation")
    ) {
      const woundEvaluation = woundData.find(
        (item) => item.section_slug === "wound_evaluation"
      );
      if (woundEvaluation.wound_details || woundEvaluation.wound) {
        setWounds(woundEvaluation.wound_details || []);
        setInitialWound(woundEvaluation.wound || initialWound);
      }
    }
  }, [state, prevData, woundData, patientId, id]);

  const handleWoundChange = (index, field, value) => {
    const newWounds = [...wounds];
    if (field === "clinical_signs_of_infection") {
      const previousSelections = newWounds[index][field] || [];
      newWounds[index][field] = [...new Set([...previousSelections, ...value])]; // Merge and avoid duplicates
    } else {
      newWounds[index][field] = value;
      if (field === "width_cm" || field === "length_cm") {
        const width = parseFloat(newWounds[index].width_cm) || 0;
        const length = parseFloat(newWounds[index].length_cm) || 0;
        newWounds[index].area_cm = width * length;
      }
    }
    setWounds(newWounds);
  };

  const removeWound = (index) => {
    const newWounds = [...wounds];
    newWounds.splice(index, 1);

    setWounds(newWounds);
  };

  const handleImageChange = async (index, files) => {
    const newWounds = [...wounds];
    newWounds[index].images = await Promise.all(
      Array.from(files).map(fileToBase64)
    );
    setWounds(newWounds);
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();

      // Ensure `other_factor` and `patient_education` are arrays in the payload
      formData.append(
        "other_factor",
        JSON.stringify(
          Array.isArray(initialWound.other_factor)
            ? initialWound.other_factor
            : [initialWound.other_factor || ""]
        )
      );
      formData.append(
        "patient_education",
        JSON.stringify(
          Array.isArray(initialWound.patient_education)
            ? initialWound.patient_education
            : [initialWound.patient_education || ""]
        )
      );
      formData.append("patient_id", patientId);
      formData.append("encounter_id", id);
      Object.keys(initialWound && initialWound).forEach((key) => {
        if (key !== "other_factor" && key !== "patient_education") {
          if (Array.isArray(initialWound[key])) {
            formData.append(key, JSON.stringify(initialWound[key])); // Convert array to JSON string
          } else {
            formData.append(key, initialWound[key]);
          }
        }
      });

      wounds.forEach((wound, index) => {
        Object.keys(wound).forEach((key) => {
          if (key !== "images") {
            // Convert clinical_signs_of_infection array to JSON string
            if (key === "clinical_signs_of_infection") {
              formData.append(
                `wounds[${index}][${key}]`,
                JSON.stringify(wound[key])
              );
            } else {
              formData.append(`wounds[${index}][${key}]`, wound[key]);
            }
          }
        });
        (wound.images || []).forEach((image, imgIndex) => {
          formData.append(`wounds[${index}][images][${imgIndex}]`, image);
        });
      });

      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN_URL}/store-wound`,
        formData,
        config
      );

      toast.success(response.data.message);
      setWounds([]);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, options } = e.target;

    if (options) {
      // Check if options is defined
      const selectedValues = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      // Update state with previous selections and new selections
      setInitialWound((prev) => ({
        ...prev,
        [name]: [...new Set([...(prev[name] || []), ...selectedValues])],
      }));
    } else {
      const { value } = e.target;
      setInitialWound((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  console.log("Current state:", prevData);

  console.log("patientId", patientId);
  console.log("encounterId", id);

  const displayImage = (image) => {
    return <img src={image} alt="Wound" className="wound-image" />;
  };

  console.log("woundData", woundData);
  return (
    <div>
      <div className="overflow-auto h-100">
        <div className="vital-heading ">
          <div className="d-flex align-items-center">
            {/* <h5>Wound Encounter</h5> */}

            <button
              type="button"
              class="btn btn-primary ms-auto"
              onClick={addWound}
            >
              Add Wound
            </button>
          </div>
        </div>

        {wounds.map((wound, index) => (
          <div
            className="vital-forms-bg vital-bg-two-form mt-2 wound-class-bg mt-2"
            key={index}
          >
            <div className="vital-cards-form d-flex wound-height  justify-content-end align-items-center">
              <h5>Progress Notes</h5>
              <button
                type="button"
                className="btn ms-auto text-center visit-btn-wound"
                onClick={() => removeWound(index)}
              >
                <FontAwesomeIcon
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "1px 3px 1px 3px",
                    backgroundColor: "#ba2748",
                    color: "#fff",
                  }}
                  icon={faTimes}
                  className="fa-visit-icon "
                />
              </button>
            </div>

            <div className="row g-3 mt-3">
              <div className="col-4">
                <table>
                  <tr>
                    <th>Location</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            // placeholder="Location"
                            value={wound.location}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "location",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Wound Type</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div></div>
                        <div>
                          <input
                            type="text"
                            className=""
                            value={wound.type}
                            onChange={(e) =>
                              handleWoundChange(index, "type", e.target.value)
                            }
                          />
                        </div>
                      </div>{" "}
                    </td>
                  </tr>

                  <tr>
                    <th>Status</th>
                    <td>
                      <div className="vital-cards-form d-flex wound-height  align-items-center">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className="visti-input-feilds ms-auto"
                            value={wound.status}
                            onChange={(e) =>
                              handleWoundChange(index, "status", e.target.value)
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.status}
                            onChange={(e) =>
                              handleWoundChange(index, "status", e.target.value)
                            }
                          >
                            <option value="">select</option>
                            <option value="Initial Evaluation">
                              Initial Evaluation
                            </option>
                            <option value="Healed">Healed</option>
                            <option value="Deteriorating">Deteriorating</option>
                            <option value="Improving">Improving</option>
                            <option value="Increased in size">
                              Increased in size
                            </option>
                            <option value="Stalled">Stalled</option>
                            <option value="Infected">Infected</option>
                            <option value="Unchanged">Unchanged</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Stage</th>
                    <td>
                      <div className="vital-cards-form d-flex wound-height  align-items-center">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className=""
                            value={wound.stage}
                            onChange={(e) =>
                              handleWoundChange(index, "stage", e.target.value)
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.stage}
                            onChange={(e) =>
                              handleWoundChange(index, "stage", e.target.value)
                            }
                          >
                            <option value="">select</option>
                            <option value="i">i</option>
                            <option value="ii">ii</option>
                            <option value="iii">iii</option>
                            <option value="iv">iv</option>
                            <option value="Deep Tissue Injury">
                              Deep Tissue Injury
                            </option>
                            <option value="Unstageable">Unstageable</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div className="col-4">
                <table>
                  <tr>
                    <th>Width (cm)</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height  align-items-center">
                        <div>
                          <input
                            type="number"
                            className=""
                            value={wound.width_cm}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "width_cm",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Length (cm)</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          <h5></h5>
                        </div>
                        <div>
                          <input
                            type="number"
                            className=""
                            value={wound.length_cm}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "length_cm",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Depth (cm)</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            value={wound.depth_cm}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "depth_cm",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Area (cm)</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            value={wound.area_cm}
                            // onChange={(e) =>
                            //   handleWoundChange(index, "area_cm", e.target.value)
                            // }
                            readOnly
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-4">
                <table className="image-wound-width">
                  <tr>
                    <th className="">Image Upload</th>
                    <td>
                      <div className="vital-cards-form ">
                        <div>
                          <input
                            type="file"
                            className=""
                            multiple
                            onChange={(e) =>
                              handleImageChange(index, e.target.files)
                            }
                          />
                        </div>
                        {/* {wound.images &&
                          wound.images.map((image, imgIndex) => (
                            <div key={imgIndex}>
                              <img
                                src={image}
                                alt={`Wound ${index + 1} Image ${imgIndex + 1}`}
                                width={70}
                                height={70}
                              />
                            </div>
                          ))} */}
                        {Array.isArray(wound.images) &&
                        wound.images.length > 0 ? (
                          wound.images.map((image, imgIndex) => (
                            <div key={imgIndex}>
                              <img
                                src={image}
                                alt={`Wound ${index + 1} Image ${imgIndex + 1}`}
                                width={70}
                                height={70}
                              />
                            </div>
                          ))
                        ) : (
                          <p>No images available</p>
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <h3 className="mb-3 mt-3">Wound Evaluation</h3>
            <div className="row g-3">
              <div className="col-4">
                <table>
                  <tr>
                    <th>Exudate Amount</th>
                    <td>
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className=""
                            // placeholder="exudate_amount"
                            value={wound.exudate_amount}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "exudate_amount",
                                e.target.value
                              )
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.exudate_amount}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "exudate_amount",
                                e.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            <option value="None">None</option>
                            <option value="Minimum">Minimum</option>
                            <option value="Moderate">Moderate</option>
                            <option value="Heavy">Heavy</option>
                            <option value="Copious">Copious</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Exudate Type</th>
                    <td>
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className=""
                            // placeholder="exudate_type"
                            value={wound.exudate_type}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "exudate_type",
                                e.target.value
                              )
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.exudate_type}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "exudate_type",
                                e.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            <option value="None">None</option>
                            <option value="Serious">Serious</option>
                            <option value="Serosanguineous">
                              Serosanguineous
                            </option>
                            <option value="Sanguineous">Sanguineous</option>
                            <option value="Purulent">Purulent</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Granulation Tissue</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height  align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            // placeholder="granulation_tissue"
                            value={wound.granulation_tissue}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "granulation_tissue",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Fibrous Tissue</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            // placeholder="fibrous_tissue"
                            value={wound.fibrous_tissue}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "fibrous_tissue",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Necrotic Tissue</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            // placeholder="necrotic_tissue"
                            value={wound.necrotic_tissue}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "necrotic_tissue",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Wound Bed</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className=""
                            // placeholder="wound_bed"
                            value={wound.wound_bed}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "wound_bed",
                                e.target.value
                              )
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.wound_bed}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "wound_bed",
                                e.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            <option value="None">None</option>
                            <option value="Partial Thickness">
                              Partial Thickness
                            </option>
                            <option value="Full Thickness">
                              Full Thickness
                            </option>
                            <option value="Granulation Tissue">
                              Granulation Tissue
                            </option>
                            <option value="Hypergranulation Tissue">
                              Hypergranulation Tissue
                            </option>
                            <option value="Slough">Slough</option>
                            <option value="Eschar">Eschar</option>
                            <option value="Healed">Healed</option>
                            <option value="Scar">Scar</option>
                            <option value="Dry Scab">Dry Scab</option>
                            <option value="Unassessable">Unassessable</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Clinical Signs Of Infection</th>
                    <td rowSpan={4}>
                      <div className="vital-cards-form d-flex wound-height   align-items-center ">
                        <h5></h5>
                        <div className="w-100">
                          <select
                            name="clinical_signs_of_infection"
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "clinical_signs_of_infection",
                                Array.from(
                                  e.target.selectedOptions,
                                  (option) => option.value
                                )
                              )
                            }
                            value={wound.clinical_signs_of_infection || []}
                            className="form-select"
                            id="clinical_signs_of_infection"
                            // style={{width:"200px"}}
                          >
                            <option value="">
                              {wound.clinical_signs_of_infection
                                ? wound.clinical_signs_of_infection
                                : "Select"}
                            </option>
                            <option value="Erythema">Erythema</option>
                            <option value="Swelling">Swelling</option>
                            <option value="Warmth">Warmth</option>
                            <option value="Purulent Drainage">
                              Purulent Drainage
                            </option>
                            <option value="Malodor">Malodor</option>
                            <option value="Increased in size">
                              Increased in size
                            </option>
                            <option value="Delayed Healing">
                              Delayed Healing{" "}
                            </option>
                            <option value="Change in wound appearance">
                              Change in wound appearance{" "}
                            </option>
                          </select>
                          {/* <input
                        type="text"
                        className=""
                        value={wound.clinical_signs_of_infection}
                        onChange={(e) =>
                          handleWoundChange(index, "clinical_signs_of_infection", e.target.value)
                        }
                      /> */}
                        </div>
                      </div>
                      {Array.isArray(wound.clinical_signs_of_infection) && (
                        <div className="mt-2">
                          <p>
                            {" "}
                            <span className="fw-bold">Selected:</span>{" "}
                            {wound.clinical_signs_of_infection.join(", ")}
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                </table>
              </div>

              <div className="col-4">
                <table>
                  <tr>
                    <th>Undermining</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height  align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            // placeholder="undermining"
                            value={wound.undermining}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "undermining",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Tunneling</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <h5></h5>

                        <div>
                          <input
                            type="text"
                            className=""
                            // placeholder="tunneling"
                            value={wound.tunneling}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "tunneling",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Sinus Tract (cm)</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            // placeholder="sinus_tract_cm"
                            value={wound.sinus_tract_cm}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "sinus_tract_cm",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Exposed Structure</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className=""
                            value={wound.exposed_structure}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "exposed_structure",
                                e.target.value
                              )
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.exposed_structure}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "exposed_structure",
                                e.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            <option value="None">None</option>
                            <option value="Muscle">Muscle</option>
                            <option value="Bone">Bone</option>
                            <option value="Tendon">Tendon</option>
                            <option value="Ligament">Ligament</option>
                            <option value="Fistula">Fistula</option>
                            <option value="Mesh">Mesh</option>
                            <option value="Hardware">Hardware</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Periwound Color</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height  align-items-center">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className=""
                            value={wound.periwound_color}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "periwound_color",
                                e.target.value
                              )
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.periwound_color}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "periwound_color",
                                e.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            <option value="Normal ethnic">Normal ethnic</option>
                            <option value="Hyperpigmentation">
                              Hyperpigmentation
                            </option>
                            <option value="Hypopigmentation">
                              Hypopigmentation
                            </option>
                            <option value="Erythematous">Erythematous</option>
                            <option value="Scar">Scar</option>
                            <option value="Ecchymosis">Ecchymosis</option>
                            <option value="Pink Hue">Pink Hue</option>
                            <option value="Calloused">Calloused</option>
                            <option value="Macerated">Macerated</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Wound Edges</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height  align-items-center">
                        <div className="w-100">
                          {/* <input
                            type="text"
                            className=""
                            value={wound.wound_edges}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "wound_edges",
                                e.target.value
                              )
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.wound_edges}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "wound_edges",
                                e.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            <option value="Non Visible">Non Visible</option>
                            <option value="Attached">Attached</option>
                            <option value="Unattached">Unattached</option>
                            <option value="Rolled under">Rolled under</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div className="col-4">
                <table>
                  <tr>
                    <th>epithelialization</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          {/* <input
                            type="text"
                            className=""
                            value={wound.epithelialization}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "epithelialization",
                                e.target.value
                              )
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.epithelialization}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "epithelialization",
                                e.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            <option value="Surface intact">
                              Surface intact
                            </option>
                            <option value="<25%">{`< 25%`}</option>
                            <option value="25-50%">25-50%</option>
                            <option value="51-75%">51-75%</option>
                            <option value="75-99%">75-99%</option>
                            <option value="100% Re-epithelialization">
                              100% Re-epithelialization
                            </option>
                            <option value="Partial Thickness Loss">
                              Partial Thickness Loss
                            </option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Pain Level</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            value={wound.pain_level}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "pain_level",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Odor</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <div>
                          {/* <input
                            type="text"
                            className=""
                            value={wound.pain_level}
                            onChange={(e) =>
                              handleWoundChange(index, "odor", e.target.value)
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.odor}
                            onChange={(e) =>
                              handleWoundChange(index, "odor", e.target.value)
                            }
                          >
                            <option value="">select</option>
                            <option value="None">None</option>
                            <option value="Foul">Foul</option>
                            <option value="Malodorous">Malodorous</option>
                            <option value="Mild">Mild</option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Infection</th>
                    <td>
                      {" "}
                      <div className="vital-cards-form d-flex wound-height   align-items-center">
                        <h5></h5>
                        <div>
                          {/* <input
                            type="text"
                            className=""
                            value={wound.infection}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "infection",
                                e.target.value
                              )
                            }
                          /> */}
                          <select
                            className="form-select"
                            value={wound.infection}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "infection",
                                e.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Rule out infection">
                              Rule out infection
                            </option>
                            <option value="Suspected infection">
                              Suspected infection
                            </option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>Wound Duration</th>
                    <td>
                      <div className="vital-cards-form d-flex wound-height  align-items-center">
                        <div>
                          <input
                            type="text"
                            className=""
                            value={wound.wound_duration}
                            onChange={(e) =>
                              handleWoundChange(
                                index,
                                "wound_duration",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        ))}
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <div className="row  my-4">
            <div className="col-6">
              <div className="heading-visit-area text-center ">
                <h5 style={{ fontWeight: "700" }}>Vascular</h5>
              </div>
            </div>

            <div className="col-6">
              <div className="heading-visit-area text-center ">
                <h5 style={{ fontWeight: "700" }}>Neurology</h5>
              </div>
            </div>
          </div>

          <div className="row g-3 wound-class-bg ">
            <div className="col-3">
              <div style={{}}>
                <h5 style={{ fontWeight: "700" }}>Pulses</h5>
              </div>

              <table>
                <tr>
                  <th>Right Dp</th>
                  <td>
                    <div className="vital-cards-form d-flex wound-height align-items-center">
                      <div>
                        <input
                          className=" shadow-sm"
                          type="text"
                          id="right_dp"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          name="right_dp"
                          value={initialWound.right_dp}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Right Pt</th>
                  <td>
                    {" "}
                    <div className="vital-cards-form wound-height d-flex mt-2 align-items-center">
                      <div>
                        <input
                          className=" shadow-sm"
                          type="text"
                          id="right_pt"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          // placeholder="right dp"
                          name="right_pt"
                          value={initialWound.right_pt}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>left Dp</th>
                  <td>
                    {" "}
                    <div className="vital-cards-form wound-height d-flex align-items-center ">
                      <div>
                        <input
                          className=" shadow-sm"
                          type="text"
                          id="left_dp"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          // placeholder="right dp"
                          name="left_dp"
                          value={initialWound.left_dp}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>left Dp </th>
                  <td>
                    {" "}
                    <div className="vital-cards-form wound-height d-flex  align-items-center">
                      <div>
                        <input
                          className=" shadow-sm"
                          type="text"
                          id="left_pt"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          // placeholder="right dp"
                          name="left_pt"
                          value={initialWound.left_pt}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <div className="col-3">
              <div style={{}}>
                <h5 style={{ fontWeight: "700" }}>Skin Temperature</h5>
              </div>
              <table>
                <tr>
                  <th>Right</th>
                  <td>
                    <div className="vital-cards-form d-flex wound-height  align-items-center">
                      <div>
                        <input
                          className=""
                          type="text"
                          id="right_temp"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          // placeholder="right temp"
                          name="right_temp"
                          value={initialWound.right_temp}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <label htmlFor="right_temp">Right </label> */}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Left</th>
                  <td>
                    <div className="vital-cards-form  d-flex wound-height  align-items-center">
                      <div>
                        <input
                          type="text"
                          className="shadow-sm"
                          id="left_temp"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          // placeholder="right pt"
                          name="left_temp"
                          value={initialWound.left_temp}
                          onChange={handleChange}
                        />
                      </div>
                      {/* <label htmlFor="left_temp">Left</label> */}
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <div className="col-3">
              <div style={{}}>
                <h5 style={{ fontWeight: "700" }}>Pin Prick</h5>
              </div>

              <table>
                <tr>
                  <th>Right</th>
                  <td>
                    {" "}
                    <div className="vital-cards-form d-flex wound-height align-items-center">
                      <div>
                        <input
                          className=""
                          type="text"
                          id="right_prick"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          // // placeholder="right_prick"
                          name="right_prick"
                          value={initialWound.right_prick}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Left</th>
                  <td>
                    {" "}
                    <div className="vital-cards-form d-flex wound-height   align-items-center">
                      <div>
                        <input
                          type="text"
                          className=""
                          id="left_prick"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          // placeholder="left_prick"
                          name="left_prick"
                          value={initialWound.left_prick}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <div className="col-3">
              <div style={{}}>
                <h5 style={{ fontWeight: "700" }}>Monofilament</h5>
              </div>

              <table>
                <tr>
                  <th>Right</th>
                  <td>
                    {" "}
                    <div className="vital-cards-form d-flex wound-height   align-items-center ">
                      <div>
                        <input
                          className=""
                          type="text"
                          id="right_mono"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          name="right_mono"
                          value={initialWound.right_mono}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Left</th>
                  <td>
                    {" "}
                    <div className="vital-cards-form d-flex wound-height  align-items-center ">
                      <div className="w-100">
                        <input
                          type="text"
                          className=""
                          id="left_mono"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #000",
                            background: "transparent",
                          }}
                          name="left_mono"
                          value={initialWound.left_mono}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div className="col-6 wound-b-n">
            <table>
              <tr>
                <th>Other Related Factors</th>
                <td>
                  <div className="vital-cards-form wound-height">
                    <div>
                      <select
                        name="other_factor"
                        // multiple
                        value={initialWound.other_factor || []}
                        onChange={handleChange}
                        className="form-select w-100"
                        id="other_factor"
                      >
                        <option value="">
                          {initialWound.other_factor
                            ? initialWound.other_factor
                            : "Select"}
                        </option>
                        <option value="Age">Age</option>
                        <option value="Nutrition">Nutrition</option>
                        <option value="Skin Moisture">Skin Moisture</option>
                        <option value="Limited Mobility">
                          Limited Mobility
                        </option>
                        <option value="Incontinence">Incontinence</option>
                        <option value="Vascular Insufficiency">
                          Vascular Insufficiency
                        </option>
                        <option value="Arterial Insufficiency">
                          Arterial Insufficiency
                        </option>
                        <option value="Non-compliance">Non-compliance</option>
                        <option value="Diabetes">Diabetes</option>
                        <option value="Nicotine Dependence">
                          Nicotine Dependence
                        </option>
                      </select>
                      <div className="mt-2">
                        <span className="fw-bold">Selected:</span>{" "}
                        {Array.isArray(initialWound.other_factor)
                          ? initialWound.other_factor.join(", ")
                          : "None"}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <th>Patient Education</th>
                <td>
                  <div className="vital-cards-form d-flex wound-height align-items-center">
                    <div className="w-100">
                      <select
                        name="patient_education"
                        // multiple
                        value={initialWound.patient_education || []}
                        onChange={handleChange}
                        className="form-select w-100"
                        id="patient_education"
                      >
                        <option value="">
                          {initialWound.patient_education
                            ? initialWound.patient_education
                            : "Select"}
                        </option>
                        <option value="Signs and Symptoms of Infection">
                          Signs and Symptoms of Infection
                        </option>
                        <option value="Increase Protein Intake">
                          Increase Protein Intake
                        </option>
                        <option value="Diabetic Foot Care">
                          Diabetic Foot Care
                        </option>
                        <option value="Dressing Change & Frequency">
                          Dressing Change & Frequency
                        </option>
                        <option value="Glucose Control">Glucose Control</option>
                        <option value="Smoking Cessation">
                          Smoking Cessation
                        </option>
                        <option value="Pad and Protect Bony Prominence">
                          Pad and Protect Bony Prominence
                        </option>
                        <option value="Prevent Friction/Shear">
                          Prevent Friction/Shear
                        </option>
                        <option value="Incontinence Care">
                          Incontinence Care
                        </option>
                        <option value="Offloading">Offloading</option>
                        <option value="Turning and Repositioning">
                          Turning and Repositioning
                        </option>
                      </select>
                      <div className="mt-2">
                        <span className="fw-bold">Selected:</span>{" "}
                        {Array.isArray(initialWound.patient_education)
                          ? initialWound.patient_education.join(", ")
                          : "None"}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default WoundEncounter;
