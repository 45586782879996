import React, { useState, useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../PatientProfile.css";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import AdmissionForm from "./AdmissionForm";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DischargeForm from "./DischargeForm";
import { useSelector } from "react-redux";
import NoData from "../../../../assets/images/no-data-found.png";

const Admission = ({ id }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [showAdmissionForm, setShowAdmissionForm] = useState(false);
  const [admissions, setAdmissions] = useState([]);
  const [disableDischargeForm, setDisableDischargeForm] = useState(false);
  const [disableAdmissionForm, setDisableAdmissionForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAdmission, setSelectedAdmission] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [dischargePatients, setDischargePatients] = useState([]);
  const [loadingDischarge, setLoadingDischarge] = useState(false);
  const [ShowDischargeForm, setShowDischargeForm] = useState(false);
  const [dischargeDetails, setDischageDetails] = useState(null);

  const getAdmissions = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-admission-discharge/${id}`,
        config
      );
      setAdmissions(res.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAdmission = () => {
    setShowAdmissionForm(false);
    getAdmissions();
  };
  const handleDischargePatient = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/discharge-patient/${id}`,
        config
      );
      handleGetDischargePatient();
      getAdmissions();
      toast.success(response.data.message);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const handleGetDischargePatient = async () => {
    try {
      setLoadingDischarge(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-discharged-patients/${id}`,
        config
      );
      setDischargePatients(response.data.data);
      toast.success(response.data.message);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setLoadingDischarge(false);
    }
  };

  const handleDischargeClick = (patient) => {
    setShowDischargeForm(true);
    setDischageDetails(patient);
  };

  const handleDischargeFormClose = () => {
    setShowDischargeForm(false);
    getAdmissions();
  };
  useEffect(() => {
    getAdmissions();
    handleGetDischargePatient();
  }, []);

  console.log("getadmissions", admissions);
  return (
    <>
      {showAdmissionForm ? (
        <AdmissionForm
          id={id}
          disableDischargeForm={disableDischargeForm}
          handleCloseAdmission={handleCloseAdmission}
          disableAdmissionForm={disableAdmissionForm}
          selectedAdmission={selectedAdmission}
        />
      ) : ShowDischargeForm ? (
        <DischargeForm
          details={dischargeDetails}
          handleDischargeFormClose={handleDischargeFormClose}
        />
      ) : (
        <div className=" h-100">
          <div className="overflow-auto h-100">
            <div className="vital-heading">
              <div className="d-flex align-items-center">
                {permissions.admission_table && (
                  <h5>Admission/Discharge Information</h5>
                )}
                <div className="ms-auto">
                  {admissions &&
                  admissions.length > 0 &&
                  admissions[0].registered_type === true ? (
                    <>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        variant="secondary"
                        disabled={btnDisabled}
                      >
                        Registered
                      </Button>
                    </>
                  ) : (
                    <>
                      {permissions.admission_add_button && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            setShowAdmissionForm(true);
                            setDisableDischargeForm(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ paddingRight: "5px" }}
                          />{" "}
                          Admission
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {permissions.admission_table && (
              <div className="encounter-table">
                <table className="table">
                  <thead>
                    <tr className="mb-2">
                      <th>Admission Date</th>
                      <th>Floor</th>
                      <th>Room</th>
                      <th>Bed</th>
                      {/* <th>Type</th> */}
                      {/* <th>Facility</th> */}
                      {/* <th>New Admission</th> */}
                      <th>Discharge Date</th>
                      {permissions.admission_action_col && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="12">
                          <div className="loading-spinner">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : admissions && admissions.length > 0 ? (
                      admissions.map((admission) => {
                        return (
                          <tr key={admission.id}>
                            <td>
                              {admission.admission_date
                                ? moment(admission.admission_date)
                                    .utc()
                                    .format("MM/DD/YYYY")
                                : ""}
                            </td>
                            <td>{admission.floor_name}</td>
                            <td>{admission.room_name}</td>
                            <td>{admission.bed_name}</td>
                            {/* <td>{admission.new_admission}</td> */}
                            <td>
                              {admission.discharge_date
                                ? moment(admission.discharge_date)
                                    .utc()
                                    .format("MM/DD/YYYY hh:mm:ss A")
                                : ""}
                            </td>
                            {permissions.admission_action_col && (
                              <td>
                                <ul className="d-flex admission-icons">
                                  {/* <li>
                                <a href="#">
                                  <i className="fas fa-edit"></i>
                                </a>
                              </li> */}
                                  <li>
                                    {/* <button    type="button"  className="btn btn-discharge" onClick={() => {setShowAdmissionForm(true);setDisableDischargeForm(false);setDisableAdmissionForm(true); setSelectedAdmission(admission);}}>Discharge</button> */}
                                    <button
                                      type="button"
                                      className="btn btn-discharge"
                                      onClick={() =>
                                        handleDischargeClick(admission)
                                      }
                                    >
                                      Discharge
                                    </button>
                                  </li>
                                  {/* <li>
                                <a href="#">
                                  <i className="fas fa-eject"></i>
                                </a>
                              </li> */}
                                  {/* <li>
                                <a href="#">
                                  <i className="fas fa-trash-alt"></i>
                                </a>
                              </li> */}
                                </ul>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="12" align="center">
                          <img
                            src={NoData}
                            alt="No data found"
                            style={{ width: "150px", margin: "20px 0" }}
                          />
                          <p>No Admission found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {permissions.admission_history_table && (
              <>
                {" "}
                <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                  <h5>Admission History</h5>
                </div>
                <div className="encounter-table">
                  <table className="table">
                    <thead>
                      <tr className="mb-2">
                        <th>Admission Date</th>
                        <th>Floor</th>
                        <th>Room</th>
                        <th>Bed</th>
                        {/* <th>Type</th> */}
                        {/* <th>Facility</th> */}
                        {/* <th>New Admission</th> */}
                        <th>Discharge Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingDischarge ? (
                        <tr>
                          <td colSpan="12">
                            <div className="loading-spinner">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : dischargePatients && dischargePatients.length > 0 ? (
                        dischargePatients.map((admission) => {
                          return (
                            <tr key={admission.id}>
                              {console.log("discharge", admission)}
                              <td>
                                {admission.admission_date
                                  ? moment(admission.admission_date)
                                      .utc()
                                      .format("MM/DD/YYYY hh:mm:ss A")
                                  : ""}
                              </td>
                              <td>{admission.floor_name}</td>
                              <td>{admission.room_name}</td>
                              <td>{admission.bed_name}</td>
                              {/* <td>{admission.new_admission}</td> */}
                              <td>
                                {admission.discharge_date
                                  ? moment(admission.discharge_date)
                                      .utc()
                                      .format("MM/DD/YYYY hh:mm:ss A")
                                  : ""}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12" align="center">
                            <img
                              src={NoData}
                              alt="No data found"
                              style={{ width: "150px", margin: "20px 0" }}
                            />
                            <p>No Admission history found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Admission;
