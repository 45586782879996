import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
/* Import PrimeReact CSS files if not already included */
// @import 'primereact/resources/themes/saga-blue/theme.css'; /* Replace with your theme */
import "primereact/resources/primereact.min.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// import 'primeicons/primeicons.css';
import React, { useEffect } from "react";
import LoginForm from "./pages/login/LoginPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PatientList from "./pages/patient/PatientList";
import PatientDetail from "./pages/patient detail/detail/PatientDetail";
import RegistrationForm from "./pages/patient/registration/Registration";
import Settings from "./pages/patient detail/setting/Settings";
import VisitReason from "./pages/patient detail/profile/encounters/VisitReason";
import { PrimeReactProvider } from "primereact/api";
import { useDispatch } from "react-redux";
import { fetchRolesPermissions } from "./components/redux/ACL/ACLSlice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRolesPermissions());
  }, [dispatch]);

  return (
    <div className="">
      <PrimeReactProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/patient_list" element={<PatientList />} />
            <Route path="/patient_detail" element={<PatientDetail />} />
            <Route path="/registration_form" element={<RegistrationForm />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/visit_reason" element={<VisitReason />} />
          </Routes>
        </BrowserRouter>
      </PrimeReactProvider>
    </div>
  );
}

export default App;
