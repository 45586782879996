import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../profile/PatientProfile.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "react-bootstrap/Toast";
import { Modal, Button, Form } from "react-bootstrap";
import { faTimes, faEdit, faEject } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Preview() {
  const [floors, setFloors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showRoomModal, setShowRoomModal] = useState(false);
  const [roomsAndBeds, setRoomsAndBeds] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [showBedModal, setShowBedModel] = useState(false);
  const [activeFloor, setActiveFloor] = useState(null);
  const [newRoomName, setNewRoomName] = useState("");
  const [showAddRoomDialog, setShowAddRoomDialog] = useState(false);
  const [showAddRoomModal, setShowAddRoomModal] = useState(false);
  const [modalInputValue, setModalInputValue] = useState("");
  const [editRoomId, setEditRoomId] = useState(null);
  const [editBedId, setEditBedId] = useState(null);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(true);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [floorName, setFloorName] = useState("");
  const [roomName, setRoomName] = useState("");
  const toggleShowA = () => setShowA(true);
  const toggleCloseA = () => setShowA(false);
  const toggleShowB = () => setShowB(!showB);
  const handleCloseBedModal = () => setShowBedModel(false);

  const handleCloseRoomModal = () => {
    setShowAddRoomModal(false);
    setEditRoomId(null);
    setModalInputValue("");
  };

  const handleEditClick = (floor) => {
    console.log("editFloor", floor);
    setSelectedFloor(floor);
    setFloorName(floor.floor_name);
    setShowModal(true);
  };

  const handleRoomEditClick = (room) => {
    console.log("editRoom", room);
    setSelectedRoom(room);
    setRoomName(room.room_name);
    setShowRoomModal(true);
  };

  const handleSave = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/edit_floor",
        { id: selectedFloor.id, floor_name: floorName },
        config
      );
      if (response.status === 200) {
        // Update the floor name in the UI
        const updatedFloors = floors.map((floor) =>
          floor.id === selectedFloor.id
            ? { ...floor, floor_name: floorName }
            : floor
        );
        // Assuming you have a method to update floors state
        // setFloors(updatedFloors);

        setShowModal(false); // Close the modal
      }
      getFloors();
    } catch (error) {
      console.error("Failed to update floor name:", error);
      // Handle error (show error message, etc.)
    }
  };
  const getFloors = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/floors",
        config
      );
      setFloors(response.data.data);
      setActiveFloor(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getRooms = async (floorId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/rooms-beds/${floorId}`,
        config
      );
      setRooms(res.data.data.floor);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getRoomsAndBeds = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/map-rooms-beds/${id}`,
        config
      );
      setRoomsAndBeds(response.data.data.floor);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handleDeleteRoom = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/delete-room/${id}`,
        config
      );
      toast.success(response.data.message);
      if (activeFloor) {
        getRooms(activeFloor.id);
        getRoomsAndBeds(activeFloor.id); // Reload the rooms and beds
      }
    } catch (error) {
      console.log("error", error.message);
      toast.error(error.message);
    }
  };

  const handleDeleteBed = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/delete-bed/${id}`,
        config
      );
      toast.success(response.data.message);
      if (activeFloor) {
        getRooms(activeFloor.id);
        getRoomsAndBeds(activeFloor.id); // Reload the rooms and beds
      }
    } catch (error) {
      console.log("error", error.message);
      toast.error(error.message);
    }
  };

  const handleFloorClick = (floor) => {
    setActiveFloor(floor);
    getRooms(floor.id);
    getRoomsAndBeds(floor.id);
  };

  useEffect(() => {
    getFloors();
  }, []);

  useEffect(() => {
    if (activeFloor) {
      getRooms(activeFloor.id);
      getRoomsAndBeds(activeFloor.id);
    }
  }, [activeFloor]);

  const handleAddRoomSubmit = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const newRoomData = {
        floor_id: activeFloor.id,
        room_title: newRoomName,
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + "/add-new-room",
        newRoomData,
        config
      );
      // Refresh the rooms list
      getRooms(activeFloor.id);
      getRoomsAndBeds(activeFloor.id);
      setNewRoomName("");
      setShowAddRoomDialog(false); // Close the dialog after adding the room
      toast.success(response.data.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAddBed = async (roomId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const newBedData = {
        room_id: roomId,
        bed_title: modalInputValue,
      };
      // Assuming you have an API endpoint to add a bed to a specific room
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/add-new-bed`,
        newBedData,
        config
      );
      // Refresh the rooms list after adding the bed
      getRooms(activeFloor.id);
      getRoomsAndBeds(activeFloor.id);
      handleCloseBedModal();
      toast.success(response.data.message);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };
  const handleEditBed = async (roomId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const editBedData = {
        room_id: roomId,
        bed_id: editBedId,
        bed_title: modalInputValue,
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/update-beds`,
        editBedData,
        config
      );
      // Refresh the rooms list after editing the bed
      getRooms(activeFloor.id);
      getRoomsAndBeds(activeFloor.id);
      handleCloseBedModal();
      toast.success(response.data.message);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };
  const handleEditRoom = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const editRoomData = {
        room_id: selectedRoom.id,
        room_title: roomName,
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/update-room`,
        editRoomData,
        config
      );
      // Refresh the rooms list after editing the room
      getRooms(activeFloor.id);
      getRoomsAndBeds(activeFloor.id);
      setShowRoomModal(false);
      handleCloseRoomModal();
      toast.success(response.data.message);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  console.log("rooms", roomsAndBeds);
  console.log("floor", floors);

  return (
    <div>
      <div className="preview-links mt-4">
        <ul
          className="nav nav-tabs"
          id="myTab"
          role="tablist"
          style={{ borderBottom: "none" }}
        >
          {floors.map((floor, index) => (
            <li
              className="nav-item"
              key={index}
              role="presentation"
              style={{ marginRight: "10px", marginBottom: "10px" }}
            >
              {console.log("floorId", floor)}
              <button
                className={`btn ${
                  activeFloor === floor ? "btn-success" : "btn-outline-success"
                }`}
                onClick={() => handleFloorClick(floor)}
                type="button"
                role="tab"
                aria-controls={`floor${index + 1}-tab-pane`}
                aria-selected={activeFloor === floor ? "true" : "false"}
                style={{ padding: "6px", width: "100px" }}
              >
                {floor.floor_name}
                <button
                  type="button"
                  className="btn text-center visit-btn-wound btn-map-wound"
                  onClick={() => handleEditClick(floor)}
                >
                  {" "}
                  <FontAwesomeIcon
                    style={{
                      borderRadius: "5px",
                      fontSize: "14px",
                      padding: "1px 3px 1px 3px",
                      backgroundColor: "#17a2b8",
                      color: "#fff",
                    }}
                    icon={faEdit}
                    className="fa-visit-icon"
                  />
                </button>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <button className="btn btn-primary mt-3 mb-3" onClick={toggleShowA}>
        Add Room
      </button>

      <Toast show={showA} onClose={toggleCloseA}>
        <Toast.Header>
          <strong className="me-auto">Room Title</strong>
        </Toast.Header>
        <Toast.Body>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              placeholder="Enter room name"
              value={newRoomName}
              onChange={(e) => setNewRoomName(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-primary ms-auto"
              onClick={handleAddRoomSubmit}
            >
              Add
            </button>
          </div>
        </Toast.Body>
      </Toast>

      {/* Modal for edit floor name */}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className="allery-header">
          <Modal.Title>Edit Floor Name</Modal.Title>
        </Modal.Header>
        <Modal.Body className="allergy-body">
          <Form>
            <Form.Group controlId="formFloorName">
              <Form.Label>Floor Name</Form.Label>
              <Form.Control
                type="text"
                value={floorName}
                onChange={(e) => setFloorName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for edit room name  */}

      <Modal show={showRoomModal} onHide={() => setShowRoomModal(false)}>
        <Modal.Header className="allery-header">
          {/* <Modal.Title>{editRoomId ? "Edit Room" : "Add Room"}</Modal.Title> */}
          <Modal.Title>Edit Room Name</Modal.Title>
        </Modal.Header>
        <Modal.Body className="allergy-body">
          <Form>
            <Form.Group controlId="roomName">
              <Form.Label>Room Name</Form.Label>
              {/* <Form.Control
                type="text"
                value={modalInputValue}
                onChange={(e) => setModalInputValue(e.target.value)}
              /> */}
              <Form.Control
                type="text"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRoomModal(false)}>
            Close
          </Button>
          {/* <Button
            variant="primary"
            type="button"
            onClick={editRoomId ? handleEditRoom : handleAddRoomSubmit}
          >
            {editRoomId ? "Edit Room" : "Add Room"}
          </Button> */}
          <Button variant="primary" type="button" onClick={handleEditRoom}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {roomsAndBeds.rooms &&
          roomsAndBeds.rooms.map((room, index) => (
            <div key={index}>
              {console.log("roomId", room)}
              <div className="floor-1-bg">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapseroom${index}`}
                        aria-expanded="false"
                        aria-controls={`collapseroom${index}`}
                      >
                        {room.room_name}
                        <button
                          type="button"
                          className="btn text-center visit-btn-wound btn-map-wound"
                          // onClick={() => {
                          //   setEditRoomId(room.id);
                          //   setModalInputValue(room.room_title);
                          //   setShowAddRoomModal(true);
                          // }}
                          onClick={() => handleRoomEditClick(room)}
                        >
                          <FontAwesomeIcon
                            style={{
                              borderRadius: "5px",
                              fontSize: "14px",
                              padding: "1px 3px 1px 3px",
                              backgroundColor: "#17a2b8",
                              color: "#fff",
                            }}
                            icon={faEdit}
                            className="fa-visit-icon"
                          />
                        </button>

                        <button
                          type="button"
                          className="btn text-center visit-btn-wound"
                          onClick={() => handleDeleteRoom(room.id)}
                        >
                          <FontAwesomeIcon
                            style={{
                              borderRadius: "5px",
                              fontSize: "14px",
                              padding: "1px 3px 1px 3px",
                              backgroundColor: "#ba2748",
                              color: "#fff",
                            }}
                            icon={faTimes}
                            className="fa-visit-icon"
                          />
                        </button>
                      </button>
                    </h2>
                    <div
                      id={`collapseroom${index}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setShowBedModel(true)}
                        >
                          Add Bed
                        </button>
                        <Modal show={showBedModal} onHide={handleCloseBedModal}>
                          <Modal.Header className="allery-header">
                            <Modal.Title>
                              {editBedId ? "Edit Bed" : "Add Bed"}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="allergy-body">
                            <Form>
                              <Form.Group controlId="bedName">
                                <Form.Label>Bed Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={modalInputValue}
                                  onChange={(e) =>
                                    setModalInputValue(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseBedModal}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              type="button"
                              onClick={
                                editBedId
                                  ? () => handleEditBed(room.id)
                                  : () => handleAddBed(room.id)
                              }
                            >
                              {editBedId ? "Edit Bed" : "Add Bed"}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <ul
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          {room &&
                            room.beds.map((bed, bedIndex) => (
                              <div key={bedIndex}>
                                {console.log("beds", bed)}
                                <div style={{ display: "flex" }}>
                                  <p
                                    style={{
                                      border: "1px solid #dee2e6",
                                      borderRadius: "5px",
                                      padding: "0.5rem",
                                      marginTop: "5px",
                                      backgroundColor: "#fff",
                                      color: "#828194",
                                      width: "100%",
                                      margin: "5px",
                                    }}
                                  >
                                    {bed.bed_title && bed.bed_title}
                                    <br />
                                  </p>
                                  <button
                                    type="button"
                                    className="btn  text-center "
                                    onClick={() => {
                                      setEditBedId(bed.id);
                                      setModalInputValue(bed.bed_title);
                                      setShowBedModel(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        borderRadius: "5px",
                                        fontSize: "14px",
                                        padding: "1px 3px 1px 3px",
                                        backgroundColor: "#17a2b8",
                                        color: "#fff",
                                      }}
                                      icon={faEdit}
                                      className="fa-visit-icon"
                                    />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn ms-auto text-center visit-btn-wound"
                                    onClick={() => handleDeleteBed(bed.id)}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        borderRadius: "5px",
                                        fontSize: "14px",
                                        padding: "1px 3px 1px 3px",
                                        backgroundColor: "#ba2748",
                                        color: "#fff",
                                      }}
                                      icon={faTimes}
                                      className="fa-visit-icon"
                                    />
                                  </button>
                                </div>
                              </div>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active h-100 overflow-auto"
          id="floor1-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex="0"
        ></div>
        <div
          className="tab-pane fade"
          id="floor2-tab-pane"
          role="tabpanel"
          aria-labelledby="profile-tab"
          tabIndex="0"
        >
          ...
        </div>
        <div
          className="tab-pane fade"
          id="floor3-tab-pane"
          role="tabpanel"
          aria-labelledby="contact-tab"
          tabIndex="0"
        >
          ...
        </div>
        <div
          className="tab-pane fade"
          id="floor4-tab-pane"
          role="tabpanel"
          aria-labelledby="contact-tab"
          tabIndex="0"
        >
          ...
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Preview;
