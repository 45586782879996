import React, { useState, useEffect } from "react";
import Card from "../../../../components/card/Card";
import "../PatientProfile.css";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

const Summary = ({ id }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [contacts, setContact] = useState([]);
  const [medications, setMedications] = useState([]);
  const [problems, setProblems] = useState([]);
  const [patient, setPatients] = useState({});
  const [allergies, setAllergies] = useState([]);
  const [encounters, setEncounters] = useState({});
  const [admission, setAdmission] = useState({});
  const [loading, setLoading] = useState({
    contacts: true,
    medications: true,
    problems: true,
    patient: true,
    allergies: true,
    encounters: true,
    admission: true,
  });

  const getData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const summary = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/patient-summary/${id}`,
        config
      );
      setContact(summary.data.contacts);
      setLoading((prev) => ({ ...prev, contacts: false }));
      setMedications(summary.data.medications);
      setLoading((prev) => ({ ...prev, medications: false }));
      setPatients(summary.data.patient);
      setLoading((prev) => ({ ...prev, patient: false }));
      setProblems(summary.data.problems);
      setLoading((prev) => ({ ...prev, problems: false }));
      setAllergies(summary.data.allergies);
      setLoading((prev) => ({ ...prev, allergies: false }));
      setEncounters(summary.data.encounters);
      setLoading((prev) => ({ ...prev, encounters: false }));
      setAdmission(summary.data.admissions);
      setLoading((prev) => ({ ...prev, admission: false }));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const cardsData = [

  //   {
  //     title: "Basic Information",
  //     loading: loading.patient,
  //     items: [
  //       { label: "Name : ", value: `${patient.title}  ${patient.first_name}`, link: "#" },
  //       { label: "Gender : ", value: patient.gender, link: "#" },
  //       { label: "DOB : ", value:  moment(patient.date_of_birth)
  //         // .utc()
  //         .format("MM/DD/YYYY"), link: "#" },
  //       { label: "Email : ", value: patient.email, link: "#" },
  //       { label: "Phone No : ", value: patient.phone_no, link: "#" },
  //     ],
  //   },

  //   {
  //     title: "Problems",
  //     loading: loading.problems,
  //     items: [
  //       ...problems.map((problem, index) => ({
  //         value: (
  //           <span style={{ display: "flex" }}>
  //             <h4>{problem.diagnosis}-</h4>{" "}
  //             <p style={{ fontSize: "11px" }}>{problem.name}</p>
  //           </span>
  //         ),
  //         link: "#",
  //       })),
  //       {
  //         // value: (
  //         //   <button style={{color:'blue',border:"none",background:'none'}}>View All</button>
  //         // ),
  //         link: "#",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Allergies",
  //     loading: loading.allergies,
  //     items:  allergies.map((allergy, index) => ({
  //       value: (
  //         <div style={{ display: "flex", justifyContent: "space-between",color:"red" }}>
  //           <h6 style={{color:"red"}}>-{allergy.allergy}</h6>
  //           <span style={{ fontStyle: "italic",color:"red" }}>
  //             {allergy.allergy_type.title}
  //           </span>
  //         </div>
  //       ),

  //       link: "#",
  //     })),
  //   },

  //   {
  //     title: "Medication",
  //     loading: loading.medications,
  //     items: medications.map((medication, index) => ({
  //       value: (
  //         <div style={{ display: "flex" }}>
  //           <h6>{medication.title && medication.title}-</h6>
  //           <span style={{ fontStyle: "italic" }}>
  //             {medication.dose && medication.dose}
  //           </span>
  //           <span>{medication.dosage_unit && medication.dosage_unit}</span>
  //           <span>{medication.quantity && medication.quantity}</span>
  //           <span>{medication.frequency && medication.frequency}</span>
  //         </div>
  //       ),

  //       link: "#",
  //     })),
  //   },
  //   {
  //     title: "Most Recent Encounter",
  //     loading: loading.encounters,
  //     items: [
  //       {
  //         label: "Date : ",
  //         value: encounters && encounters.encounter_date ? moment(encounters.encounter_date).format("MM/DD/YYYY hh:mm:ss A") : "",
  //         link: "#",
  //       },
  //       {
  //         label: "Type : ",
  //         value:
  //           encounters.encounter_type_title && encounters.encounter_type_title,
  //         link: "#",
  //       },
  //       {
  //         label: "Speciality : ",
  //         value: encounters.specialty_title && encounters.specialty_title,
  //         link: "#",
  //       },
  //       {
  //         label: "Reason : ",
  //         value: encounters.reason && encounters.reason,
  //         link: "#",
  //       },
  //       {
  //         label: "Location : ",
  //         value: encounters.location && encounters.location,
  //         link: "#",
  //       },
  //       {
  //         label: "Provider : ",
  //         value: encounters.provider_name && encounters.provider_name,
  //         link: "#",
  //       },
  //     ],
  //   },

  //   {
  //     title: "Admission History",
  //     loading: loading.admission,
  //     items: [
  //       {
  //         label: "Date : ",
  //         value: admission && admission.admission_date ? moment(admission.admission_date).format("MM/DD/YYYY hh:mm:ss A") : "",
  //         link: "#",
  //       },
  //       // {
  //       //   label: "Type : ",
  //       //   value:
  //       //     admission.admission_type &&  admission.admission_type,
  //       //   link: "#",
  //       // },
  //       {
  //         label: "Room No : ",
  //         value: admission.room_no && admission.room_no,
  //         link: "#",
  //       },
  //       {
  //         label: "Location : ",
  //         value: admission.location && admission.location,
  //         link: "#",
  //       },
  //       // {
  //       //   label: "Patient Type: ",
  //       //   value: admission.patient_type && admission.patient_type,
  //       //   link: "#",
  //       // },
  //       // {
  //       //   label: "Discharge Date : ",
  //       //   value:admission && admission.discharge_date ? moment(admission.discharge_date).format("MM/DD/YYYY hh:mm:ss A") : " ",
  //       //   link: "#",
  //       // },
  //       {
  //         // label:"Remaining Days : ",
  //         value:admission.admission_date_result && admission.admission_date_result.color ? (
  //           admission.admission_date_result.color === "green" ? (
  //             <p style={{ color: "green", fontWeight: "bolder", backgroundColor: "yellow", width: "5rem", paddingLeft: "5px" }}>
  //               {admission.admission_date_result.remaining_days}
  //             </p>
  //           ) : (
  //             <p style={{ color: "red", fontWeight: "bolder", backgroundColor: "yellow", width: "5rem", paddingLeft: "5px" }}>
  //               {admission.admission_date_result.exceeded_days}
  //             </p>
  //           )
  //         ) : (
  //           " "
  //         ),
  //       }
  //     ],
  //   },

  // ];
  const cardsData = [
    {
      title: "Basic Information",
      loading: loading.patient,
      items:
        Object.keys(patient).length > 0
          ? [
              {
                label: "Name : ",
                value: `${patient.title ? patient.title : ""}  ${
                  patient.first_name
                }`,
                link: "#",
              },
              { label: "Gender : ", value: patient.gender, link: "#" },
              {
                label: "DOB : ",
                value: moment(patient.date_of_birth).format("MM/DD/YYYY"),
                link: "#",
              },
              { label: "Email : ", value: patient.email, link: "#" },
              { label: "Phone No : ", value: patient.phone_no, link: "#" },
            ]
          : [{ value: "No data found" }],
    },
    {
      title: "Problems",
      loading: loading.problems,
      items:
        problems.length > 0
          ? problems.map((problem, index) => ({
              value: (
                <span style={{ display: "flex" }}>
                  <h4>{problem.diagnosis}-</h4>{" "}
                  <p style={{ fontSize: "11px" }}>{problem.name}</p>
                </span>
              ),
              link: "#",
            }))
          : [{ value: "No data found" }],
    },
    {
      title: "Allergies",
      loading: loading.allergies,
      items:
        allergies.length > 0
          ? allergies.map((allergy, index) => ({
              value: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "red",
                  }}
                >
                  <h6 style={{ color: "red" }}>-{allergy.allergy}</h6>
                  <span style={{ fontStyle: "italic", color: "red" }}>
                    {allergy.allergy_type.title}
                  </span>
                </div>
              ),
              link: "#",
            }))
          : [{ value: "No data found" }],
    },
    {
      title: "Medication",
      loading: loading.medications,
      items:
        medications.length > 0
          ? medications.map((medication, index) => ({
              value: (
                <div style={{ display: "flex" }}>
                  <h6>{medication.title && medication.title}-</h6>
                  <span style={{ fontStyle: "italic" }}>
                    {medication.dose && medication.dose}
                  </span>
                  <span>
                    {medication.dosage_unit && medication.dosage_unit}
                  </span>
                  <span>{medication.quantity && medication.quantity}</span>
                  <span>{medication.frequency && medication.frequency}</span>
                </div>
              ),
              link: "#",
            }))
          : [{ value: "No data found" }],
    },
    {
      title: "Most Recent Encounter",
      loading: loading.encounters,
      items:
        encounters && Object.keys(encounters).length > 0
          ? [
              {
                label: "Date : ",
                value: encounters.encounter_date
                  ? moment(encounters.encounter_date).format(
                      "MM/DD/YYYY hh:mm:ss A"
                    )
                  : "",
                link: "#",
              },
              {
                label: "Type : ",
                value: encounters.encounter_type_title,
                link: "#",
              },
              {
                label: "Speciality : ",
                value: encounters.specialty_title,
                link: "#",
              },
              {
                label: "Reason : ",
                value: encounters.reason,
                link: "#",
              },
              {
                label: "Location : ",
                value: encounters.location,
                link: "#",
              },
              {
                label: "Provider : ",
                value: encounters.provider_name,
                link: "#",
              },
            ]
          : [{ value: "No data found" }],
    },
    {
      title: "Admission History",
      loading: loading.admission,
      items:
        admission && Object.keys(admission).length > 0
          ? [
              {
                label: "Date : ",
                value: admission.admission_date
                  ? moment(admission.admission_date).format(
                      "MM/DD/YYYY hh:mm:ss A"
                    )
                  : "",
                link: "#",
              },
              {
                label: "Room No : ",
                value: admission.room_no,
                link: "#",
              },
              {
                label: "Location : ",
                value: admission.location,
                link: "#",
              },
              // {
              //   value: admission.admission_date_result && admission.admission_date_result.color ? (
              //     admission.admission_date_result.color === "green" ? (
              //       <p style={{ color: "green", fontWeight: "bolder", backgroundColor: "yellow", width: "5rem", paddingLeft: "5px" }}>
              //         {admission.admission_date_result.remaining_days}
              //       </p>
              //     ) : (
              //       <p style={{ color: "red", fontWeight: "bolder", backgroundColor: "yellow", width: "5rem", paddingLeft: "5px" }}>
              //         {admission.admission_date_result.exceeded_days}
              //       </p>
              //     )
              //   ) : (
              //     " "
              //   ),
              // }
            ]
          : [{ value: "No data found" }],
    },
  ];
  return (
    <div className="summary-container overflow-auto">
      <div className="d-flex flex-column h-100">
        <div className="problem-links">
          <h5>Demographics</h5>
        </div>
        <div className="active-bg-links flex-fill overflow-auto">
          <div className="row g-3">
            {cardsData.map((card, index) => (
              <div key={index} className="col-lg-6 col-xl-4">
                <Card
                  title={card.title}
                  items={card.items}
                  loading={card.loading}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
