import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";

const EditEncounter = ({ id, editEncounter, handleCloseEncounterClick }) => {
  const [saveLoading, setSaveLoading] = useState(false);
  console.log("edit", editEncounter);
  const formatDate = (dateTime) => {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [formData, setFormData] = useState({});
  const [formValues, setFormValues] = useState({
    encounter_id: editEncounter.id,
    signed_at: new Date(editEncounter.encounter_date),
    reason: editEncounter.reason,
    provider_id_patient: editEncounter.provider_name,
    specialty: editEncounter.specialty,
    encounter_type: editEncounter.encounter_type_title,
    location: editEncounter.location, // Assuming you have location in editEncounter
  });

  const getFormData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN_URL}/patient-encounter-information/${id}`,
        config
      );
      setFormData(response.data);
    } catch (error) {
      console.log("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    getFormData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleDateChange = (e) => {
    const { value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      signed_at: value, // Update with the date value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaveLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      // Format the date to a string if needed by the API
      const formattedDate =
        formValues.signed_at instanceof Date
          ? formValues.signed_at.toISOString() // Adjust the format if necessary
          : formValues.signed_at;

      const response = await axios.post(
        `${process.env.REACT_APP_ORIGIN_URL}/update-patient-encounter`,
        {
          ...formValues,
          signed_at: formattedDate, // Ensure signed_at is included in the payload
        },
        config
      );
      toast.success(response.data.message);
      console.log("Data updated successfully");
    } catch (error) {
      console.error("Error updating data:", error);
      toast.error(error.message);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <div>
      <div className="d-flex flex-column h-100">
        <div className="encounter-links mt-2">
          <div className="row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating">
                <FloatLabel>
                  <Calendar
                    id="signed_at"
                    value={formValues.signed_at}
                    onChange={handleDateChange}
                    dateFormat="mm/dd/yy"
                    showIcon
                  />

                  <label htmlFor="signed_at">Encounter Date Time</label>
                </FloatLabel>

                {/* <input
                    type="date"
                    className="form-control"
                    id="signed_at"
                    name="signed_at"
                    value={formValues.signed_at}
                    onChange={handleChange}
                  /> */}
              </div>
            </div>

            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="reason"
                  name="reason"
                  value={formValues.reason}
                  onChange={handleChange}
                  placeholder="Visit Reason"
                />
                <label htmlFor="reason">Visit Reason</label>
              </div>
            </div>

            <div className="col-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="provider_id_patient"
                  name="provider_id_patient"
                  value={formValues.provider_id_patient}
                  onChange={handleChange}
                >
                  {formData.provider &&
                    formData.provider.map((provider) => (
                      <option key={provider.id} value={provider.id}>
                        {provider.name}
                      </option>
                    ))}
                </select>
                <label htmlFor="provider_id_patient">Provider</label>
              </div>
            </div>

            <div className="col-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="specialty"
                  name="specialty"
                  value={formValues.specialty}
                  onChange={handleChange}
                  disabled={editEncounter}
                >
                  {formData.Specialty &&
                    formData.Specialty.map((specialty) => (
                      <option key={specialty.id} value={specialty.id}>
                        {specialty.title}
                      </option>
                    ))}
                </select>
                <label htmlFor="specialty">
                  Speciality{" "}
                  <span style={{ color: "red", fontSize: "13px" }}>*</span>
                </label>
              </div>
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="encounter_type"
                  name="encounter_type"
                  value={formValues.encounter_type}
                  onChange={handleChange}
                  disabled={editEncounter}
                >
                  {formData.encounter_type &&
                    formData.encounter_type.map((encounter) => (
                      <option key={encounter.id} value={encounter.id}>
                        {encounter.title}
                      </option>
                    ))}
                </select>
                <label htmlFor="encounter_type">
                  Encounter Type{" "}
                  <span style={{ color: "red", fontSize: "13px" }}>*</span>
                </label>
              </div>
            </div>

            {/* <div className="col-3">
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="location"
                    name="location"
                    value={formValues.location}
                    onChange={handleChange}
                  >
                    {formData.facilities &&
                      formData.facilities.map((facility) => (
                        <option key={facility.id} value={facility.id}>
                          {facility.address}
                        </option>
                      ))}
                  </select>
                  <label htmlFor="location">Location</label>
                </div>
              </div> */}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ marginRight: "5px" }}
              variant="secondary"
              onClick={handleCloseEncounterClick}
            >
              Cancel
            </Button>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              {saveLoading ? "Processing..." : "Update"}
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default EditEncounter;
