import React, { useState } from "react";
import Incidents from "./Incidents";
import MedicalIncident from "./MedicalIncident";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const IncidentTabs = ({ details }) => {
  const [showIncidents, setShowIncidents] = useState(false);
  const [showMedicalIncident, setShowMedicalIncident] = useState(false);

  const handleCloseWarningIncident = () => {
    setShowIncidents(false);
    setShowMedicalIncident(false);
  };

  return (
    <div>
      {showIncidents ? (
        <Incidents
          details={details}
          handleCloseWarningIncident={handleCloseWarningIncident}
        />
      ) : showMedicalIncident ? (
        <MedicalIncident
          details={details}
          handleCloseWarningIncident={handleCloseWarningIncident}
        />
      ) : (
        <div className="document-content d-flex align-items-center">
          <div className="avatar dropdown">
            <div
              className="admin-name ms-4 dropdown-toggle no-arrow-dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <a className="all-doc">All Incidents</a>
            </div>
          </div>
          <div className="ms-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowMedicalIncident(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />{" "}
              Medical Incident
            </button>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginLeft: "1rem" }}
              onClick={() => setShowIncidents(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />{" "}
              Warning Letter
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncidentTabs;
