import React, { useState, useEffect, useRef } from "react";
import "../PatientProfile.css";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { problemValidation } from "../../../../components/validation/RegistrationValidation";
import { Calendar } from "primereact/calendar";
import { FloatLabel } from "primereact/floatlabel";
import { useSelector } from "react-redux";

const getCurrentLocalDateTime = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60 * 1000);
  return localDate.toISOString().slice(0, 16);
};
const initialValue = {
  diagnosis: "",
  cd_description: "",
  chronicity_id: "",
  severity_id: "",
  type_id: "",
  status_id: "",
  onset: new Date(),
  comments: "",
};
const Problems = ({ id }) => {
  const permissions = useSelector(
    (state) => state.user.currentUser.data.acl.permissions
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingProblem, setLoadingProblem] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const inputRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [selectedProblem, setSelectedProblem] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: problemValidation,
    onSubmit: async (values, action) => {
      if (isEditing) {
        handleUpdate(); // If in edit mode, call update function
      } else {
        try {
          setSaveLoading(true);
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          const response = await axios.post(
            process.env.REACT_APP_ORIGIN_URL + `/add-problem`,
            {
              patient_id: id,
              diagnosis: values.diagnosis,
              name: values.cd_description,
              chronicity_id: values.chronicity_id,
              severity_id: values.severity_id,
              type_id: values.type_id,
              status_id: values.status_id,
              onset: values.onset,
              comments: values.comments,
            },
            config
          );
          toast.success(response.data.message);
          console.log(response);
          setFieldValue("diagnosis", ""); // Empty the diagnosis field
          setFieldValue("cd_description", ""); // Empty the name field
          setSelectedProblem(null);
          setSearchInput("");
          getProblems();
          setIsEditing(false);
          resetForm();
        } catch (error) {
          toast.error(error.message);
          console.log(error.message);
        } finally {
          setSaveLoading(false);
        }
        action.resetForm();
      }
    },
  });
  const getSearchResults = async (query) => {
    if (query.trim() === "") {
      setSearchResults([]);
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/search-code/${query}`,
        config
      );
      setSearchResults(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getProblems = async () => {
    try {
      setLoadingProblem(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/get-problem/${id}`,
        config
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoadingProblem(false);
    }
  };

  const handleProblemClick = (problem) => {
    console.log("clickproblem", problem);
    setSelectedProblem(problem);
    setIsEditing(true);
    // Populate the input fields with the selected problem's data
    setFieldValue("diagnosis", problem.diagnosis);
    setFieldValue("cd_description", problem.name);
    setFieldValue("chronicity_id", problem.chronicity_id);
    setFieldValue("severity_id", problem.severity_id);
    setFieldValue("type_id", problem.type_id);
    setFieldValue("status_id", problem.status_id);
    setFieldValue("onset", problem.onset);
    setFieldValue("comments", problem.comments);
    setSearchInput(problem.diagnosis);
  };

  const handleUpdate = async () => {
    try {
      setSaveLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/update-problem`,
        {
          problem_id: selectedProblem.id, // Assuming there's an ID for each problem
          diagnosis: values.diagnosis,
          name: values.cd_description,
          chronicity_id: values.chronicity_id,
          severity_id: values.severity_id,
          type_id: values.type_id,
          status_id: values.status_id,
          onset: values.onset,
          comments: values.comments,
        },
        config
      );
      if (response.data.success) {
        toast.success("Problem updated successfully");
      } else {
        toast.error("Failed to update problem");
      }
      // Reset the selected problem state and input fields after successful update
      setSelectedProblem(null);
      setFieldValue("diagnosis", "");
      setFieldValue("cd_description", "");
      setFieldValue("comments", ""); // Reset comments field
      setFieldValue("chronicity_id", "");
      setFieldValue("severity_id", "");
      setFieldValue("type_id", ""); // Reset type field
      setFieldValue("status_id", ""); // Reset status field
      setFieldValue("onset", "");
      setIsEditing(false);
      // Reset other input fields as needed
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    } finally {
      setSaveLoading(false);
    }
  };
  const getOptions = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + `/list-options`,
        config
      );
      setOptions(res.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (value) => {
    setSearchInput(value);
    getSearchResults(value);
  };
  const handleSearchResultClick = (result) => {
    setFieldValue("diagnosis", `${result.code}`);
    setFieldValue("cd_description", result.description);
    setSearchInput(`${result.code}`);
    // setSearchInput('');
    setSearchResults([]);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    }
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]);
  const handleReset = () => {
    if (isEditing) {
      setIsEditing(false);
      resetForm();
      setSearchInput("");
    } else {
      resetForm();
      setSearchInput("");
    }
  };
  useEffect(() => {
    getProblems();
    getOptions();
  }, []);
  console.log("options", options);
  console.log("res", data);
  return (
    <div className="problem-container">
      <div className="d-flex flex-column h-100">
        <div className="problem-links">
          <h3>Problems</h3>
        </div>
        <div className="active-bg-links flex-fill overflow-auto">
          <div className="row g-3">
            <div className="col-4">
              <div className="active-content">
                <h5>Problems Lists</h5>
                <div className="problem-anchors p-3">
                  <div className="problem-search-feild mb-4">
                    <div className="input-group input-search rounded ">
                      <input
                        type="search"
                        className="form-control rounded"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search-addon"
                      />
                    </div>
                  </div>
                  {loadingProblem ? (
                    <div className="loading-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    data &&
                    data.map((problem, index) => {
                      return (
                        <>
                          {console.log("ptoblem", problem)}
                          <ul
                            key={index}
                            onClick={() => handleProblemClick(problem)}
                            style={{ cursor: "pointer" }}
                          >
                            {/* <li style={{ display: "flex", padding: "5px" }}>
                              <h6>Name:&nbsp;</h6>
                              {problem.name}
                            </li> */}
                            <li style={{ display: "flex", padding: "5px" }}>
                              <span style={{ display: "flex" }}>
                                <h6>
                                  {problem.diagnosis} {problem.name}
                                </h6>
                              </span>
                            </li>
                            <li
                              style={{
                                display: "flex",
                                padding: "0px 5px 0px 5px",
                              }}
                            >
                              <h6>Chronicity:&nbsp;</h6>
                              {problem.chronicity.title}
                            </li>
                            <li
                              style={{
                                display: "flex",
                                padding: "0px 5px 0px 5px",
                              }}
                            >
                              <h6>Severity:&nbsp;</h6>
                              {problem.severity.title}
                            </li>
                            {/* <li
                              style={{
                                display: "flex",
                                padding: "0px 5px 0px 5px",
                              }}
                            >
                              <h6>Type:&nbsp;</h6>
                              {problem.type.title}
                            </li> */}
                            <li
                              style={{
                                display: "flex",
                                padding: "0px 5px 0px 5px",
                              }}
                            >
                              <h6>Status:&nbsp;</h6>
                              {problem.status.title}
                            </li>
                            {/* <li
                              style={{
                                display: "flex",
                                padding: "0px 5px 0px 5px",
                              }}
                            >
                              <h6>Comments:&nbsp;</h6>
                              {problem.comments}
                            </li> */}
                          </ul>
                          <hr></hr>
                        </>
                      );
                    })
                  )}

                  {/* Additional list items go here */}
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="problem-right-bg">
                <div className="d-flex  border-bottom mb-3  problems-bg-add">
                  <ul>
                    <li>
                      <h5>Add Problems</h5>
                    </li>
                  </ul>
                  {/* <div className="add-problem-link ms-auto">
                    <a>Add via speech</a>
                  </div> */}
                </div>
                <div className="p-3">
                  <div className="d-flex align-items-center mb-3">
                    <form className="form-diagnosis">
                      <div className="form-floating">
                        <input
                          type="text"
                          className={`form-control w-100 ${
                            errors.diagnosis && touched.diagnosis
                              ? "is-invalid"
                              : ""
                          }`}
                          id="diagnosis"
                          placeholder="diagnosis"
                          ref={inputRef}
                          value={searchInput}
                          // value={values.diagnosis}
                          name="diagnosis"
                          onChange={(e) => handleInputChange(e.target.value)}
                          disabled={isEditing}
                        />
                        <label htmlFor="diagnosis">
                          ICD-10{" "}
                          <span style={{ color: "red", fontSize: "13px" }}>
                            *
                          </span>
                        </label>
                      </div>
                      {errors.diagnosis && touched.diagnosis ? (
                        <p style={{ color: "red" }}>{errors.diagnosis}</p>
                      ) : null}
                      {loading ? (
                        <div className="loading-spinner">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        searchInput &&
                        searchResults.length > 0 && (
                          <ul className="search-diagnosis">
                            {searchResults.map((result) => (
                              <React.Fragment key={result.id}>
                                <li
                                  style={{ display: "flex", cursor: "pointer" }}
                                  onClick={() =>
                                    handleSearchResultClick(result)
                                  }
                                >
                                  {result.code} {result.description}
                                </li>
                              </React.Fragment>
                            ))}
                          </ul>
                        )
                      )}
                    </form>
                  </div>

                  <div className="form-floating">
                    <input
                      type="text"
                      className={`form-control w-100 ${
                        errors.cd_description && touched.cd_description
                          ? "is-invalid"
                          : ""
                      }`}
                      id="cd_description"
                      placeholder="cd_description"
                      value={values.cd_description}
                      name="cd_description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isEditing}
                    />
                    <label for="cd_description">
                      Name{" "}
                      <span style={{ color: "red", fontSize: "13px" }}>*</span>
                    </label>
                  </div>
                  {errors.cd_description && touched.cd_description ? (
                    <p style={{ color: "red" }}>{errors.cd_description}</p>
                  ) : null}
                  <div className="row g-3 mb-3 mt-1">
                    <div className="col-xl-4">
                      <div className="form-floating ">
                        <select
                          className="form-select"
                          id="type_id"
                          name="type_id"
                          aria-label="Floating label select example"
                          value={values.type_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">-Select-</option>

                          {optionsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            options.Type &&
                            options.Type.map((type, index) => (
                              <option key={type.id} value={type.id}>
                                {type.title}
                              </option>
                            ))
                          )}
                        </select>
                        <label for="type_id">Type</label>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="form-floating ">
                        <select
                          className="form-select"
                          id="chronicity_id"
                          name="chronicity_id"
                          aria-label="Floating label select example"
                          value={values.chronicity_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">-Select-</option>

                          {optionsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            options.Chronicity &&
                            options.Chronicity.map((chronicity, index) => (
                              <option key={chronicity.id} value={chronicity.id}>
                                {chronicity.title}
                              </option>
                            ))
                          )}
                        </select>

                        <label for="chronicity_id">Chronicity </label>
                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-floating">
                        <select
                          className="form-select"
                          id="severity_id"
                          name="severity_id"
                          aria-label="Floating label select example"
                          value={values.severity_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">-Select-</option>

                          {optionsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            options.Severity &&
                            options.Severity.map((severity, index) => (
                              <option key={severity.id} value={severity.id}>
                                {severity.title}
                              </option>
                            ))
                          )}
                        </select>
                        <label for="severity_id">Severity </label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-3 mt-1">
                    <div className="col-xl-4">
                      <div className="form-floating ">
                        <select
                          className="form-select"
                          id="status_id"
                          name="status_id"
                          aria-label="Floating label select example"
                          value={values.status_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="">-Select-</option>

                          {optionsLoading ? (
                            <option>Loading...</option>
                          ) : (
                            options.Status &&
                            options.Status.map((status, index) => (
                              <option key={status.id} value={status.id}>
                                {status.title}
                              </option>
                            ))
                          )}
                        </select>

                        <label for="status_id">Status </label>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="form-floating ">
                        <FloatLabel>
                          <Calendar
                            // type="datetime-local"
                            // className="form-control w-100"
                            // id="onset"
                            // placeholder="onset"
                            value={values.onset}
                            // name="onset"
                            onChange={(e) => setFieldValue("onset", e.value)}
                            onBlur={handleBlur}
                            dateFormat="mm/dd/yy"
                            showIcon
                            showTime
                            hourFormat="12"
                            hideOnDateTimeSelect="true"
                          />
                          <label htmlFor="onset">Onset</label>
                        </FloatLabel>
                      </div>
                    </div>
                  </div>
                  <div className="form-floating mb-5">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="comments"
                      name="comments"
                      style={{ height: "100px" }}
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    <label for="comments">Comments</label>
                  </div>
                  <div className="d-flex">
                    <button
                      type="button"
                      onClick={handleReset}
                      className="btn btn-outline-dark me-3"
                    >
                      Reset
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={handleSubmit}
                    >
                      {isEditing
                        ? saveLoading
                          ? "Processing..."
                          : "Update"
                        : saveLoading
                        ? "Processing..."
                        : "Save"}
                    </button>
                  </div>
                  {/* Additional forms and inputs go here */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Problems;
