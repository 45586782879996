import React, { useState, useEffect } from "react";
import "./MyProfile.css";
import axios from "axios";
import PatientDetail from "../../detail/PatientDetail";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function MyProfile() {
  const [details, setDetails] = useState([]);

  const getUserDetails = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const res = await axios.get(
        process.env.REACT_APP_ORIGIN_URL + "/login-user-details",
        config
      );
      setDetails(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  console.log("detailsmmmm", details);
  const handleInput = (e) => {
    const { id, value } = e.target;
    // Create a copy of the details object
    const updatedDetails = { ...details };

    // Update the nested property if it exists
    if (updatedDetails.details && id in updatedDetails.details) {
      updatedDetails.details[id] = value;
    } else {
      // If the nested property doesn't exist, update the top-level property
      updatedDetails[id] = value;
    }

    // Set the updated state
    setDetails(updatedDetails);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.post(
        process.env.REACT_APP_ORIGIN_URL + `/update-profile`,
        details,
        config
      );

      toast.success(response.data.message);
      getUserDetails();
    } catch (error) {
      toast.error(error.message);
      console.log("error", error.message);
    }
  };
  return (
    <div className=" settings-right flex-fill">
      <div className="tab-content h-100" id="v-pills-tabContent">
        {/* my profile tab start */}
        <div
          className="active fade  show tab-pane"
          id="v-pills-myprofile"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab"
        >
          <div className="myprofile-bg h-100 ">
            <h5 className="mb-3">My Profile</h5>
            <div className="row g-3  ">
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating">
                  <select
                    className="form-select w-100"
                    id="title"
                    name="title"
                    value={details && details.title}
                    onChange={handleInput}
                  >
                    <option value="">{details && details.title}</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                  </select>
                  <label htmlFor="title">Title</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    id="first_name"
                    placeholder="first name"
                    value={details.first_name}
                    onChange={handleInput}
                  />
                  <label htmlFor="first_name">First Name</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="text"
                    name="middle_name"
                    className="form-control"
                    id="middle_name"
                    placeholder="middle name"
                    value={details && details.middle_name}
                    onChange={handleInput}
                  />
                  <label htmlFor="middle_name">Middle Name</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="text"
                    name="last_name"
                    className="form-control"
                    id="last_name"
                    placeholder="last name"
                    value={details && details.last_name}
                    onChange={handleInput}
                  />
                  <label htmlFor="last_name">Last Name</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="text"
                    name="suffix"
                    class="form-control"
                    id="suffix"
                    placeholder="suffix"
                    value={details && details.suffix}
                    onChange={handleInput}
                  />
                  <label for="suffix">Suffix</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating">
                  <select
                    name="gender"
                    value={details && details.gender}
                    onChange={handleInput}
                    className="form-select w-100"
                    id="gender"
                  >
                    <option value="" selected>
                      {details && details.gender}
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  <label for="floatingSelectGrid">Gender</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value={details && details.email}
                    onChange={handleInput}
                  />
                  <label for="floatingInput">Email</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="date"
                    name="date_of_birth"
                    className="form-control"
                    id="date_of_birth"
                    placeholder="date of birth"
                    value={moment(details && details.date_of_birth)
                      .utc()
                      .format("YYYY-MM-DD")}
                    onChange={handleInput}
                  />
                  <label for="date_of_birth">Date of Birth</label>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <select
                    name="country"
                    value={details && details.country}
                    onChange={handleInput}
                    className="form-select w-100"
                    id="country"
                  >
                    <option value="" selected>
                      {details && details.country}
                    </option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                  </select>
                  <label for="country">Country</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control w-100"
                    id="city"
                    placeholder="city"
                    name="city"
                    value={details && details.city}
                    onChange={handleInput}
                  />
                  <label for="floatingSelectGrid">City</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating">
                  <div className="form-floating ">
                    <input
                      type="text"
                      name="ein"
                      className="form-control"
                      id="ein"
                      placeholder="ein"
                      value={details && details.ein}
                      onChange={handleInput}
                    />
                    <label for="ein">EIN</label>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="text"
                    name="zip_code"
                    className="form-control"
                    id="zip_code"
                    placeholder="zip code"
                    value={details && details.zip_code}
                    onChange={handleInput}
                  />
                  <label for="zip_code">Zip Code</label>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="text"
                    name="snn"
                    className="form-control"
                    id="snn"
                    placeholder="snn"
                    value={details && details.snn}
                    onChange={handleInput}
                  />
                  <label for="snn">SNN</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="number"
                    name="home_phone"
                    className="form-control"
                    id="home_phone"
                    placeholder="home phone"
                    value={details && details.home_phone}
                    onChange={handleInput}
                  />
                  <label for="home_phone">Home Phone</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating ">
                  <input
                    type="text"
                    name="npi"
                    className="form-control"
                    id="npi"
                    placeholder="npi"
                    value={details && details.npi}
                    onChange={handleInput}
                  />
                  <label for="npi">NPI</label>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control w-100"
                    id="tax_type"
                    placeholder="tax type"
                    name="tax_type"
                    value={details && details.tax_type}
                    onChange={handleInput}
                  />
                  <label for="tax_type">TAX TYPE</label>
                </div>
              </div>
            </div>
            {/* electric forms */}
            <div className="electric-forms">
              <h5 className="mb-3">Electronic Prescribing</h5>
              <div className="row g-3 ">
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-1">
                    <input
                      type="text"
                      name="epcs_status"
                      className="form-control"
                      id="epcs_status"
                      placeholder="epcs status"
                      value={details && details.epcs_status}
                      onChange={handleInput}
                    />
                    <label htmlFor="epcs_status">EPCS Status</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-1">
                    <input
                      type="text"
                      name="dea_number"
                      className="form-control"
                      id="dea_number"
                      placeholder="dea number"
                      value={details && details.dea_number}
                      onChange={handleInput}
                    />
                    <label htmlFor="dea_number">DEA Number</label>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="form-floating mb-1">
                    <input
                      type="text"
                      name="nadean"
                      className="form-control"
                      id="nadean"
                      placeholder="nadean"
                      value={details && details.nadean}
                      onChange={handleInput}
                    />
                    <label htmlFor="nadean">NADEAN</label>
                  </div>
                </div>
                {/* More electronic prescribing form inputs */}
              </div>
            </div>
            {/* electric buttons */}
            <div className="electric-btn">
              <div className="d-flex mt-3">
                <button type="button" className="btn btn-electric">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-success ms-3"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default MyProfile;
