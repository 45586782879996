import React, { useEffect, useState } from "react";
import "../PatientProfile.css";
import { Button } from "react-bootstrap";
import axios from "axios";

const ReviewSystem = ({
  sectionId,
  patientId,
  handleCloseReviewSystem,
  handleCloseReview,
  signed,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [updatedData, setUpdatedData] = useState({});

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setCheckboxes((prevCheckboxes) =>
      Object.keys(prevCheckboxes).reduce((acc, key) => {
        acc[key] = newSelectAll;
        return acc;
      }, {})
    );
  };

  const handleCheckboxChange = (name) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: !prevCheckboxes[name],
    }));
  };

  const handleTextareaChange = (slug, event) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [slug]: event.target.value,
    }));
  };

  const handleInitialData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_ORIGIN_URL}/get-details-review/${sectionId}/${patientId}`,
        config
      );
      const data = response.data.data;

      const initialCheckboxes = {};
      const initialUpdatedData = {};

      data.forEach((item) => {
        initialCheckboxes[item.slug] = false;
        initialUpdatedData[item.slug] = item.text || "";
      });

      setInitialData(data);
      setCheckboxes(initialCheckboxes);
      setUpdatedData(initialUpdatedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleInitialData();
  }, []);

  const handleUpdateData = async () => {
    try {
      setSaveLoading(true);
      const payload = {
        id: sectionId,
        ...updatedData,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_ORIGIN_URL}/update-details-review`,
        payload,
        config
      );
      handleCloseReview();
    } catch (error) {
      console.log("error", error);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <div className="visit-text-area h-100">
      <div className="chief-complaint mt-3">
        <div className="mb-2" style={{ display: "flex" }}>
          <h5>Review of System</h5>
          <div>
            <input
              style={{ marginLeft: "15px" }}
              className="form-check-input"
              type="checkbox"
              id="selectAll"
              onChange={handleSelectAllChange}
              checked={selectAll}
              disabled={signed}
            />
            <label className="form-check-label" style={{ marginLeft: "5px" }}>
              Select All
            </label>
          </div>
        </div>
        {initialData.map((item) => (
          <div key={item.slug}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <input
                style={{ marginLeft: "15px" }}
                className="form-check-input mt-3"
                type="checkbox"
                id={item.slug}
                name={item.slug}
                onChange={() => handleCheckboxChange(item.slug)}
                checked={checkboxes[item.slug]}
                disabled={signed}
              />
            </div>
            <div className="form-floating mt-1">
              <textarea
                className="form-control"
                name={item.slug}
                value={updatedData[item.slug]}
                onChange={(event) => handleTextareaChange(item.slug, event)}
                disabled={!checkboxes[item.slug] || signed}
              ></textarea>
              <label>{item.title}</label>
            </div>
          </div>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            background: "#ecf5ff",
            padding: "20px",
          }}
        >
          <Button
            type="button"
            variant="secondary"
            style={{ marginRight: "5px" }}
            onClick={handleCloseReviewSystem || handleCloseReview}
          >
            Cancel
          </Button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpdateData}
            disabled={signed}
          >
            {saveLoading ? "Processing..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewSystem;
